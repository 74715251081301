import cn from "classnames";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "../../../Common/Components/Avatar";
import { ReactComponent as ArrowDown } from "../../../Common/images/ArrowDown.svg";
import { profileTabs } from "../../../constants/profile";

import Profile from "../../../stores/profile";
import User from "../../../stores/user";
import ViolationStore from "../../../stores/violations";
import logout from "../../../utils/logout";
import "./index.scss";
import splitName from "../../../utils/splitName";
import NotificationsStore from "../../../Project/stores/app/NotificationsStore";
import useOnClickOutside from "../../../Project/hooks/useOnClickOutside";

const ProfileLink = () => {
  const { hasNew } = NotificationsStore;
  const {
    firstName,
    lastName,
    setImageSrc,
    hasPhoto,
    imageSrc,
    currentPoints,
  } = User;
  const [open, setOpen] = useState(false);
  let profile = "";

  const { newMessageInViolation } = ViolationStore;

  React.useEffect(() => {
    setImageSrc();
  }, [hasPhoto, setImageSrc]);

  // display the shortes name if lastName length too big;
  if (firstName && lastName) {
    if (lastName?.length > 10) {
      if (firstName?.length > 10) {
        profile = firstName[0] + " " + lastName[0];
      } else {
        profile = firstName + " " + lastName[0] + ".";
      }
    } else {
      profile = lastName + " " + firstName[0] + ".";
    }
  }

  const switchOpen = () => {
    setOpen((open) => !open);
  };

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  return (
    <div
      ref={ref}
      className={`profile-link ${open ? "active" : ""}`}
      onClick={switchOpen}
    >
      <Avatar
        imageSrc={imageSrc}
        altText={splitName(firstName, lastName)}
        notifications={hasNew && !open}
      />
      <div className="profile-link__user-data">
        <div className="profile-link__username">{profile ?? ""}</div>
        {typeof currentPoints === "number" && (
          <div className="profile-link__points">
            {currentPoints}
            <span className="profile-link__points--measure"> Б.</span>
          </div>
        )}
      </div>

      <button className={`button-icon ${open ? "active" : ""}`}>
        <ArrowDown />
      </button>
      <div className={`profile-link__menu ${open ? "active" : ""}`}>
        {profileTabs
          .filter((link) => link.value !== "settings")
          .map((link) => {
            const addNotificationsIcon =
              link.content === "Уведомления" && hasNew
                ? "link__notification"
                : "";

            const addNewMessageIcon =
              link.content === "Мои сообщения" && newMessageInViolation
                ? "link__new-message"
                : "";

            return (
              <Link
                key={link.value}
                to={link.path}
                onClick={() => Profile.setTab(link.value)}
                className={cn(addNotificationsIcon, addNewMessageIcon)}
              >
                {link.content}
              </Link>
            );
          })}
        <Link to="/" onClick={logout}>
          Выход
        </Link>
      </div>
    </div>
  );
};

export default observer(ProfileLink);
