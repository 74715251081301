import "./styles.scss";

import { observer } from "mobx-react";
import React from "react";

import { IPageHeaderProps } from "./types";

const PageHeader: React.FC<IPageHeaderProps> = ({ children }) => {
  return <h2 id="PageHeader">{children}</h2>;
};

export default observer(PageHeader);
