import React from "react";
import { IProductCardProps } from "./types";
import "./styles.scss";
import Button from "../Button";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../api/api";
import DummyShopPhoto from "../DummyShopPhoto";
import { URL_SHOP_ID } from "../../routes/paths";

const ProductCard: React.FC<IProductCardProps> = ({
  product,
  isCanBuy = false,
  onClick = () => {},
}) => {
  const { id, title, imageId, price, croppedImageId } = product;
  const history = useHistory();

  const pushHandler = () => history.push(URL_SHOP_ID(id!));

  return (
    <div className="productCard">
      {imageId ? (
        <img
          src={
            croppedImageId
              ? BASE_URL + "shop/api/products/croppedImage/" + croppedImageId
              : BASE_URL + "shop/api/products/image/" + imageId
          }
          className="productCard__img"
          alt="Фотография товара"
          onClick={pushHandler}
        />
      ) : (
        <div className="productCard__non-photo">
          <DummyShopPhoto onClick={pushHandler} />
        </div>
      )}
      <div className="productCard__info">
        <p className="productCard__title" onClick={pushHandler}>
          {title}
        </p>
        <span className="productCard__price">{price} баллов</span>
        {/* <Button
          disabled={!isCanBuy}
          type={isCanBuy ? "primary" : "disabled"}
          onClick={() => onClick(product)}
        >
          {isCanBuy ? "Списать баллы" : "Недостаточно баллов"}
        </Button> */}
      </div>
    </div>
  );
};

export default ProductCard;
