import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import Button from "../../../Common/Components/Button";
import Violation from "../../Pages/Violation";

import chat from "../../../stores/chat";

const ViolationPopup = () => {
  const { switchOpen } = chat;

  const { id } = useParams();

  return (
    <div className="MyStatements__popup">
      <Button
        title="Открыть чат"
        type={`${
          localStorage.getItem(id) !== null ? "button_secondary" : "primary"
        }`}
        onClick={switchOpen}
      />
      <Violation openChat={switchOpen} />
    </div>
  );
};

export default observer(ViolationPopup);
