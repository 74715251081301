import moment from "moment";
import getDateAndTime from "../../utils/getDateAndTime";

const getDay = (date: string) => {
  if (moment().isSame(moment(date), "day")) {
    return { date: "Сегодня", items: [] };
  } else if (moment().add(-1, "days").isSame(moment(date), "day")) {
    return { date: "Вчера", items: [] };
  } else {
    return {
      date: getDateAndTime(date).dm,
      items: [],
    };
  }
};

const listDividingByDates = (items: any[], dateKey: string) => {
  const result: any = [];

  items.forEach((item, i) => {
    if (i !== 0) {
      if (!moment(item[dateKey]).isSame(moment(items[i - 1][dateKey]), "day")) {
        result.push(getDay(item[dateKey]));
      }
      result[result.length - 1].items.push(item);
    } else {
      result.push(getDay(item[dateKey]));
      result[0].items.push(item);
    }
  });

  return result;
};

export default listDividingByDates;
