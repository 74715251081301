import React, { useEffect, useRef } from "react";
import "./index.scss";
import { ReactComponent as ChatIcon } from "../../../Common/images/chat.svg";
import ChatHeader from "../../../Common/Components/ChatHeader";
import ChatFooter from "../../../Common/Components/ChatFooter";
import ChatMain from "../../../Common/Components/ChatMain";
import { observer } from "mobx-react";

import Chat from "../../../stores/chat";
import ViolationsStore from "../../../stores/violations";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const SupportChat = () => {
  const { open, newMessages, makeChatRead, switchOpen, resetNotification } =
    Chat;

  const { violation } = ViolationsStore;
  const { id, hasNewChatMessages } = violation;

  const ref = useRef(null);

  useEffect(() => {
    const chatRef = ref.current;
    const isMobile = window.innerWidth < 640;

    if (open) {
      resetNotification();
    }

    if (isMobile && open && chatRef) {
      disableBodyScroll(chatRef, {
        allowTouchMove: (el) => el.className === "chat__main",
      });
    } else {
      enableBodyScroll(chatRef);
    }
  }, [open, resetNotification]);

  useEffect(() => {
    if (hasNewChatMessages) switchOpen();
  }, [hasNewChatMessages, switchOpen]);

  const openChat = () => switchOpen();

  const closeChat = () => makeChatRead(id);

  return (
    <div className={`SupportChat ${open ? "active" : ""}`}>
      <div className="chat" ref={ref}>
        <ChatHeader closeChat={closeChat} />
        <ChatMain />
        <ChatFooter />
      </div>
      <div className="SupportChat__open" onClick={openChat}>
        <button
          className={`button-icon ${
            hasNewChatMessages || newMessages
              ? "button_secondary"
              : "button_primary"
          } SupportChat__btn`}
        >
          <ChatIcon />
        </button>
      </div>
    </div>
  );
};

export default observer(SupportChat);
