import "./styles.scss";

import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";

import { INavigationMenuProps } from "./types";
import classNames from "classnames";

const NavigationMenu: React.FC<INavigationMenuProps> = ({
  links,
  currentPath,
}) => {
  return (
    <div className="NavigationMenu" data-testid="NavigationMenu">
      {links.map((item, idx) => (
        <Link
          key={idx}
          to={item.path}
          className={classNames("NavigationMenu__link", {
            "NavigationMenu__link--active": currentPath.includes(item.path),
          })}
        >
          {item.content}
        </Link>
      ))}
    </div>
  );
};

export default observer(NavigationMenu);
