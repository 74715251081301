import React from "react";
import login from "../../../utils/login";
import Button from "../Button";

import "./index.scss";

const AuthWarning = ({ warning }) => {
  return (
    <div className="AuthWarning">
      <h1>{warning}</h1>
      <Button type="primary" title="Войти" onClick={login} />
    </div>
  );
};

export default AuthWarning;
