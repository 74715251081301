import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";

import votesStore from "../../../stores/votes";
import normalizeDate from "../../../utils/normalizeDate";

import "./index.scss";

const CompletedVote = inject("history")(
  observer((props) => {
    const { history } = props;
    const { pathname } = history.location;
    const path = pathname.split("/");
    const [, , id] = path;

    const { fetchComletedVote, completedVote, userAnswers } = votesStore;

    const {
      title,
      endDateTime,
      startDateTime,
      description,
      votersNumber,
      questions,
      isDigitalResult,
      isActive,
      isDone,
    } = completedVote;

    const pastNormalizeDate = startDateTime && normalizeDate(startDateTime);
    const futureNormalizedDate = endDateTime && normalizeDate(endDateTime);

    const futureDate = Date.parse(endDateTime);
    const now = new Date().getTime();
    const pastDate = Date.parse(startDateTime);
    const delta = futureDate - pastDate;
    const passedTime = now - pastDate >= 0 ? now - pastDate : 0;
    const resPercent = Math.floor((passedTime * 100) / delta);

    useEffect(() => {
      fetchComletedVote(id);
    }, [fetchComletedVote, id]);

    const myAnswersList = [];
    for (let key in userAnswers) {
      myAnswersList.push(...(userAnswers[key]?.answerIds || ""));
      if (userAnswers[key]?.answer) {
        myAnswersList.push(userAnswers[key]?.questionId);
      }
    }

    return (
      <div className="completed-vote block">
        {isActive === false && (
          <div className="item__status finished">
            <span className="item__status-text">Завершено</span>
          </div>
        )}
        {isDone && userAnswers?.length > 0 && (
          <div className="item__status finished">
            <span className="item__status-text">Ваш ответ принят</span>
          </div>
        )}
        <div className="header">
          <h1>{title && title}</h1>
        </div>
        <div className="tile-date">
          <div
            className={
              resPercent > 75
                ? "line close"
                : 50 < resPercent
                ? "line middle"
                : "line green"
            }
            style={{
              width: `${
                resPercent >= 100 ? 100 : !resPercent ? 0 : resPercent
              }%`,
            }}
          />
          <div className="default-line" />
          <span className="tile-date-start">{pastNormalizeDate}</span>
          <span className="tile-date-end">{futureNormalizedDate}</span>
        </div>
        <div className="description">
          <p>{description && description}</p>
        </div>
        <div className="header">
          <h4>Всего проголосовало</h4>
        </div>
        <div className="votersnumbers">
          <span className="numbers">{votersNumber && votersNumber} </span>
          <span>чел.</span>
        </div>
        <div className="header">
          <h2 className="header-answers">Ответы участников</h2>
        </div>
        {questions &&
          questions.map((item, idx) => {
            const itemVoters = item.voters;
            const TextFieldDelta =
              item.type === "TextField" &&
              Math.floor((item.voters / itemVoters) * 100);

            let isAnsweredFreeQuestion = false;
            for (const answer of myAnswersList) {
              if (item.type === "TextField" && answer === item.id) {
                isAnsweredFreeQuestion = true;
              }
            }

            return (
              <div key={idx} className="answer-item">
                <div className="header">
                  <div className="number">
                    <span>{idx + 1}. </span>
                  </div>
                  <h3 className="question">{item.text}</h3>
                  {item.type === "Radiobutton" && (
                    <span className="variant">Один вариант ответа</span>
                  )}
                  {item.type === "Checkbox" && (
                    <span className="variant">
                      Возможен выбор нескольких вариантов
                    </span>
                  )}
                </div>
                {item.answers &&
                  item.answers.map((it, i) => {
                    const delta_ = Math.floor((it.voters / itemVoters) * 100);
                    const width = delta_ > 100 ? 100 : !!delta_ ? delta_ : 0;
                    let isAnswered = false;
                    for (const answer of myAnswersList) {
                      if (
                        answer === it.id ||
                        (it.isOtherOption && answer === item.id)
                      ) {
                        isAnswered = true;
                      }
                    }

                    return (
                      <div key={i}>
                        <span className="answer-text">{it.text}</span>
                        {it.isOtherOption && (
                          <span className="answer-text">Свой вариант</span>
                        )}
                        {item.type !== "TextField" && (
                          <span className="answer-voters">
                            {it.voters} {isDigitalResult ? "чел." : "%"}
                          </span>
                        )}
                        {item.type !== "TextField" && (
                          <div
                            className={`line ${
                              isAnswered ? "user-chooze" : ""
                            }`}
                            style={{
                              width: `${width}%`,
                            }}
                          ></div>
                        )}
                      </div>
                    );
                  })}
                {item.type === "TextField" && (
                  <>
                    <span className="answer-voters">
                      {item.voters} {isDigitalResult ? "чел." : "%"}
                    </span>
                    <div
                      className={`line ${
                        isAnsweredFreeQuestion ? "user-chooze" : ""
                      }`}
                      style={{
                        width: `${!TextFieldDelta ? 0 : TextFieldDelta}%`,
                      }}
                    ></div>
                  </>
                )}
              </div>
            );
          })}
      </div>
    );
  })
);

export default CompletedVote;
