import * as api from "./openapi";
import { axiosInstance, config } from "..";
import { BASE_URL } from "../../constants/api";

const URL_API_SHOP = BASE_URL + "shop/api";

export const SHOP = {
  Products: new api.ProductsApi(config, URL_API_SHOP, axiosInstance),
  Categories: new api.CategoriesApi(config, URL_API_SHOP, axiosInstance),
  ProjectSettings: new api.ProjectSettingsApi(
    config,
    URL_API_SHOP,
    axiosInstance
  ),
  Purchases: new api.PurchasesApi(config, URL_API_SHOP, axiosInstance),
  Points: new api.PointsApi(config, URL_API_SHOP, axiosInstance),
};
