import { observer } from "mobx-react";
import React, { useState } from "react";

import Textarea from "../../../Common/Components/Textarea";
import Button from "../../../Common/Components/Button";

import aboutStore from "../../../stores/about";
import User from "../../../stores/user";

import "./index.scss";

const FaqForm = observer(() => {
  const { sendForm, onFieldChange, faq, decline } = aboutStore;

  const [disabled, changeDisable] = useState(true);
  const [isShowForm, setIsShowForm] = useState(false);

  const { isAuth } = User;

  return isShowForm ? (
    <div className="about-form">
      <div className="about-form__header">
        <h3 className="about-form__header-title">Новый вопрос</h3>
        <button
          className="about-form__header-button"
          onClick={() => {
            setIsShowForm(false);
          }}
        />
      </div>
      <div className="form">
        <span>Вопрос</span>
        <Textarea
          onChange={onFieldChange}
          value={faq}
          changeDisable={changeDisable}
          decline={decline}
        />
        <div
          className={`accept button ${disabled}`}
          disabled={disabled || !isAuth}
          onClick={() => {
            if (isAuth) {
              sendForm().finally(() => {
                changeDisable(true);
                setIsShowForm(false);
              });
            }
          }}
        >
          <span>{isAuth ? "Отправить" : "Неавторизован"}</span>
        </div>
        <div
          className="decline button"
          onClick={() => {
            changeDisable(true);
            decline();
            setIsShowForm(false);
          }}
        >
          <span>Отменить</span>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="about-form__button"
      onClick={() => {
        setIsShowForm(true);
      }}
    >
      <Button type="primary" title="Задать вопрос" />
    </div>
  );
});

export default FaqForm;
