import React, { useState } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { ReactComponent as CheckGreenIcon } from "../../assets/icons/check-green.svg";
import { ReactComponent as CheckGreyIcon } from "../../assets/icons/check-grey.svg";

import { ISwithersProps } from "./types";
import "./styles.scss";

const Switchers: React.FC<ISwithersProps> = ({
  list = [],
  currentType: selectedType,
}) => {
  const [currentType, setCurrentType] = useState(
    selectedType ? selectedType : "all"
  );

  return (
    <div className="switchers">
      <span className="switchers__title">Показать на странице</span>
      <div className="switchers__list">
        {list.map((item, idx) => {
          const { text, type, onChange } = item;
          const isCurrentTab = currentType === type;
          return (
            <button
              key={idx}
              className={cn(
                "switchers__link",
                isCurrentTab && "switchers__link-active"
              )}
              onClick={() => {
                onChange(type);
                setCurrentType(type);
              }}
            >
              {isCurrentTab ? (
                <CheckGreenIcon className="switchers__arrow" />
              ) : (
                <CheckGreyIcon className="switchers__arrow" />
              )}
              {text}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default observer(Switchers);
