import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useState } from "react";
import votesStore from "../../../stores/votes";
import CustomField from "../CustomField";
import VoteRadiobuttons from "./VoteRadiobuttons";
import VoteRadiobuttonsText from "./VoteRadiobuttonsText";

const RadioButtonVoteComponent = observer((props) => {
  const radiobuttonCLick = (key, id) => {
    votesStore.radiobuttonHandleChange(key, id);
    votesStore.declineCustomValue(id);
    changeVisibility(false);
  };

  const [hidden, changeVisibility] = useState(false);

  const { item } = props;

  const answersHasImage = item?.answers?.some((i) => i?.filesInfo.length !== 0);
  return (
    <>
      <span className="hints">Выберите один вариант </span>
      {answersHasImage ? (
        <VoteRadiobuttons
          options={item.answers || []}
          name={item.id}
          click={radiobuttonCLick}
          id={item.id}
        />
      ) : (
        <VoteRadiobuttonsText
          options={item.answers || []}
          name={item.id}
          click={radiobuttonCLick}
          id={item.id}
        />
      )}

      {item.hasOtherOption && (
        <CustomField
          acceptCustomValue={(value) =>
            votesStore.acceptCustomValue(item.id, value)
          }
          id={item.id}
          onChange={(value) => votesStore.onCustomChange(item.id, value)}
          hidden={hidden}
          onClick={changeVisibility}
          type={item.type}
          decline={() => votesStore.declineCustomValue(item.id)}
          customOpen={votesStore.customOpen}
          value={votesStore.customValue[item.id]}
        />
      )}
    </>
  );
});

export default RadioButtonVoteComponent;
