import React, { useState } from "react";
import { observer } from "mobx-react";
import AnswerComponent from "../AnswerComponent";
import aboutStore from "../../../stores/about";
import cn from "classnames";

const AboutQuestionItem = (props) => {
  const { elem } = props;
  const [shown, changeShown] = useState(false);
  const [answer, setAnswer] = useState(undefined);

  const getAnswer = (id) => {
    aboutStore.getAnswer(id).then(({ data }) => {
      setAnswer(data);
    });
  };

  const onClick = () => {
    shown === false ? changeShown(elem.id) : changeShown(false);
  };

  return (
    <div
      className={cn(
        "question-wrapper",
        answer && answer.id === shown && "question-wrapper--active"
      )}
      onClick={onClick}
    >
      <div
        className="questions__item"
        onClick={() => {
          getAnswer(elem.id);
        }}
      >
        <p className="questions__item-title">{elem.value}</p>
      </div>
      <AnswerComponent answer={answer} />
    </div>
  );
};

export default observer(AboutQuestionItem);
