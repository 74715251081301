import React, { useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import "./style.scss";
import { AISAE } from "../../../../constants/api";
import FileViewer from "../../FileViewer";
import VoteFiles from "../../../Pages/Vote/voteFiles";

const VoteRadiobuttons = (props) => {
  const { options, name, click, id } = props;

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [currentFileId, setFileCurrentId] = useState("");

  const multiMedia = {};

  options.forEach((item, idx) => {
    multiMedia[idx] = [];
    item.filesInfo.forEach((i) => {
      const type = i.name.split(".")[i.name.split(".").length - 1];
      if (type === "jpg" || type === "png" || type === "jpeg") {
        multiMedia[idx].push({
          type: "image",
          reference: i.id,
        });
      }
    });
  });

  const notMultimedia = {};

  options.forEach((item, idx) => {
    notMultimedia[idx] = [];
    item.filesInfo.forEach((i) => {
      const type = i.name.split(".")[i.name.split(".").length - 1];
      if (
        type !== "jpg" &&
        type !== "png" &&
        type !== "jpeg" &&
        //TODO: костыль для ограничения файлов на фронте
        notMultimedia[idx].length < 2 &&
        notMultimedia[idx].length === 0
      ) {
        notMultimedia[idx].push(i);
      }
    });
  });

  return (
    <div className="answers">
      {options &&
        toJS(options).map((item, idx) => (
          <div className="answer" key={idx}>
            {
              <div
                className="answer__image"
                style={{
                  backgroundImage:
                    multiMedia[idx] &&
                    multiMedia[idx]?.length !== 0 &&
                    `url(${AISAE}api/votesfiles/answers/files/${multiMedia[idx][0].reference})`,
                }}
                onClick={() => {
                  setIsShowPopup(true);
                  setFileCurrentId(idx);
                }}
              >
                {multiMedia[idx].length > 1 && (
                  <span className="imageScaleButton__number">
                    {multiMedia[idx].length}
                  </span>
                )}
                {multiMedia[idx] && multiMedia[idx]?.length !== 0 && (
                  <button className="imageScaleButton"></button>
                )}
              </div>
            }
            <div className="answer__body">
              <div className="files">
                {notMultimedia[idx] &&
                  notMultimedia[idx]?.length !== 0 &&
                  notMultimedia[idx].map((i, indx) => {
                    return (
                      <VoteFiles
                        key={indx}
                        type={i.type}
                        reference={i.id}
                        name={i.name}
                        size={i.size}
                        section={"answer"}
                      />
                    );
                  })}
              </div>
              <label className="answer__body-label">
                <div
                  className={`answer__text ${
                    item.checked ? "answer__text--active" : ""
                  }`}
                >
                  {item.text}
                </div>
                <input
                  value={item.text}
                  type="radio"
                  checked={item.checked && item.checked}
                  name={name}
                  onChange={() => {
                    click(idx, id);
                  }}
                />
                <div
                  className={`answer__button ${
                    item.checked ? "answer__button--active" : ""
                  }`}
                ></div>
              </label>
            </div>
          </div>
        ))}

      {isShowPopup && (
        <FileViewer
          files={multiMedia[currentFileId]}
          startSlide={0}
          setShowFiles={() => {
            setIsShowPopup(false);
          }}
          url={`${AISAE}api/votesfiles/answers/files/`}
        />
      )}
    </div>
  );
};

export default observer(VoteRadiobuttons);
