import React from "react";

const IconLinkBack = () => {
  return (
    <svg
      width="24"
      height="12"
      viewBox="0 0 24 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.274981 5.31498L0.275824 5.31406L5.17445 0.281803C5.54143 -0.0951806 6.13501 -0.0937777 6.50031 0.285093C6.86556 0.663916 6.86415 1.27664 6.49716 1.65367L3.20823 5.03226H23.0625C23.5803 5.03226 24 5.46552 24 6C24 6.53448 23.5803 6.96774 23.0625 6.96774H3.20828L6.49712 10.3463C6.8641 10.7234 6.86551 11.3361 6.50026 11.7149C6.13496 12.0938 5.54134 12.0951 5.1744 11.7182L0.275776 6.68594L0.274933 6.68502C-0.0922385 6.30673 -0.0910658 5.69202 0.274981 5.31498Z"
        fill="#55BB31"
      />
    </svg>
  );
};

export default IconLinkBack;
