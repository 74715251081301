import React from "react";
import "./index.scss";
import { observer } from "mobx-react";

const InputWithIcon = ({
  value,
  onChange,
  placeholder,
  icon,
  success = false,
  disabled = false,
  onFocus = () => {},
  onBlur = () => {},
  isReadOnly = false,
}) => {
  const onChangeHandler = (e) => {
    onChange(e.target.value);
  };

  return (
    <div
      className={`InputWithIcon ${disabled ? "disabled" : ""} ${
        !value && "InputWithIcon__isEmpty"
      }`}
    >
      <input
        readOnly={isReadOnly && "readonly"}
        className={success ? "success" : ""}
        onFocus={onFocus}
        onBlur={onBlur}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChangeHandler}
        disabled={disabled}
      />
      {icon}
    </div>
  );
};

export default observer(InputWithIcon);
