import React from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import "./index.scss";

const Radiobuttons = observer((props) => {
  const { options, name, click, id } = props;

  return (
    <div className="radiobuttons-wrapper">
      {options &&
        toJS(options).map((item, idx) => {
          return (
            <label className="container" key={idx}>
              {item.text}
              <input
                value={item.text}
                type="radio"
                checked={item.checked && item.checked}
                name={name}
                onChange={() => {
                  click(idx, id);
                }}
              />
              <span className={`checkmark ${item.checked}`}></span>
            </label>
          );
        })}
    </div>
  );
});

export default Radiobuttons;
