/** Прод Сервер */
// export const BASE_URL = "https://активный.екатеринбург.рф/";

/** Тестовый сервер */
export const BASE_URL = "https://ae.aiwoo.info/";
// export const BASE_URL = "http://localhost:8999/";

export const AUTH = BASE_URL + "auth/";
export const AISAE = BASE_URL + "aisae/";
export const IMAGE_URL = BASE_URL + "news/api";
export const NEWS_ITEM_IMAGE_URL = BASE_URL + "news/api/news/";
export const NEWS_FILE_API = BASE_URL + "news/api/news/file/";
export const NEWS_PHOTO_API = BASE_URL + "news/api/news/photo/";
export const IDP = BASE_URL + "idp/";

export const AUTH_URL = "https://ae.aiwoo.info/";
// export const AUTH_URL = "http://localhost:8180/";
export const SETTINGS = AUTH_URL + "auth/realms/AISAE/account/";
