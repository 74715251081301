import React from "react";
import ReactPlayer from "react-player/lazy";

const VideoPLayer = (props) => {
  const { url } = props;

  return (
    <div>
      <ReactPlayer url={url} />
    </div>
  );
};

export default VideoPLayer;
