import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import ErrorPicture from "../ErrorPicture";
import getRequestsDescription from "../../utils/getRequestsDescription";
import Button from "../Button";
import "./index.scss";

const ErrorComponent: FC<{ errorStatus: number }> = ({ errorStatus }) => {
  const history = useHistory();

  return (
    <div className="error">
      <ErrorPicture status={errorStatus} />
      <div className="error__status">
        <div className="error__header">Ошибка {errorStatus}</div>
        <div className="error__description">
          {getRequestsDescription(errorStatus)}
        </div>
        <Button type="sub" className="error__back" onClick={history.goBack}>
          Назад
        </Button>
      </div>
    </div>
  );
};

export default ErrorComponent;
