import "./index.scss";

import { observer } from "mobx-react";
import React, { useEffect } from "react";

import Button from "../../../Common/Components/Button";
import Navigation from "../../../Project/components/Navigation";
import MenuLinksStore from "../../../Project/stores/app/NavBarLinksStore";
import User from "../../../stores/user";
import login from "../../../utils/login";
import Logo from "../Logo";
import ProfileLink from "../ProfileLink";
import removeLocalStorageItems from "../../../Project/utils/removeLocalStorageItems";

const TopMenu = () => {
  const { menuHeaderAndFooterItems, getShopShownSettings } = MenuLinksStore;

  const { isAuth } = User;

  useEffect(() => {
    getShopShownSettings();
  }, [getShopShownSettings]);

  return (
    <div className="topmenu">
      <div className="menu-container">
        <Logo removeLocalStorageItems={removeLocalStorageItems} />
        <div className="menu-items">
          <Navigation links={menuHeaderAndFooterItems} />
        </div>
        {isAuth ? (
          <ProfileLink />
        ) : (
          <div className="topmenu__auth">
            <Button title="Вход" type="sub" onClick={login} />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(TopMenu);
