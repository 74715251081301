import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../../../Common/Components/Button";
import Avatar from "../../../Common/Components/Avatar";
import User from "../../../stores/user";
import Map from "../Map";
import "./index.scss";
import cn from "classnames";
import splitName from "../../../utils/splitName";
import { URL_PROFILE_EDIT } from "../../../Project/routes/paths";

const UserData = () => {
  const {
    imageSrc,
    setImageSrc,
    firstName,
    lastName,
    isGeolocationAllowed,
    middleName,
    email,
    birthDate,
    phone = {
      mobileNumber: "",
    },
    snils,
    hasPhoto,
    gender,
    livingAddress,
    registrationAddress,
    loadUser,
  } = User;

  const { mobileNumber } = phone;

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  useEffect(() => {
    setImageSrc();
  }, [hasPhoto, setImageSrc]);

  return (
    <div className="UserData">
      <div className="UserData__main">
        <Avatar imageSrc={imageSrc} altText={splitName(firstName, lastName)} />
        <div className="UserData__name">
          <h2>{lastName}</h2>
          <h2>{firstName}</h2>
          {middleName && <h2>{middleName}</h2>}
        </div>
      </div>

      {gender && (
        <div className="UserData__section">
          <span className="UserData__title">Пол</span>
          <span className={cn("UserData__info", "UserData__info-bold")}>
            {gender}
          </span>
        </div>
      )}
      {birthDate && (
        <div className="UserData__section">
          <span className="UserData__title">Дата рождения</span>
          <span className={cn("UserData__info", "UserData__info-bold")}>
            {birthDate}
          </span>
        </div>
      )}
      {snils && (
        <div className="UserData__section">
          <span className="UserData__title">Снилс</span>
          <span className={cn("UserData__info", "UserData__info-bold")}>
            {snils}
          </span>
        </div>
      )}
      {mobileNumber !== null && (
        <div className="UserData__section">
          <span className="UserData__title">Сотовый телефон</span>
          <span className={cn("UserData__info", "UserData__info-bold")}>
            {mobileNumber}
          </span>
        </div>
      )}
      {email && (
        <div className="UserData__section">
          <span className="UserData__title">E-mail</span>
          <span className={cn("UserData__info", "UserData__info-bold")}>
            {email}
          </span>
        </div>
      )}

      <div className="UserData__section">
        <span className="UserData__title">Геолокация</span>
        <span className={cn("UserData__info", "UserData__info-bold")}>
          {isGeolocationAllowed ? "Разрешена" : "Не разрешена"}
        </span>
      </div>

      {registrationAddress?.address && (
        <>
          <div className="UserData__section">
            <span className="UserData__title">Адрес регистрации</span>
            <span className={cn("UserData__info", "UserData__info-bold")}>
              {registrationAddress.address}
            </span>
          </div>
          {!!registrationAddress?.coordinates && (
            <Map
              coordinates={registrationAddress?.coordinates}
              disableBlock={true}
            />
          )}
        </>
      )}
      {livingAddress?.address && (
        <>
          <div className="UserData__section">
            <span className="UserData__title">Адрес проживания</span>
            <span className={cn("UserData__info", "UserData__info-bold")}>
              {livingAddress.address}
            </span>
          </div>
          {!!livingAddress?.coordinates && (
            <Map coordinates={livingAddress?.coordinates} />
          )}
        </>
      )}
      <Link to={URL_PROFILE_EDIT} className={"UserData-btn"}>
        <Button title="Редактировать данные" type="primary" />
      </Link>
    </div>
  );
};

export default observer(UserData);
