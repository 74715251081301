/* tslint:disable */
/* eslint-disable */
/**
 * Открытая часть. \"Магазин поощрений\"
 * Описание методов для работы с API \"Магазин поощрений\"
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const ActionType = {
    Vote: 'Vote',
    Purchase: 'Purchase'
} as const;

export type ActionType = typeof ActionType[keyof typeof ActionType];


/**
 * 
 * @export
 * @interface Coordinates
 */
export interface Coordinates {
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface PointsCardModel
 */
export interface PointsCardModel {
    /**
     * 
     * @type {number}
     * @memberof PointsCardModel
     */
    'points'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PointsCardModel
     */
    'hasCard'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PointsCardModel
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface PointsHistoryModel
 */
export interface PointsHistoryModel {
    /**
     * 
     * @type {TransactionType}
     * @memberof PointsHistoryModel
     */
    'transactionType'?: TransactionType;
    /**
     * 
     * @type {number}
     * @memberof PointsHistoryModel
     */
    'numberOfPoints'?: number;
    /**
     * 
     * @type {ActionType}
     * @memberof PointsHistoryModel
     */
    'action'?: ActionType;
    /**
     * 
     * @type {string}
     * @memberof PointsHistoryModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PointsHistoryModel
     */
    'transactionDate'?: string;
}
/**
 * 
 * @export
 * @interface PointsHistoryPagedModel
 */
export interface PointsHistoryPagedModel {
    /**
     * 
     * @type {number}
     * @memberof PointsHistoryPagedModel
     */
    'count'?: number;
    /**
     * 
     * @type {Array<PointsHistoryModel>}
     * @memberof PointsHistoryPagedModel
     */
    'items'?: Array<PointsHistoryModel> | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'imageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'croppedImageId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDto
     */
    'categories'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'organizationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    'organizationAddress'?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof ProductDto
     */
    'organizationCoordinates'?: Coordinates;
}
/**
 * 
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'imageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'croppedImageId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductModel
     */
    'categories'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductModel
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'organizationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'organizationAddress'?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof ProductModel
     */
    'organizationCoordinates'?: Coordinates;
}
/**
 * 
 * @export
 * @interface ProductPagedModel
 */
export interface ProductPagedModel {
    /**
     * 
     * @type {number}
     * @memberof ProductPagedModel
     */
    'count'?: number;
    /**
     * 
     * @type {Array<ProductModel>}
     * @memberof ProductPagedModel
     */
    'items'?: Array<ProductModel> | null;
}
/**
 * 
 * @export
 * @interface ProjectSettingsModel
 */
export interface ProjectSettingsModel {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsModel
     */
    'isVisibleInMainMenu'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsModel
     */
    'isVisibleInProfile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsModel
     */
    'isVisibleInMobile'?: boolean;
}
/**
 * 
 * @export
 * @interface PurchaseModel
 */
export interface PurchaseModel {
    /**
     * 
     * @type {string}
     * @memberof PurchaseModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseModel
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchaseModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PurchaseModel
     */
    'imageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PurchaseModel
     */
    'croppedImageId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PurchaseModel
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PurchaseModel
     */
    'purchaseDate'?: string;
}
/**
 * 
 * @export
 * @interface PurchasePagedModel
 */
export interface PurchasePagedModel {
    /**
     * 
     * @type {number}
     * @memberof PurchasePagedModel
     */
    'count'?: number;
    /**
     * 
     * @type {Array<PurchaseModel>}
     * @memberof PurchasePagedModel
     */
    'items'?: Array<PurchaseModel> | null;
}
/**
 * 
 * @export
 * @interface SelectListCategoryItem
 */
export interface SelectListCategoryItem {
    /**
     * 
     * @type {string}
     * @memberof SelectListCategoryItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectListCategoryItem
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TransactionType = {
    Debit: 'Debit',
    Accrual: 'Accrual'
} as const;

export type TransactionType = typeof TransactionType[keyof typeof TransactionType];



/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение списка категорий товаров и услуг
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение списка категорий товаров и услуг
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectListCategoryItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение списка категорий товаров и услуг
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesGet(options?: any): AxiosPromise<Array<SelectListCategoryItem>> {
            return localVarFp.categoriesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriesApi - interface
 * @export
 * @interface CategoriesApi
 */
export interface CategoriesApiInterface {
    /**
     * 
     * @summary Получение списка категорий товаров и услуг
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    categoriesGet(options?: AxiosRequestConfig): AxiosPromise<Array<SelectListCategoryItem>>;

}

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI implements CategoriesApiInterface {
    /**
     * 
     * @summary Получение списка категорий товаров и услуг
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesGet(options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PointsApi - axios parameter creator
 * @export
 */
export const PointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение количества баллов для текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/points`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение истории транзакций
         * @param {TransactionType} [transactionType] Тип транзакции
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsHistoryGet: async (transactionType?: TransactionType, skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/points/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (transactionType !== undefined) {
                localVarQueryParameter['transactionType'] = transactionType;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointsApi - functional programming interface
 * @export
 */
export const PointsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение количества баллов для текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointsCardModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение истории транзакций
         * @param {TransactionType} [transactionType] Тип транзакции
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointsHistoryGet(transactionType?: TransactionType, skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointsHistoryPagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointsHistoryGet(transactionType, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PointsApi - factory interface
 * @export
 */
export const PointsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение количества баллов для текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsGet(options?: any): AxiosPromise<PointsCardModel> {
            return localVarFp.pointsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение истории транзакций
         * @param {TransactionType} [transactionType] Тип транзакции
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointsHistoryGet(transactionType?: TransactionType, skip?: number, take?: number, options?: any): AxiosPromise<PointsHistoryPagedModel> {
            return localVarFp.pointsHistoryGet(transactionType, skip, take, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointsApi - interface
 * @export
 * @interface PointsApi
 */
export interface PointsApiInterface {
    /**
     * 
     * @summary Получение количества баллов для текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApiInterface
     */
    pointsGet(options?: AxiosRequestConfig): AxiosPromise<PointsCardModel>;

    /**
     * 
     * @summary Получение истории транзакций
     * @param {TransactionType} [transactionType] Тип транзакции
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApiInterface
     */
    pointsHistoryGet(transactionType?: TransactionType, skip?: number, take?: number, options?: AxiosRequestConfig): AxiosPromise<PointsHistoryPagedModel>;

}

/**
 * PointsApi - object-oriented interface
 * @export
 * @class PointsApi
 * @extends {BaseAPI}
 */
export class PointsApi extends BaseAPI implements PointsApiInterface {
    /**
     * 
     * @summary Получение количества баллов для текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public pointsGet(options?: AxiosRequestConfig) {
        return PointsApiFp(this.configuration).pointsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение истории транзакций
     * @param {TransactionType} [transactionType] Тип транзакции
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointsApi
     */
    public pointsHistoryGet(transactionType?: TransactionType, skip?: number, take?: number, options?: AxiosRequestConfig) {
        return PointsApiFp(this.configuration).pointsHistoryGet(transactionType, skip, take, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение обрезанного изображение товара/услуги
         * @param {string} croppedImageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCroppedimageCroppedImageIdGet: async (croppedImageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'croppedImageId' is not null or undefined
            assertParamExists('productsCroppedimageCroppedImageIdGet', 'croppedImageId', croppedImageId)
            const localVarPath = `/products/croppedimage/{croppedImageId}`
                .replace(`{${"croppedImageId"}}`, encodeURIComponent(String(croppedImageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка товаров/услуг
         * @param {Array<string>} [categoriesIds] Уникальные идентификаторы категорий
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGet: async (categoriesIds?: Array<string>, skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoriesIds) {
                localVarQueryParameter['categoriesIds'] = categoriesIds;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение товара/услуги по id
         * @param {string} [id] Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsIdGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение изображение товара/услуги
         * @param {string} imageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsImageImageIdGet: async (imageId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('productsImageImageIdGet', 'imageId', imageId)
            const localVarPath = `/products/image/{imageId}`
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Списать баллы
         * @param {string} productId Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductIdBuyPost: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productsProductIdBuyPost', 'productId', productId)
            const localVarPath = `/products/{productId}/buy`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение обрезанного изображение товара/услуги
         * @param {string} croppedImageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsCroppedimageCroppedImageIdGet(croppedImageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsCroppedimageCroppedImageIdGet(croppedImageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка товаров/услуг
         * @param {Array<string>} [categoriesIds] Уникальные идентификаторы категорий
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGet(categoriesIds?: Array<string>, skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGet(categoriesIds, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение товара/услуги по id
         * @param {string} [id] Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsIdGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение изображение товара/услуги
         * @param {string} imageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsImageImageIdGet(imageId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsImageImageIdGet(imageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Списать баллы
         * @param {string} productId Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsProductIdBuyPost(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsProductIdBuyPost(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение обрезанного изображение товара/услуги
         * @param {string} croppedImageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCroppedimageCroppedImageIdGet(croppedImageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsCroppedimageCroppedImageIdGet(croppedImageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка товаров/услуг
         * @param {Array<string>} [categoriesIds] Уникальные идентификаторы категорий
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGet(categoriesIds?: Array<string>, skip?: number, take?: number, options?: any): AxiosPromise<ProductPagedModel> {
            return localVarFp.productsGet(categoriesIds, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение товара/услуги по id
         * @param {string} [id] Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsIdGet(id?: string, options?: any): AxiosPromise<ProductDto> {
            return localVarFp.productsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение изображение товара/услуги
         * @param {string} imageId Уникальный идентификатор изображения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsImageImageIdGet(imageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsImageImageIdGet(imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Списать баллы
         * @param {string} productId Уникальный идентификатор товара/услуги
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductIdBuyPost(productId: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsProductIdBuyPost(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - interface
 * @export
 * @interface ProductsApi
 */
export interface ProductsApiInterface {
    /**
     * 
     * @summary Получение обрезанного изображение товара/услуги
     * @param {string} croppedImageId Уникальный идентификатор изображения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    productsCroppedimageCroppedImageIdGet(croppedImageId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение списка товаров/услуг
     * @param {Array<string>} [categoriesIds] Уникальные идентификаторы категорий
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    productsGet(categoriesIds?: Array<string>, skip?: number, take?: number, options?: AxiosRequestConfig): AxiosPromise<ProductPagedModel>;

    /**
     * 
     * @summary Получение товара/услуги по id
     * @param {string} [id] Уникальный идентификатор товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    productsIdGet(id?: string, options?: AxiosRequestConfig): AxiosPromise<ProductDto>;

    /**
     * 
     * @summary Получение изображение товара/услуги
     * @param {string} imageId Уникальный идентификатор изображения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    productsImageImageIdGet(imageId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Списать баллы
     * @param {string} productId Уникальный идентификатор товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    productsProductIdBuyPost(productId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI implements ProductsApiInterface {
    /**
     * 
     * @summary Получение обрезанного изображение товара/услуги
     * @param {string} croppedImageId Уникальный идентификатор изображения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsCroppedimageCroppedImageIdGet(croppedImageId: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsCroppedimageCroppedImageIdGet(croppedImageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка товаров/услуг
     * @param {Array<string>} [categoriesIds] Уникальные идентификаторы категорий
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsGet(categoriesIds?: Array<string>, skip?: number, take?: number, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsGet(categoriesIds, skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение товара/услуги по id
     * @param {string} [id] Уникальный идентификатор товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsIdGet(id?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение изображение товара/услуги
     * @param {string} imageId Уникальный идентификатор изображения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsImageImageIdGet(imageId: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsImageImageIdGet(imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Списать баллы
     * @param {string} productId Уникальный идентификатор товара/услуги
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsProductIdBuyPost(productId: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsProductIdBuyPost(productId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectSettingsApi - axios parameter creator
 * @export
 */
export const ProjectSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение настроек отображение проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projectsettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectSettingsApi - functional programming interface
 * @export
 */
export const ProjectSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение настроек отображение проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectsettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectSettingsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectsettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectSettingsApi - factory interface
 * @export
 */
export const ProjectSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение настроек отображение проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectsettingsGet(options?: any): AxiosPromise<ProjectSettingsModel> {
            return localVarFp.projectsettingsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectSettingsApi - interface
 * @export
 * @interface ProjectSettingsApi
 */
export interface ProjectSettingsApiInterface {
    /**
     * 
     * @summary Получение настроек отображение проекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApiInterface
     */
    projectsettingsGet(options?: AxiosRequestConfig): AxiosPromise<ProjectSettingsModel>;

}

/**
 * ProjectSettingsApi - object-oriented interface
 * @export
 * @class ProjectSettingsApi
 * @extends {BaseAPI}
 */
export class ProjectSettingsApi extends BaseAPI implements ProjectSettingsApiInterface {
    /**
     * 
     * @summary Получение настроек отображение проекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSettingsApi
     */
    public projectsettingsGet(options?: AxiosRequestConfig) {
        return ProjectSettingsApiFp(this.configuration).projectsettingsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchasesApi - axios parameter creator
 * @export
 */
export const PurchasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение списка покупок
         * @param {boolean} [isActive] Активированные/неактивированные
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesGet: async (isActive?: boolean, skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/purchases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchasesApi - functional programming interface
 * @export
 */
export const PurchasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchasesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение списка покупок
         * @param {boolean} [isActive] Активированные/неактивированные
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchasesGet(isActive?: boolean, skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchasePagedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchasesGet(isActive, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchasesApi - factory interface
 * @export
 */
export const PurchasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchasesApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение списка покупок
         * @param {boolean} [isActive] Активированные/неактивированные
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesGet(isActive?: boolean, skip?: number, take?: number, options?: any): AxiosPromise<PurchasePagedModel> {
            return localVarFp.purchasesGet(isActive, skip, take, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchasesApi - interface
 * @export
 * @interface PurchasesApi
 */
export interface PurchasesApiInterface {
    /**
     * 
     * @summary Получение списка покупок
     * @param {boolean} [isActive] Активированные/неактивированные
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasesApiInterface
     */
    purchasesGet(isActive?: boolean, skip?: number, take?: number, options?: AxiosRequestConfig): AxiosPromise<PurchasePagedModel>;

}

/**
 * PurchasesApi - object-oriented interface
 * @export
 * @class PurchasesApi
 * @extends {BaseAPI}
 */
export class PurchasesApi extends BaseAPI implements PurchasesApiInterface {
    /**
     * 
     * @summary Получение списка покупок
     * @param {boolean} [isActive] Активированные/неактивированные
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasesApi
     */
    public purchasesGet(isActive?: boolean, skip?: number, take?: number, options?: AxiosRequestConfig) {
        return PurchasesApiFp(this.configuration).purchasesGet(isActive, skip, take, options).then((request) => request(this.axios, this.basePath));
    }
}


