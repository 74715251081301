import React from "react";
import { observer } from "mobx-react";

import "./styles.scss";

export interface IProps {
  content?: string | null;
}

const EskInfo: React.FC<IProps> = ({ content }) => {
  return (
    <div className="esk-info">
      <div className="esk-info__content">
        <h2 className="esk-info__title">Как оформить карту ЕСК</h2>
        <div dangerouslySetInnerHTML={{ __html: content as string }} />
      </div>
    </div>
  );
};

export default observer(EskInfo);
