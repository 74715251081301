import cn from "classnames";
import { observer } from "mobx-react";
import React from "react";
import ReactSelect from "react-select";
import "./styles.scss";
import { ISelectProps } from "./types";

const Select: React.FC<ISelectProps> = ({
  className = "",
  options,
  currentType,
  placeholder,
  onChange,
  isSearchable = true,
}) => {
  const selectOptions = options.map((option) => ({
    value: option.type,
    label: option.text,
  }));

  const currentOption = selectOptions.find(
    (option) => option.value === currentType
  );

  return (
    <div className={cn("select", className)}>
      <ReactSelect
        isSearchable={isSearchable}
        className="react-select"
        classNamePrefix="react-select"
        options={selectOptions}
        value={currentOption}
        onChange={(value) => {
          onChange(value ? value.value : null);
        }}
      />
      {placeholder && <div className="select__placeholder">{placeholder}</div>}
    </div>
  );
};

export default observer(Select);
