import React, { FC } from "react";
import { ReactComponent as ErrorSVG } from "../../../Common/images/error-base.svg";
import "./index.scss";

const ErrorPicture: FC<{ status: number }> = ({ status }) => {
  return (
    <div className="error-image">
      <span className="error-image__status">{status}</span>
      <ErrorSVG />
    </div>
  );
};

export default ErrorPicture;
