import React, { useEffect } from "react";
import { observer } from "mobx-react";

import InstructionsStore from "../../stores/app/InstructionsStore";

const ConditionsPage = () => {
  const { getConditionsContent, clearInstructions, conditions } =
    InstructionsStore;

  useEffect(() => {
    getConditionsContent();

    return () => clearInstructions();
  }, [getConditionsContent, clearInstructions]);

  return (
    <div className="instructions">
      {conditions && <div dangerouslySetInnerHTML={{ __html: conditions }} />}
    </div>
  );
};

export default observer(ConditionsPage);
