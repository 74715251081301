import React from "react";
import VideoPlayer from "../VideoPlayer";

import { AISAE } from "../../../constants/api";

import "./index.scss";

const File = (props) => {
  const { item, onClick, url = AISAE, className = "" } = props;

  if (!item?.type) return null;

  const type = item.type.match(/image|video|application/im)[0].toLowerCase();
  const src = item.reference ? url + item.reference : URL.createObjectURL(item);

  console.log({ type });

  return (
    <div className={"file-wrapper " + className} onClick={onClick}>
      {type === "image" && <img src={src} alt={item.reference} />}
      {type === "video" && <VideoPlayer url={src} />}
      {type === "application" && <div className="file-application"></div>}
    </div>
  );
};

export default File;
