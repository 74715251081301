import React, { FC } from "react";
import File from "../../../Main/Components/File";
import DummyPhoto from "../DummyPhoto";
import { TFile } from "../WasBecame";

type TWasBecameCard = {
  title: string;
  file?: TFile;
  onClick: () => void;
};

const WasBecameCard: FC<TWasBecameCard> = ({ title, file, onClick }) => {
  return (
    <div className="was-became__file">
      <p>{title}</p>
      {file?.reference ? (
        <File item={file} onClick={onClick} />
      ) : (
        <div className="was-became__dummy-wrapper">
          <DummyPhoto />
        </div>
      )}
    </div>
  );
};

export default WasBecameCard;
