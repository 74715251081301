import React from "react";
import "./index.scss";
import { ReactComponent as ArrowIcon } from "../../images/arrowBack.svg";
import { ReactComponent as CloseIcon } from "../../images/closeGreen.svg";

import { ReactComponent as EkbLogo } from "../../images/Coat_of_Arms_of_Yekaterinburg_(Sverdlovsk_oblast).svg";

const ChatHeader = ({closeChat}) => {

  return (
    <div className="chat__header">
      <div className="chat__logo">
        <EkbLogo />
      </div>
      <h4 className="chat__admin">Администратор</h4>
      <button
        className="button-icon chat__close"
        onClick={closeChat}
      >
        <ArrowIcon />
        <CloseIcon />
      </button>
    </div>
  );
};

export default ChatHeader;
