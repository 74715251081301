import React from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import {
  CarouselProvider,
  Slider,
  Slide,
  Dot,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import { ReactComponent as VideoIcon } from "../../../Common/images/typeVideo.svg";
import { ReactComponent as CloseIcon } from "../../../Common/images/closeWhite.svg";
import { ReactComponent as ArrowRight } from "../../../Common/images/arrowRight.svg";
import { ReactComponent as ArrowLeft } from "../../../Common/images/arrowLeft.svg";

import "./index.scss";

import { AISAE } from "../../../constants/api";

const FileViewer = ({ files, startSlide = 0, setShowFiles, url = AISAE }) => {
  const typeOfContent = (file, forNav = false) => {
    const type = file.type.match(/image|video/im)[0].toLowerCase();

    const src = file.reference
      ? url + file.reference
      : URL.createObjectURL(file);

    switch (type) {
      case "image":
        return <img className="FileViewer__photo" src={src} alt="file" />;

      case "video":
        return (
          <>
            <video className="FileViewer__video" controls={!forNav}>
              <source src={`${src}#t=0.1`} type="video/mp4" />
            </video>
            {forNav && (
              <div className="FileViewer__video-icon">
                <VideoIcon />
              </div>
            )}
          </>
        );

      default:
        return;
    }
  };

  if (!files?.length) {
    return null;
  }

  return (
    <div className="FileViewer-popup">
      <div className="FileViewer">
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={40}
          totalSlides={files.length}
          dragEnabled={false}
          touchEnabled={true}
          infinite={true}
          currentSlide={startSlide}
        >
          <Slider>
            {files.map((file, id) => {
              return (
                <Slide
                  key={id}
                  className={cn(
                    "FileViewer__slide",
                    file.type.includes("image") && "FileViewer__slide-photo"
                  )}
                >
                  {typeOfContent(file)}
                </Slide>
              );
            })}
          </Slider>
          {files.length > 1 && (
            <div className="FileViewer__nav">
              <ButtonBack className="button-icon FileViewer__btn">
                <ArrowLeft />
              </ButtonBack>
              <div className="FileViewer__all">
                {files.map((file, id) => (
                  <Dot className="FileViewer__dot" slide={id} key={id}>
                    {typeOfContent(file, true)}
                  </Dot>
                ))}
              </div>
              <ButtonNext className="button-icon FileViewer__btn">
                <ArrowRight />
              </ButtonNext>
            </div>
          )}
        </CarouselProvider>
      </div>
      <button
        className="button-icon FileViewer__close"
        onClick={() => setShowFiles(false)}
      >
        <CloseIcon />
      </button>
    </div>
  );
};

export default observer(FileViewer);
