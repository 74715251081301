import { makeAutoObservable } from "mobx";

class BurgerMenu {
  fixed = false;
  active = false;
  button = null;

  switchActive = () => {
    this.active = !this.active;
  };

  setFixed = (bool) => {
    this.fixed = bool;
  };

  setButton = (btn) => {
    this.button = btn;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new BurgerMenu();
