import React from "react";
import Button from "../Button";
import "./index.scss";

const Popup = ({
  header = null,
  content = null,
  onSubmit = null,
  onDiscard = null,
  onClose = null,
  submitTitle = null,
  discardTitle = null,
}) => {
  return (
    <div className="Popup">
      <div className="Popup__content">
        <h3 className="Popup__header">{header}</h3>
        {content && <span className="Popup__p">{content}</span>}
        <div className="Popup__nav">
          {submitTitle && (
            <Button title={submitTitle} onClick={onSubmit} type="primary" />
          )}
          {discardTitle && (
            <Button title={discardTitle} onClick={onDiscard} type="primary" />
          )}
        </div>
        {onClose && (
          <button className="Popup__close" onClick={onClose}></button>
        )}
      </div>
    </div>
  );
};

export default Popup;
