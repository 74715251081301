import React from "react";
import { MapContainer, TileLayer, Marker, useMapEvent } from "react-leaflet";

import { iconPerson } from "../../../Common/Components/LeafletCustomIcon";
import { MAP_TILES_URL, CRS_YANDEX } from "./../../../constants/map";

import "./index.scss";

const SetViewOnClick = ({ setPosition }) => {
  const map = useMapEvent("click", async (e) => {
    map.setView(e.latlng, map.getZoom(), {
      animate: true,
    });
    setPosition([e.latlng.lat, e.latlng.lng]);
  });

  return null;
};

const Map = ({ position, setPosition, setMap = () => {} }) => {
  const defaultPosition = ["56.83919795", "60.60609597916098"];
  const postionToString = position[0] && [
    position[0].toString(),
    position[1].toString(),
  ];

  return (
    <div className="map-wrapper">
      <MapContainer
        center={position?.length ? position : defaultPosition}
        zoom={12}
        maxZoom={18}
        minZoom={11}
        attributionControl={false}
        maxBounds={[
          [56.973763, 60.844435],
          [56.653589, 60.425006],
        ]}
        className="map-component"
        whenCreated={setMap}
        crs={CRS_YANDEX}
      >
        <TileLayer url={MAP_TILES_URL} />
        {position && position[0] && (
          <Marker position={postionToString} icon={iconPerson}></Marker>
        )}
        <SetViewOnClick setPosition={setPosition} />
      </MapContainer>
    </div>
  );
};

export default Map;
