import "./styles.scss";

import cn from "classnames";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { INavigationMenuMoreProps } from "./types";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const NavigationMenuMore: React.FC<INavigationMenuMoreProps> = ({ links }) => {
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setIsShowMenu(false);
  });

  return (
    <div
      ref={ref}
      className={cn(
        "NavigationMenuMore",
        isShowMenu && "NavigationMenuMore-active"
      )}
    >
      <button
        className={cn(
          "NavigationMenuMore__button",
          isShowMenu && "NavigationMenuMore__button-active"
        )}
        onClick={() => {
          setIsShowMenu(!isShowMenu);
        }}
      >
        ещё <span className="NavigationMenuMore__button-icon" />
      </button>
      {isShowMenu && (
        <div className="NavigationMenuMore__list">
          {links.map((item, idx) => (
            <Link
              to={item.path}
              key={idx}
              className="NavigationMenuMore__link"
              onClick={() => {
                setIsShowMenu(false);
              }}
            >
              {item.content}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default observer(NavigationMenuMore);
