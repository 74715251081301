import React from "react";
import ClampLines from "react-clamp-lines";
import normalizeDate from "../../../utils/normalizeDate";
import { URL_FILE_ID } from "../../../constants/api";
import DummyShopPhoto from "../../../Project/components/DummyShopPhoto";
import "./index.scss";

const Cards = (props) => {
  const { item, onTileClick } = props;

  const pastNormalizeDate = normalizeDate(item.startDateTime);
  const futureNormalizedDate = normalizeDate(item.endDateTime);

  const futureDate = Date.parse(item.endDateTime);
  const now = new Date().getTime();
  const pastDate = Date.parse(item.startDateTime);
  const delta = futureDate - pastDate;
  const passedTime = now - pastDate >= 0 ? now - pastDate : 0;
  const resPercent = Math.floor((passedTime * 100) / delta);

  return (
    <div
      className={
        ((item.isImportant && item.status === "Active") ?? item.fileId
          ? "tile important wphoto"
          : item.isImportant && item.status === "Active"
          ? "tile important"
          : "tile") + (item.isDone ? " done" : "")
      }
      onClick={() => {
        onTileClick(item.id, item.status, item.isDone);
      }}
    >
      {item.isImportant &&
        item.status === "Active" &&
        (item.previewFileId ? (
          <div className="tile-image">
            <img src={URL_FILE_ID(item.previewFileId)} alt="vote" />
          </div>
        ) : (
          <div className="tile-image">
            <DummyShopPhoto />
          </div>
        ))}
      <div className="tile__content-wrapper">
        <div
          className={
            resPercent >= 100
              ? "tile-date line-close"
              : 50 < resPercent
              ? "tile-date  line-middle"
              : "tile-date line-green"
          }
        >
          <div
            className={
              resPercent > 75
                ? "line close"
                : 50 < resPercent
                ? "line middle"
                : "line green"
            }
            style={{ width: `${resPercent >= 100 ? 100 : resPercent}%` }}
          />
          <div className="default-line" />
          <span className="tile-date-start">{pastNormalizeDate}</span>
          <span className="tile-date-end">{futureNormalizedDate}</span>
        </div>
        <div className="tile-name">
          {item.title && (
            <h3>{item.title}</h3>
            // <ClampLines
            //   text={item.title}
            //   lines={3}
            //   id={item.id}
            //   ellipsis="..."
            //   buttons={false}
            // />
          )}
        </div>
        <div className="tile-info">
          {resPercent >= 100 && (
            <span className="end-text">Голосование завершено</span>
          )}
          {resPercent < 100 && (
            <>
              <span className="question-text">Вопросов</span>
              <span className="number">{item.questionsNumber}</span>
              <span className="line">|</span>
              <span className="voted-text">Проголосовало</span>
              <span className="number">{item.votersNumber}</span>
            </>
          )}
        </div>
        <div className="tile-desc">
          {item.annotation && (
            <ClampLines
              text={item.annotation}
              lines={3}
              id={item.id}
              ellipsis="..."
              innerElement="p"
              buttons={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Cards;
