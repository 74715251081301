import React, { useEffect } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import Tabs from "../../../components/Tabs";
import Button from "../../../components/Button";
import EskInfo from "../../../components/EskInfo";
import PointsHistory from "../../../components/PointsHistory";
import Select from "../../../components/Select";

import MyPointsStore from "../../../stores/app/myPointsStore";
import UserStore from "../../../../stores/user";
import NavBarLinksStore from "../../../stores/app/NavBarLinksStore";

import { history } from "../../../helpers/history";
import { URL_PROFILE_SHOP } from "../../../routes/paths";
import { profileTabs, myPointsOptions } from "../../../constants/profileTabs";

import "./styles.scss";

const MyPoints: React.FC = () => {
  const {
    operationsHistoryList,
    currentPoints,
    itemsPerRequest,
    setUserId,
    getOperationsList,
    reset,
    init,
    setTransactionType,
    getHistoryMore,
    isRequestMore,
    isLoading,
    content,
  } = MyPointsStore;

  const {
    isShopShowing: { isVisibleInProfile },
  } = NavBarLinksStore;

  const { id, isHaveEskCard } = UserStore;

  const { location } = history;

  useEffect(() => {
    if (id) setUserId(id);
  }, [id, setUserId]);

  useEffect(() => {
    if (id) init();

    return () => reset();
  }, [id, init, reset]);

  const pointsHistoryTabs = [
    {
      text: "Показать все",
      type: "All",
      onChange: () => {
        setTransactionType(undefined);
        getOperationsList(undefined, undefined, itemsPerRequest);
      },
    },
    {
      text: "Начисления",
      type: "Accrual",
      onChange: () => {
        setTransactionType("Accrual");
        getOperationsList("Accrual", undefined, itemsPerRequest);
      },
    },
    {
      text: "Списания",
      type: "Debit",
      onChange: () => {
        setTransactionType("Debit");
        getOperationsList("Debit", undefined, itemsPerRequest);
      },
    },
  ];
  const goToProfileShop = () => history.push(URL_PROFILE_SHOP);
  return (
    <div className="my-points">
      {isVisibleInProfile && (
        <>
          <Tabs routes={profileTabs} />
          <Select
            isSearchable={false}
            className="my-purchases__select"
            options={myPointsOptions}
            currentType={location.pathname}
            onChange={(path) => {
              history.push(`${path}`);
            }}
          />
        </>
      )}
      <div className="my-points__content">
        {isHaveEskCard && <h2 className="esk-info__title">Есть карта ЕСК</h2>}
        {typeof currentPoints === "number" && (
          <div
            className={cn(
              "my-points__header",
              isHaveEskCard && "my-points__header-active"
            )}
          >
            <span
              className={cn(
                "my-points__header-count",
                isHaveEskCard && "my-points__header-count-active"
              )}
            >
              {currentPoints}
            </span>

            <span className="my-points__header-text">Баллов</span>
            {isHaveEskCard && isVisibleInProfile && (
              <Button
                type="buy"
                children="Потратить баллы"
                onClick={goToProfileShop}
                disabled={currentPoints === 0}
              />
            )}
          </div>
        )}
        {isHaveEskCard && (
          <div dangerouslySetInnerHTML={{ __html: content as string }} />
        )}
        {isHaveEskCard ? (
          <PointsHistory
            historyList={operationsHistoryList}
            tabs={pointsHistoryTabs}
            getHistoryList={getHistoryMore}
            isRequestMore={isRequestMore}
            isLoading={isLoading}
          />
        ) : (
          <>
            <EskInfo content={content} />
            <PointsHistory
              historyList={operationsHistoryList}
              tabs={[
                {
                  text: "Начисления",
                  type: "Accrual",
                  onChange: () => {
                    setTransactionType(undefined);
                    getOperationsList(undefined, undefined, itemsPerRequest);
                  },
                },
              ]}
              getHistoryList={getHistoryMore}
              isRequestMore={isRequestMore}
              isLoading={isLoading}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default observer(MyPoints);
