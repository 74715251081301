import { makeAutoObservable, runInAction } from "mobx";
import { AISAE } from "../constants/api";
import debounce from "../utils/debounce";

class Address {
  value = "";
  position = [];
  map = null;
  showMap = "";
  loading = false;

  onChange = (value) => {
    this.value = value;
  };

  changePos = (value) => {
    this.position = value;
  };

  setMap = (map) => {
    this.map = map;
  };

  switchMap = () => {
    this.showMap = !this.showMap;
  };

  getAddress = async (latlng) => {
    try {
      const response = await fetch(
        AISAE +
          `api/Geolocations/addressbycoords?latitude=${latlng[0]}&longitude=${latlng[1]}`
      );

      if (!response.ok) throw new Error(`[Address]: Can't get Latlng`);

      const data = await response.text();

      this.value = data;
    } catch (error) {
      console.log(error);
    }
  };

  getLatlng = async (value) => {
    const response = await fetch(
      AISAE + "api/Geolocations/coordsbyadress?address=" + value
    );
    if (!response.ok) throw new Error(`[Address]: Can't get Latlng`);

    const data = await response.json();

    const { latitude, longitude } = data;
    this.changePos([latitude, longitude]);

    if (this.map) this.map.setView([latitude, longitude], 18);
  };

  setPosAndGetAddress = (latlng) => {
    this.position = latlng;

    this.getAddress(latlng);
  };

  debounceGetLatIng = debounce((x) => {
    runInAction(() => (this.loading = false)); // set loading false when loaded
    this.getLatlng(x);
  }, 3000);

  setValueAndGetPos = (value) => {
    this.value = value;
    this.loading = true; // set loading true for user info
    this.debounceGetLatIng(value);
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new Address();
