const setStatus = (item) => {
  //TODO: допилить статусы
  switch (item) {
    case "created":
      return "Создано";
    case "inwork":
      return "В работе";
    case "moderated":
      return "В работе";
    case "finished":
      return "Завершено";
    case "rejected":
      return "Отклонено";
    default:
      return;
  }
};

export default setStatus;
