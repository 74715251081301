import axios from "axios";
import { makeAutoObservable } from "mobx";
import { AISAE } from "../constants/api";
import login from "../utils/login";
import user from "./user";

class NewQuestion {
  text = "";
  status = {
    loading: false,
    success: false,
    error: false,
  };

  changeText = (value) => {
    this.text = value;
  };

  sendQuestion = async () => {
    try {
      this.status.loading = true;

      const ok = await user.checkAuth();
      if (!ok) return login();

      const text = {
        question: this.text,
      };

      await axios.post(AISAE + "api/questions/create", text);

      this.status.success = true;
    } catch (error) {
      this.status.error = true;
      console.error(error);
    } finally {
      this.status.loading = false;
    }
  };

  resetStatus = () => {
    this.status = {
      success: false,
      error: false,
    };
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new NewQuestion();
