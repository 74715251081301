import { observer } from "mobx-react";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import Button from "../../../../Common/Components/Button";
import Popup from "../../../../Common/Components/Popup";

import newQuestion from "../../../../stores/newQuestion";

const SettingsQuestions = () => {
  const { text, changeText, sendQuestion, status, resetStatus } = newQuestion;

  const clearTextArea = () => {
    changeText("");
  };

  const submitQuestion = async () => {
    await sendQuestion();
    clearTextArea();
  };

  const textareaHandler = (e) => {
    changeText(e.target.value);
  };

  return (
    <>
      <div className="Settings__section">
        <h1>У вас есть вопросы?</h1>
        <TextareaAutosize
          value={text}
          onChange={textareaHandler}
          className="Settings__textarea"
        />
      </div>
      <div className="Settings__buttons">
        <Button
          onClick={submitQuestion}
          title="Отправить"
          type={text?.trim?.().length ? "primary" : "disabled"}
          disabled={!text?.trim?.().length || status.loading}
        />
        <Button
          onClick={clearTextArea}
          title="Отмена"
          type={text.trim?.().length ? "primary" : "disabled"}
          disabled={!text.trim?.().length || status.loading}
        />
      </div>
      {status?.success && (
        <Popup
          header="Ваш вопрос отправлен модератору проекта."
          submitTitle="Закрыть"
          onSubmit={resetStatus}
        />
      )}
      {status?.error && (
        <Popup
          header="Что-то пошло не так!"
          content="Попробуйте еще раз!"
          submitTitle="Закрыть"
          onSubmit={resetStatus}
        />
      )}
    </>
  );
};

export default observer(SettingsQuestions);
