import React from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { IMultiSelection } from "./types";
import { ReactComponent as CheckGreenIcon } from "../../assets/icons/check-green.svg";
import { ReactComponent as CheckGreyIcon } from "../../assets/icons/check-grey.svg";

import "./styles.scss";

const MultiSelectionButtons: React.FC<IMultiSelection> = ({
  options,
  selectedList,
  setItem,
  clearSelectedList,
  title = "",
}) => {
  const selectedListIsNotEmpty = selectedList.length > 0;

  return (
    <div className="multi-selection">
      {title && (
        <span className="multi-selection__title">Показать рубрики</span>
      )}
      <div className="multi-selection__list">
        <button
          className={cn(
            "multi-selection__item",
            !selectedListIsNotEmpty && "multi-selection__item-active"
          )}
          onClick={clearSelectedList}
        >
          {!selectedListIsNotEmpty ? <CheckGreenIcon /> : <CheckGreyIcon />}
          Все
        </button>
        {options.map((item, idx) => {
          const { id, title } = item;
          const isActive = selectedList.includes(id);
          return (
            <button
              key={idx}
              className={cn(
                "multi-selection__item",
                isActive && "multi-selection__item-active"
              )}
              onClick={() => {
                setItem(id);
              }}
            >
              {isActive ? <CheckGreenIcon /> : <CheckGreyIcon />}
              {title}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default observer(MultiSelectionButtons);
