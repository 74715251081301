import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo";
import CopyrightsContainer from "../Copyrights";
import MenuLinksStore from "../../../Project/stores/app/NavBarLinksStore";
import { observer } from "mobx-react";
import { footerLinks } from "../../../Project/constants/layoutItems";
import "./index.scss";
import removeLocalStorageItems from "../../../Project/utils/removeLocalStorageItems";
import Navigation from "../../../Project/components/Navigation";

const Footer = () => {
  const { menuHeaderAndFooterItems } = MenuLinksStore;
  return (
    <div className="footer">
      <div className="container">
        <div className="menu-container">
          <Logo removeLocalStorageItems={removeLocalStorageItems} />
          <div className="menu-items">
            {menuHeaderAndFooterItems && (
              <Navigation links={menuHeaderAndFooterItems} />
            )}
          </div>
        </div>
        <div className="link-container">
          <div className="link-items">
            {footerLinks &&
              footerLinks.map((item, idx) => {
                return (
                  <Link className="link-item" key={idx} to={`${item.path}`}>
                    <span>{item.content}</span>
                  </Link>
                );
              })}
          </div>
        </div>
        <CopyrightsContainer />
      </div>
    </div>
  );
};

export default observer(Footer);
