import React from "react";

import "./index.scss";

const Textarea = ({ onChange, value, changeDisable, decline, id }) => {
  const localChangeValue = (value) => {
    if (value === "") {
      changeDisable(true);
      decline(id);
    } else {
      changeDisable(false);
      onChange(value);
    }
  };

  return (
    <div className="textarea-wrapper">
      <textarea
        type="text"
        // defaultValue={value}
        value={value}
        placeholder="Введите текст"
        onChange={(event) => {
          localChangeValue(event.target.value);
        }}
      />
    </div>
  );
};

export default Textarea;
