import axios from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { AISAE } from "../constants/api";
import login from "../utils/login";
import User from "./user";
import violations from "./violations";

class Chat {
  open = false;
  messages = [];
  message = {
    text: "",
    violationId: null,
    authorId: null,
    files: [],
  };
  valid = false;
  lastUpdate = new Date().toISOString();
  newMessages = false;
  isBlock = true;

  switchOpen = () => {
    this.open = !this.open;
  };

  getMessages = async (id) => {
    this.message.violationId = id;
    this.messages = [];

    try {
      await axios.get(AISAE + "api/chat/messages/" + id).then((res) => {
        if (res.data.messages.length) {
          this.isBlock = false;
        }

        this.lastUpdate = res.data.lastUpdate;
        this.messages = res.data.messages;
        this.syncMessages(id);
      });
    } catch (error) {
      console.error(error);
    }
  };

  syncMessages = async (id) => {
    this.message.violationId = id;
    try {
      const res = await fetch(
        AISAE + `api/chat/messages/${id}?lastUpdate=${this.lastUpdate}`
      );

      if (!res.ok) throw new Error(`can't sync messages`);

      const data = await res.json();
      this.lastUpdate = data.lastUpdate;

      if (data.messages.length) {
        if (this.isBlock) this.isBlock = false;

        if (
          this.messages[this.messages.length - 1].id !==
          data.messages[data.messages.length - 1].id
        ) {
          this.messages.push(...data.messages);
        }

        const hasAdminMessage = data.messages.some((msg) => !msg.isMyMessage);

        if (hasAdminMessage) {
          this.newMessages = true;
        }
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  resetNotification = () => {
    this.newMessages = false;
  };

  sendMessage = async () => {
    const ok = await User.checkAuth();

    if (!this.valid) return;

    if (!ok) {
      login();
      return;
    }

    try {
      const body = new FormData();

      body.append("Text", this.message.text);
      body.append("ViolationId", this.message.violationId);
      body.append("AuthorId", User.id);

      this.message.files.forEach((file) => {
        const type = file.type.match(/image|video|application|audio/m)[0];
        if (type === "image") body.append("Photos", file);

        if (type === "video") body.append("Videos", file);

        if (type === "application") body.append("Docs", file);

        if (type === "audio") body.append("Audios", file);
      });

      const res = await fetch(AISAE + "api/chat/send", {
        method: "POST",
        body,
      });

      if (!res.ok) throw new Error(`Can't send message`);

      runInAction(() => {
        this.message.text = "";
        this.message.files = [];
        this.valid = false;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.syncMessages(this.message.violationId);
    }
  };

  makeChatRead = (id) => {
    axios.post(AISAE + `api/chat/read/${id}`).then(() => {
      this.switchOpen();
      violations.fetchViolation(id);
    });
  };

  changeText = (value) => {
    this.message.text = value;
  };

  changeValid = (bool) => {
    this.valid = bool;
  };

  changeTextAndValidate = (value) => {
    const isValid = value.toString().trim().length > 0;

    this.changeText(value);
    this.changeValid(isValid);
  };

  setUpMessage = (violationId) => {
    this.message = {
      text: "",
      violationId,
      authorId: User.id,
      files: [],
    };
    this.messages = [];
    this.isBlock = true;
    this.getMessages(violationId);
  };

  close;

  addFiles = (files) => {
    this.message.files.push(...files);
  };

  addFilesAndValidate = (files) => {
    if (files.length > 0) {
      this.valid = true;
    }
    this.addFiles(files);
  };

  deleteFile = (id) => {
    this.message.files = this.message.files.filter((file) => file.id !== id);
  };

  deleteFileAndValidate = (id) => {
    this.deleteFile(id);
    if (this.message.files.length === 0 && this.message.text.length === 0)
      this.valid = false;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new Chat();
