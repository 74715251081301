import React, { FC, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Scrollbars } from "react-custom-scrollbars";
import NewsItemStore from "../../../stores/newsItem";
import { NewsItemFull } from "../../../Main/Pages/News/NewsItemComponent/NewsItemFull";

import "./index.scss";

const StickyNewsSection: FC<{ isSticky: boolean; id: string; news: any[] }> = ({
  isSticky,
  id,
  news,
}) => {
  const scrollBar = useRef<Scrollbars>(null);

  useEffect(() => {
    scrollBar.current && scrollBar.current.scrollTop(0);
  }, [id]);

  return (
    <div
      className={
        isSticky
          ? "sticky-news-section__sticky"
          : "sticky-news-section__not-sticky"
      }
    >
      <Scrollbars
        ref={scrollBar}
        renderThumbVertical={(props) => (
          <div {...props} className="sticky-news-section__thumb-vertical" />
        )}
        renderTrackVertical={(props) => (
          <div {...props} className="sticky-news-section__track-vertical" />
        )}
      >
        <div className="sticky-news-section__news">
          {isSticky && <h2 className="sticky-news-section__title">Новости</h2>}

          <NewsItemFull
            news={news}
            newsItem={NewsItemStore.newsItem}
            errorStatus={NewsItemStore.errorStatus}
            mainPhoto={NewsItemStore.mainPhoto}
            attachedPhotos={NewsItemStore.attachedPhotos}
            prevButtonDisabled={!!news?.length ? news[0].id === id : false}
            nextButtonDisabled={
              !!news?.length ? news[news?.length - 1].id === id : false
            }
          />
        </div>
      </Scrollbars>
    </div>
  );
};

export default observer(StickyNewsSection);
