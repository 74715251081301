import React, { FC } from "react";
import { ReactComponent as CityLogo } from "../../../Common/images/ekb_logo.svg";

import "./index.scss";

const DummyShopPhoto: FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <div className="dummy-shop-photo" onClick={() => onClick && onClick()}>
      <div className="dummy-shop-photo__text">
        <CityLogo />
      </div>
    </div>
  )
}

export default DummyShopPhoto;