import React, { useState } from "react";
import { Link } from "react-router-dom";
import getDateAndTime from "../../../../utils/getDateAndTime";

import { ReactComponent as NotificationsActiveIcon } from "../../../../Common/images/notifications-active.svg";
import { ReactComponent as NotificationsDisabledIcon } from "../../../../Common/images/notifications-disabled.svg";
import { ReactComponent as Arrow } from "../../../../Common/images/greenArrowRight.svg";

import "./index.scss";
import NotificationsStore from "../../../../Project/stores/app/NotificationsStore";
import {
  URL_PROFILE_NOTIFICATIONS_ID,
  URL_PROFILE_PURCHASES_ID,
  URL_VOTE_ID,
} from "../../../../Project/routes/paths";
import Button from "../../../../Common/Components/Button";
import { AbsoluteLoader } from "../../Loader";

const NotificationsItem = (props) => {
  const { item } = props;
  const { id, body, text, date, isChecked } = item;

  const { dm, time } = getDateAndTime(date);

  const link = URL_PROFILE_NOTIFICATIONS_ID(body.id);
  const [isLoading, setIsLoading] = useState(false);

  const openViolation = () => {
    setIsLoading(true);
    NotificationsStore.checkNotification(id).finally(() => setIsLoading(false));
  };

  return (
    <div className="notifications-item">
      {isLoading && <AbsoluteLoader />}
      <div className="notifications-item__status">
        {isChecked ? (
          <NotificationsDisabledIcon />
        ) : (
          <NotificationsActiveIcon />
        )}
        <span>
          {dm}, {time}
        </span>
      </div>
      <span className="notifications-item__type">{text}</span>
      <h3 className="notifications-item__title">{body.title}</h3>
      {body.status === "violation" && (
        <Link
          className="notifications-item__link"
          to={link}
          onClick={openViolation}
        >
          <span>Просмотр сообщения</span>
          <Arrow />
        </Link>
      )}
      {body.status === "chat" && (
        <Link
          className="notifications-item__link"
          to={link}
          onClick={openViolation}
        >
          <span>Открыть чат</span>
          <Arrow />
        </Link>
      )}
      {body.status === "purchase" && (
        <Link
          className="notifications-item__link"
          to={URL_PROFILE_PURCHASES_ID(body.id)}
          onClick={openViolation}
        >
          <span>Просмотр покупки</span>
          <Arrow />
        </Link>
      )}
      {body.status === "voteResult" && (
        <Link
          className="notifications-item__link"
          to={URL_VOTE_ID(body.id)}
          onClick={openViolation}
        >
          <span>Просмотр голосования</span>
          <Arrow />
        </Link>
      )}
      {body.status === "question" && (
        <div className="notifications-item__question">
          <p>{body.answer}</p>
          {!isChecked && (
            <Button
              onClick={openViolation}
              type="primary"
              title="Просмотрено"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationsItem;
