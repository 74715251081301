import "./index.scss";

import React from "react";

const Loader = () => (
  <>
    <style>
      {`
      .throbber-container {
        height:35vh;
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .throbber-svg .throbber-big {
        width: 104px;
        height: 104px;
        margin: auto;
      }

      .throbber-big svg {
        width: 104px;
        height: 104px;
        -webkit-transform-origin: 0 52px;
        transform-origin: 0 54px;
      }

      @-webkit-keyframes animate-circle {
        from {
            -webkit-transform: scale(0);
                    transform: scale(0);
            opacity: 1;
        }
        to {
            -webkit-transform: scale(1);
                    transform: scale(1);
            opacity: 0;
        }
    }
    
    @keyframes animate-circle {
        from {
            -webkit-transform: scale(0);
                    transform: scale(0);
            opacity: 1;
        }
        to {
            -webkit-transform: scale(1);
                    transform: scale(1);
            opacity: 0;
        }
    }
    
    body {
        // background: #55BB31;
    }
    
    .loader {
        --size: 10rem;
        
        position: fixed;
        top: 50%;
        left: 50%;
        height: var(--size);
        width: var(--size);
        -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
    }
    
    .loader > .circle {
        --duration: 2s;
        position: absolute;
        height: inherit;
        width: inherit;
        background: #55BB31;
        border-radius: 50%;
        -webkit-animation: animate-circle var(--duration) cubic-bezier(.9,.24,.62,.79) infinite;
                animation: animate-circle var(--duration) cubic-bezier(.9,.24,.62,.79) infinite;
    }
    
    .loader > .circle:nth-of-type(1) {
        -webkit-animation-delay: 0;
                animation-delay: 0;
    }
    
    .loader > .circle:nth-of-type(2) {
        -webkit-animation-delay: calc(var(--duration) / -3);
                animation-delay: calc(var(--duration) / -3);
    }
    
    .loader > .circle:nth-of-type(3) {
        -webkit-animation-delay: calc(var(--duration) / -6);
                animation-delay: calc(var(--duration) / -6);
    }
    `}
    </style>
    <div className="loader">
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
    </div>
  </>
);

const LoaderSpinner = () => {
  return (
    <div className="throbber-container">
      <div className="white"></div>
      <div className="throbber-svg throbber-window">
        <div>
          <h3 className="line-1">Идёт загрузка</h3>
          <div className="line-2">Это может занять некоторое время</div>
        </div>
        <div className="throbber-big">
          <Loader />
        </div>
      </div>
    </div>
  );
};

export const AbsoluteLoader = () => {
  return (
    <div className="absolute-loader">
      <Loader />
    </div>
  );
};

export default LoaderSpinner;
