import axios from "axios";

export class FetchService {
  get(url, data, token) {
    return this.fetch(url, "get", data, token);
  }

  post(url, data, token) {
    return this.fetch(url, "post", data, token);
  }

  fetch(url, method, data, token) {
    return axios(
      {
        url,
        method,
        data,
        //   headers: {
        //     Authorization: `bearer ${token}`
        //   }
      }
      //   {
      //     withCredentials: true,
      //     credentials: "same-origin",
      //     crossdomain: true,
      //     mode: "no-cors",
      //     headers: {
      //       "Access-Control-Allow-Origin": "*",
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //     },
      //   }
    );
  }
}

export default new FetchService();
