import * as api from "./openapi";
import { axiosInstance, config } from "..";
import { URL_API_NEWS } from "../../constants/api";

export const NEWS = {
  News: new api.NewsApi(config, URL_API_NEWS, axiosInstance),
  FAQRubrics: new api.FAQRubricsApi(config, URL_API_NEWS, axiosInstance),
  FAQsApi: new api.FAQsApi(config, URL_API_NEWS, axiosInstance),
  MobileProject: new api.MobileProjectApi(config, URL_API_NEWS, axiosInstance),
  NewsRubrics: new api.NewsRubricsApi(config, URL_API_NEWS, axiosInstance),
  ProjectDescription: new api.ProjectDescriptionApi(
    config,
    URL_API_NEWS,
    axiosInstance
  ),
  Documents: new api.DocumentsApi(config, URL_API_NEWS, axiosInstance),
};
