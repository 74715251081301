import axios from "axios";
import { makeAutoObservable } from "mobx";
import { API } from "../api";
import { BASE_URL } from "../constants/api";
import fetchService from "../utils/fetch";

class About {
  constructor() {
    this.about = "";
    this.errorStatus = undefined;
    this.faqs = [];
    this.rubrics = [];
    this.answer = undefined;
    this.faq = "";

    makeAutoObservable(this);
  }

  fetchAboutInfo = async () => {
    await fetchService
      .get(BASE_URL + "news/api/projectdescription/content")
      .then((res) => {
        if (res.status === 200) {
          this.about = res.data;
        }
      })
      .catch((error) => {
        console.error("fetchAboutInfo", error);
        if (error && error.response) {
          const { status } = error.response;
          this.errorStatus = status;
        }
      });
  };

  fetchFaqs = () => {
    fetchService
      .get(BASE_URL + "news/api/faq/list")
      .then((res) => {
        if (res.status === 200) {
          this.faqs = res.data.items || [];
        }
      })
      .catch((error) => {
        console.error("fetchFaqs", error.response);
      });
  };

  fetchRubricList = () => {
    fetchService
      .get(BASE_URL + "news/api/faq/rubric/list")
      .then((res) => {
        if (res.status === 200) {
          this.rubrics = res.data;
        }
      })
      .catch((error) => {
        console.error("fetchFaqs", error.response);
      });
  };

  getAnswer = (id) => {
    return API.news.FAQsApi.faqIdGet(id);
  };

  resetStatuses = () => {
    this.errorStatus = undefined;
  };

  sendForm = async () => {
    const url = BASE_URL + "aisae/api/questions/create";
    const body = { question: this.faq };
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios
      .post(url, body, config)
      .then(() => {
        this.decline();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  onFieldChange = (value) => {
    this.faq = value;
  };

  decline = () => {
    this.faq = "";
  };
}

export default new About();
