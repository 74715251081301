import React, { FC, useEffect } from "react";
import { observer } from "mobx-react";
import NewsStore from "../../../stores/news";
import { ReactComponent as ArrowDownIcon } from "../../../Common/images/ArrowDown.svg";
import "./index.scss";

const NewsRubrics: FC = () => {
  const { setCountRubricsItem } = NewsStore;

  useEffect(() => {
    const resize = () => {
      if (1024 < window.innerWidth && window.innerWidth < 1280) {
        setCountRubricsItem(0);
      }
      if (window.innerWidth <= 1024) {
        setCountRubricsItem(3);
      }
      if (window.innerWidth >= 1280) {
        setCountRubricsItem(9);
      }
    };

    resize();

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [setCountRubricsItem]);

  return (
    <div className="news-rubrics">
      <div
        className={`news-rubrics__button ${
          !NewsStore.selectedRubrics.length
            ? "news-rubrics__button--active"
            : ""
        }`}
        onClick={() => {
          NewsStore.onClickRubric(null);
        }}
      >
        Все
      </div>
      {NewsStore.rubrics
        .slice(0, NewsStore.countRubricsItem)
        .map((rubric, idx) => (
          <div
            key={idx}
            className={`news-rubrics__button ${
              NewsStore.selectedRubrics.includes(rubric.id)
                ? "news-rubrics__button--active"
                : ""
            }`}
            onClick={() => {
              NewsStore.onClickRubric(rubric.id);
            }}
          >
            {rubric.title}
          </div>
        ))}
      {NewsStore.isShowRubrics &&
        NewsStore.rubrics
          .slice(NewsStore.countRubricsItem)
          .map((rubric, idx) => (
            <div
              key={idx}
              className={`news-rubrics__button ${
                NewsStore.selectedRubrics.includes(rubric.id)
                  ? "news-rubrics__button--active"
                  : ""
              }`}
              onClick={() => {
                NewsStore.onClickRubric(rubric.id);
              }}
            >
              {rubric.title}
            </div>
          ))}
      {NewsStore.rubrics?.length > NewsStore.countRubricsItem + 1 && (
        <button
          className={`news-rubrics__button-alt`}
          onClick={() => {
            NewsStore.setIsShowRubrics(!NewsStore.isShowRubrics);
          }}
        >
          {NewsStore.isShowRubrics ? (
            <>
              Скрыть <ArrowDownIcon className="news-rubrics__arrow-top" />
            </>
          ) : (
            <>
              И ещё {NewsStore.rubrics?.length - NewsStore.countRubricsItem}{" "}
              <ArrowDownIcon />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default observer(NewsRubrics);
