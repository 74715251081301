import "../Common/fonts/fonts.css";
import "../Common/reset.css";
import "../Common/vars.scss";
import "./AppWrapper.scss";
import "./index.scss";
import "core-js";
import "core-js/stable";
import "leaflet/dist/leaflet.css";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "regenerator-runtime/runtime";

import { observer, Provider } from "mobx-react";
import React from "react";

import { history } from "../Project/helpers/history";
import ScrollToTop from "../utils/ScrollTopRouter";
import App from "./App";

const AppWrapper = () => {
  const historyStore = { history };
  return (
    <Provider {...historyStore}>
      <ScrollToTop />
      <App />
    </Provider>
  );
};

export default observer(AppWrapper);
