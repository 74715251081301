import {
  URL_VOTES,
  URL_NEWS,
  URL_VIOLATIONS,
  URL_CONDITIONS,
  URL_AGREEMENT,
  URL_ABOUT,
  URL_SHOP,
  URL_POLICY,
} from "../routes/paths";

export const navigateLinks = [
  { path: URL_VOTES, content: "Опросы" },
  { path: URL_VIOLATIONS, content: "Сообщить о проблеме" },
  { path: URL_NEWS, content: "Новости" },
  { path: URL_SHOP, content: "Магазин поощрений" },
  { path: URL_ABOUT, content: "О проекте" },
  // { path: QUARTERLY_URL, content: "Квартальные" },
];

export const footerLinks = [
  {
    content: "Условия участия в проекте Активный Екатеринбург",
    path: URL_CONDITIONS,
  },
  {
    content: "Соглашение о пользовании информационными системами",
    path: URL_AGREEMENT,
  },
  {
    content: "Политика обработки персональных данных",
    path: URL_POLICY,
  },
];

export const copyrights = [
  `2020 - ${new Date().getFullYear()}. Активный Екатеринбург. Все права защищены`,
];
