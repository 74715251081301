import { IOperationsHistoryItem } from "../../stores/app/myPointsStore";

export type IPointsHistoryItemProps = {
  item: IOperationsHistoryItem;
};

interface IpointsActions {
  [key: string]: string;
}
export const pointsActions: IpointsActions = {
  Vote: "Прохождение голосования",
  Purchase: "Покупка",
  Default: "Действие",
};
