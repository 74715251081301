import { makeAutoObservable } from "mobx";

class ProfileDropzone {
  constructor() {
    this.acceptedFiles = [];
    makeAutoObservable(this);
  }

  addfiles = (files) => {
    this.acceptedFiles.push(...files);
  };

  deleteFile = (id) => {
    this.acceptedFiles = this.acceptedFiles.filter((file) => file.id !== id);
  };
}

export default new ProfileDropzone();
