import React from "react";
import "./index.scss";
import { observer } from "mobx-react";

import login from "../../../utils/login";

import { ReactComponent as LoginIcon } from "../../images/login.svg";

import User from "../../../stores/user";
import ProfileMobileMenu from "../ProfileMobileMenu";

const MobileLinkToProfile = () => {
  const { isAuth } = User;

  return isAuth ? (
    <ProfileMobileMenu />
  ) : (
    <button className="MobileLinkToProfile" onClick={login}>
      <LoginIcon />
    </button>
  );
};

export default observer(MobileLinkToProfile);
