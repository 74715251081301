import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import Filter from "../FilterType";
import NotificationsItem from "./NotificationsItem";
import Popup from "../../../Common/Components/Popup";
import DropDown from "../DropDown";

import "./index.scss";
import InfinityScroll from "../../../Project/components/InfinityScroll";
import NotificationsStore from "../../../Project/stores/app/NotificationsStore";
import LoaderSpinner from "../Loader";

const Notifications = () => {
  const {
    type,
    reset,
    setType,
    isLoading,
    notifications,
    filterOptions,
    isRequestMore,
    dropdownOptions,
    itemsPerRequest,
    selectedTypeName,
    getNotifications,
    getNotificationsMore,
  } = NotificationsStore;

  const [openQuestion, setOpenQuestion] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState({
    title: "",
    body: "",
  });

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  useEffect(() => {
    getNotifications(type, undefined, itemsPerRequest);
  }, [type]);

  const openPopupQuestion = (title, body) => {
    setCurrentQuestion({ title, body });
    setOpenQuestion(true);
  };

  const closePopupQuestion = () => {
    setOpenQuestion(false);
    setCurrentQuestion({ title: "", body: "" });
  };

  if (isLoading)
    return (
      <div className="notifications">
        <LoaderSpinner />
      </div>
    );

  return (
    <div className="notifications">
      <div className="notifications__filter">
        <Filter options={filterOptions} currentType={type} />
      </div>
      <div className="notifications__filter-adaptive">
        <DropDown
          isReadOnly={true}
          value={selectedTypeName}
          options={dropdownOptions}
          onSubmit={(id) => {
            setType(id);
          }}
        />
      </div>
      <InfinityScroll
        request={getNotificationsMore}
        isRequestMore={isRequestMore}
      >
        <div className="notifications__list">
          {notifications.map((item) => (
            <NotificationsItem
              item={item}
              key={item.id}
              openPopupQuestion={openPopupQuestion}
            />
          ))}
        </div>
      </InfinityScroll>

      {openQuestion && (
        <Popup
          header={currentQuestion.title}
          content={currentQuestion.body}
          onDiscard={closePopupQuestion}
          discardTitle="Закрыть"
        />
      )}
    </div>
  );
};

export default observer(Notifications);
