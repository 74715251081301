import { observer } from "mobx-react";
import React from "react";
import cn from "classnames";
import { NEWS_ITEM_IMAGE_URL } from "../../../../constants/api";
import normalizeDate from "../../../../utils/normalizeDate";
import "./index.scss";

import { ReactComponent as HasVideoIcon } from "../../../../Common/images/hasVideo.svg";
import { ReactComponent as HasPhotoIcon } from "../../../../Common/images/hasPhoto.svg";

export const NewsList = ({ onNewsItemClick, pathId, news }) => {
  return (
    <>
      {news &&
        news.slice().map((item) => {
          return (
            <div
              onClick={() => onNewsItemClick(item.id)}
              key={item.id}
              className={"newsListItem"}
            >
              <div
                className={"newsItemImage"}
                style={{
                  backgroundImage: `url(${NEWS_ITEM_IMAGE_URL}${item.id}/croppedimage)`,
                }}
              />
              <div className={"newsItemInfo"}>
                <div className={"newsDate"}>
                  <span>{normalizeDate(item.date)}</span>
                  <div className="hasMedia">
                    {item.hasPhoto && <HasPhotoIcon />}
                    {item.hasVideo && <HasVideoIcon />}
                  </div>
                </div>
                <h4
                  className={cn(
                    "newsItemText",
                    item.id === pathId ? "active" : ""
                  )}
                >
                  {item.title}
                </h4>
              </div>
              <div className={item.id === pathId ? "lineActive" : "line"}></div>
            </div>
          );
        })}
    </>
  );
};

export default observer(NewsList);
