import "./index.scss";

import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import cn from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import splitName from "../../../utils/splitName";
import { profileTabs } from "../../../constants/profile";
import Profile from "../../../stores/profile";
import User from "../../../stores/user";
import ViolationStore from "../../../stores/violations";
import logout from "../../../utils/logout";
import { ReactComponent as CloseIcon } from "../../images/close.svg";
import { ReactComponent as LogoutIcon } from "../../images/logout.svg";
import Avatar from "../Avatar";
import Button from "../Button";
import NotificationsStore from "../../../Project/stores/app/NotificationsStore";
import { URL_PROFILE_EDIT } from "../../../Project/routes/paths";

const MobileLinkToProfile = () => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const { idp, imageSrc, setImageSrc, hasPhoto, firstName, lastName } = User;
  const { hasNew } = NotificationsStore;

  const switchShow = () => {
    setShow((show) => !show);
  };

  const hideAndOpenTab = (value) => {
    Profile.setTab(value);
    switchShow();
  };

  const { newMessageInViolation, checkNewMessagesInMyViolation } =
    ViolationStore;

  useEffect(() => {
    setImageSrc();
  }, [hasPhoto, setImageSrc]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkNewMessagesInMyViolation();
    }, 3000);

    return () => clearInterval(interval);
  }, [checkNewMessagesInMyViolation]);

  useEffect(() => {
    const menuProfile = ref.current;

    if (show && menuProfile) {
      disableBodyScroll(menuProfile);
    } else {
      enableBodyScroll(menuProfile);
    }

    return () => enableBodyScroll(menuProfile);
  }, [show]);

  return (
    <>
      <button
        className={`MobileLinkToProfile ${show ? "active" : ""}`}
        onClick={switchShow}
      >
        {show ? (
          <CloseIcon />
        ) : (
          <Avatar
            notifications={hasNew && !show}
            imageSrc={imageSrc}
            altText={splitName(firstName, lastName)}
          />
        )}
      </button>
      <div className={`ProfileMobileMenu ${show ? "active" : ""}`} ref={ref}>
        <div className="ProfileMobileMenu__img">
          <Avatar
            imageSrc={imageSrc}
            altText={splitName(firstName, lastName)}
          />
          <button className="button-icon" onClick={logout}>
            <LogoutIcon />
          </button>
        </div>
        <div className="ProfileMobileMenu__links">
          {profileTabs &&
            profileTabs.map((item, idx) => {
              const addNotificationsIcon =
                item.content === "Уведомления" && hasNew
                  ? "link__notification"
                  : "";

              const addNewMessageIcon =
                item.content === "Мои сообщения" && newMessageInViolation
                  ? "link__new-message"
                  : "";

              return (
                <Link
                  className={cn(
                    "ProfileMobileMenu__item",
                    addNotificationsIcon,
                    addNewMessageIcon
                  )}
                  key={idx}
                  onClick={() => hideAndOpenTab(item.value)}
                  to={item.path}
                >
                  {item.content}
                </Link>
              );
            })}
        </div>
        <span className={`ProfileMobileMenu__stats`}>
          Заполненность профиля{" "}
          <span className={idp === 100 ? "lightGreen" : "green"}>{idp}%</span>
        </span>
        <Link
          to={URL_PROFILE_EDIT}
          onClick={() => {
            hideAndOpenTab("edit");
          }}
        >
          <Button title="Редактировать данные" type="primary" />
        </Link>
      </div>
    </>
  );
};

export default observer(MobileLinkToProfile);
