import React, { useState } from "react";

import SubmitButton from "../../../Common/Components/SubmitButton";

import "./index.scss";

const Switcher = (props) => {
  const { options } = props;

  const active = options.find((item) => item.active);
  const [type, switchType] = useState(active.type);

  return (
    <div className="filter">
      {options &&
        options.map((item, idx) => {
          return (
            <SubmitButton
              text={item.text}
              onClick={item.action ? item.action : () => {}}
              type={item.type}
              switchType={switchType}
              active={type === item.type}
              setTypeFormFilter={item.setViewModeFilter}
              key={idx}
            />
          );
        })}
    </div>
  );
};

export default Switcher;
