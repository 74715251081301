import "./index.scss";
import "leaflet/dist/leaflet.css";

import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import AuthWarning from "../../../Common/Components/AuthWarning";
import RadioButtons from "../../../Common/Components/Radiobuttons";
import { ReactComponent as CloseIcon } from "../../../Common/images/close.svg";
import { ReactComponent as ReactLogo } from "../../../Common/images/Coat_of_Arms_of_Yekaterinburg_(Sverdlovsk_oblast).svg";
import WasBecame from "../../../Project/components/WasBecame";
import BurgerMenu from "../../../stores/burgerMenu";
import Chat from "../../../stores/chat";
import User from "../../../stores/user";
import violationsStore from "../../../stores/violations";
import normalizeDate from "../../../utils/normalizeDate";
import setStatus from "../../../utils/setstatus";
import FileComponent from "../../Components/File";
import FileViewer from "../../Components/FileViewer";
import MapComponent from "../../Components/Map";
import SupportChat from "../../Components/SupportChat";
import ErrorComponent from "../../../Project/components/ErrorComponent";
import { API } from "../../../api";
import FileSaver from "file-saver";

const ViolationComponent = inject("history")(
  observer((props) => {
    // for Popup FileViewer
    const [showFiles, setShowFiles] = useState(false);
    const [isShowResultFilesModal, setIsShowResultFilesModal] = useState(false);
    const [startSlide, setStartSlide] = useState(0);
    // closeFullStatement only for MyStatement component
    const { history, openChat } = props;
    const { pathname } = history.location;
    // @ts-ignore: Unreachable code error
    /* eslint-disable */
    const { isAuth, id: userId } = User;
    const { newMessages, syncMessages, getMessages } = Chat;
    const { fixed } = BurgerMenu;

    const path = pathname.split("/");
    const [, , id, myId] = path;

    const isPopup = id === "statements" || id === "notifications";

    const {
      fetchViolation,
      violation,
      handleChangeAgreement,
      handleSupporViolation,
      selected,
      options,
      errorStatus,
      resetViolation,
      resetStatuses,
    } = violationsStore;

    const {
      category,
      date,
      status,
      number,
      coordinates,
      fileReferences,
      address,
      isOwn,
      subCategories,
      supportCount,
      isSupported,
      firstName,
      middleName,
      lastName,
    } = violation;

    const closeFullStatement = () => {
      history.push(`/profile/` + id); // переменная id в ViolationPopup является /statements или /notifications из /profile
    };

    useEffect(() => {
      // Fetch happens in Profile before
      // Start only in page /vilation/{id}
      if (!isPopup) {
        fetchViolation(id);
      } else {
        fetchViolation(myId);
      }
      resetStatuses();

      return () => resetViolation();
    }, [fetchViolation, id, isPopup, resetStatuses, myId]);

    // button for burger menu in Profile
    useEffect(() => {
      if (isPopup) {
        BurgerMenu.setButton({
          title: "Октрыть чат",
          onClick: openChat,
          type: newMessages ? "button_secondary" : "primary",
        });
      }
      return () => BurgerMenu.setButton(null);
    }, [isPopup, openChat, newMessages]);

    useEffect(() => {
      if (isPopup && myId) {
        getMessages(myId);
      }
    }, [isPopup, myId, violation, getMessages]);

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     if (myId) {
    //       syncMessages(myId);
    //     }
    //   }, 3000);

    //   return () => clearInterval(interval);
    // }, [syncMessages, myId]);

    const date_ = date && normalizeDate(date);

    const openFileViewer = (index) => {
      setStartSlide(index);
      setShowFiles(true);
    };

    const convertType = (type) => {
      switch (type) {
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "application/docx";
        case "application/pdf":
          return "application/pdf";
        case "application/msword":
          return "application/doc";
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          return "application/pptp";
        case "application/vnd.ms-powerpoint":
          return "application/pptp";
        case "application/vnd.ms-excel":
          return "application/xls";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "application/xlsx";
        default:
          return;
      }
    };

    const handleClickLink = async (file) => {
      await API.aisae.Violations.apiViolationsFileGet(file.id, {
        responseType: "blob",
      }).then((res) => {
        const { data } = res;

        const name = `${file.id}.${convertType(data.type).split("/")[1]}`;
        const fileData = new File([data], name);
        FileSaver.saveAs(fileData, name);
      });
    };

    const MultimediaFiles = [];

    violation?.closingAttachedFiles?.forEach((item) => {
      const type = item.contentType
        .match(/image|video|application/im)[0]
        .toLowerCase();

      if (type === "image") {
        MultimediaFiles.push({
          id: item.id,
          reference: `api/violations/file?fileId=${item.id}`,
          type: item.contentType.split("/")[0],
        });
      }
      if (type === "video") {
        MultimediaFiles.push({
          id: item.id,
          reference: `api/violations/file?fileId=${item.id}`,
          type: item.contentType.split("/")[0],
        });
      }
    });

    console.log("MultimediaFiles", MultimediaFiles);

    return !errorStatus ? (
      <div className="violation component">
        {
          // for Profile Close Btn
          isPopup ? (
            <>
              <button
                className={`button-icon close-btn`}
                onClick={closeFullStatement}
              >
                <CloseIcon />
              </button>
              <button
                className={`button-icon close-btn-burger 
                  ${fixed ? "fixed" : ""}`}
                onClick={closeFullStatement}
              >
                <CloseIcon />
              </button>
            </>
          ) : null
        }
        <div className="component-date">
          <span className="number">N {number}</span>
          <span className="date">{date_}</span>
          <div className={`item__status ${status}`}>
            <span className="item__status-text">{setStatus(status)}</span>
          </div>
        </div>
        <div className="header">
          <h1>{category}</h1>
        </div>
        <div className="header">
          <h2>Подкатегории</h2>
        </div>
        <div className="subcategory">
          {subCategories &&
            subCategories?.map((item, idx) => {
              return <p key={idx}>{item}</p>;
            })}
        </div>
        <div className="files-block">
          <div className="header">
            <h2>Файлы ({fileReferences && fileReferences.length})</h2>
          </div>
          <div className="files">
            {fileReferences &&
              fileReferences?.map((item, idx) => (
                <FileComponent
                  item={item}
                  key={idx}
                  onClick={() => openFileViewer(idx)}
                />
              ))}
          </div>
          <div className="address">
            <div className="header">
              <h2>Адрес</h2>
            </div>
            <span>{address}</span>
          </div>
        </div>
        {coordinates && <MapComponent coordinates={coordinates} zoom={16} />}
        {isAuth ? (
          !isPopup && !isSupported && !isOwn ? (
            <div className="support-block">
              {status !== "rejected" && status !== "finished" && (
                <>
                  <div className="header">
                    <h3>Поддержите сообщение</h3>
                  </div>
                  <span className="email">уведомлять меня по e-mail</span>
                  <RadioButtons
                    selected={selected}
                    click={handleChangeAgreement}
                    options={options}
                    name={"support"}
                  />

                  <div
                    className="button"
                    onClick={() => {
                      handleSupporViolation(id);
                    }}
                  >
                    <span>Поддержать</span>
                  </div>
                </>
              )}
            </div>
          ) : (
            status !== "rejected" &&
            status !== "finished" && (
              <div
                className={`support-block ${
                  isPopup ? "support-block_popup" : "support-block_result"
                }`}
              >
                <h3 className="support-block__header">
                  это сообщение поддержали
                </h3>
                <h4 className="support-block__info">{supportCount} человек</h4>
              </div>
            )
          )
        ) : (
          <div className="support-block">
            <AuthWarning warning="Для того чтобы поддержать сообщение необходимо авторизоваться!" />
          </div>
        )}
        {isPopup ? <SupportChat /> : null}
        {showFiles && (
          <FileViewer
            files={fileReferences}
            startSlide={startSlide}
            setShowFiles={setShowFiles}
          />
        )}

        {(status === "finished" || status === "rejected") && (
          <div className="result-block">
            <div className="result-block__header">
              <ReactLogo className="result-block__header-logo" />
              <div className="result-block__header-text">Ответ ведомства</div>
            </div>
            <div className="result-block__date">
              <span className="result-block__date-from">{date_}</span>
              <span className="result-block__date-from">-</span>
              <span className="result-block__date-to">
                {violation.dateClose && normalizeDate(violation.dateClose)}
              </span>
            </div>
            <div className="result-block__comment">
              <h3 className="result-block__comment-header">Комментарий</h3>
              <h3 className="result-block__comment-text">
                {violation.closingComment ?? "Нет комментария"}
              </h3>
            </div>
            <div className="result-block__files">
              <div className="result-block__files-header">
                Файлы ({violation?.closingAttachedFiles?.length})
              </div>
              <div className="result-block__files-files">
                {violation?.closingAttachedFiles?.map((item, idx) => {
                  const type = item.contentType
                    .match(/image|video|application/im)[0]
                    .toLowerCase();
                  return (
                    <>
                      <FileComponent
                        item={{
                          id: item.id,
                          reference: `api/violations/file?fileId=${item.id}`,
                          type: item.contentType,
                        }}
                        key={idx}
                        onClick={() => {
                          (type === "image" || type === "video") &&
                            setIsShowResultFilesModal(true);
                          type === "application" &&
                            handleClickLink({
                              id: item.id,
                              reference: `api/violations/file?fileId=${item.id}`,
                              type: item.contentType,
                            });
                        }}
                        className={type === "application" && "application"}
                      />
                    </>
                  );
                })}
              </div>
            </div>
            {(!!violation.wasPhotos[0] || !!violation.becamePhotos[0]) && (
              <>
                <div className="result-block__files-header">Было/Стало</div>
                <WasBecame
                  wasFile={{
                    reference:
                      violation.wasPhotos[0] &&
                      `api/violations/file?fileId=${violation.wasPhotos[0]}`,
                    type: "image",
                  }}
                  becameFile={{
                    reference:
                      violation.becamePhotos[0] &&
                      `api/violations/file?fileId=${violation.becamePhotos[0]}`,
                    type: "image",
                  }}
                />
              </>
            )}
            {isShowResultFilesModal && (
              <FileViewer
                files={MultimediaFiles}
                startSlide={0}
                setShowFiles={() => {
                  setIsShowResultFilesModal(false);
                }}
              />
            )}
          </div>
        )}
      </div>
    ) : (
      <ErrorComponent errorStatus={errorStatus} />
    );
  })
);

export default ViolationComponent;
