import { makeAutoObservable } from "mobx";

class Loading {
  loading = false;

  turnOn = () => {
    this.loading = true;
  };

  turnOff = () => {
    this.loading = false;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new Loading();
