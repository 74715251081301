import React, { useEffect } from "react";
import { observer } from "mobx-react";

import Radiobuttons from "../../../Common/Components/Radiobuttons";
import Checkbox from "../../../Common/Components/Checkboxes";

import settingsStore from "../../../stores/settings";

import "./index.scss";
import SettingsQuestions from "./SettingsQuestions";

const Settings = () => {
  const { settings, fetchSettigns, changeCheckBox, changeRadioButton } =
    settingsStore;
  const { events, news } = settings;

  useEffect(() => {
    fetchSettigns();
  }, [fetchSettigns]);

  const changeNews = (idx) => {
    changeRadioButton("news", idx);
  };

  const changeEvents = (idx) => {
    changeCheckBox("events", idx);
  };

  return (
    <div className="Settings">
      {news?.length && (
        <div className="Settings__section">
          <h1>Подписка на события</h1>
          <p>Хочу Получать информацию о событиях проекта на e-mail</p>
          <Radiobuttons
            click={changeNews}
            options={news}
            name={"newsNotification"}
          />
        </div>
      )}
      {events?.length && (
        <div className="Settings__section">
          <p>Уведомлять меня по почте об изменениях в разделах:</p>
          <Checkbox
            click={changeEvents}
            options={events}
            name={"newsNotification"}
          />
        </div>
      )}
      <div className="Settings__line"></div>
      <SettingsQuestions />
    </div>
  );
};

export default observer(Settings);
