import { makeAutoObservable, runInAction } from "mobx";
import { AISAE } from "../constants/api";
import debounce from "../utils/debounce";
import User from "./user";

class Address {
  value = "";
  position = [];
  map = null;
  showMap = "";
  loading = false;

  onChange = (value) => {
    this.value = value;
  };

  changePos = (value) => {
    this.position = value;
  };

  setMap = (map) => {
    this.map = map;
  };

  switchShowMap = () => {
    this.showMap = !this.showMap;
  };

  getAddress = async (latlng) => {
    try {
      const response = await fetch(
        AISAE +
          `api/Geolocations/addressbycoords?latitude=${latlng[0]}&longitude=${latlng[1]}`
      );

      if (!response.ok) throw new Error(`[Address]: Can't get Latlng`);

      const data = await response.text();

      this.value = data;
    } catch (error) {
      console.log(error);
    }
  };

  getLatlng = async (value) => {
    try {
      const response = await fetch(
        AISAE + "api/Geolocations/coordsbyadress?address=" + value
      );
      if (!response.ok) throw new Error(`[Address]: Can't get Latlng`);

      const data = await response.json();

      const { latitude, longitude } = data;
      this.changePos([latitude, longitude]);

      if (this.map) this.map.setView([latitude, longitude], 18);
    } catch (error) {
      console.error(error);
    }
  };

  setPosAndGetAddress = (latlng) => {
    this.position = latlng;

    this.getAddress(latlng);
  };

  debounceGetLatIng = debounce((x) => {
    this.loading = false; // set loading false when loaded
    this.getLatlng(x);
  }, 3000);

  setValueAndGetPos = (value) => {
    this.value = value;
    this.loading = true; // set loading true for user info
    this.debounceGetLatIng(value);
  };

  constructor() {
    makeAutoObservable(this);
  }
}

class EditProfileStore {
  isLoading = false;

  form = {
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    birthDate: "",
    snils: "",
    phoneNumber: "",
    email: "",
  };

  isGeolocationAllowed = false;
  dynamicImage = null;
  staticImage = null;
  photo = true;
  imageUrl = null;

  addresses = {
    living: new Address(),
    registration: new Address(),
  };

  saveImageUrl = (image) => {
    this.imageUrl = image.getCroppedCanvas().toDataURL();
  };

  setImageUrl = (src) => {
    this.imageUrl = src;
  };

  saveDynamicImage = (image) => {
    this.dynamicImage = image;
  };

  onClickisGeolocationAllowed = () => {
    this.isGeolocationAllowed = !this.isGeolocationAllowed;
  };

  checkGeolocationStatus = () => {
    if (this.isGeolocationAllowed) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;

        const coordsObject = {
          latitude: latitude,
          longitude: longitude,
        };
        User.setHasLocation(true);
        User.setLocation(coordsObject);
      });
    } else {
      User.setHasLocation(false);
      User.deleteLocation();
    }
  };

  setLoading = (bool) => {
    this.isLoading = bool;
  };

  changeForm = (value, name) => {
    this.form[name] = value;
  };

  saveStaticImage = (image) => {
    this.staticImage = !!image ? URL.createObjectURL(image) : null;
  };

  setPhoto = async (photo) => {
    try {
      photo.getCroppedCanvas().toBlob(async (blob) => {
        const formData = new FormData();

        formData.append("photo", blob);
        const res = await fetch(AISAE + "api/keycloak/photo", {
          method: "POST",
          body: formData,
        });

        if (res.status !== 200) throw new Error();
      });
      try {
        this.setLoading(true);

        const res = await fetch(AISAE + "api/keycloak/profile");

        if (res.status !== 200) throw new Error();

        const data = await res.json();

        if (data) {
          runInAction(() => {
            this.photo = data.hasPhoto;
          });
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {}
  };

  deletePhoto = async () => {
    try {
      const res = await fetch(AISAE + "api/keycloak/photo/delete", {
        method: "POST",
      });

      if (res.status !== 200) throw new Error();
    } catch (error) {}
  };

  getProfile = async () => {
    try {
      this.setLoading(true);

      const res = await fetch(AISAE + "api/keycloak/profile");

      if (res.status !== 200) throw new Error();

      const data = await res.json();

      if (data) {
        runInAction(() => {
          this.form = {
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            gender: data.gender,
            birthDate: data.birthDate,
            snils: data.snils,
            phoneNumber: data.phones.mobileNumber,
            homeNumber: data.phones.homeNumber,
            email: data.email,
          };

          this.isGeolocationAllowed = !!data.geolocation;
          this.addresses.registration.value = data.registrationAddress.address;
          if (data.registrationAddress.coordinates) {
            this.addresses.registration.coordinates = [
              data.registrationAddress.coordinates.latitude,
              data.registrationAddress.coordinates.longitude,
            ];
          }

          this.addresses.living.value = data.livingAddress.address;
          if (data.livingAddress.coordinates) {
            this.addresses.living.coordinates = [
              data.livingAddress.coordinates.latitude,
              data.livingAddress.coordinates.longitude,
            ];
          }

          this.photo = data.hasPhoto;
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  saveUserData = async () => {
    this.checkGeolocationStatus();

    try {
      this.setLoading(true);
      const body = JSON.stringify({
        isGeolocationAllowed: this.isGeolocationAllowed,
        firstName: this.form.firstName || "",
        lastName: this.form.lastName || "",
        middleName: this.form.middleName || "",
        email: this.form.email || "",
        birthDate: this.form.birthDate || "",
        phones: {
          mobileNumber: this.form.phoneNumber || "",
        },
        snils: this.form.snils || "",
        gender: this.form.gender || "",
        livingAddress: this.addresses.living.value || "",
        registrationAddress: this.addresses.registration.value || "",
        livingAddressCoordinates:
          this.addresses.living.position.length !== 0
            ? {
                latitude: this.addresses.living.position[0],
                longitude: this.addresses.living.position[1],
              }
            : null,
        registrationAddressCoordinates:
          this.addresses.registration.position.length !== 0
            ? {
                latitude: this.addresses.registration.position[0],
                longitude: this.addresses.registration.position[1],
              }
            : null,
      });
      await fetch(AISAE + "api/keycloak/edit", {
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
    } finally {
      this.setLoading(false);
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new EditProfileStore();
