import React, { useState } from "react";

import SubmitButton from "../../../Common/Components/SubmitButton";
import { observer } from "mobx-react";

import "./index.scss";

const Filter = (props) => {
  const { options, currentType } = props;
  const active = options.find((item) => item.active);

  const [type, switchType] = useState(currentType ? currentType : active.type);

  const onClick = (type, action) => {
    if (currentType !== type) action();
  };

  return (
    <>
      <div className="filter">
        {options &&
          options.map((item, idx) => {
            return (
              <SubmitButton
                text={item.text}
                onClick={() => onClick(item.type, item.action)}
                type={item.type}
                switchType={switchType}
                active={
                  currentType ? currentType === item.type : type === item.type
                }
                setTypeFormFilter={item.setTypeFormFilter}
                key={idx}
              />
            );
          })}
      </div>
      <div className="filter media-query">
        {options &&
          options.map((item, idx) => {
            return (
              <SubmitButton
                text={item.text}
                onClick={item.action}
                type={item.type}
                switchType={switchType}
                active={
                  currentType ? currentType === item.type : type === item.type
                }
                setTypeFormFilter={item.setTypeFormFilter}
                key={idx}
              />
            );
          })}
      </div>
    </>
  );
};

export default observer(Filter);
