import "./index.scss";

import { inject, observer } from "mobx-react";
import React from "react";
import { OKShareButton, TwitterShareButton, VKShareButton } from "react-share";

import { BASE_URL } from "../../../../../../constants/api";

export const NewsItemFooter = inject("history")(
  observer((props) => {
    const {
      news,
      history,
      id,
      title,
      image,
      prevButtonDisabled = false,
      nextButtonDisabled = false,
    } = props;
    const { pathname } = history.location;
    const [, newsRoute] = pathname.split("/");

    const onPrevClick = () => {
      const index = news.findIndex((item) => item.id === id);
      if (index > 0 && news.length) {
        history.push(`/${newsRoute}/${news[index - 1].id}`);
      }
    };

    const onNextClick = () => {
      const index = news.findIndex((item) => item.id === id);
      if (news.length && index < news.length - 1) {
        history.push(`/${newsRoute}/${news[index + 1].id}`);
      }
    };

    return (
      <div className={"footerWrapper"}>
        <h4>Поделиться в соцсетях</h4>
        <div className={"footerButtons"}>
          <div className={"social"}>
            <VKShareButton
              url={`${BASE_URL}news/${id}`}
              title={title}
              image={image}
            >
              <div className={"vk footerButtonsItem"}></div>
            </VKShareButton>

            <TwitterShareButton url={`${BASE_URL}news/${id}`} title={title}>
              <div className="twitter footerButtonsItem"></div>
            </TwitterShareButton>
            <OKShareButton
              url={`${BASE_URL}news/${id}`}
              // title={title}
              title={"Активный Екатеринбург"}
              description={title}
              image={image}
            >
              <div className="ok footerButtonsItem"></div>
            </OKShareButton>
          </div>
          <div className="footerWrapper__controll-buttons">
            <button
              disabled={prevButtonDisabled}
              onClick={onPrevClick}
              className={`controlsButton footerButtonsItem prev ${
                prevButtonDisabled ? "controlsButton--disabled" : ""
              }`}
            ></button>

            <button
              disabled={nextButtonDisabled}
              onClick={onNextClick}
              className={`controlsButton footerButtonsItem next ${
                nextButtonDisabled ? "controlsButton--disabled" : ""
              }`}
            ></button>
          </div>
        </div>
      </div>
    );
  })
);
