import React from "react";
import "./index.scss";
import { observer } from "mobx-react";

const Avatar = (props) => {
  const { notifications, imageSrc, altText } = props;

  return (
    <div className="Avatar-wrapper">
      {imageSrc ? (
        <img className={`Avatar`} src={imageSrc} alt={altText} />
      ) : (
        <div className={`Avatar`}>{altText}</div>
      )}
      {notifications && <div className="Avatar_notifications"></div>}
    </div>
  );
};

export default observer(Avatar);
