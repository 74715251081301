import React, { useEffect, useRef, useState } from "react";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";

import normalizeDate from "../../../../../utils/normalizeDate";
import { NewsItemFooter } from "./NewsItemFooter";
import {
  NEWS_FILE_API,
  NEWS_PHOTO_API,
  IMAGE_URL,
  NEWS_ITEM_IMAGE_URL,
} from "../../../../../constants/api";
import ShowFile from "../../../../Components/File";
import FileViewer from "../../../../Components/FileViewer";
import ErrorComponent from "../../../../../Project/components/ErrorComponent";

import "./index.scss";

export const NewsItemFull = inject("history")(
  observer((props) => {
    const {
      history,
      prevButtonDisabled,
      nextButtonDisabled,
      newsItem,
      errorStatus,
      mainPhoto,
      attachedPhotos,
      news,
    } = props;
    const { pathname } = history.location;
    const [, , id] = pathname.split("/");
    const [isOpenPhoto, setIsOpenPhoto] = useState(false);
    const [isOpenMedia, setIsOpenMedia] = useState(false);
    const [isOpenPhotos, setIsOpenPhotos] = useState(false);
    const contentRef = useRef(null);
    const [loadedMedia, setLoadedMedia] = useState([]);
    const [startSlide, setStartSlide] = useState(0);

    const handleShowPhoto = (idx) => {
      setStartSlide(idx);
      setIsOpenPhoto(true);
    };

    const handleClosePhoto = () => {
      setIsOpenPhoto(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleShowMedia = (idx) => {
      setStartSlide(idx);
      setIsOpenMedia(!isOpenMedia);
    };

    const handleCloseMedia = () => {
      setIsOpenMedia(false);
    };

    const handleShowPhotos = (idx) => {
      setStartSlide(idx);
      setIsOpenPhotos(!isOpenPhotos);
    };
    const handleClosePhotos = () => {
      setIsOpenPhotos(false);
    };

    useEffect(() => {
      (async () => {
        if (contentRef?.current?.innerHTML) {
          const imagesList = contentRef.current.getElementsByTagName("img");
          const figuresList =
            contentRef.current.getElementsByClassName("image");

          if (imagesList.length > 0 && figuresList.length > 0) {
            let result = [];

            figuresList?.forEach((figure, idx) => {
              figure.addEventListener("click", () => {
                handleShowMedia(idx);
              });
            });

            for (let idx = 0, length = imagesList.length; idx < length; idx++) {
              const data = await fetch(imagesList[idx].src);
              const blob = await data.blob();
              const imageFile = new File([blob], `image-${idx}`, blob);
              result.push(imageFile);
            }
            setLoadedMedia(result);
          } else {
            setLoadedMedia([]);
          }
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentRef?.current, id]);

    const morePhoto = newsItem.photos?.length > 3;

    if (!id) {
      return null;
    }

    let files = null;
    let showFiles = null;

    if (isOpenPhoto) {
      files = [...mainPhoto];
      showFiles = handleClosePhoto;
    }

    if (isOpenMedia) {
      files = [...loadedMedia];
      showFiles = handleCloseMedia;
    }

    if (isOpenPhotos) {
      files = [...attachedPhotos];
      showFiles = handleClosePhotos;
    }

    return !errorStatus ? (
      <>
        <div className={"date"}>
          {newsItem.date && normalizeDate(newsItem.date)}
        </div>
        <div className={"title"}>
          <h2>{newsItem.title}</h2>
        </div>
        <div className={"newsImageWrapper"}>
          <img src={`${IMAGE_URL}/news/${id}/previewimage`} alt="news main" />
          <button
            onClick={() => {
              handleShowPhoto(0);
            }}
            className={"button-icon imageScaleButton"}
          ></button>
        </div>
        <div className={"newsItemContent"}>
          <div
            ref={contentRef}
            dangerouslySetInnerHTML={{
              __html:
                newsItem.content &&
                newsItem.content
                  .replace(
                    /<oembed url="(http.*?\/\/)(?:www\.)?(?:(?:youtu.be|youtube.com)(?:\/))(?:watch.*?=)?(.*?)".*?oembed>/g,
                    '<iframe src="$1youtube.com/embed/$2" ' +
                      `width="100%" height="100%"` +
                      ' frameborder="0" ' +
                      'allow="accelerometer; autoplay;' +
                      " clipboard-write; encrypted-media; " +
                      'gyroscope; picture-in-picture"' +
                      'style="--aspect-ratio: 16/9;"' +
                      " allowfullscreen></iframe>"
                  )
                  .replaceAll("<a", `<a target="_blank"`),
            }}
          />

          {newsItem.files && newsItem.files.length !== 0 && (
            <h4 className={"files"}>Вложения</h4>
          )}
          <div className="filesLinks">
            {newsItem.files &&
              newsItem.files.length !== 0 &&
              toJS(newsItem.files).map((file) => {
                // @ts-ignore: Unreachable code error
                /* eslint-disable */
                return (
                  <a
                    href={`${NEWS_FILE_API}${file.id}`}
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                    title={file.name}
                    key={file.id}
                  >
                    {file.name}
                  </a>
                );
              })}
          </div>
          {newsItem.photos && newsItem.photos.length > 0 && (
            <>
              <h4 className="photo__title">Фотографии</h4>
              <div className="photo">
                {newsItem.photos.map((photo, idx) => {
                  const openAttachedPhotos = () => {
                    handleShowPhotos(idx);
                  };
                  return (
                    <ShowFile
                      key={photo.id}
                      item={{
                        reference: photo.id,
                        type: "image",
                      }}
                      url={NEWS_PHOTO_API}
                      onClick={openAttachedPhotos}
                      className={
                        `photo__attached ` +
                        (morePhoto
                          ? "photo__attached_many"
                          : "photo__attached_few")
                      }
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
        <NewsItemFooter
          news={news}
          prevButtonDisabled={prevButtonDisabled}
          nextButtonDisabled={nextButtonDisabled}
          id={id}
          title={newsItem.title}
          image={`${IMAGE_URL}/news/${id}/image`}
        />
        {(isOpenPhoto || isOpenMedia || isOpenPhotos) && (
          <FileViewer
            files={files}
            startSlide={startSlide}
            setShowFiles={showFiles}
            url={NEWS_ITEM_IMAGE_URL}
          />
        )}
      </>
    ) : (
      <ErrorComponent errorStatus={errorStatus} />
    );
  })
);
