import React from "react";
import { useHistory } from 'react-router-dom';

import { ReactComponent as ReactLogo } from "../../../Common/images/Coat_of_Arms_of_Yekaterinburg_(Sverdlovsk_oblast).svg";
import "./index.scss";

const Logo = (props) => {
  const { removeLocalStorageItems } = props;

  const history = useHistory();

  const onClick = () => {
    removeLocalStorageItems();
    history.push("/")
  }

  return (
    <div className="logo" onClick={onClick}>
      <ReactLogo />
      <span>АКТИВНЫЙ ЕКАТЕРИНБУРГ</span>
    </div>
  );
};

export default Logo;
