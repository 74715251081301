import React from "react";

import "./styles.scss";
import { useHistory } from "react-router-dom";
import { ReactComponent as GreenArrowRight } from "./../../../Common/images/greenArrowRight.svg";
import { IPurchasesItem } from "../../stores/app/MyPurchasesStore";
import DummyShopPhoto from "../DummyShopPhoto";
import { BASE_URL } from "../../api/api";
import { URL_SHOP_ID } from "../../routes/paths";

export type IPurchasesItemProps = {
  item: IPurchasesItem;
};

const PurchasesItem: React.FC<IPurchasesItemProps> = ({ item }) => {
  const { id, productId, title, imageId } = item;
  const history = useHistory();

  const goToProductPage = () => history.push(URL_SHOP_ID(productId!));
  return (
    <div className="purchases-item" id={id ?? undefined}>
      <div className="purchases-item__image">
        {imageId ? (
          <img
            src={BASE_URL + "shop/api/products/image/" + imageId}
            alt="Фотография товара"
            className="purchases-item__image-img"
          />
        ) : (
          <DummyShopPhoto />
        )}
      </div>
      <div className="purchases-item__info">
        <p className="purchases-item__title">{title}</p>
        <span onClick={goToProductPage} className="purchases-item__link">
          О товаре
          <GreenArrowRight />
        </span>
      </div>
    </div>
  );
};

export default PurchasesItem;
