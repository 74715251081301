import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";

import { ReactComponent as PlusIcon } from "../../images/plus.svg";

import "./index.scss";

const Dropzone = ({
  addFiles,
  icon = <PlusIcon />,
  className = "",
  accept,
}) => {
  const onDrop = useCallback(
    async (acceptedFiles) => {
      const files = [];
      if (acceptedFiles?.length) {
        for (let i = 0; i < acceptedFiles.length; i++) {
          acceptedFiles[i].id = uuidv4();
          files.push(acceptedFiles[i]);
        }
      }
      await addFiles(files);
    },
    [addFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept ? accept : "image/*, video/*, application/*, audio/*",
  });

  return (
    <div {...getRootProps({ className: `Dropzone ${className}` })}>
      <input {...getInputProps()} />
      {icon}
    </div>
  );
};

export default Dropzone;
