/* tslint:disable */
/* eslint-disable */
/**
 * МИС Активный Екатеринбург
 * Описание методов для работы с API \"МИС Активный Екатеринбург\"
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddressModel
 */
export interface AddressModel {
    /**
     * 
     * @type {Coordinates}
     * @memberof AddressModel
     */
    'coordinates'?: Coordinates;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    'address'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnouncementVoteModel
 */
export interface AnnouncementVoteModel {
    /**
     * 
     * @type {string}
     * @memberof AnnouncementVoteModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementVoteModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementVoteModel
     */
    'annotation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementVoteModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementVoteModel
     */
    'fileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementVoteModel
     */
    'croppedFileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementVoteModel
     */
    'previewFileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementVoteModel
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementVoteModel
     */
    'startDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnouncementVoteModel
     */
    'endDateTime'?: string;
}
/**
 * 
 * @export
 * @interface AnswerModel
 */
export interface AnswerModel {
    /**
     * 
     * @type {string}
     * @memberof AnswerModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnswerModel
     */
    'fileId'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnswerModel
     */
    'filesIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AnswerResultModel
 */
export interface AnswerResultModel {
    /**
     * 
     * @type {string}
     * @memberof AnswerResultModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnswerResultModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnswerResultModel
     */
    'fileId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnswerResultModel
     */
    'voters'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AnswerResultModel
     */
    'isOtherOption'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnswerResultModel
     */
    'filesIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AttachedFileReference
 */
export interface AttachedFileReference {
    /**
     * 
     * @type {string}
     * @memberof AttachedFileReference
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachedFileReference
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AttachedFileReference
     */
    'type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AttachedFileReference
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface AuthorModel
 */
export interface AuthorModel {
    /**
     * 
     * @type {string}
     * @memberof AuthorModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorModel
     */
    'fullname'?: string | null;
}
/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ChatMessageItemModel
 */
export interface ChatMessageItemModel {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageItemModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageItemModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessageItemModel
     */
    'isMyMessage'?: boolean;
    /**
     * 
     * @type {AuthorModel}
     * @memberof ChatMessageItemModel
     */
    'author'?: AuthorModel;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageItemModel
     */
    'date'?: string;
    /**
     * 
     * @type {Array<AttachedFileReference>}
     * @memberof ChatMessageItemModel
     */
    'fileReferences'?: Array<AttachedFileReference> | null;
}
/**
 * 
 * @export
 * @interface ChatMessageListItemModel
 */
export interface ChatMessageListItemModel {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageListItemModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageListItemModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {AuthorModel}
     * @memberof ChatMessageListItemModel
     */
    'author'?: AuthorModel;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageListItemModel
     */
    'date'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessageListItemModel
     */
    'isMyMessage'?: boolean;
    /**
     * 
     * @type {Array<AttachedFileReference>}
     * @memberof ChatMessageListItemModel
     */
    'fileReferences'?: Array<AttachedFileReference> | null;
}
/**
 * 
 * @export
 * @interface ChatMessageListModel
 */
export interface ChatMessageListModel {
    /**
     * 
     * @type {Array<ChatMessageListItemModel>}
     * @memberof ChatMessageListModel
     */
    'messages'?: Array<ChatMessageListItemModel> | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageListModel
     */
    'lastUpdate'?: string | null;
}
/**
 * 
 * @export
 * @interface ClosingAttachedFileModel
 */
export interface ClosingAttachedFileModel {
    /**
     * 
     * @type {string}
     * @memberof ClosingAttachedFileModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClosingAttachedFileModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClosingAttachedFileModel
     */
    'contentType'?: string | null;
}
/**
 * 
 * @export
 * @interface CoordinateModel
 */
export interface CoordinateModel {
    /**
     * 
     * @type {number}
     * @memberof CoordinateModel
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof CoordinateModel
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface Coordinates
 */
export interface Coordinates {
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Coordinates
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface EnumModel
 */
export interface EnumModel {
    /**
     * 
     * @type {string}
     * @memberof EnumModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EnumModel
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface FileForm
 */
export interface FileForm {
    /**
     * 
     * @type {string}
     * @memberof FileForm
     */
    'data'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileForm
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileForm
     */
    'contentType'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FileTypes = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type FileTypes = typeof FileTypes[keyof typeof FileTypes];


/**
 * 
 * @export
 * @interface KeycloackProfileForm
 */
export interface KeycloackProfileForm {
    /**
     * 
     * @type {string}
     * @memberof KeycloackProfileForm
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeycloackProfileForm
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeycloackProfileForm
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeycloackProfileForm
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeycloackProfileForm
     */
    'birthDate'?: string | null;
    /**
     * 
     * @type {PhoneListModel}
     * @memberof KeycloackProfileForm
     */
    'phones'?: PhoneListModel;
    /**
     * 
     * @type {string}
     * @memberof KeycloackProfileForm
     */
    'snils'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeycloackProfileForm
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeycloackProfileForm
     */
    'livingAddress'?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof KeycloackProfileForm
     */
    'livingAddressCoordinates'?: Coordinates;
    /**
     * 
     * @type {string}
     * @memberof KeycloackProfileForm
     */
    'registrationAddress'?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof KeycloackProfileForm
     */
    'registrationAddressCoordinates'?: Coordinates;
    /**
     * 
     * @type {boolean}
     * @memberof KeycloackProfileForm
     */
    'isGeolocationAllowed'?: boolean;
}
/**
 * 
 * @export
 * @interface KeycloakProfile
 */
export interface KeycloakProfile {
    /**
     * 
     * @type {string}
     * @memberof KeycloakProfile
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeycloakProfile
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeycloakProfile
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeycloakProfile
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeycloakProfile
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeycloakProfile
     */
    'birthDate'?: string | null;
    /**
     * 
     * @type {PhoneListModel}
     * @memberof KeycloakProfile
     */
    'phones'?: PhoneListModel;
    /**
     * 
     * @type {string}
     * @memberof KeycloakProfile
     */
    'snils'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KeycloakProfile
     */
    'gender'?: string | null;
    /**
     * 
     * @type {AddressModel}
     * @memberof KeycloakProfile
     */
    'livingAddress'?: AddressModel;
    /**
     * 
     * @type {AddressModel}
     * @memberof KeycloakProfile
     */
    'registrationAddress'?: AddressModel;
    /**
     * 
     * @type {boolean}
     * @memberof KeycloakProfile
     */
    'hasPhoto'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KeycloakProfile
     */
    'idp'?: number;
    /**
     * 
     * @type {string}
     * @memberof KeycloakProfile
     */
    'geolocation'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KeycloakProfile
     */
    'isGeolocationAllowed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof KeycloakProfile
     */
    'hasCard'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KeycloakProfile
     */
    'points'?: number;
}
/**
 * 
 * @export
 * @interface KkotuChatMessageForm
 */
export interface KkotuChatMessageForm {
    /**
     * 
     * @type {string}
     * @memberof KkotuChatMessageForm
     */
    'violationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof KkotuChatMessageForm
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @interface NotificationBodyModel
 */
export interface NotificationBodyModel {
    /**
     * 
     * @type {string}
     * @memberof NotificationBodyModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationBodyModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationBodyModel
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationBodyModel
     */
    'answer'?: string | null;
}
/**
 * 
 * @export
 * @interface NotificationListModel
 */
export interface NotificationListModel {
    /**
     * 
     * @type {Array<NotificationsItemModel>}
     * @memberof NotificationListModel
     */
    'notifications'?: Array<NotificationsItemModel> | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationListModel
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface NotificationsItemModel
 */
export interface NotificationsItemModel {
    /**
     * 
     * @type {string}
     * @memberof NotificationsItemModel
     */
    'id'?: string;
    /**
     * 
     * @type {NotificationBodyModel}
     * @memberof NotificationsItemModel
     */
    'body'?: NotificationBodyModel;
    /**
     * 
     * @type {string}
     * @memberof NotificationsItemModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationsItemModel
     */
    'date'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsItemModel
     */
    'isChecked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationsItemModel
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface NotificationsSettingsForm
 */
export interface NotificationsSettingsForm {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSettingsForm
     */
    'news'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSettingsForm
     */
    'violations'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSettingsForm
     */
    'votes'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSettingsForm
     */
    'newsChanges'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSettingsForm
     */
    'points'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationsSettingsItemModel
 */
export interface NotificationsSettingsItemModel {
    /**
     * 
     * @type {string}
     * @memberof NotificationsSettingsItemModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationsSettingsItemModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSettingsItemModel
     */
    'checked'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationsSettingsModel
 */
export interface NotificationsSettingsModel {
    /**
     * 
     * @type {Array<NotificationsSettingsItemModel>}
     * @memberof NotificationsSettingsModel
     */
    'news'?: Array<NotificationsSettingsItemModel> | null;
    /**
     * 
     * @type {Array<NotificationsSettingsItemModel>}
     * @memberof NotificationsSettingsModel
     */
    'events'?: Array<NotificationsSettingsItemModel> | null;
}
/**
 * 
 * @export
 * @interface PhoneListModel
 */
export interface PhoneListModel {
    /**
     * 
     * @type {string}
     * @memberof PhoneListModel
     */
    'mobileNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'keycloakUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'answeredUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'questionText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'answerText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    'answeredDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Question
     */
    'isAnswered'?: boolean;
}
/**
 * 
 * @export
 * @interface QuestionAnswer
 */
export interface QuestionAnswer {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswer
     */
    'fullname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswer
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswer
     */
    'date'?: string | null;
}
/**
 * 
 * @export
 * @interface QuestionAnswersMapModel
 */
export interface QuestionAnswersMapModel {
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswersMapModel
     */
    'questionId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionAnswersMapModel
     */
    'answerIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionAnswersMapModel
     */
    'answer'?: string | null;
}
/**
 * 
 * @export
 * @interface QuestionForm
 */
export interface QuestionForm {
    /**
     * 
     * @type {string}
     * @memberof QuestionForm
     */
    'question'?: string | null;
}
/**
 * 
 * @export
 * @interface QuestionModel
 */
export interface QuestionModel {
    /**
     * 
     * @type {string}
     * @memberof QuestionModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionModel
     */
    'type'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionModel
     */
    'hasOtherOption'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionModel
     */
    'isRequired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionModel
     */
    'fileId'?: string | null;
    /**
     * 
     * @type {Array<AnswerModel>}
     * @memberof QuestionModel
     */
    'answers'?: Array<AnswerModel> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionModel
     */
    'filesIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface QuestionResultModel
 */
export interface QuestionResultModel {
    /**
     * 
     * @type {string}
     * @memberof QuestionResultModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionResultModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuestionResultModel
     */
    'type'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionResultModel
     */
    'hasOtherOption'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionResultModel
     */
    'isRequired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuestionResultModel
     */
    'fileId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuestionResultModel
     */
    'voters'?: number;
    /**
     * 
     * @type {Array<AnswerResultModel>}
     * @memberof QuestionResultModel
     */
    'answers'?: Array<AnswerResultModel> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionResultModel
     */
    'filesIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ResultForm
 */
export interface ResultForm {
    /**
     * 
     * @type {string}
     * @memberof ResultForm
     */
    'voteId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResultForm
     */
    'isCompleted'?: boolean;
    /**
     * 
     * @type {Array<QuestionAnswersMapModel>}
     * @memberof ResultForm
     */
    'answers'?: Array<QuestionAnswersMapModel> | null;
}
/**
 * 
 * @export
 * @interface ResultModel
 */
export interface ResultModel {
    /**
     * 
     * @type {VoteResultModel}
     * @memberof ResultModel
     */
    'vote'?: VoteResultModel;
    /**
     * 
     * @type {Array<UserAnswerModel>}
     * @memberof ResultModel
     */
    'userAnswers'?: Array<UserAnswerModel> | null;
}
/**
 * 
 * @export
 * @interface SelectedCategoryDtoWithChildren
 */
export interface SelectedCategoryDtoWithChildren {
    /**
     * 
     * @type {string}
     * @memberof SelectedCategoryDtoWithChildren
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectedCategoryDtoWithChildren
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SelectedCategoryDtoWithChildren
     */
    'isSelected'?: boolean;
    /**
     * 
     * @type {Array<SelectedCategoryDtoWithChildren>}
     * @memberof SelectedCategoryDtoWithChildren
     */
    'children'?: Array<SelectedCategoryDtoWithChildren> | null;
}
/**
 * 
 * @export
 * @interface StatisticsModel
 */
export interface StatisticsModel {
    /**
     * 
     * @type {number}
     * @memberof StatisticsModel
     */
    'activeUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsModel
     */
    'votesCompleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsModel
     */
    'decisionsMade'?: number;
}
/**
 * 
 * @export
 * @interface SubCategoryDto
 */
export interface SubCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof SubCategoryDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategoryDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface UserAnswerModel
 */
export interface UserAnswerModel {
    /**
     * 
     * @type {string}
     * @memberof UserAnswerModel
     */
    'questionId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAnswerModel
     */
    'answerIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserAnswerModel
     */
    'answer'?: string | null;
}
/**
 * 
 * @export
 * @interface UserQuestionModel
 */
export interface UserQuestionModel {
    /**
     * 
     * @type {string}
     * @memberof UserQuestionModel
     */
    'id'?: string;
    /**
     * 
     * @type {QuestionAnswer}
     * @memberof UserQuestionModel
     */
    'answer'?: QuestionAnswer;
    /**
     * 
     * @type {string}
     * @memberof UserQuestionModel
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserQuestionModel
     */
    'issueDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserQuestionModel
     */
    'isAnswered'?: boolean;
}
/**
 * 
 * @export
 * @interface ViolationDto
 */
export interface ViolationDto {
    /**
     * 
     * @type {string}
     * @memberof ViolationDto
     */
    'id'?: string;
    /**
     * 
     * @type {Array<AttachedFileReference>}
     * @memberof ViolationDto
     */
    'fileReferences'?: Array<AttachedFileReference> | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationDto
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof ViolationDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationDto
     */
    'category'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViolationDto
     */
    'subCategories'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationDto
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationDto
     */
    'middleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationDto
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationDto
     */
    'address'?: string | null;
    /**
     * 
     * @type {Coordinates}
     * @memberof ViolationDto
     */
    'coordinates'?: Coordinates;
    /**
     * 
     * @type {number}
     * @memberof ViolationDto
     */
    'supportCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationDto
     */
    'isSupported'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationDto
     */
    'closingComment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationDto
     */
    'dateClose'?: string | null;
    /**
     * 
     * @type {Array<ClosingAttachedFileModel>}
     * @memberof ViolationDto
     */
    'closingAttachedFiles'?: Array<ClosingAttachedFileModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationDto
     */
    'hasNewChatMessages'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationDto
     */
    'isOwn'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViolationDto
     */
    'wasPhotos'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViolationDto
     */
    'becamePhotos'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ViolationListDto
 */
export interface ViolationListDto {
    /**
     * 
     * @type {string}
     * @memberof ViolationListDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationListDto
     */
    'photo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationListDto
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof ViolationListDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationListDto
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationListDto
     */
    'category'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationListDto
     */
    'district'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ViolationListDto
     */
    'supportCount'?: number;
    /**
     * 
     * @type {Coordinates}
     * @memberof ViolationListDto
     */
    'coordinates'?: Coordinates;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationListDto
     */
    'isSupported'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ViolationListDto
     */
    'hasNewChatMessages'?: boolean;
}
/**
 * 
 * @export
 * @interface ViolationWorkEndFileForm
 */
export interface ViolationWorkEndFileForm {
    /**
     * 
     * @type {string}
     * @memberof ViolationWorkEndFileForm
     */
    'data'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationWorkEndFileForm
     */
    'contentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViolationWorkEndFileForm
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {FileTypes}
     * @memberof ViolationWorkEndFileForm
     */
    'fileType'?: FileTypes;
}
/**
 * 
 * @export
 * @interface ViolationWorkEndForm
 */
export interface ViolationWorkEndForm {
    /**
     * 
     * @type {string}
     * @memberof ViolationWorkEndForm
     */
    'violationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ViolationWorkEndForm
     */
    'comment'?: string | null;
    /**
     * 
     * @type {ViolationWorkEndType}
     * @memberof ViolationWorkEndForm
     */
    'type'?: ViolationWorkEndType;
    /**
     * 
     * @type {Array<ViolationWorkEndFileForm>}
     * @memberof ViolationWorkEndForm
     */
    'files'?: Array<ViolationWorkEndFileForm> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ViolationWorkEndType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type ViolationWorkEndType = typeof ViolationWorkEndType[keyof typeof ViolationWorkEndType];


/**
 * 
 * @export
 * @interface VoteBannerModel
 */
export interface VoteBannerModel {
    /**
     * 
     * @type {string}
     * @memberof VoteBannerModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteBannerModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteBannerModel
     */
    'annotation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteBannerModel
     */
    'fileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteBannerModel
     */
    'сroppedFileId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoteBannerModel
     */
    'questionsNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoteBannerModel
     */
    'votersNumber'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VoteBannerModel
     */
    'isGeo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteBannerModel
     */
    'isDone'?: boolean;
}
/**
 * 
 * @export
 * @interface VoteCardModel
 */
export interface VoteCardModel {
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'annotation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'fileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'croppedFileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'previewFileId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof VoteCardModel
     */
    'questionsNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof VoteCardModel
     */
    'votersNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'status'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoteCardModel
     */
    'isImportant'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'startDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteCardModel
     */
    'endDateTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VoteCardModel
     */
    'isGeo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteCardModel
     */
    'isDone'?: boolean;
}
/**
 * 
 * @export
 * @interface VoteCardModelHolder
 */
export interface VoteCardModelHolder {
    /**
     * 
     * @type {Array<VoteCardModel>}
     * @memberof VoteCardModelHolder
     */
    'votes'?: Array<VoteCardModel> | null;
    /**
     * 
     * @type {Array<EnumModel>}
     * @memberof VoteCardModelHolder
     */
    'votesStatusTypes'?: Array<EnumModel> | null;
    /**
     * 
     * @type {number}
     * @memberof VoteCardModelHolder
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface VoteModel
 */
export interface VoteModel {
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'fileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'croppedFileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'previewFileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'startDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteModel
     */
    'endDateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoteModel
     */
    'idp'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VoteModel
     */
    'isDone'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VoteModel
     */
    'numberOfPoints'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof VoteModel
     */
    'isReadOnly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteModel
     */
    'isGeo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteModel
     */
    'isSuitableForIdp'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteModel
     */
    'isSuitableForLivingAddress'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteModel
     */
    'isSuitableForRegistrationAddress'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteModel
     */
    'isSuitableForGender'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteModel
     */
    'isSuitableForAge'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteModel
     */
    'isNotInRegion'?: boolean;
    /**
     * 
     * @type {Array<QuestionModel>}
     * @memberof VoteModel
     */
    'questions'?: Array<QuestionModel> | null;
    /**
     * 
     * @type {Array<CoordinateModel>}
     * @memberof VoteModel
     */
    'coordinates'?: Array<CoordinateModel> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoteModel
     */
    'filesIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface VoteModelHolder
 */
export interface VoteModelHolder {
    /**
     * 
     * @type {VoteModel}
     * @memberof VoteModelHolder
     */
    'vote'?: VoteModel;
    /**
     * 
     * @type {Array<EnumModel>}
     * @memberof VoteModelHolder
     */
    'voteTypes'?: Array<EnumModel> | null;
    /**
     * 
     * @type {Array<EnumModel>}
     * @memberof VoteModelHolder
     */
    'answerTypes'?: Array<EnumModel> | null;
}
/**
 * 
 * @export
 * @interface VoteResultModel
 */
export interface VoteResultModel {
    /**
     * 
     * @type {string}
     * @memberof VoteResultModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteResultModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteResultModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteResultModel
     */
    'fileId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteResultModel
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VoteResultModel
     */
    'startDateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof VoteResultModel
     */
    'endDateTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof VoteResultModel
     */
    'votersNumber'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VoteResultModel
     */
    'isDigitalResult'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteResultModel
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VoteResultModel
     */
    'isDone'?: boolean;
    /**
     * 
     * @type {Array<QuestionResultModel>}
     * @memberof VoteResultModel
     */
    'questions'?: Array<QuestionResultModel> | null;
    /**
     * 
     * @type {Array<CoordinateModel>}
     * @memberof VoteResultModel
     */
    'coordinates'?: Array<CoordinateModel> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoteResultModel
     */
    'filesIds'?: Array<string> | null;
}

/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение списка всех категорий
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка категории до выбранной
         * @param {string} id Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesIdSelectedGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCategoriesIdSelectedGet', 'id', id)
            const localVarPath = `/api/categories/{id}/selected`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Поиск категорий по тегу
         * @param {string} searchString тег
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesSearchbytagSearchStringGet: async (searchString: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchString' is not null or undefined
            assertParamExists('apiCategoriesSearchbytagSearchStringGet', 'searchString', searchString)
            const localVarPath = `/api/categories/searchbytag/{searchString}`
                .replace(`{${"searchString"}}`, encodeURIComponent(String(searchString)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка всех подкатегорий для выбранной категории
         * @param {string} [categoryId] Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesSubcategoriesGet: async (categoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/categories/subcategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение списка всех категорий
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка категории до выбранной
         * @param {string} id Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesIdSelectedGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectedCategoryDtoWithChildren>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesIdSelectedGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Поиск категорий по тегу
         * @param {string} searchString тег
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesSearchbytagSearchStringGet(searchString: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesSearchbytagSearchStringGet(searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка всех подкатегорий для выбранной категории
         * @param {string} [categoryId] Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCategoriesSubcategoriesGet(categoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCategoriesSubcategoriesGet(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение списка всех категорий
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesGet(options?: any): AxiosPromise<Array<CategoryDto>> {
            return localVarFp.apiCategoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка категории до выбранной
         * @param {string} id Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesIdSelectedGet(id: string, options?: any): AxiosPromise<Array<SelectedCategoryDtoWithChildren>> {
            return localVarFp.apiCategoriesIdSelectedGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Поиск категорий по тегу
         * @param {string} searchString тег
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesSearchbytagSearchStringGet(searchString: string, options?: any): AxiosPromise<Array<CategoryDto>> {
            return localVarFp.apiCategoriesSearchbytagSearchStringGet(searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка всех подкатегорий для выбранной категории
         * @param {string} [categoryId] Уникальный идентификатор категории
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCategoriesSubcategoriesGet(categoryId?: string, options?: any): AxiosPromise<Array<SubCategoryDto>> {
            return localVarFp.apiCategoriesSubcategoriesGet(categoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriesApi - interface
 * @export
 * @interface CategoriesApi
 */
export interface CategoriesApiInterface {
    /**
     * 
     * @summary Получение списка всех категорий
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    apiCategoriesGet(options?: AxiosRequestConfig): AxiosPromise<Array<CategoryDto>>;

    /**
     * 
     * @summary Получение списка категории до выбранной
     * @param {string} id Уникальный идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    apiCategoriesIdSelectedGet(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<SelectedCategoryDtoWithChildren>>;

    /**
     * 
     * @summary Поиск категорий по тегу
     * @param {string} searchString тег
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    apiCategoriesSearchbytagSearchStringGet(searchString: string, options?: AxiosRequestConfig): AxiosPromise<Array<CategoryDto>>;

    /**
     * 
     * @summary Получение списка всех подкатегорий для выбранной категории
     * @param {string} [categoryId] Уникальный идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApiInterface
     */
    apiCategoriesSubcategoriesGet(categoryId?: string, options?: AxiosRequestConfig): AxiosPromise<Array<SubCategoryDto>>;

}

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI implements CategoriesApiInterface {
    /**
     * 
     * @summary Получение списка всех категорий
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public apiCategoriesGet(options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).apiCategoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка категории до выбранной
     * @param {string} id Уникальный идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public apiCategoriesIdSelectedGet(id: string, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).apiCategoriesIdSelectedGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Поиск категорий по тегу
     * @param {string} searchString тег
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public apiCategoriesSearchbytagSearchStringGet(searchString: string, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).apiCategoriesSearchbytagSearchStringGet(searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка всех подкатегорий для выбранной категории
     * @param {string} [categoryId] Уникальный идентификатор категории
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public apiCategoriesSubcategoriesGet(categoryId?: string, options?: AxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).apiCategoriesSubcategoriesGet(categoryId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChatApi - axios parameter creator
 * @export
 */
export const ChatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение файла из чата заявления
         * @param {string} [fileId] Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatFileGet: async (fileId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/chat/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Флаг о наличии сообщений в чатах для текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatHasNewMessagesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/chat/hasNewMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение сообщение из ккоту
         * @param {KkotuChatMessageForm} [kkotuChatMessageForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatKkotuPost: async (kkotuChatMessageForm?: KkotuChatMessageForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/chat/kkotu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kkotuChatMessageForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение сообщений чата заявления
         * @param {string} violationId Уникальный идентификатор заявления
         * @param {string} [lastUpdate] Дата последнего обновления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatMessagesViolationIdGet: async (violationId: string, lastUpdate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'violationId' is not null or undefined
            assertParamExists('apiChatMessagesViolationIdGet', 'violationId', violationId)
            const localVarPath = `/api/chat/messages/{violationId}`
                .replace(`{${"violationId"}}`, encodeURIComponent(String(violationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lastUpdate !== undefined) {
                localVarQueryParameter['lastUpdate'] = (lastUpdate as any instanceof Date) ?
                    (lastUpdate as any).toISOString() :
                    lastUpdate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отметить все сообщения в чате заявления прочитанными
         * @param {string} violationId Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatReadViolationIdPost: async (violationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'violationId' is not null or undefined
            assertParamExists('apiChatReadViolationIdPost', 'violationId', violationId)
            const localVarPath = `/api/chat/read/{violationId}`
                .replace(`{${"violationId"}}`, encodeURIComponent(String(violationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Отправка сообщения в чат заявления
         * @param {string} [text] 
         * @param {string} [violationId] 
         * @param {string} [authorId] 
         * @param {Array<File>} [photos] 
         * @param {Array<File>} [videos] 
         * @param {Array<File>} [audios] 
         * @param {Array<File>} [docs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatSendPost: async (text?: string, violationId?: string, authorId?: string, photos?: Array<File>, videos?: Array<File>, audios?: Array<File>, docs?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/chat/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (text !== undefined) { 
                localVarFormParams.append('Text', text as any);
            }
    
            if (violationId !== undefined) { 
                localVarFormParams.append('ViolationId', violationId as any);
            }
    
            if (authorId !== undefined) { 
                localVarFormParams.append('AuthorId', authorId as any);
            }
                if (photos) {
                photos.forEach((element) => {
                    localVarFormParams.append('Photos', element as any);
                })
            }

                if (videos) {
                videos.forEach((element) => {
                    localVarFormParams.append('Videos', element as any);
                })
            }

                if (audios) {
                audios.forEach((element) => {
                    localVarFormParams.append('Audios', element as any);
                })
            }

                if (docs) {
                docs.forEach((element) => {
                    localVarFormParams.append('Docs', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatApi - functional programming interface
 * @export
 */
export const ChatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение файла из чата заявления
         * @param {string} [fileId] Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChatFileGet(fileId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChatFileGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Флаг о наличии сообщений в чатах для текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChatHasNewMessagesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChatHasNewMessagesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение сообщение из ккоту
         * @param {KkotuChatMessageForm} [kkotuChatMessageForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChatKkotuPost(kkotuChatMessageForm?: KkotuChatMessageForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChatKkotuPost(kkotuChatMessageForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение сообщений чата заявления
         * @param {string} violationId Уникальный идентификатор заявления
         * @param {string} [lastUpdate] Дата последнего обновления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChatMessagesViolationIdGet(violationId: string, lastUpdate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessageListModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChatMessagesViolationIdGet(violationId, lastUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отметить все сообщения в чате заявления прочитанными
         * @param {string} violationId Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChatReadViolationIdPost(violationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChatReadViolationIdPost(violationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Отправка сообщения в чат заявления
         * @param {string} [text] 
         * @param {string} [violationId] 
         * @param {string} [authorId] 
         * @param {Array<File>} [photos] 
         * @param {Array<File>} [videos] 
         * @param {Array<File>} [audios] 
         * @param {Array<File>} [docs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiChatSendPost(text?: string, violationId?: string, authorId?: string, photos?: Array<File>, videos?: Array<File>, audios?: Array<File>, docs?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChatMessageItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiChatSendPost(text, violationId, authorId, photos, videos, audios, docs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChatApi - factory interface
 * @export
 */
export const ChatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение файла из чата заявления
         * @param {string} [fileId] Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatFileGet(fileId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiChatFileGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Флаг о наличии сообщений в чатах для текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatHasNewMessagesGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiChatHasNewMessagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение сообщение из ккоту
         * @param {KkotuChatMessageForm} [kkotuChatMessageForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatKkotuPost(kkotuChatMessageForm?: KkotuChatMessageForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiChatKkotuPost(kkotuChatMessageForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение сообщений чата заявления
         * @param {string} violationId Уникальный идентификатор заявления
         * @param {string} [lastUpdate] Дата последнего обновления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatMessagesViolationIdGet(violationId: string, lastUpdate?: string, options?: any): AxiosPromise<ChatMessageListModel> {
            return localVarFp.apiChatMessagesViolationIdGet(violationId, lastUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отметить все сообщения в чате заявления прочитанными
         * @param {string} violationId Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatReadViolationIdPost(violationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiChatReadViolationIdPost(violationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Отправка сообщения в чат заявления
         * @param {string} [text] 
         * @param {string} [violationId] 
         * @param {string} [authorId] 
         * @param {Array<File>} [photos] 
         * @param {Array<File>} [videos] 
         * @param {Array<File>} [audios] 
         * @param {Array<File>} [docs] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiChatSendPost(text?: string, violationId?: string, authorId?: string, photos?: Array<File>, videos?: Array<File>, audios?: Array<File>, docs?: Array<File>, options?: any): AxiosPromise<ChatMessageItemModel> {
            return localVarFp.apiChatSendPost(text, violationId, authorId, photos, videos, audios, docs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatApi - interface
 * @export
 * @interface ChatApi
 */
export interface ChatApiInterface {
    /**
     * 
     * @summary Получение файла из чата заявления
     * @param {string} [fileId] Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApiInterface
     */
    apiChatFileGet(fileId?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Флаг о наличии сообщений в чатах для текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApiInterface
     */
    apiChatHasNewMessagesGet(options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @summary Получение сообщение из ккоту
     * @param {KkotuChatMessageForm} [kkotuChatMessageForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApiInterface
     */
    apiChatKkotuPost(kkotuChatMessageForm?: KkotuChatMessageForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение сообщений чата заявления
     * @param {string} violationId Уникальный идентификатор заявления
     * @param {string} [lastUpdate] Дата последнего обновления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApiInterface
     */
    apiChatMessagesViolationIdGet(violationId: string, lastUpdate?: string, options?: AxiosRequestConfig): AxiosPromise<ChatMessageListModel>;

    /**
     * 
     * @summary Отметить все сообщения в чате заявления прочитанными
     * @param {string} violationId Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApiInterface
     */
    apiChatReadViolationIdPost(violationId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Отправка сообщения в чат заявления
     * @param {string} [text] 
     * @param {string} [violationId] 
     * @param {string} [authorId] 
     * @param {Array<File>} [photos] 
     * @param {Array<File>} [videos] 
     * @param {Array<File>} [audios] 
     * @param {Array<File>} [docs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApiInterface
     */
    apiChatSendPost(text?: string, violationId?: string, authorId?: string, photos?: Array<File>, videos?: Array<File>, audios?: Array<File>, docs?: Array<File>, options?: AxiosRequestConfig): AxiosPromise<ChatMessageItemModel>;

}

/**
 * ChatApi - object-oriented interface
 * @export
 * @class ChatApi
 * @extends {BaseAPI}
 */
export class ChatApi extends BaseAPI implements ChatApiInterface {
    /**
     * 
     * @summary Получение файла из чата заявления
     * @param {string} [fileId] Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public apiChatFileGet(fileId?: string, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).apiChatFileGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Флаг о наличии сообщений в чатах для текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public apiChatHasNewMessagesGet(options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).apiChatHasNewMessagesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение сообщение из ккоту
     * @param {KkotuChatMessageForm} [kkotuChatMessageForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public apiChatKkotuPost(kkotuChatMessageForm?: KkotuChatMessageForm, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).apiChatKkotuPost(kkotuChatMessageForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение сообщений чата заявления
     * @param {string} violationId Уникальный идентификатор заявления
     * @param {string} [lastUpdate] Дата последнего обновления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public apiChatMessagesViolationIdGet(violationId: string, lastUpdate?: string, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).apiChatMessagesViolationIdGet(violationId, lastUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отметить все сообщения в чате заявления прочитанными
     * @param {string} violationId Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public apiChatReadViolationIdPost(violationId: string, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).apiChatReadViolationIdPost(violationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Отправка сообщения в чат заявления
     * @param {string} [text] 
     * @param {string} [violationId] 
     * @param {string} [authorId] 
     * @param {Array<File>} [photos] 
     * @param {Array<File>} [videos] 
     * @param {Array<File>} [audios] 
     * @param {Array<File>} [docs] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public apiChatSendPost(text?: string, violationId?: string, authorId?: string, photos?: Array<File>, videos?: Array<File>, audios?: Array<File>, docs?: Array<File>, options?: AxiosRequestConfig) {
        return ChatApiFp(this.configuration).apiChatSendPost(text, violationId, authorId, photos, videos, audios, docs, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Общая статистика на баннере главной страницы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonStatisticsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/common/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Общая статистика на баннере главной страницы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCommonStatisticsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatisticsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCommonStatisticsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonApiFp(configuration)
    return {
        /**
         * 
         * @summary Общая статистика на баннере главной страницы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonStatisticsGet(options?: any): AxiosPromise<StatisticsModel> {
            return localVarFp.apiCommonStatisticsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonApi - interface
 * @export
 * @interface CommonApi
 */
export interface CommonApiInterface {
    /**
     * 
     * @summary Общая статистика на баннере главной страницы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApiInterface
     */
    apiCommonStatisticsGet(options?: AxiosRequestConfig): AxiosPromise<StatisticsModel>;

}

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI implements CommonApiInterface {
    /**
     * 
     * @summary Общая статистика на баннере главной страницы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public apiCommonStatisticsGet(options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).apiCommonStatisticsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Удаление файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDeleteIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileDeleteIdPost', 'id', id)
            const localVarPath = `/api/File/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение ссылки на скачивание файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDownloadIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileDownloadIdGet', 'id', id)
            const localVarPath = `/api/File/download/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileIdGet', 'id', id)
            const localVarPath = `/api/File/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Загрузка файлов
         * @param {Array<FileForm>} [fileForm] Массив файлов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileUploadPost: async (fileForm?: Array<FileForm>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/File/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Удаление файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileDeleteIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileDeleteIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение ссылки на скачивание файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileDownloadIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileDownloadIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Загрузка файлов
         * @param {Array<FileForm>} [fileForm] Массив файлов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileUploadPost(fileForm?: Array<FileForm>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileUploadPost(fileForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary Удаление файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDeleteIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileDeleteIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение ссылки на скачивание файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDownloadIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileDownloadIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Загрузка файлов
         * @param {Array<FileForm>} [fileForm] Массив файлов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileUploadPost(fileForm?: Array<FileForm>, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileUploadPost(fileForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - interface
 * @export
 * @interface FileApi
 */
export interface FileApiInterface {
    /**
     * 
     * @summary Удаление файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    apiFileDeleteIdPost(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение ссылки на скачивание файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    apiFileDownloadIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    apiFileIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Загрузка файлов
     * @param {Array<FileForm>} [fileForm] Массив файлов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    apiFileUploadPost(fileForm?: Array<FileForm>, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI implements FileApiInterface {
    /**
     * 
     * @summary Удаление файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileDeleteIdPost(id: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileDeleteIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение ссылки на скачивание файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileDownloadIdGet(id: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileDownloadIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileIdGet(id: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Загрузка файлов
     * @param {Array<FileForm>} [fileForm] Массив файлов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileUploadPost(fileForm?: Array<FileForm>, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileUploadPost(fileForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeolocationsApi - axios parameter creator
 * @export
 */
export const GeolocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение адреса по координатам
         * @param {number} [latitude] Широта
         * @param {number} [longitude] Долгота
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGeolocationsAddressbycoordsGet: async (latitude?: number, longitude?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Geolocations/addressbycoords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение координат по адресу
         * @param {string} [address] Адрес
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGeolocationsCoordsbyadressGet: async (address?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Geolocations/coordsbyadress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeolocationsApi - functional programming interface
 * @export
 */
export const GeolocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeolocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение адреса по координатам
         * @param {number} [latitude] Широта
         * @param {number} [longitude] Долгота
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGeolocationsAddressbycoordsGet(latitude?: number, longitude?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGeolocationsAddressbycoordsGet(latitude, longitude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение координат по адресу
         * @param {string} [address] Адрес
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGeolocationsCoordsbyadressGet(address?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Coordinates>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGeolocationsCoordsbyadressGet(address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeolocationsApi - factory interface
 * @export
 */
export const GeolocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeolocationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение адреса по координатам
         * @param {number} [latitude] Широта
         * @param {number} [longitude] Долгота
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGeolocationsAddressbycoordsGet(latitude?: number, longitude?: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiGeolocationsAddressbycoordsGet(latitude, longitude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение координат по адресу
         * @param {string} [address] Адрес
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGeolocationsCoordsbyadressGet(address?: string, options?: any): AxiosPromise<Coordinates> {
            return localVarFp.apiGeolocationsCoordsbyadressGet(address, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeolocationsApi - interface
 * @export
 * @interface GeolocationsApi
 */
export interface GeolocationsApiInterface {
    /**
     * 
     * @summary Получение адреса по координатам
     * @param {number} [latitude] Широта
     * @param {number} [longitude] Долгота
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationsApiInterface
     */
    apiGeolocationsAddressbycoordsGet(latitude?: number, longitude?: number, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary Получение координат по адресу
     * @param {string} [address] Адрес
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationsApiInterface
     */
    apiGeolocationsCoordsbyadressGet(address?: string, options?: AxiosRequestConfig): AxiosPromise<Coordinates>;

}

/**
 * GeolocationsApi - object-oriented interface
 * @export
 * @class GeolocationsApi
 * @extends {BaseAPI}
 */
export class GeolocationsApi extends BaseAPI implements GeolocationsApiInterface {
    /**
     * 
     * @summary Получение адреса по координатам
     * @param {number} [latitude] Широта
     * @param {number} [longitude] Долгота
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationsApi
     */
    public apiGeolocationsAddressbycoordsGet(latitude?: number, longitude?: number, options?: AxiosRequestConfig) {
        return GeolocationsApiFp(this.configuration).apiGeolocationsAddressbycoordsGet(latitude, longitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение координат по адресу
     * @param {string} [address] Адрес
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationsApi
     */
    public apiGeolocationsCoordsbyadressGet(address?: string, options?: AxiosRequestConfig) {
        return GeolocationsApiFp(this.configuration).apiGeolocationsCoordsbyadressGet(address, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KeycloakApi - axios parameter creator
 * @export
 */
export const KeycloakApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakCallbackGet: async (returnUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Редактирование профиля текущего пользователя
         * @param {KeycloackProfileForm} [keycloackProfileForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakEditPost: async (keycloackProfileForm?: KeycloackProfileForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/edit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(keycloackProfileForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakGeolocDeletePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/geoloc/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Coordinates} [coordinates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakGeolocSetPost: async (coordinates?: Coordinates, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/geoloc/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coordinates, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Авторизация в системе
         * @param {string} [address] Url для ридеректа на приложение авторизации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakLoginGet: async (address?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Выход из системы
         * @param {string} [address] Url для ридеркта на приложение авторизации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakLogoutGet: async (address?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение id текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakNotificationsCheckPost: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/notifications/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Постраничное получение списка уведомлений
         * @param {boolean} [isRead] Фильтрация прочитанные/непрочитанные
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakNotificationsGet: async (isRead?: boolean, skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isRead !== undefined) {
                localVarQueryParameter['isRead'] = isRead;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Флаг о наличии новых уведомлений для текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakNotificationsHasnewGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/notifications/hasnew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение настроек уведомлений пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakNotificationsSettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/notifications/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Установка настроек уведомлений
         * @param {NotificationsSettingsForm} [notificationsSettingsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakNotificationsSettingsPost: async (notificationsSettingsForm?: NotificationsSettingsForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/notifications/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationsSettingsForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Удаление фотографии текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakPhotoDeletePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/photo/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение фотографии текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakPhotoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Добавление фотографии текущего пользователя
         * @param {File} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakPhotoPost: async (photo: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'photo' is not null or undefined
            assertParamExists('apiKeycloakPhotoPost', 'photo', photo)
            const localVarPath = `/api/keycloak/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение данных профиля текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakProfileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/keycloak/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KeycloakApi - functional programming interface
 * @export
 */
export const KeycloakApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KeycloakApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakCallbackGet(returnUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakCallbackGet(returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Редактирование профиля текущего пользователя
         * @param {KeycloackProfileForm} [keycloackProfileForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakEditPost(keycloackProfileForm?: KeycloackProfileForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakEditPost(keycloackProfileForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakGeolocDeletePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakGeolocDeletePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Coordinates} [coordinates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakGeolocSetPost(coordinates?: Coordinates, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakGeolocSetPost(coordinates, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Авторизация в системе
         * @param {string} [address] Url для ридеректа на приложение авторизации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakLoginGet(address?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakLoginGet(address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Выход из системы
         * @param {string} [address] Url для ридеркта на приложение авторизации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakLogoutGet(address?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakLogoutGet(address, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение id текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakNotificationsCheckPost(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakNotificationsCheckPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Постраничное получение списка уведомлений
         * @param {boolean} [isRead] Фильтрация прочитанные/непрочитанные
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakNotificationsGet(isRead?: boolean, skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationListModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakNotificationsGet(isRead, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Флаг о наличии новых уведомлений для текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakNotificationsHasnewGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakNotificationsHasnewGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение настроек уведомлений пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakNotificationsSettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsSettingsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakNotificationsSettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Установка настроек уведомлений
         * @param {NotificationsSettingsForm} [notificationsSettingsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakNotificationsSettingsPost(notificationsSettingsForm?: NotificationsSettingsForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakNotificationsSettingsPost(notificationsSettingsForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Удаление фотографии текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakPhotoDeletePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakPhotoDeletePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение фотографии текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakPhotoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakPhotoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Добавление фотографии текущего пользователя
         * @param {File} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakPhotoPost(photo: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakPhotoPost(photo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение данных профиля текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeycloakProfileGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KeycloakProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeycloakProfileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KeycloakApi - factory interface
 * @export
 */
export const KeycloakApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KeycloakApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakCallbackGet(returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKeycloakCallbackGet(returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Редактирование профиля текущего пользователя
         * @param {KeycloackProfileForm} [keycloackProfileForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakEditPost(keycloackProfileForm?: KeycloackProfileForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiKeycloakEditPost(keycloackProfileForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakGeolocDeletePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiKeycloakGeolocDeletePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Coordinates} [coordinates] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakGeolocSetPost(coordinates?: Coordinates, options?: any): AxiosPromise<void> {
            return localVarFp.apiKeycloakGeolocSetPost(coordinates, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Авторизация в системе
         * @param {string} [address] Url для ридеректа на приложение авторизации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakLoginGet(address?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKeycloakLoginGet(address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Выход из системы
         * @param {string} [address] Url для ридеркта на приложение авторизации
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakLogoutGet(address?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKeycloakLogoutGet(address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение id текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakMeGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiKeycloakMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakNotificationsCheckPost(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKeycloakNotificationsCheckPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Постраничное получение списка уведомлений
         * @param {boolean} [isRead] Фильтрация прочитанные/непрочитанные
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakNotificationsGet(isRead?: boolean, skip?: number, take?: number, options?: any): AxiosPromise<NotificationListModel> {
            return localVarFp.apiKeycloakNotificationsGet(isRead, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Флаг о наличии новых уведомлений для текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakNotificationsHasnewGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiKeycloakNotificationsHasnewGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение настроек уведомлений пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakNotificationsSettingsGet(options?: any): AxiosPromise<NotificationsSettingsModel> {
            return localVarFp.apiKeycloakNotificationsSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Установка настроек уведомлений
         * @param {NotificationsSettingsForm} [notificationsSettingsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakNotificationsSettingsPost(notificationsSettingsForm?: NotificationsSettingsForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiKeycloakNotificationsSettingsPost(notificationsSettingsForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Удаление фотографии текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakPhotoDeletePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiKeycloakPhotoDeletePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение фотографии текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakPhotoGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiKeycloakPhotoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Добавление фотографии текущего пользователя
         * @param {File} photo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakPhotoPost(photo: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiKeycloakPhotoPost(photo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение данных профиля текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeycloakProfileGet(options?: any): AxiosPromise<KeycloakProfile> {
            return localVarFp.apiKeycloakProfileGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KeycloakApi - interface
 * @export
 * @interface KeycloakApi
 */
export interface KeycloakApiInterface {
    /**
     * 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakCallbackGet(returnUrl?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Редактирование профиля текущего пользователя
     * @param {KeycloackProfileForm} [keycloackProfileForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakEditPost(keycloackProfileForm?: KeycloackProfileForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakGeolocDeletePost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {Coordinates} [coordinates] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakGeolocSetPost(coordinates?: Coordinates, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Авторизация в системе
     * @param {string} [address] Url для ридеректа на приложение авторизации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakLoginGet(address?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Выход из системы
     * @param {string} [address] Url для ридеркта на приложение авторизации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakLogoutGet(address?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение id текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakMeGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakNotificationsCheckPost(id?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Постраничное получение списка уведомлений
     * @param {boolean} [isRead] Фильтрация прочитанные/непрочитанные
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakNotificationsGet(isRead?: boolean, skip?: number, take?: number, options?: AxiosRequestConfig): AxiosPromise<NotificationListModel>;

    /**
     * 
     * @summary Флаг о наличии новых уведомлений для текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakNotificationsHasnewGet(options?: AxiosRequestConfig): AxiosPromise<boolean>;

    /**
     * 
     * @summary Получение настроек уведомлений пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakNotificationsSettingsGet(options?: AxiosRequestConfig): AxiosPromise<NotificationsSettingsModel>;

    /**
     * 
     * @summary Установка настроек уведомлений
     * @param {NotificationsSettingsForm} [notificationsSettingsForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakNotificationsSettingsPost(notificationsSettingsForm?: NotificationsSettingsForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Удаление фотографии текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakPhotoDeletePost(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение фотографии текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakPhotoGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Добавление фотографии текущего пользователя
     * @param {File} photo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakPhotoPost(photo: File, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение данных профиля текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApiInterface
     */
    apiKeycloakProfileGet(options?: AxiosRequestConfig): AxiosPromise<KeycloakProfile>;

}

/**
 * KeycloakApi - object-oriented interface
 * @export
 * @class KeycloakApi
 * @extends {BaseAPI}
 */
export class KeycloakApi extends BaseAPI implements KeycloakApiInterface {
    /**
     * 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakCallbackGet(returnUrl?: string, options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakCallbackGet(returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Редактирование профиля текущего пользователя
     * @param {KeycloackProfileForm} [keycloackProfileForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakEditPost(keycloackProfileForm?: KeycloackProfileForm, options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakEditPost(keycloackProfileForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakGeolocDeletePost(options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakGeolocDeletePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Coordinates} [coordinates] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakGeolocSetPost(coordinates?: Coordinates, options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakGeolocSetPost(coordinates, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Авторизация в системе
     * @param {string} [address] Url для ридеректа на приложение авторизации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakLoginGet(address?: string, options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakLoginGet(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Выход из системы
     * @param {string} [address] Url для ридеркта на приложение авторизации
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakLogoutGet(address?: string, options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakLogoutGet(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение id текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakMeGet(options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakNotificationsCheckPost(id?: string, options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakNotificationsCheckPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Постраничное получение списка уведомлений
     * @param {boolean} [isRead] Фильтрация прочитанные/непрочитанные
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakNotificationsGet(isRead?: boolean, skip?: number, take?: number, options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakNotificationsGet(isRead, skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Флаг о наличии новых уведомлений для текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakNotificationsHasnewGet(options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakNotificationsHasnewGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение настроек уведомлений пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakNotificationsSettingsGet(options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakNotificationsSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Установка настроек уведомлений
     * @param {NotificationsSettingsForm} [notificationsSettingsForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakNotificationsSettingsPost(notificationsSettingsForm?: NotificationsSettingsForm, options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakNotificationsSettingsPost(notificationsSettingsForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Удаление фотографии текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakPhotoDeletePost(options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakPhotoDeletePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение фотографии текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakPhotoGet(options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakPhotoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Добавление фотографии текущего пользователя
     * @param {File} photo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakPhotoPost(photo: File, options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakPhotoPost(photo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение данных профиля текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakApi
     */
    public apiKeycloakProfileGet(options?: AxiosRequestConfig) {
        return KeycloakApiFp(this.configuration).apiKeycloakProfileGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionApi - axios parameter creator
 * @export
 */
export const QuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {QuestionForm} [questionForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsCreatePost: async (questionForm?: QuestionForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/questions/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [isAnswered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsGet: async (pageNumber?: number, pageSize?: number, isAnswered?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (isAnswered !== undefined) {
                localVarQueryParameter['isAnswered'] = isAnswered;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiQuestionsIdGet', 'id', id)
            const localVarPath = `/api/questions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionApi - functional programming interface
 * @export
 */
export const QuestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {QuestionForm} [questionForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionsCreatePost(questionForm?: QuestionForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionsCreatePost(questionForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [isAnswered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionsGet(pageNumber?: number, pageSize?: number, isAnswered?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Question>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionsGet(pageNumber, pageSize, isAnswered, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiQuestionsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserQuestionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiQuestionsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionApi - factory interface
 * @export
 */
export const QuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionApiFp(configuration)
    return {
        /**
         * 
         * @param {QuestionForm} [questionForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsCreatePost(questionForm?: QuestionForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiQuestionsCreatePost(questionForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [isAnswered] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsGet(pageNumber?: number, pageSize?: number, isAnswered?: boolean, options?: any): AxiosPromise<Array<Question>> {
            return localVarFp.apiQuestionsGet(pageNumber, pageSize, isAnswered, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiQuestionsIdGet(id: string, options?: any): AxiosPromise<UserQuestionModel> {
            return localVarFp.apiQuestionsIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionApi - interface
 * @export
 * @interface QuestionApi
 */
export interface QuestionApiInterface {
    /**
     * 
     * @param {QuestionForm} [questionForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApiInterface
     */
    apiQuestionsCreatePost(questionForm?: QuestionForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {boolean} [isAnswered] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApiInterface
     */
    apiQuestionsGet(pageNumber?: number, pageSize?: number, isAnswered?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<Question>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApiInterface
     */
    apiQuestionsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<UserQuestionModel>;

}

/**
 * QuestionApi - object-oriented interface
 * @export
 * @class QuestionApi
 * @extends {BaseAPI}
 */
export class QuestionApi extends BaseAPI implements QuestionApiInterface {
    /**
     * 
     * @param {QuestionForm} [questionForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionsCreatePost(questionForm?: QuestionForm, options?: AxiosRequestConfig) {
        return QuestionApiFp(this.configuration).apiQuestionsCreatePost(questionForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {boolean} [isAnswered] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionsGet(pageNumber?: number, pageSize?: number, isAnswered?: boolean, options?: AxiosRequestConfig) {
        return QuestionApiFp(this.configuration).apiQuestionsGet(pageNumber, pageSize, isAnswered, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public apiQuestionsIdGet(id: string, options?: AxiosRequestConfig) {
        return QuestionApiFp(this.configuration).apiQuestionsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ResultApi - axios parameter creator
 * @export
 */
export const ResultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Создание ответа на голосование
         * @param {number} [latitude] Широта текущей позиции пользователя
         * @param {number} [longitude] Долгота текущей позиции пользователя
         * @param {ResultForm} [resultForm] Модель ответа пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiResultsCreatePost: async (latitude?: number, longitude?: number, resultForm?: ResultForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/results/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resultForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение завершенного голосования по его уникальному идентификатору и ответов пользователя на него
         * @param {string} voteId Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiResultsGet: async (voteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'voteId' is not null or undefined
            assertParamExists('apiResultsGet', 'voteId', voteId)
            const localVarPath = `/api/results`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (voteId !== undefined) {
                localVarQueryParameter['voteId'] = voteId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResultApi - functional programming interface
 * @export
 */
export const ResultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Создание ответа на голосование
         * @param {number} [latitude] Широта текущей позиции пользователя
         * @param {number} [longitude] Долгота текущей позиции пользователя
         * @param {ResultForm} [resultForm] Модель ответа пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiResultsCreatePost(latitude?: number, longitude?: number, resultForm?: ResultForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiResultsCreatePost(latitude, longitude, resultForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение завершенного голосования по его уникальному идентификатору и ответов пользователя на него
         * @param {string} voteId Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiResultsGet(voteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiResultsGet(voteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResultApi - factory interface
 * @export
 */
export const ResultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResultApiFp(configuration)
    return {
        /**
         * 
         * @summary Создание ответа на голосование
         * @param {number} [latitude] Широта текущей позиции пользователя
         * @param {number} [longitude] Долгота текущей позиции пользователя
         * @param {ResultForm} [resultForm] Модель ответа пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiResultsCreatePost(latitude?: number, longitude?: number, resultForm?: ResultForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiResultsCreatePost(latitude, longitude, resultForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение завершенного голосования по его уникальному идентификатору и ответов пользователя на него
         * @param {string} voteId Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiResultsGet(voteId: string, options?: any): AxiosPromise<ResultModel> {
            return localVarFp.apiResultsGet(voteId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ResultApi - interface
 * @export
 * @interface ResultApi
 */
export interface ResultApiInterface {
    /**
     * 
     * @summary Создание ответа на голосование
     * @param {number} [latitude] Широта текущей позиции пользователя
     * @param {number} [longitude] Долгота текущей позиции пользователя
     * @param {ResultForm} [resultForm] Модель ответа пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultApiInterface
     */
    apiResultsCreatePost(latitude?: number, longitude?: number, resultForm?: ResultForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение завершенного голосования по его уникальному идентификатору и ответов пользователя на него
     * @param {string} voteId Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultApiInterface
     */
    apiResultsGet(voteId: string, options?: AxiosRequestConfig): AxiosPromise<ResultModel>;

}

/**
 * ResultApi - object-oriented interface
 * @export
 * @class ResultApi
 * @extends {BaseAPI}
 */
export class ResultApi extends BaseAPI implements ResultApiInterface {
    /**
     * 
     * @summary Создание ответа на голосование
     * @param {number} [latitude] Широта текущей позиции пользователя
     * @param {number} [longitude] Долгота текущей позиции пользователя
     * @param {ResultForm} [resultForm] Модель ответа пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultApi
     */
    public apiResultsCreatePost(latitude?: number, longitude?: number, resultForm?: ResultForm, options?: AxiosRequestConfig) {
        return ResultApiFp(this.configuration).apiResultsCreatePost(latitude, longitude, resultForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение завершенного голосования по его уникальному идентификатору и ответов пользователя на него
     * @param {string} voteId Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResultApi
     */
    public apiResultsGet(voteId: string, options?: AxiosRequestConfig) {
        return ResultApiFp(this.configuration).apiResultsGet(voteId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ViolationsApi - axios parameter creator
 * @export
 */
export const ViolationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение списка заявлений по категории
         * @param {string} [categoryId] Уникальный идентификатор категории
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsBycategoryGet: async (categoryId?: string, page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/bycategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка заявлений по заданным параметрам
         * @param {string} [status] Статус
         * @param {string} [categoryId] Уникальный идентификатор категории
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsByparametersGet: async (status?: string, categoryId?: string, page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/byparameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка заявлений по статусу
         * @param {string} [status] Статус
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsBystatusGet: async (status?: string, page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/bystatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Создание заявления
         * @param {string} [categoryId] 
         * @param {Array<string>} [subCategoryIds] 
         * @param {string} [address] 
         * @param {Array<File>} [photos] 
         * @param {Array<File>} [videos] 
         * @param {Array<File>} [docs] 
         * @param {string} [latitude] 
         * @param {string} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsCreatePost: async (categoryId?: string, subCategoryIds?: Array<string>, address?: string, photos?: Array<File>, videos?: Array<File>, docs?: Array<File>, latitude?: string, longitude?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (categoryId !== undefined) { 
                localVarFormParams.append('CategoryId', categoryId as any);
            }
                if (subCategoryIds) {
                subCategoryIds.forEach((element) => {
                    localVarFormParams.append('SubCategoryIds', element as any);
                })
            }

    
            if (address !== undefined) { 
                localVarFormParams.append('Address', address as any);
            }
                if (photos) {
                photos.forEach((element) => {
                    localVarFormParams.append('Photos', element as any);
                })
            }

                if (videos) {
                videos.forEach((element) => {
                    localVarFormParams.append('Videos', element as any);
                })
            }

                if (docs) {
                docs.forEach((element) => {
                    localVarFormParams.append('Docs', element as any);
                })
            }

    
            if (latitude !== undefined) { 
                localVarFormParams.append('Latitude', latitude as any);
            }
    
            if (longitude !== undefined) { 
                localVarFormParams.append('Longitude', longitude as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка созданных заявлений
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsCreatedGet: async (page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/created`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к заявлению
         * @param {string} [fileId] Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsFileGet: async (fileId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка завершенных заявлений
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsFinishedGet: async (page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/finished`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Заврешение работы с заявлением
         * @param {string} id 
         * @param {ViolationWorkEndForm} [violationWorkEndForm] Форма завершения работы с заявлением
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdEndworkPost: async (id: string, violationWorkEndForm?: ViolationWorkEndForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationsIdEndworkPost', 'id', id)
            const localVarPath = `/api/Violations/{id}/endwork`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(violationWorkEndForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение заявления по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationsIdGet', 'id', id)
            const localVarPath = `/api/Violations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Поддержать заявление
         * @param {string} id Уникальный идентификатор заявления
         * @param {boolean} [isSubscribe] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdSupportPost: async (id: string, isSubscribe?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiViolationsIdSupportPost', 'id', id)
            const localVarPath = `/api/Violations/{id}/support`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isSubscribe !== undefined) {
                localVarQueryParameter['isSubscribe'] = isSubscribe;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка заявлений, находящихся в работе
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsInworkGet: async (page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/inwork`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка заявлений текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsMyviolationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/myviolations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка опубликованных заявлений
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsPublishedGet: async (page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Violations/published`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ViolationsApi - functional programming interface
 * @export
 */
export const ViolationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ViolationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение списка заявлений по категории
         * @param {string} [categoryId] Уникальный идентификатор категории
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsBycategoryGet(categoryId?: string, page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsBycategoryGet(categoryId, page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка заявлений по заданным параметрам
         * @param {string} [status] Статус
         * @param {string} [categoryId] Уникальный идентификатор категории
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsByparametersGet(status?: string, categoryId?: string, page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsByparametersGet(status, categoryId, page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка заявлений по статусу
         * @param {string} [status] Статус
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsBystatusGet(status?: string, page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsBystatusGet(status, page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Создание заявления
         * @param {string} [categoryId] 
         * @param {Array<string>} [subCategoryIds] 
         * @param {string} [address] 
         * @param {Array<File>} [photos] 
         * @param {Array<File>} [videos] 
         * @param {Array<File>} [docs] 
         * @param {string} [latitude] 
         * @param {string} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsCreatePost(categoryId?: string, subCategoryIds?: Array<string>, address?: string, photos?: Array<File>, videos?: Array<File>, docs?: Array<File>, latitude?: string, longitude?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsCreatePost(categoryId, subCategoryIds, address, photos, videos, docs, latitude, longitude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка созданных заявлений
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsCreatedGet(page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsCreatedGet(page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к заявлению
         * @param {string} [fileId] Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsFileGet(fileId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsFileGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка завершенных заявлений
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsFinishedGet(page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsFinishedGet(page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Заврешение работы с заявлением
         * @param {string} id 
         * @param {ViolationWorkEndForm} [violationWorkEndForm] Форма завершения работы с заявлением
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsIdEndworkPost(id: string, violationWorkEndForm?: ViolationWorkEndForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsIdEndworkPost(id, violationWorkEndForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение заявления по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ViolationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Поддержать заявление
         * @param {string} id Уникальный идентификатор заявления
         * @param {boolean} [isSubscribe] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsIdSupportPost(id: string, isSubscribe?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsIdSupportPost(id, isSubscribe, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка заявлений, находящихся в работе
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsInworkGet(page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsInworkGet(page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка заявлений текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsMyviolationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsMyviolationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка опубликованных заявлений
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiViolationsPublishedGet(page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ViolationListDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiViolationsPublishedGet(page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ViolationsApi - factory interface
 * @export
 */
export const ViolationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ViolationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение списка заявлений по категории
         * @param {string} [categoryId] Уникальный идентификатор категории
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsBycategoryGet(categoryId?: string, page?: number, number?: number, options?: any): AxiosPromise<Array<ViolationListDto>> {
            return localVarFp.apiViolationsBycategoryGet(categoryId, page, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка заявлений по заданным параметрам
         * @param {string} [status] Статус
         * @param {string} [categoryId] Уникальный идентификатор категории
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsByparametersGet(status?: string, categoryId?: string, page?: number, number?: number, options?: any): AxiosPromise<Array<ViolationListDto>> {
            return localVarFp.apiViolationsByparametersGet(status, categoryId, page, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка заявлений по статусу
         * @param {string} [status] Статус
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsBystatusGet(status?: string, page?: number, number?: number, options?: any): AxiosPromise<Array<ViolationListDto>> {
            return localVarFp.apiViolationsBystatusGet(status, page, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Создание заявления
         * @param {string} [categoryId] 
         * @param {Array<string>} [subCategoryIds] 
         * @param {string} [address] 
         * @param {Array<File>} [photos] 
         * @param {Array<File>} [videos] 
         * @param {Array<File>} [docs] 
         * @param {string} [latitude] 
         * @param {string} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsCreatePost(categoryId?: string, subCategoryIds?: Array<string>, address?: string, photos?: Array<File>, videos?: Array<File>, docs?: Array<File>, latitude?: string, longitude?: string, options?: any): AxiosPromise<ViolationDto> {
            return localVarFp.apiViolationsCreatePost(categoryId, subCategoryIds, address, photos, videos, docs, latitude, longitude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка созданных заявлений
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsCreatedGet(page?: number, number?: number, options?: any): AxiosPromise<Array<ViolationListDto>> {
            return localVarFp.apiViolationsCreatedGet(page, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение файла, прикрепленного к заявлению
         * @param {string} [fileId] Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsFileGet(fileId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsFileGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка завершенных заявлений
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsFinishedGet(page?: number, number?: number, options?: any): AxiosPromise<Array<ViolationListDto>> {
            return localVarFp.apiViolationsFinishedGet(page, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Заврешение работы с заявлением
         * @param {string} id 
         * @param {ViolationWorkEndForm} [violationWorkEndForm] Форма завершения работы с заявлением
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdEndworkPost(id: string, violationWorkEndForm?: ViolationWorkEndForm, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsIdEndworkPost(id, violationWorkEndForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение заявления по id
         * @param {string} id Уникальный идентификатор заявления
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdGet(id: string, options?: any): AxiosPromise<ViolationDto> {
            return localVarFp.apiViolationsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Поддержать заявление
         * @param {string} id Уникальный идентификатор заявления
         * @param {boolean} [isSubscribe] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsIdSupportPost(id: string, isSubscribe?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiViolationsIdSupportPost(id, isSubscribe, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка заявлений, находящихся в работе
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsInworkGet(page?: number, number?: number, options?: any): AxiosPromise<Array<ViolationListDto>> {
            return localVarFp.apiViolationsInworkGet(page, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка заявлений текущего пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsMyviolationsGet(options?: any): AxiosPromise<Array<ViolationListDto>> {
            return localVarFp.apiViolationsMyviolationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка опубликованных заявлений
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество элементов на странице
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiViolationsPublishedGet(page?: number, number?: number, options?: any): AxiosPromise<Array<ViolationListDto>> {
            return localVarFp.apiViolationsPublishedGet(page, number, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ViolationsApi - interface
 * @export
 * @interface ViolationsApi
 */
export interface ViolationsApiInterface {
    /**
     * 
     * @summary Получение списка заявлений по категории
     * @param {string} [categoryId] Уникальный идентификатор категории
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsBycategoryGet(categoryId?: string, page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<Array<ViolationListDto>>;

    /**
     * 
     * @summary Получение списка заявлений по заданным параметрам
     * @param {string} [status] Статус
     * @param {string} [categoryId] Уникальный идентификатор категории
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsByparametersGet(status?: string, categoryId?: string, page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<Array<ViolationListDto>>;

    /**
     * 
     * @summary Получение списка заявлений по статусу
     * @param {string} [status] Статус
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsBystatusGet(status?: string, page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<Array<ViolationListDto>>;

    /**
     * 
     * @summary Создание заявления
     * @param {string} [categoryId] 
     * @param {Array<string>} [subCategoryIds] 
     * @param {string} [address] 
     * @param {Array<File>} [photos] 
     * @param {Array<File>} [videos] 
     * @param {Array<File>} [docs] 
     * @param {string} [latitude] 
     * @param {string} [longitude] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsCreatePost(categoryId?: string, subCategoryIds?: Array<string>, address?: string, photos?: Array<File>, videos?: Array<File>, docs?: Array<File>, latitude?: string, longitude?: string, options?: AxiosRequestConfig): AxiosPromise<ViolationDto>;

    /**
     * 
     * @summary Получение списка созданных заявлений
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsCreatedGet(page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<Array<ViolationListDto>>;

    /**
     * 
     * @summary Получение файла, прикрепленного к заявлению
     * @param {string} [fileId] Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsFileGet(fileId?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение списка завершенных заявлений
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsFinishedGet(page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<Array<ViolationListDto>>;

    /**
     * 
     * @summary Заврешение работы с заявлением
     * @param {string} id 
     * @param {ViolationWorkEndForm} [violationWorkEndForm] Форма завершения работы с заявлением
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsIdEndworkPost(id: string, violationWorkEndForm?: ViolationWorkEndForm, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение заявления по id
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<ViolationDto>;

    /**
     * 
     * @summary Поддержать заявление
     * @param {string} id Уникальный идентификатор заявления
     * @param {boolean} [isSubscribe] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsIdSupportPost(id: string, isSubscribe?: boolean, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение списка заявлений, находящихся в работе
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsInworkGet(page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<Array<ViolationListDto>>;

    /**
     * 
     * @summary Получение списка заявлений текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsMyviolationsGet(options?: AxiosRequestConfig): AxiosPromise<Array<ViolationListDto>>;

    /**
     * 
     * @summary Получение списка опубликованных заявлений
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApiInterface
     */
    apiViolationsPublishedGet(page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<Array<ViolationListDto>>;

}

/**
 * ViolationsApi - object-oriented interface
 * @export
 * @class ViolationsApi
 * @extends {BaseAPI}
 */
export class ViolationsApi extends BaseAPI implements ViolationsApiInterface {
    /**
     * 
     * @summary Получение списка заявлений по категории
     * @param {string} [categoryId] Уникальный идентификатор категории
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsBycategoryGet(categoryId?: string, page?: number, number?: number, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsBycategoryGet(categoryId, page, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка заявлений по заданным параметрам
     * @param {string} [status] Статус
     * @param {string} [categoryId] Уникальный идентификатор категории
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsByparametersGet(status?: string, categoryId?: string, page?: number, number?: number, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsByparametersGet(status, categoryId, page, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка заявлений по статусу
     * @param {string} [status] Статус
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsBystatusGet(status?: string, page?: number, number?: number, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsBystatusGet(status, page, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Создание заявления
     * @param {string} [categoryId] 
     * @param {Array<string>} [subCategoryIds] 
     * @param {string} [address] 
     * @param {Array<File>} [photos] 
     * @param {Array<File>} [videos] 
     * @param {Array<File>} [docs] 
     * @param {string} [latitude] 
     * @param {string} [longitude] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsCreatePost(categoryId?: string, subCategoryIds?: Array<string>, address?: string, photos?: Array<File>, videos?: Array<File>, docs?: Array<File>, latitude?: string, longitude?: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsCreatePost(categoryId, subCategoryIds, address, photos, videos, docs, latitude, longitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка созданных заявлений
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsCreatedGet(page?: number, number?: number, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsCreatedGet(page, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение файла, прикрепленного к заявлению
     * @param {string} [fileId] Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsFileGet(fileId?: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsFileGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка завершенных заявлений
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsFinishedGet(page?: number, number?: number, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsFinishedGet(page, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Заврешение работы с заявлением
     * @param {string} id 
     * @param {ViolationWorkEndForm} [violationWorkEndForm] Форма завершения работы с заявлением
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsIdEndworkPost(id: string, violationWorkEndForm?: ViolationWorkEndForm, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsIdEndworkPost(id, violationWorkEndForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение заявления по id
     * @param {string} id Уникальный идентификатор заявления
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsIdGet(id: string, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Поддержать заявление
     * @param {string} id Уникальный идентификатор заявления
     * @param {boolean} [isSubscribe] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsIdSupportPost(id: string, isSubscribe?: boolean, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsIdSupportPost(id, isSubscribe, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка заявлений, находящихся в работе
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsInworkGet(page?: number, number?: number, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsInworkGet(page, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка заявлений текущего пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsMyviolationsGet(options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsMyviolationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка опубликованных заявлений
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество элементов на странице
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ViolationsApi
     */
    public apiViolationsPublishedGet(page?: number, number?: number, options?: AxiosRequestConfig) {
        return ViolationsApiFp(this.configuration).apiViolationsPublishedGet(page, number, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VoteApi - axios parameter creator
 * @export
 */
export const VoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Получение списка анонсов голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesAnnouncementGet: async (page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/votes/announcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение анонса голосования по id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesAnnouncementIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesAnnouncementIdGet', 'id', id)
            const localVarPath = `/api/votes/announcement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка доступных голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesAvailableGet: async (page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/votes/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение всех баннеров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesBannersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/votes/banners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение списка завершенных голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesCompletedGet: async (page?: number, number?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/votes/completed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (number !== undefined) {
                localVarQueryParameter['number'] = number;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение голосования по уникальному идентификатору
         * @param {string} id Уникальный идентификатор
         * @param {number} [latitude] Широта текущей позиции пользователя
         * @param {number} [longitude] Долгота текущей позиции пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdGet: async (id: string, latitude?: number, longitude?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesIdGet', 'id', id)
            const localVarPath = `/api/votes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdImageGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesIdImageGet', 'id', id)
            const localVarPath = `/api/votes/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение статического файла для голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdStaticGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiVotesIdStaticGet', 'id', id)
            const localVarPath = `/api/votes/{id}/static`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VoteApi - functional programming interface
 * @export
 */
export const VoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Получение списка анонсов голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesAnnouncementGet(page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoteCardModelHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesAnnouncementGet(page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение анонса голосования по id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesAnnouncementIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnouncementVoteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesAnnouncementIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка доступных голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesAvailableGet(page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoteCardModelHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesAvailableGet(page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение всех баннеров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesBannersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VoteBannerModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesBannersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение списка завершенных голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesCompletedGet(page?: number, number?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoteCardModelHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesCompletedGet(page, number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение голосования по уникальному идентификатору
         * @param {string} id Уникальный идентификатор
         * @param {number} [latitude] Широта текущей позиции пользователя
         * @param {number} [longitude] Долгота текущей позиции пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesIdGet(id: string, latitude?: number, longitude?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoteModelHolder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesIdGet(id, latitude, longitude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesIdImageGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesIdImageGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение статического файла для голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesIdStaticGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesIdStaticGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VoteApi - factory interface
 * @export
 */
export const VoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VoteApiFp(configuration)
    return {
        /**
         * 
         * @summary Получение списка анонсов голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesAnnouncementGet(page?: number, number?: number, options?: any): AxiosPromise<VoteCardModelHolder> {
            return localVarFp.apiVotesAnnouncementGet(page, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение анонса голосования по id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesAnnouncementIdGet(id: string, options?: any): AxiosPromise<AnnouncementVoteModel> {
            return localVarFp.apiVotesAnnouncementIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка доступных голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesAvailableGet(page?: number, number?: number, options?: any): AxiosPromise<VoteCardModelHolder> {
            return localVarFp.apiVotesAvailableGet(page, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение всех баннеров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesBannersGet(options?: any): AxiosPromise<Array<VoteBannerModel>> {
            return localVarFp.apiVotesBannersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение списка завершенных голосований
         * @param {number} [page] Номер страницы
         * @param {number} [number] Количество отображаемых элементов
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesCompletedGet(page?: number, number?: number, options?: any): AxiosPromise<VoteCardModelHolder> {
            return localVarFp.apiVotesCompletedGet(page, number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение голосования по уникальному идентификатору
         * @param {string} id Уникальный идентификатор
         * @param {number} [latitude] Широта текущей позиции пользователя
         * @param {number} [longitude] Долгота текущей позиции пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdGet(id: string, latitude?: number, longitude?: number, options?: any): AxiosPromise<VoteModelHolder> {
            return localVarFp.apiVotesIdGet(id, latitude, longitude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение файла по id
         * @param {string} id Уникальный идентификатор файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdImageGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesIdImageGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение статического файла для голосования
         * @param {string} id Уникальный идентификатор голосования
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesIdStaticGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesIdStaticGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VoteApi - interface
 * @export
 * @interface VoteApi
 */
export interface VoteApiInterface {
    /**
     * 
     * @summary Получение списка анонсов голосований
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество отображаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesAnnouncementGet(page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<VoteCardModelHolder>;

    /**
     * 
     * @summary Получение анонса голосования по id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesAnnouncementIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<AnnouncementVoteModel>;

    /**
     * 
     * @summary Получение списка доступных голосований
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество отображаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesAvailableGet(page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<VoteCardModelHolder>;

    /**
     * 
     * @summary Получение всех баннеров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesBannersGet(options?: AxiosRequestConfig): AxiosPromise<Array<VoteBannerModel>>;

    /**
     * 
     * @summary Получение списка завершенных голосований
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество отображаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesCompletedGet(page?: number, number?: number, options?: AxiosRequestConfig): AxiosPromise<VoteCardModelHolder>;

    /**
     * 
     * @summary Получение голосования по уникальному идентификатору
     * @param {string} id Уникальный идентификатор
     * @param {number} [latitude] Широта текущей позиции пользователя
     * @param {number} [longitude] Долгота текущей позиции пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesIdGet(id: string, latitude?: number, longitude?: number, options?: AxiosRequestConfig): AxiosPromise<VoteModelHolder>;

    /**
     * 
     * @summary Получение файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesIdImageGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary Получение статического файла для голосования
     * @param {string} id Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApiInterface
     */
    apiVotesIdStaticGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * VoteApi - object-oriented interface
 * @export
 * @class VoteApi
 * @extends {BaseAPI}
 */
export class VoteApi extends BaseAPI implements VoteApiInterface {
    /**
     * 
     * @summary Получение списка анонсов голосований
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество отображаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesAnnouncementGet(page?: number, number?: number, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesAnnouncementGet(page, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение анонса голосования по id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesAnnouncementIdGet(id: string, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesAnnouncementIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка доступных голосований
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество отображаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesAvailableGet(page?: number, number?: number, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesAvailableGet(page, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение всех баннеров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesBannersGet(options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesBannersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение списка завершенных голосований
     * @param {number} [page] Номер страницы
     * @param {number} [number] Количество отображаемых элементов
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesCompletedGet(page?: number, number?: number, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesCompletedGet(page, number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение голосования по уникальному идентификатору
     * @param {string} id Уникальный идентификатор
     * @param {number} [latitude] Широта текущей позиции пользователя
     * @param {number} [longitude] Долгота текущей позиции пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesIdGet(id: string, latitude?: number, longitude?: number, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesIdGet(id, latitude, longitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение файла по id
     * @param {string} id Уникальный идентификатор файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesIdImageGet(id: string, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesIdImageGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение статического файла для голосования
     * @param {string} id Уникальный идентификатор голосования
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VoteApi
     */
    public apiVotesIdStaticGet(id: string, options?: AxiosRequestConfig) {
        return VoteApiFp(this.configuration).apiVotesIdStaticGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VotesFilesApi - axios parameter creator
 * @export
 */
export const VotesFilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesFilesAnswersFilesFileIdGet: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiVotesFilesAnswersFilesFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/VotesFiles/answers/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesFilesQuestionsFilesFileIdGet: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiVotesFilesQuestionsFilesFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/VotesFiles/questions/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesFilesVotesFilesFileIdGet: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('apiVotesFilesVotesFilesFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/VotesFiles/votes/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VotesFilesApi - functional programming interface
 * @export
 */
export const VotesFilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VotesFilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesFilesAnswersFilesFileIdGet(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesFilesAnswersFilesFileIdGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesFilesQuestionsFilesFileIdGet(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesFilesQuestionsFilesFileIdGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVotesFilesVotesFilesFileIdGet(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVotesFilesVotesFilesFileIdGet(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VotesFilesApi - factory interface
 * @export
 */
export const VotesFilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VotesFilesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesFilesAnswersFilesFileIdGet(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesFilesAnswersFilesFileIdGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesFilesQuestionsFilesFileIdGet(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesFilesQuestionsFilesFileIdGet(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVotesFilesVotesFilesFileIdGet(fileId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVotesFilesVotesFilesFileIdGet(fileId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VotesFilesApi - interface
 * @export
 * @interface VotesFilesApi
 */
export interface VotesFilesApiInterface {
    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApiInterface
     */
    apiVotesFilesAnswersFilesFileIdGet(fileId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApiInterface
     */
    apiVotesFilesQuestionsFilesFileIdGet(fileId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApiInterface
     */
    apiVotesFilesVotesFilesFileIdGet(fileId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * VotesFilesApi - object-oriented interface
 * @export
 * @class VotesFilesApi
 * @extends {BaseAPI}
 */
export class VotesFilesApi extends BaseAPI implements VotesFilesApiInterface {
    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApi
     */
    public apiVotesFilesAnswersFilesFileIdGet(fileId: string, options?: AxiosRequestConfig) {
        return VotesFilesApiFp(this.configuration).apiVotesFilesAnswersFilesFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApi
     */
    public apiVotesFilesQuestionsFilesFileIdGet(fileId: string, options?: AxiosRequestConfig) {
        return VotesFilesApiFp(this.configuration).apiVotesFilesQuestionsFilesFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesFilesApi
     */
    public apiVotesFilesVotesFilesFileIdGet(fileId: string, options?: AxiosRequestConfig) {
        return VotesFilesApiFp(this.configuration).apiVotesFilesVotesFilesFileIdGet(fileId, options).then((request) => request(this.axios, this.basePath));
    }
}


