import React from "react";

import "./index.scss";

const QuarterlyPage = () => {
  return (
    <div className="quartelry component">
      <div className="header">
        <h1 className="header_title">Рейтинг квартальных</h1>
        <div className="table">
          <div className="table-item">
            <div className="rate">
              <svg
                width="38"
                height="38"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="#ffbf00"
              >
                <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
              </svg>
              <div className="numbers">5.0</div>
            </div>
            <div className="unit">
              <div className="img"></div>
              <div className="info">
                <span className="name">Коновалов Федор Сергеевич</span>
                <span className="district">Верх-Исетский район</span>
                <span className="territory">Имущественный комплекс № 13</span>
              </div>
            </div>
          </div>
          <div className="table-item">
            <div className="rate">
              <svg
                width="38"
                height="38"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="#c4cace"
              >
                <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
              </svg>
              <div className="numbers">4.8</div>
            </div>
            <div className="unit">
              <div className="img2" />
              <div className="info">
                <span className="name">Стрельцов Сергей Васильевич</span>
                <span className="district">Орджоникидзевский район</span>
                <span className="territory">Имущественный комплекс № 9</span>
              </div>
            </div>
          </div>
          <div className="table-item">
            <div className="rate">
              <svg
                width="38"
                height="38"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="#c4cace"
              >
                <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
              </svg>
              <div className="numbers">4.8</div>
            </div>
            <div className="unit">
              <div className="img3" />
              <div className="info">
                <span className="name">Пахалюк Ольга Анатольевна</span>
                <span className="district">Чкаловский райо</span>
                <span className="territory">Имущественный комплекс №3</span>
              </div>
            </div>
          </div>
          <div className="table-item">
            <div className="rate">
              <svg
                width="38"
                height="38"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="#c4cace"
              >
                <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
              </svg>
              <div className="numbers">4.6</div>
            </div>
            <div className="unit">
              <div className="img4" />
              <div className="info">
                <span className="name">Плотникова Елена Евгеньевна</span>
                <span className="district">Ленинский район</span>
                <span className="territory">Имущественный комплекс №1</span>
              </div>
            </div>
          </div>
          <div className="table-item">
            <div className="rate">
              <svg
                width="38"
                height="38"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="#c4cace"
              >
                <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
              </svg>
              <div className="numbers">4.4</div>
            </div>
            <div className="unit">
              <div className="img5" />
              <div className="info">
                <span className="name">Кривошеев Андрей Владимирович</span>
                <span className="district">Кировский район</span>
                <span className="territory">Имущественный комплекс №2</span>
              </div>
            </div>
          </div>
          <div className="table-item">
            <div className="rate">
              <svg
                width="38"
                height="38"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="#c4cace"
              >
                <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
              </svg>
              <div className="numbers">4.3</div>
              <div className="numbers"></div>
            </div>
            <div className="unit">
              <div className="img6" />
              <div className="info">
                <span className="name">Пиягин Егор Владимирович</span>
                <span className="district">Ленинский район</span>
                <span className="territory">Имущественный комплекс №9</span>
              </div>
            </div>
          </div>
          <div className="table-item">
            <div className="rate">
              <svg
                width="38"
                height="38"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="#cd7f32"
              >
                <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
              </svg>
              <div className="numbers">4.0</div>
            </div>
            <div className="unit">
              <div className="img7" />
              <div className="info">
                <span className="name">Плотникова Елена Евгеньевна</span>
                <span className="district">Чкаловский район</span>
                <span className="territory">Имущественный комплекс №2</span>
              </div>
            </div>
          </div>
          <div className="table-item">
            <div className="rate">
              <svg
                width="38"
                height="38"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="#cd7f32"
              >
                <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
              </svg>
              <div className="numbers">3.8</div>
            </div>
            <div className="unit">
              <div className="img8" />
              <div className="info">
                <span className="name">Кузнецова Ксения Алексеевна</span>
                <span className="district">Верх-Исетский район</span>
                <span className="territory">Имущественный комплекс №15</span>
              </div>
            </div>
          </div>
          <div className="table-item">
            <div className="rate">
              <svg
                width="38"
                height="38"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="#cd7f32"
              >
                <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
              </svg>
              <div className="numbers">3.8</div>
            </div>
            <div className="unit">
              <div className="img9" />
              <div className="info">
                <span className="name">Лаптева Нина Васильевна</span>
                <span className="district">Октябрьский район</span>
                <span className="territory">Имущественный комплекс №3</span>
              </div>
            </div>
          </div>
          <div className="table-item">
            <div className="rate">
              <svg
                width="38"
                height="38"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                fill="#cd7f32"
              >
                <path d="M31.547 12a.848.848 0 00-.677-.577l-9.427-1.376-4.224-8.532a.847.847 0 00-1.516 0l-4.218 8.534-9.427 1.355a.847.847 0 00-.467 1.467l6.823 6.664-1.612 9.375a.847.847 0 001.23.893l8.428-4.434 8.432 4.432a.847.847 0 001.229-.894l-1.615-9.373 6.822-6.665a.845.845 0 00.214-.869z" />
              </svg>
              <div className="numbers">3.7</div>
            </div>
            <div className="unit">
              <div className="img10" />
              <div className="info">
                <span className="name">Лаптева Нина Васильевна</span>
                <span className="district">Октябрьский район</span>
                <span className="territory">Имущественный комплекс №3</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuarterlyPage;
