import { makeAutoObservable, runInAction } from "mobx";

import { API } from "../../../api";
import { navigateLinks } from "../../constants/layoutItems";
import { URL_SHOP } from "../../routes/paths";

class NavBarLinksStore {
  isShopShowing = {
    isVisibleInMainMenu: false,
    isVisibleInProfile: false,
  };

  constructor() {
    makeAutoObservable(this);
  }

  get menuHeaderAndFooterItems() {
    return this.isShopShowing.isVisibleInMainMenu
      ? navigateLinks
      : navigateLinks.filter((item) => item.path !== URL_SHOP);
  }

  getShopShownSettings = async () => {
    return API.shop.ProjectSettings.projectsettingsGet().then(({ data }) => {
      runInAction(() => {
        this.isShopShowing = {
          isVisibleInMainMenu: data.isVisibleInMainMenu ?? false,
          isVisibleInProfile: data.isVisibleInProfile ?? false,
        };
      });
    });
  };
}

export default new NavBarLinksStore();
