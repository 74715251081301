import { makeAutoObservable, runInAction } from "mobx";
import { AISAE } from "../constants/api";
import login from "../utils/login";
import Address from "./address";
import bigLoader from "./bigLoader";
import Categories from "./categories";
import loading from "./loading";
import Dropzone from "./profileDropzone";
import User from "./user";

//Create new Statement in Profile
class Statement {
  statuses = {
    success: false,
    error: false,
  };
  errorMessage = null;

  resetStatuses = () => {
    this.statuses = {
      success: false,
      error: false,
    };
    this.errorMessage = null;
  };

  resetAll = () => {
    Address.value = "";
    Address.position = [];
    Address.showMap = false;
    Categories.form2 = [];
    Dropzone.acceptedFiles = [];
    Categories.reset();
    Categories.loadCategories();
  };

  loadStatment = async () => {
    const ok = await User.checkAuth();

    if (!ok) {
      login();
      return;
    }

    try {
      loading.turnOn();
      bigLoader.turnOn();

      const errorHandler = (msg) => {
        this.loadError(msg);
        throw new Error(msg);
      };

      const userId = User.id;
      const categoryId = Categories.selectedCategoryId;
      !categoryId && errorHandler("Не указана категория");

      let l = [];

      Categories.form2.forEach((item) => {
        if (item.length !== 0) {
          l = item;
        }
      });

      const selectedId =
        Categories.form2.length > 0 ? l.find((it) => it.selected).id : null;

      !selectedId &&
        Categories.isHaveSubCategories &&
        errorHandler("Не указана подкатегория");

      const address = Address.value;
      !address && errorHandler("Неправильно указан адрес");

      const [latitude, longitude] = Address.position;
      !latitude && !longitude && errorHandler("Неправильно указан адрес");

      const files = Dropzone.acceptedFiles;

      if (!this.statuses.error) {
        const data = new FormData();

        data.append("CategoryId", categoryId);
        if (selectedId) data.append("SubCategoryIds", selectedId);
        data.append("Address", address);
        data.append("Latitude", latitude);
        data.append("Longitude", longitude);
        data.append("UserId", userId);

        files.forEach((file) => {
          const type = file.type.match(/image|video/m)[0];
          if (type === "image") {
            data.append("Photos", file);
          } else if (type === "video") {
            data.append("Videos", file);
          }
        });

        const res = await fetch(AISAE + "api/Violations/create", {
          method: "POST",
          body: data,
        });

        if (!res.ok) {
          runInAction(() => (this.statuses.error = true));
          throw new Error(res);
        }

        runInAction(() => {
          this.statuses = {
            success: true,
            error: false,
          };
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      loading.turnOff();
      bigLoader.turnOff();
    }
  };

  loadError = (msg) => {
    this.statuses = {
      success: false,
      error: true,
    };
    this.errorMessage = msg;
    return true;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new Statement();
