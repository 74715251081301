import React, { useCallback, useEffect, useRef } from "react";
import "./index.scss";

import BurgerMenuStore from "../../../stores/burgerMenu";

import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import Button from "../../../Common/Components/Button";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import MenuLinksStore from "../../../Project/stores/app/NavBarLinksStore";

const BurgerMenu = () => {
  const { active, fixed, button } = BurgerMenuStore;
  const { menuHeaderAndFooterItems } = MenuLinksStore;
  const ref = useRef(null);

  const handleScroll = useCallback(() => {
    const memo = window.pageYOffset >= 200;

    if (memo !== fixed) {
      BurgerMenuStore.setFixed(memo);
    }
  }, [fixed]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", () => handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const menu = ref.current;

    if (active && menu) {
      disableBodyScroll(menu);
    } else {
      enableBodyScroll(menu);
    }

    return () => enableBodyScroll(menu);
  }, [active]);

  return (
    <div
      className={`BurgerMenu ${active ? "active" : ""} 
        ${fixed ? "fixed" : ""} 
        ${button ? "withButton" : ""}`}
      ref={ref}
    >
      <div className="BurgerMenu__header">
        <a href="/" className="BurgerMenu__logo">
          <div className="BurgerMenu__icon"></div>
          <div className="BurgerMenu__title">АКТИВНЫЙ ЕКАТЕРИНБУРГ</div>
        </a>
        <div
          className={`Burger__icon burger  ${active ? "active" : ""}`}
          onClick={BurgerMenuStore.switchActive}
        >
          <span className="burger__item"></span>
          <span className="burger__item"></span>
          <span className="burger__item"></span>
        </div>
      </div>

      {button ? (
        <div
          className={`BurgerMenu__subButton ${fixed && !active ? "fixed" : ""}`}
        >
          <Button
            title={button.title}
            type={button.type ? button.type : "primary"}
            onClick={button.onClick}
          />
        </div>
      ) : null}

      <div className={`BurgerMenu__menu ${active ? "active" : ""}`}>
        {menuHeaderAndFooterItems.map((item) => (
          <Link
            key={item.path}
            onClick={BurgerMenuStore.switchActive}
            to={item.path}
            children={item.content}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(BurgerMenu);
