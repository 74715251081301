import React from "react";
import { observer } from "mobx-react";

import "./index.scss";

const Checkbox = observer((props) => {
  const { options, click, id, hasOtherOption } = props;

  return (
    <div className={`checklist-wrapper ${hasOtherOption ? "custom" : ""}`}>
      {options &&
        options.map((option, idx) => {
          const { text, checked } = option;

          return (
            <label
              key={idx}
              className={`checkbox ${hasOtherOption ? "custom" : ""}`}
            >
              <input
                value={text}
                type="checkbox"
                checked={checked}
                className="input-checkbox"
                onChange={() => click(idx, id)}
              />
              <span className={`checkmark ${checked}`}></span>
              <span className="text-label">{text}</span>
            </label>
          );
        })}
    </div>
  );
});

export default Checkbox;
