import React, { Suspense, useEffect } from "react";
import { observer } from "mobx-react";
import { Route, useLocation } from "react-router-dom";
import cn from "classnames";

import BigLoader from "../Common/Components/BigLoader";
import MobileLinkToProfile from "../Common/Components/MobileLinkToProfile";
import EskPanel from "../Project/components/EskPanel";
import BurgerMenu from "./Components/BurgerMenu";
import Footer from "./Components/Footer";
import LoaderSpinner from "./Components/Loader";
//import MobileApp from "./Components/MobileApp";
import TopMenu from "./Components/TopMenu";

//import mobileAppStore from "../stores/mobileAppStore";
import User from "../stores/user";

import { routes as naviagtionRoutes } from "../Project/routes";
import NotificationsStore from "../Project/stores/app/NotificationsStore";
import {
  URL_HOME,
  URL_PROFILE_VIOLATIONS,
  URL_PROFILE,
  URL_PROFILE_SETTINGS,
  URL_SHOP,
  URL_VIOLATIONS,
  URL_VOTES,
} from "../Project/routes/paths";

const MainPage = React.lazy(() => import("../Project/pages/Home"));
const ProfileComponent = React.lazy(() => import("./Pages/Profile"));

const App = (props) => {
  const { isAuth } = User;

  useEffect(() => {
    User.loadUser();
  }, []);

  const { pathname } = useLocation();
  const [, routes] = pathname.split("/");

  const isProductRoute =
    pathname.includes(URL_SHOP) && !pathname.includes("/activate");

  const isMainPath = pathname === URL_HOME;
  const isSettingPage = pathname === URL_PROFILE_SETTINGS;

  // geolocation access
  useEffect(() => {
    if (isAuth && (routes === "votes" || routes === "vote")) {
      const success = ({ coords }) => {
        const { latitude, longitude } = User.location;

        const coordsObject = {
          latitude: coords.latitude,
          longitude: coords.longitude,
        };

        if (latitude !== coords.latitude || longitude !== coords.longitude)
          User.setLocation(coordsObject);

        if (!User.hasLocation) {
          User.setHasLocation(true);
        }
      };

      const failure = () => {
        if (User.hasLocation) {
          User.setHasLocation(false);
        }
        User.deleteLocation();
      };

      navigator.geolocation.watchPosition(success, failure, {
        maximumAge: 10000,
      });
    }

    return () => {
      if (isAuth) {
        User.deleteLocation();
      }
    };
  }, [isAuth, routes]);

  useEffect(() => {
    let interval;
    if (isAuth) {
      interval = setInterval(async () => {
        NotificationsStore.getHasNewNotifications();
      }, 10000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isAuth]);

  useEffect(() => {
    //  Прокручивет страницу наверх при изменении урла
    if (
      ![URL_HOME, URL_VOTES, URL_VIOLATIONS, URL_PROFILE_VIOLATIONS].includes(
        pathname
      ) &&
      !pathname.match(/^\/news\/.*$/)
    ) {
      // setTimeout(() => {
      window.scrollTo(0, 0);
      // }, 500);
    }
  }, [pathname]);

  //const { isShown } = mobileAppStore;
  //const checkIsHideMobileApp = sessionStorage.getItem("MobileApp");

  return (
    <div className="main">
      <div className="veil"></div>
      <TopMenu />
      <MobileLinkToProfile />
      <div className="header-adaptive">
        {/* <MobileApp /> */}
        <BurgerMenu />
      </div>

      <Suspense fallback={<LoaderSpinner />}>
        <Route exact={true} path={URL_HOME} component={MainPage} />
        <div
          className={cn(
            `main-container`,
            isMainPath && "main-container__homePage",
            //isShown && !checkIsHideMobileApp && "mobileAppEnabled",
            isSettingPage && "main-container__settings"
          )}
        >
          <div className={cn("Wrapper", isMainPath && "Wrapper__mainPage")}>
            {naviagtionRoutes.map((route, idx) => (
              <Route
                key={idx}
                exact={true}
                path={route.path}
                component={route.component}
              />
            ))}

            <Route
              exact={false}
              path={URL_PROFILE}
              component={ProfileComponent}
            />
          </div>
        </div>
      </Suspense>
      {isProductRoute && <EskPanel />}
      <Footer />
      <BigLoader />
    </div>
  );
};
export default observer(App);
