import React, { useEffect } from "react";
import { observer } from "mobx-react";

import InstructionsStore from "../../stores/app/InstructionsStore";

const AgreementPage = () => {
  const { getAgreementContent, clearInstructions, agreement } =
    InstructionsStore;

  useEffect(() => {
    getAgreementContent();

    return () => clearInstructions();
  }, [getAgreementContent, clearInstructions]);

  return (
    <div className="instructions">
      {agreement && <div dangerouslySetInnerHTML={{ __html: agreement }} />}
    </div>
  );
};

export default observer(AgreementPage);
