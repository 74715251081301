import React, { useState } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import Textarea from "../../../Common/Components/Textarea";
import Button from "../../../Common/Components/Button";

import { IFreeAnswerFieldProps } from "./types";
import "./styles.scss";
import useDesktopScreen from "../../../Project/hooks/useDesktopScreen";

const FreeAnswerField: React.FC<IFreeAnswerFieldProps> = ({
  id,
  value = "",
  onChange,
  acceptField,
  decline,
  isRequired,
}) => {
  const isDesktop = useDesktopScreen();
  const [_, setIsValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isShowField, setIsShowField] = useState(
    isDesktop && isRequired ? true : false
  );

  const acceptFieldHandler = () => {
    acceptField(id);
    setIsShowField(false);
  };
  const closeFieldHandler = () => {
    decline(id);
    setIsShowField(false);
    setIsDisabled(true);
    setIsValue("");
  };

  const onChangeHandler = (value: string) => {
    onChange(value);
    setIsValue(value);
  };

  return (
    <div
      className={cn(
        "free-answer-field",
        isShowField ? "free-answer-field--true" : "free-answer-field--false",
        value.length > 0 && "free-answer-field__isChosen"
      )}
    >
      <div
        className="header"
        onClick={() => {
          setIsShowField(true);
        }}
      >
        <h3>Свой вариант</h3>
        <button
          className={cn("header__button", isShowField ? "true" : "false")}
        />
      </div>
      <button className="header__button-close" onClick={closeFieldHandler} />
      {isShowField && (
        <div className="form">
          <Textarea
            id={id}
            value={value}
            onChange={onChangeHandler}
            changeDisable={setIsDisabled}
            decline={decline}
          />
          <div className="form__buttons">
            <Button
              title="Принять"
              type={isDisabled ? "disabled" : "primary"}
              disabled={isDisabled}
              onClick={acceptFieldHandler}
            />
            <Button
              title="Отменить"
              type="decline"
              onClick={closeFieldHandler}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(FreeAnswerField);
