import { makeAutoObservable } from "mobx";
import { API } from "../../../api";

class InstructionsStore {
  conditions?: null | string = null;
  agreement?: null | string = null;
  participation?: null | string = null;

  constructor() {
    makeAutoObservable(this);
  }

  getConditionsContent = () => {
    API.news.Documents.documentsTermsOfParticipationGet().then((res) => {
      this.conditions = res.data.content;
    });
  };

  getAgreementContent = () => {
    API.news.Documents.documentsUserAgreementGet().then(({ data }) => {
      this.agreement = data.content;
    });
  };

  getParticipationContent = () => {
    API.news.Documents.documentsPersonalDataPolicyGet().then(({ data }) => {
      this.participation = data.content;
    });
  };

  clearInstructions = () => {
    this.conditions = null;
    this.agreement = null;
    this.participation = null;
  };
}

export default new InstructionsStore();
