import { makeAutoObservable, runInAction, toJS } from "mobx";
import { API } from "../api";
import { AISAE } from "../constants/api";
import fetchService from "../utils/fetch";
import login from "../utils/login";
import bigLoader from "./bigLoader";
import loading from "./loading";
import user from "./user";

class Votes {
  isLoading = false;

  constructor() {
    this.votes = [];
    this.vote = {};
    this.id = "";
    this.selected = {};
    this.answers = [];
    this.value = "";
    this.freeAnswerValue = {};
    this.customValue = {};
    this.isValidQuestion = true;
    this.voteSuccess = false;
    this.isEmpty = false;
    this.completedVote = {};
    this.errorStatus = undefined;
    this.voteResult = undefined;
    this.userAnswers = undefined;
    this.files = [];

    makeAutoObservable(this);
  }

  fetchVotesList = async (type = "available", page = 1, countElements = 8) => {
    await fetchService
      .get(AISAE + `api/votes/${type}?page=${page}&number=${countElements}`)
      .then((res) => {
        const { votes } = res.data;
        this.votes = votes;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  clearVotes = () => {
    this.votes = [];
  };

  convertType = (type) => {
    switch (type) {
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "application/docx";
      case "application/pdf":
        return "application/pdf";
      case "application/msword":
        return "application/doc";
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return "application/pptp";
      case "application/vnd.ms-powerpoint":
        return "application/pptp";
      case "application/vnd.ms-excel":
        return "application/xls";
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "application/xlsx";
      case "image/jpeg":
        return "image/jpeg";
      default:
        return;
    }
  };

  fetchVote = async (id, location) => {
    this.errorStatus = undefined;
    this.id = id;

    runInAction(() => {
      this.isLoading = true;
    });
    await fetchService
      .get(
        AISAE +
          `api/votes/${this.id}?latitude=${location.latitude || ""}&longitude=${
            location.longitude || ""
          }`
      )
      .then((res) => {
        if (res.status === 200) {
          runInAction(() => {
            this.vote = res.data.vote;
          });
        }
      })
      .catch((error) => {
        if (error && error.response) {
          const { status } = error.response;
          this.errorStatus = status;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  };

  fetchComletedVote = async (id) => {
    await fetchService
      .get(AISAE + `api/results?voteid=${id}`)
      .then((res) => {
        this.completedVote = res.data.vote;
        this.userAnswers = res.data.userAnswers;
      })
      .catch((error) => {
        console.error("fetchComletedVote", error);
        if (error && error.response) {
          const { status } = error.response;
          this.errorStatus = status;
        }
      });
  };

  checkboxHandleChange = (key, id) => {
    const item = this.vote.questions.find((item) => item.id === id);
    item.answers[key].checked = !item.answers[key].checked;
  };

  radiobuttonHandleChange = (key, id) => {
    const item = this.vote.questions.find((item) => item.id === id);
    item.answers[key].checked = !item.answers[key].checked;
    const selected = item.answers[key];

    item.answers = item.answers.map((item) => {
      if (item.id !== selected.id) {
        item.checked = !selected.checked;
      } else {
        item.checked = true;
      }
      return item;
    });
  };

  onInputChange = (id, value, key) => {
    const item = this.vote.questions.find((item) => item.id === id);
    this.value = value;
    item.answers = [{ answer: this.value }];
  };

  onChangeFreeAnswer = (id) => {
    const item = this.vote.questions.find((item) => item.id === id);
    item.answers = [{ answer: this.freeAnswerValue[id] }];
  };

  setFreeAnswerValue = (id, value) => {
    this.freeAnswerValue[id] = value;
  };

  declineFreeAnswerValue = (id) => {
    this.freeAnswerValue[id] = "";
  };

  customOpen = (id) => {
    const item = this.vote.questions.find((item) => item.id === id);

    item.answers = item.answers.map((it) => {
      it.checked = false;
      return it;
    });
  };

  onCustomChange = (id, value) => {
    this.customValue[id] = value;
  };

  acceptCustomValue = (id) => {
    const item = this.vote.questions.find((item) => item.id === id);
    item.customAnswer = this.customValue[id];
  };

  declineCustomValue = (id) => {
    const item = this.vote.questions.find((item) => item.id === id);
    item.customAnswer = "";
    this.customValue[id] = "";
  };

  resetStatuses = () => {
    this.voteSuccess = false;
    this.errorStatus = undefined;
    this.userAnswers = undefined;
    this.isValidQuestion = true;
    this.files = [];

    this.vote &&
      this.vote.questions &&
      this.vote.questions.every((item) => {
        item.answers.map((it) => {
          delete it.checked;
          return it;
        });
        return item;
      });
  };

  validate = () => {
    this.isValidQuestion = true;
    this.vote.questions.forEach((question) => {
      if (this.isValidQuestion) {
        if (question.isRequired) {
          if (question.type === "Checkbox") {
            const answersCheckBox = [];
            question.answers.forEach((answer) => {
              if (answer.checked === true) {
                answersCheckBox.push(answer.id);
              }
              if (this.customValue[question.id]) {
                answersCheckBox.push(this.customValue);
              }
            });
            if (answersCheckBox.length < 1) {
              this.isValidQuestion = false;
            }
          }

          if (question.type === "Radiobutton") {
            const answersRadioButton = [];
            question.answers.forEach((answer) => {
              if (answer.checked === true) {
                answersRadioButton.push(answer.id);
              }
              if (this.customValue[question.id]) {
                answersRadioButton.push(this.customValue);
              }
            });
            if (answersRadioButton.length < 1) {
              this.isValidQuestion = false;
            }
          }

          if (question.type === "TextField") {
            if (question.answers.length < 1) {
              this.isValidQuestion = false;
            }
          }
        } else {
          this.isValidQuestion = true;
        }
      }
    });
    if (this.isValidQuestion) return this.isValidQuestion;
  };

  onVoteEnd = async () => {
    const ok = await user.checkAuth();

    if (!ok) {
      login();
      return;
    }

    if (this.validate()) {
      this.isValidQuestion = true;

      loading.turnOn();
      bigLoader.turnOn();

      const voteQuestionsArray = this.vote.questions;
      const answersArray = [];

      voteQuestionsArray.forEach((item) => {
        const j = {};
        j.questionId = item.id;
        j.answerIds = [];
        item.answers.forEach(
          (it) => it.checked === true && j.answerIds.push(it.id)
        );
        j.answer = item.customAnswer;
        if (item.type === "TextField" && item.answers.length !== 0) {
          j.answer = item.answers[0].answer;
        }
        answersArray.push(j);
      });

      const result = {
        userId: null,
        voteId: this.vote.id,
        isCompleted: true,
        answers: answersArray,
      };

      await fetchService
        .post(AISAE + `api/results/create`, result)
        .then((res) => {
          if (res.status === 200) {
            this.voteSuccess = true;
          }
        })
        .catch((error) => {
          console.error(error);
          if (error && error.response) {
            const { status } = error.response;
            this.errorStatus = status;
          }
        })
        .finally(() => {
          loading.turnOff();
          bigLoader.turnOff();
        });
    } else {
      this.isValidQuestion = false;
    }
  };

  resetVote = () => {
    this.vote = {};
  };
}

export default new Votes();
