import React from "react";
import { observer } from "mobx-react";

import Button from "../Button";

import { IPopupProps } from "./types";
import "./styles.scss";

const Popup: React.FC<IPopupProps> = ({
  title = "",
  text = "",
  submitHandler,
  discardHandler = () => {},
  submitTitle = "",
  discardTitle = "",
  onCloseHandler,
}) => {
  return (
    <div className="Popup">
      <div className="Popup__content">
        <h3 className="Popup__title">{title}</h3>
        <span className="Popup__text">{text}</span>
        <div className="Popup__buttons">
          <Button
            children={submitTitle}
            onClick={submitHandler}
            type="primary"
          />
          {discardTitle && (
            <Button
              children={discardTitle}
              onClick={discardHandler}
              type="primary"
            />
          )}
        </div>
        <button className="Popup__close" onClick={onCloseHandler}></button>
      </div>
    </div>
  );
};

export default observer(Popup);
