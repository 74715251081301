import React from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { IButtonProps } from "./types";
import "./styles.scss";

const ButtonComponent: React.FC<IButtonProps> = ({
  children,
  type = "default",
  size = "small",
  disabled = false,
  className = "",
  onClick,
}) => {
  return (
    <button
      disabled={disabled}
      className={cn(
        "ButtonComponent",
        `ButtonComponent--${type}`,
        `ButtonComponent--${size}`,
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default observer(ButtonComponent);
