import { observer } from "mobx-react";
import React, { useState } from "react";
import votesStore from "../../../stores/votes";
import CustomField from "../CustomField";
import VoteCheckbox from "../VoteCheckbox";
import VoteCheckboxText from "../VoteCheckboxText";

const CheckboxVoteComponent = observer((props) => {
  const checkboxClick = (key, id) => {
    votesStore.checkboxHandleChange(key, id);
  };

  const [hidden, changeVisibility] = useState(false);

  const { item } = props;
  const answersHasImage = item?.answers?.some((i) => i?.filesInfo.length !== 0);

  return (
    <>
      <span className="hints">Возможен выбор нескольких вариантов</span>
      {answersHasImage ? (
        <VoteCheckbox
          options={item.answers || []}
          click={checkboxClick}
          id={item.id}
          hasOtherOption={item.hasOtherOption}
        />
      ) : (
        <VoteCheckboxText
          options={item.answers || []}
          click={checkboxClick}
          id={item.id}
          hasOtherOption={item.hasOtherOption}
        />
      )}

      {item.hasOtherOption && (
        <CustomField
          acceptCustomValue={(value) =>
            votesStore.acceptCustomValue(item.id, value)
          }
          id={item.id}
          onChange={(value) => {
            votesStore.onCustomChange(item.id, value);
          }}
          value={votesStore.customValue[item.id]}
          hidden={hidden}
          onClick={changeVisibility}
          type={item.type}
          decline={() => votesStore.declineCustomValue(item.id)}
          customOpen={() => {}}
        />
      )}
    </>
  );
});

export default CheckboxVoteComponent;
