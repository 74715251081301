import React, { useEffect } from "react";
import { observer } from "mobx-react";

import AboutQuestionComponentsList from "../../Components/AboutQuestionList";
import FaqForm from "../../Components/FaqForm";

import aboutStore from "../../../stores/about";

import "./index.scss";
// TO DO переработать страницу
const About = observer(() => {
  const {
    fetchAboutInfo,
    resetStatuses,
    fetchFaqs,
    about,
    faqs,
    fetchRubricList,
    rubrics,
  } = aboutStore;

  useEffect(() => {
    fetchAboutInfo();
    resetStatuses();
    fetchFaqs();
    fetchRubricList();
  }, [fetchAboutInfo, fetchFaqs, fetchRubricList, resetStatuses]);

  return (
    <div className="about">
      <div className="header">
        <h2 className="header_title">О проекте</h2>
      </div>
      <div dangerouslySetInnerHTML={{ __html: about }} />
      <div className="header">
        <h2>Часто задаваемые вопросы</h2>
      </div>
      {rubrics &&
        rubrics.map((item, idx) => {
          const options = faqs && faqs.filter((it) => it.rubricId === item.id);

          options &&
            options.map((i) => {
              i.value = i.question;
              return i;
            });

          return (
            <React.Fragment key={Math.random()}>
              <div className="questions-list" key={idx}>
                <div className="header">
                  <h3>{item.title}</h3>
                </div>
                <AboutQuestionComponentsList
                  options={options}
                  key={Math.random()}
                />
              </div>
            </React.Fragment>
          );
        })}
      <FaqForm />
    </div>
  );
});

export default About;
