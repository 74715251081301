type TRequestsCodesType = {
  [key: number]: string;
};

const requestsCodes: TRequestsCodesType = {
  500: "Внутренняя проблема сервера.",
  502: "Неверный ответ сервера.",
  503: "Сервис недоступен.",
  400: "Неверный синтаксис запроса.",
  403: "Нет прав доступа к содержимому.",
  404: "Страница не найдена.",
};

export default requestsCodes;
