import React from "react";
import { observer } from "mobx-react";

import { ReactComponent as CheckGreenIcon } from "../../assets/icons/check-green.svg";

import { IProductCategoryProps } from "./types";
import "./styles.scss";

const ProductCategory: React.FC<IProductCategoryProps> = ({ text }) => {
  return (
    <div className="product-category" data-testid="product-category">
      <CheckGreenIcon />
      <span>{text}</span>
    </div>
  );
};

export default observer(ProductCategory);
