import React from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { IPointsHistoryItemProps, pointsActions } from "./types";
import "./styles.scss";

const PointsHistoryItem: React.FC<IPointsHistoryItemProps> = ({ item }) => {
  const {
    action,
    transactionType = "Accrual",
    numberOfPoints = 0,
    title = "Название",
  } = item;
  const isAccrual = transactionType === "Accrual";

  return (
    <div className="history-item" data-testid="history-item">
      <div className="history-item__counts">
        <div
          className={cn(
            "history-item__sign",
            isAccrual
              ? "history-item__sign-refill"
              : "history-item__sign-writeoff"
          )}
        />
        <span
          className={cn(
            "history-item__count",
            isAccrual
              ? "history-item__count-refill"
              : "history-item__count-writeoff"
          )}
        >
          {numberOfPoints}
        </span>
      </div>
      <div className="history-item__text">
        <span className="history-item__text-action">
          {action ? pointsActions[action] : pointsActions.Default}
        </span>
        <span className="history-item__text-name">{title}</span>
      </div>
    </div>
  );
};

export default observer(PointsHistoryItem);
