import React from "react";
import ClampLines from "react-clamp-lines";
import { AISAE } from "../../../constants/api";
import { useLocation } from "react-router-dom";
import { ReactComponent as ChatIcon } from "../../../Common/images/chat_yellow.svg";
import cn from 'classnames'

import normalizeDate from "../../../utils/normalizeDate";
import setStatus from "../../../utils/setstatus";

import "./index.scss";

const CarouselTile = (props) => {
  const { item, getItemId } = props;

  const date = item && normalizeDate(item.date);

  const { pathname } = useLocation();

  const photoSrc = item.photo
    ? AISAE + item.photo
    : require("../../../Common/images/no-image.png");

  return (
    <div
      className="tile-item"
      onClick={() => {
        getItemId(item.id);
      }}
    >
      <div className="tile-item__image">
        <img src={photoSrc} alt={item && item.photo} />
      </div>
      <div className={`item__status ${item && item.status}`}>
        <span className="item__status-text">
          {item && setStatus(item.status)}
        </span>
      </div>
      <div className="tile-item__date">
        <span className="violations-number">N {item && item.number}</span>
        <span className="violations-date">{date}</span>
        {pathname.includes("/statements") && <ChatIcon className={cn(item.hasNewChatMessages ? "violations-icon" : "violations-icon-disabled")} />}
      </div>
      <div className="header">
        {item && item.category && (
          <ClampLines
            className="tile-item__text"
            text={item && item.category}
            ellipsis="..."
            buttons={false}
            lines={3}
            id={item && item.id}
            innerElement="p"
          />
        )}
      </div>
      <div className="tile-item__district">
        <span>{item && item.district.split(" ")[0]}</span>
      </div>
      <div className="tile-item__support">
        <span>Поддержали</span>

        <span className="count">{item.supportCount}</span>
      </div>
    </div>
  );
};

export default CarouselTile;
