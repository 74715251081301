import "./style.scss";

import { observer } from "mobx-react";
import React, { useState } from "react";

import { AISAE } from "../../../constants/api";
import votesStore from "../../../stores/votes";
import CheckboxVoteComponent from "../CheckboxVoteComponent";
import FileViewer from "../FileViewer";
import FreeAnswerField from "../FreeAnswerField";
import RadioButtonVoteComponent from "../RadioButtonVoteComponent";
import VoteFiles from "../../Pages/Vote/voteFiles";

const QuestionsComponent = observer((props) => {
  const { questions, isValidQuestion } = props;
  const {
    customValue,
    freeAnswerValue,
    declineFreeAnswerValue,
    setFreeAnswerValue,
    onChangeFreeAnswer,
  } = votesStore;

  const [isShowImage, setIsShowImage] = useState(false);
  const [currentFileId, setFileCurrentId] = useState("");

  return (
    <>
      {questions &&
        questions.map((item, idx) => {
          let checkValid = true;
          if (item.isRequired) {
            if (item.type === "TextField") {
              if (item.answers.length < 1) {
                checkValid = false;
              }
            }
            if (item.type === "Checkbox") {
              const answersCheckBox = [];
              item.answers.forEach((answer) => {
                if (answer.checked === true) {
                  answersCheckBox.push(answer.id);
                  checkValid = true;
                }
              });
              if (customValue !== "") {
                answersCheckBox.push(customValue);
              }
              if (answersCheckBox.length < 1 && customValue === "") {
                checkValid = false;
              }
            }
            if (item.type === "Radiobutton") {
              const answersRadioButton = [];
              item.answers.forEach((answer) => {
                if (answer.checked === true) {
                  answersRadioButton.push(answer.id);
                }
              });
              if (customValue !== "") {
                answersRadioButton.push(customValue);
              }
              if (answersRadioButton.length < 1 && customValue === "") {
                checkValid = false;
              }
            }
          }

          const multiMedia = {};
          questions.forEach((item, idx) => {
            multiMedia[idx] = [];
            item.filesInfo.forEach((i) => {
              const type = i.name.split(".")[i.name.split(".").length - 1];
              if (type === "jpg" || type === "png" || type === "jpeg") {
                multiMedia[idx].push({
                  type: "image",
                  reference: i.id,
                });
              }
            });
          });

          const notMultimedia = {};
          questions.forEach((item, idx) => {
            notMultimedia[idx] = [];
            item.filesInfo.forEach((i) => {
              const type = i.name.split(".")[i.name.split(".").length - 1];
              if (type !== "jpg" && type !== "png" && type !== "jpeg") {
                notMultimedia[idx].push(i);
              }
            });
          });

          return (
            <div className="vote-item" key={idx}>
              <div className="header">
                <div className="number">
                  <span>{idx + 1}</span>
                </div>
                <div className="header__title">
                  <div className="header__title-top">
                    {multiMedia[idx].length !== 0 && (
                      <div
                        className="header__image"
                        style={{
                          backgroundImage: `url(${AISAE}api/votesfiles/questions/files/${multiMedia[idx][0].reference})`,
                        }}
                      >
                        {multiMedia[idx].length > 1 && (
                          <span className="imageScaleButton__number">
                            {multiMedia[idx].length}
                          </span>
                        )}
                        <button
                          className="imageScaleButton"
                          onClick={() => {
                            setIsShowImage(true);
                            setFileCurrentId(multiMedia[idx][0].reference);
                          }}
                        ></button>
                      </div>
                    )}
                    <h2 key={idx}> {item.text}</h2>
                  </div>
                  {notMultimedia[idx].map((it) => {
                    return (
                      <div>
                        <VoteFiles
                          type={it.type}
                          reference={it.id}
                          name={it.name}
                          size={it.size}
                          section={"quest"}
                        />
                      </div>
                    );
                  })}
                  {item.isRequired && (
                    <span
                      className={
                        !isValidQuestion && !checkValid
                          ? "header__validate"
                          : "header__required"
                      }
                    >
                      Обязательный вопрос
                    </span>
                  )}
                </div>
              </div>
              {item.type !== "TextField" && (
                <div className="header variants">
                  <h3>Варианты ответов</h3>
                </div>
              )}

              {item.type === "Radiobutton" && (
                <RadioButtonVoteComponent item={item} />
              )}
              {item.type === "Checkbox" && (
                <CheckboxVoteComponent item={item} />
              )}
              {item.type === "TextField" && (
                <>
                  <span className="hints">Введите свой ответ</span>
                  <FreeAnswerField
                    isRequired={item.isRequired}
                    id={item.id}
                    value={freeAnswerValue[item.id]}
                    acceptField={onChangeFreeAnswer}
                    onChange={(value) => setFreeAnswerValue(item.id, value)}
                    decline={(value) => declineFreeAnswerValue(item.id)}
                  />
                </>
              )}
              {isShowImage && (
                <FileViewer
                  files={multiMedia[idx]}
                  startSlide={0}
                  setShowFiles={() => setIsShowImage(false)}
                  url={`${AISAE}api/votesfiles/questions/files/`}
                />
              )}
            </div>
          );
        })}
    </>
  );
});

export default QuestionsComponent;
