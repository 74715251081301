import React, { useState } from "react";
import { AISAE } from "../../../constants/api";
import FileViewer from "../../../Main/Components/FileViewer";
import { ReactComponent as CloseIcon } from "../../images/close.svg";
import cn from "classnames";
import "./index.scss";
import { observer } from "mobx-react";

const ShowFile = ({ file, deleteMe = null, open = null, className = "" }) => {
  const [isShowFile, setIsShowFile] = useState(false);

  const deleteAndPrevent = (e) => {
    e.stopPropagation();
    deleteMe();
  };
  const src = file.reference
    ? AISAE + file.reference
    : URL.createObjectURL(file);

  const isDocOrAudio =
    file?.type.includes("application") ||
    file?.type.includes("audio") ||
    file?.type === "Document" ||
    file?.type === "Audio";

  const isDocument =
    file?.type.includes("application") || file?.type === "Document";

  const documentIcon = (
    <span
      className={cn("ShowFile__type-icon", "ShowFile__type-icon-document")}
    ></span>
  );

  const documentPreview = file.reference ? (
    <a
      className="ShowFile__type"
      href={`${AISAE}${file.reference}`}
      target="_blank"
      rel="noreferrer"
    >
      {documentIcon}
    </a>
  ) : (
    <div className="ShowFile__type">{documentIcon}</div>
  );

  return (
    <div
      className={`ShowFile ${className}  ${
        isDocOrAudio && "ShowFile__document"
      }`}
    >
      <div
        className="ShowFile__panel"
        onClick={() => {
          setIsShowFile(true);
        }}
      >
        {(file?.type.includes("image") || file?.type === "Image") && (
          <div className="ShowFile__type">
            <img className="ShowFile__file" src={src} alt="file" />
            <span
              className={cn("ShowFile__type-icon", "ShowFile__type-icon-photo")}
            ></span>
          </div>
        )}

        {(file?.type.includes("video") || file?.type === "Video") && (
          <div
            className="ShowFile__type"
            onClick={() => {
              setIsShowFile(true);
            }}
          >
            <video className="ShowFile__file">
              <source src={`${src}#t=0.1`} type="video/mp4" />
            </video>
            <span
              className={cn("ShowFile__type-icon", "ShowFile__type-icon-video")}
            ></span>
          </div>
        )}

        {isDocument && documentPreview}

        {(file?.type.includes("audio") || file?.type === "Audio") && (
          <a
            className="ShowFile__type"
            href={`${AISAE}${file.reference}`}
            target="_blank"
            rel="noreferrer"
          >
            <span
              className={cn("ShowFile__type-icon", "ShowFile__type-icon-audio")}
            ></span>
          </a>
        )}

        {deleteMe && (
          <button className="ShowFile__delete" onClick={deleteAndPrevent}>
            <CloseIcon />
          </button>
        )}
      </div>
      {!isDocOrAudio && isShowFile && (
        <FileViewer
          files={[file]}
          setShowFiles={() => {
            setIsShowFile(false);
          }}
        />
      )}
    </div>
  );
};

export default observer(ShowFile);
