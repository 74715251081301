import { makeAutoObservable, runInAction } from "mobx";
import { API } from "../../../api";

export interface INotification {
  id: string | null;
  body: {
    id: string | null;
    title: string | null;
    status: string | null;
    answer: string | null;
  };
  text: string | null;
  date: string | null;
  isChecked: boolean | null;
  userId: string | null;
}

export type TNotificationType = "all" | "unread";

class NotificationsStore {
  notifications: INotification[] = [];
  hasNew: boolean = false;
  isLoading: boolean = false;

  type: TNotificationType = "all";
  itemsPerRequest: number = 10;
  skipItem: number | undefined = undefined;
  allItemsCount: number | null = null;

  dropdownOptions = [
    {
      name: "Показать все",
      id: "all",
    },
    {
      name: "Непрочитанные",
      id: "unread",
    },
  ];

  get filterOptions() {
    return this.dropdownOptions.map((option) => ({
      text: option.name,
      type: option.id,
      action: () => {},
      setTypeFormFilter: this.setType,
      active: option.name === this.type,
    }));
  }

  get selectedTypeName() {
    return this.dropdownOptions.find((item) => item.id === this.type)!.name;
  }

  constructor() {
    makeAutoObservable(this);
  }

  get isRequestMore() {
    return this.allItemsCount
      ? this.allItemsCount > this.notifications.length
      : false;
  }

  getNotifications = (
    type?: TNotificationType,
    skip?: number,
    itemsPerRequest?: number
  ) => {
    if (!this.notifications.length) {
      this.isLoading = true;
    }
    const reqType = type === "unread" ? false : undefined;

    return API.aisae.Keycloak.apiKeycloakNotificationsGet(
      reqType,
      skip,
      itemsPerRequest
    )
      .then(({ data }) => {
        runInAction(() => {
          this.allItemsCount = data.count || null;
          if (data.notifications) {
            const newNotifications = data.notifications.map((notification) => ({
              id: notification.id || null,
              body: {
                id: notification.body?.id || null,
                title: notification.body?.title || null,
                status: notification.body?.status || null,
                answer: notification.body?.answer || null,
              },
              text: notification.text || null,
              date: notification.date || null,
              isChecked: notification.isChecked || false,
              userId: notification.userId || null,
            }));
            this.notifications = [...this.notifications, ...newNotifications];
          }
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  getNotificationsMore = () => {
    this.getNotifications(
      this.type,
      this.notifications.length,
      this.itemsPerRequest
    );
  };

  setType = (type: TNotificationType) => {
    this.type = type;
    this.notifications = [];
  };

  checkNotification = (id: string) => {
    return API.aisae.Keycloak.apiKeycloakNotificationsCheckPost(id)
      .catch((error) => console.error(error))
      .then(() => {
        const notification = this.notifications.find(
          (notification) => notification.id === id
        );
        runInAction(() => notification && (notification.isChecked = true));
      });
  };

  getHasNewNotifications = () => {
    return API.aisae.Keycloak.apiKeycloakNotificationsHasnewGet().then(
      ({ data }) => {
        runInAction(() => {
          this.hasNew = data;
        });
      }
    );
  };

  reset = () => {
    this.notifications = [];
    this.hasNew = false;
    this.isLoading = false;
    this.type = "all";
    this.skipItem = undefined;
    this.allItemsCount = null;
  };
}

export default new NotificationsStore();
