import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import { URL_PROFILE_POINTS } from "../../routes/paths";

import "./styles.scss";

const EskPanel = () => {
  return (
    <div className="esk-panel">
      <div className="esk-panel__content">
        <h2 className="esk-panel__title">Внимание</h2>
        <p className="esk-panel__text">
          Накопленные баллы в системе «Активный Екатеринбург» можно потратить,
          если вы являетесь держателем карты ЕСК
        </p>
        <Link to={URL_PROFILE_POINTS} className="esk-panel__link">
          Подробнее
        </Link>
      </div>
    </div>
  );
};

export default observer(EskPanel);
