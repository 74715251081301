import { makeAutoObservable, runInAction } from "mobx";
import { API } from "../../../api";
export interface ICategory {
  id: string;
  title: string;
}
class CategoriesStore {
  categoriesList: ICategory[] = [];
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }
  getCategoriesList = () => {
    this.isLoading = true;

    API.shop.Categories.categoriesGet()
      .then(({ data }) => {
        runInAction(() => {
          this.categoriesList = data.map((option) => ({
            id: option.id || "В полученном запросе apiCategoriesGet нет id",
            title: option.title || "Нет названия рубрики",
          }));
          this.isLoading = false;
        });
      })
      .catch((e) => {
        this.isLoading = false;
        throw new Error(e);
      });
  };

  resetCategories = () => {
    this.categoriesList = [];
  };
}

export default new CategoriesStore();
