import axios from "axios";
import { makeAutoObservable } from "mobx";
import { BASE_URL, NEWS_ITEM_IMAGE_URL } from "../constants/api";

const imageInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    accept: "image/...",
  },
});

const filesInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    accept: "*/*",
  },
});
class NewsItem {
  constructor() {
    this.newsItem = {};
    this.content = "";
    this.date = "";
    this.title = "";
    this.id = "";
    this.imageUrl = "";
    this.files = [];
    this.photos = [];
    this.errorStatus = undefined;
    this.mainPhoto = [];
    this.attachedPhotos = [];

    makeAutoObservable(this);
  }

  fetchNewsItem = async (id, callback) => {
    this.id = id;
    this.mainPhoto = [];
    this.attachedPhotos = [];
    axios
      .get(BASE_URL + `news/api/news/${this.id}`)
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;

          data.photos.forEach((photo) => {
            this.attachedPhotos.push({
              ...photo,
              reference: "photo/" + photo.id,
              type: "image",
            });
          });

          const mainPhoto = {
            id: this.id,
            reference: this.id + "/image",
            type: "image",
          };

          this.mainPhoto.push(mainPhoto);

          this.newsItem = data;
        }
      })
      .then(() => callback && callback())
      .catch((error) => {
        console.error(error);
        if (error && error.response) {
          const { status } = error.response;
          this.errorStatus = status;
        }
      });
  };

  fetchImage = (id) => {
    this.id = id;
    imageInstance
      .get(`news/api/news/${this.id}/image`)
      .then((res) => {
        if (res.status === 200) {
          this.imageUrl = `${NEWS_ITEM_IMAGE_URL}${this.id}/image`;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  fetchFiles = (id) => {
    filesInstance
      .get(`${NEWS_ITEM_IMAGE_URL}${id}`)
      .then((res) => {
        if (res.status === 200) {
          this.files = res.data.files;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  slideNewsItem = (id) => {
    axios
      .get(BASE_URL + `news/api/news/${id}`)
      .then((res) => {
        if (res.status === 200) {
          this.newsItem = res.data;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  resetStatuses = () => {
    this.errorStatus = undefined;
  };
}

export default new NewsItem();
