import { toJS } from "mobx";
import React, { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";

import { useHistory } from "react-router-dom";
import Button from "../../../Common/Components/Button";
import { iconPerson } from "../../../Common/Components/LeafletCustomIcon";
import { URL_SHOP, URL_VIOLATION_ID } from "../../../Project/routes/paths";
import "./index.scss";
import { MAP_TILES_URL, CRS_YANDEX } from "./../../../constants/map";

const polygonOptions = { color: "green" };

const OnLoad = ({ position, setPostion, onFound, onError }) => {
  const latlng = {
    lat: position.latitude,
    lng: position.longitude,
  };

  const map = useMapEvents({
    load() {
      map.setView(latlng, map.getZoom(), {
        animate: true,
      });
    },
    locationfound(e) {
      setPostion({ latitude: e.latlng.lat, longitude: e.latlng.lng });
      onFound(e.latlng);
    },
    locationerror() {
      onError();
    },
  });

  return null;
};

const Map = (props) => {
  const {
    coordinates = [],
    polygon = null,
    center = null,
    marker = null,
    setMap = () => {},
    onlocationfound = () => {},
    onlocationerror = () => {},
    disableBlock = false,
    zoom,
    type = undefined,
  } = props;
  const [postion, setPostion] = useState(toJS(marker || coordinates));
  const isVote = polygon ? true : false;

  const history = useHistory();

  useEffect(() => {
    if (marker) {
      setPostion(marker);
    } else if (coordinates) {
      setPostion(coordinates);
    }
  }, [marker, coordinates]);

  return (
    <div className="map-wrapper">
      <MapContainer
        center={
          center
            ? [center.latitude, center.longitude]
            : postion && Array.isArray(postion)
            ? [56.838579, 60.603363]
            : [postion.latitude, postion.longitude]
        }
        zoom={zoom ? zoom : 12}
        maxZoom={18}
        minZoom={10}
        attributionControl={false}
        maxBounds={
          disableBlock
            ? ""
            : [
                [56.973763, 60.844435],
                [56.653589, 60.425006],
              ]
        }
        scrollWheelZoom={false}
        className="map-component"
        whenCreated={setMap}
        crs={CRS_YANDEX}
      >
        <TileLayer url={MAP_TILES_URL} />
        {postion
          ? Array.isArray(postion)
            ? postion.map((item, idx) => {
                const number = item[2]?.number || null;
                const date = item[2]?.date
                  ? new Date(item[2]?.date).toLocaleDateString()
                  : null;
                const name = item[2]?.name || null;
                const district = item[2]?.district || null;
                const count = item[2]?.count;
                const price = item[2]?.balls;
                return (
                  <Marker key={idx} position={item} icon={iconPerson}>
                    {!type && (
                      <Popup className="marker-popup">
                        <div className="marker-popup__number">
                          N {number} — {date}.
                        </div>
                        <div className="marker-popup__name">{name}</div>
                        <div className="marker-popup__district">{district}</div>
                        <div className="marker-popup__support">
                          Поддержали —{" "}
                          <span className="marker-popup__support-count">
                            {count}
                          </span>
                        </div>
                        <Button
                          title="Перейти"
                          type="primary"
                          onClick={() => {
                            const info = item[2];
                            if (info) {
                              window.scrollTo(0, 0);
                              history.push(URL_VIOLATION_ID(info.id));
                            }
                          }}
                        />
                      </Popup>
                    )}
                    {type && (
                      <Popup className="marker-popup">
                        <div className="marker-popup__name">{name}</div>
                        <div className="marker-popup__support">
                          <span className="marker-popup__support-count">
                            {price} баллов
                          </span>
                        </div>
                        <Button
                          title="Перейти"
                          type="primary"
                          onClick={() => {
                            const info = item[2];
                            if (info) {
                              window.scrollTo(0, 0);
                              history.push(`${URL_SHOP}/${info.id}`);
                            }
                          }}
                        />
                      </Popup>
                    )}
                  </Marker>
                );
              })
            : postion.latitude && (
                <Marker
                  position={[postion.latitude, postion.longitude]}
                  icon={iconPerson}
                />
              )
          : null}
        {polygon && (
          <Polygon pathOptions={polygonOptions} positions={polygon} />
        )}
        <OnLoad
          position={center ? center : postion}
          setPostion={setPostion}
          isVote={isVote}
          onFound={onlocationfound}
          onError={onlocationerror}
        />
      </MapContainer>
    </div>
  );
};

export default Map;
