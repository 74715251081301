import "leaflet/dist/leaflet.css";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import User from "../../../stores/user";
import violationStore from "../../../stores/violations";
import CarouselTile from "../../Components/CarouselTile";
import Filter from "../../Components/FilterType";
import Map from "../../Components/Map";
import Switcher from "../../Components/SwitchView";
import Button from "./../../../Common/Components/Button";
import cn from "classnames";
import login from "./../../../utils/login";
import DropDown from "../../Components/DropDown";
import "./index.scss";
import IconList from "../../../Project/assets/svg/iconList";
import IconGeo from "../../../Project/assets/svg/iconGeo";
//import MobileAppStore from "../../../stores/mobileAppStore";
import {
  URL_PROFILE_VIOLATIONS,
  URL_VIOLATION_ID,
} from "../../../Project/routes/paths";

const Violations = inject("history")(
  observer((props) => {
    const { history } = props;
    const {
      fetchViolations,
      fetchTypesViolations,
      fetchNextViolationsList,
      violations,
      isEmpty,
    } = violationStore;

    const checkType = localStorage.getItem("ViolationsType");
    const checkMode = localStorage.getItem("ViolationsMode");

    useEffect(() => {
      if (!checkType) {
        fetchViolations();
      } else {
        fetchNextViolationsList(1, checkType);
      }
    }, [checkType, fetchNextViolationsList, fetchViolations]);

    useEffect(() => {
      if (checkMode === "map") setViewMode("map");
    }, [checkMode]);

    const [isFixedHeader, setIsFixedHeader] = useState(false);

    const scrollHandler = () => {
      const scrollY = window.pageYOffset;

      if (scrollY >= 200) {
        setIsFixedHeader(true);
      } else {
        setIsFixedHeader(false);
      }
    };

    useEffect(() => {
      document.addEventListener("scroll", scrollHandler);

      return () => {
        document.removeEventListener("scroll", scrollHandler);
      };
    });

    const [number, increment] = useState(2);

    const [type, setType] = useState(checkType ? checkType : "published");

    const [viewMode, setViewMode] = useState("list");

    const setViewModeFilter = (mode) => {
      localStorage.setItem("ViolationsMode", mode);
      setViewMode(mode);
    };

    const incrementNumber = () => {
      increment(number + 1);
      fetchNextViolationsList(number, checkType ? checkType : type);
    };

    const setTypeFormFilter = (type) => {
      localStorage.setItem("ViolationsType", type);
      setType(type);
      increment(2);
      fetchTypesViolations(type);
    };

    const dropdownData = [
      {
        name: "Все сообщения",
        id: "published",
      },
      // {
      //   name: "Создано",
      //   id: "created"
      // },
      {
        name: "В работе",
        id: "inwork",
      },
      {
        name: "Завершенные",
        id: "finished",
      },
    ];

    const seletedType = dropdownData.find((item) => item.id === type);

    const optionsType = [
      {
        text: "Все",
        type: "published",
        action: fetchTypesViolations,
        setTypeFormFilter,
        active: (checkType === "published" || (!checkType && type)) && true,
      },
      // {
      //   text: "Создано",
      //   type: "created",
      //   action: fetchTypesViolations,
      //   setTypeFormFilter,
      //   active: seletedType.id === "created" && true,
      // },
      {
        text: "В работе",
        type: "inwork",
        action: fetchTypesViolations,
        setTypeFormFilter,
        active: checkType === "inwork" && true,
      },
      {
        text: "Завершенные",
        type: "finished",
        action: fetchTypesViolations,
        setTypeFormFilter,
        active: checkType === "finished" && true,
      },
    ];

    const optionsMode = [
      {
        text: "Список",
        type: "list",
        setViewModeFilter,
        active: (checkMode === "list" || (!checkMode && viewMode)) && true,
      },
      {
        text: "Карта",
        type: "map",
        setViewModeFilter,
        active: checkMode === "map" && true,
      },
    ];

    const optionsModeAdaptive = [
      {
        text: <IconList />,
        type: "list",
        setViewModeFilter,
        active: (checkMode === "list" || (!checkMode && viewMode)) && true,
      },
      {
        text: <IconGeo />,
        type: "map",
        setViewModeFilter,
        active: checkMode === "map" && true,
      },
    ];

    const getItemId = (id) => {
      history.push(URL_VIOLATION_ID(id));
    };

    const coordinates = [];

    violations?.forEach((item) => {
      coordinates.push([
        item.coordinates.latitude,
        item.coordinates.longitude,
        {
          id: item.id,
          number: item.number,
          date: item.date,
          district: item.district,
          name: item.category,
          count: item.supportCount,
        },
      ]);
    });

    const isAuth = User.isAuth;
    const onClickAddProblem = () => {
      if (isAuth) {
        history.push(URL_PROFILE_VIOLATIONS);
      } else {
        login();
      }
    };

    // const { isShown } = MobileAppStore;
    // const checkIsHideMobileApp = sessionStorage.getItem("MobileApp");

    return (
      <div
        className={cn(
          "violations",
          isFixedHeader && "violations__fixed"
          //!checkIsHideMobileApp && "violations__mobileAppEnabled"
        )}
      >
        <div
          className={cn(
            "header",
            "violations__header",
            isFixedHeader && "violations__header-fixed"
            // isShown &&
            //   !checkIsHideMobileApp &&
            //   "violations__header-mobileAppEnabled"
          )}
        >
          <h2 className={cn("header_title", "violations-title")}>Сообщения</h2>
          <div className="switchers">
            <Switcher options={optionsModeAdaptive} />
          </div>
        </div>

        <div className="button__add-problem">
          <Button
            title={
              isAuth
                ? "Сообщить о проблеме"
                : "Авторизоваться и cообщить о проблеме"
            }
            type={"primary"}
            onClick={onClickAddProblem}
          />
        </div>
        <span className="showing">Показать на странице</span>
        <div className="controls">
          <Filter options={optionsType} />
          <Switcher options={optionsMode} />
        </div>
        <div className="violations__dropdown">
          <DropDown
            isReadOnly={true}
            value={seletedType.name}
            options={dropdownData}
            onSubmit={setTypeFormFilter}
          />
        </div>

        <div className={`violations-items ${viewMode}`}>
          {viewMode === "list" &&
            violations &&
            violations.map((item, idx) => {
              return (
                <CarouselTile item={item} key={idx} getItemId={getItemId} />
              );
            })}
          {viewMode === "map" && <Map coordinates={coordinates} />}
        </div>

        {viewMode !== "map" && violations.length !== 0 && !isEmpty && (
          <div className="more button" onClick={incrementNumber}>
            <span>Показать еще</span>
          </div>
        )}
      </div>
    );
  })
);

export default Violations;
