import React from "react";
import { observer } from "mobx-react";
import { Router, Switch, Route } from "react-router-dom";

import { newProjectRoutes } from "./routes";
import { history } from "./helpers/history";

import "./scss/app.global.scss";

const App = () => {
  return (
    <div className="app">
      <Router history={history}>
        <Switch>
          {newProjectRoutes.map((route, idx) => (
            <Route
              exact
              key={idx}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </Router>
    </div>
  );
};

export default observer(App);
