import React from "react";
import { ITabs } from "./types";
import { useHistory } from "react-router-dom";

import "./styles.scss";

const Tabs: React.FC<ITabs> = ({ className = "", routes }) => {
  const history = useHistory();

  const { location } = history;

  const push = (path: string) => {
    history.push(`${path}`);
  };

  return (
    <div className={`tabs ${className}`}>
      {routes.map((route, idx) => (
        <button
          key={idx}
          disabled={location.pathname === route.path}
          className={`tabs__item ${
            location.pathname === route.path ? "tabs__item--active" : ""
          }`}
          onClick={() => {
            push(route.path);
          }}
        >
          {route.name}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
