import React from "react";
import { observer } from "mobx-react";

import PointsHsitoryTabs from "../PointsHistoryTabs";
import PointsHistoryItem from "../PointsHistoryItem";

import { IPointsHistoryProps } from "./types";

import { IOperationsHistoryItem } from "../../stores/app/myPointsStore";
import InfinityScroll from "../InfinityScroll";
import listDividingByDates from "../../utils/listDividingByDates";

import "./styles.scss";
import BigLoader from "../../../Common/Components/BigLoader";

const PointsHistory: React.FC<IPointsHistoryProps> = ({
  historyList,
  tabs,
  isRequestMore,
  getHistoryList,
  isLoading,
}) => {
  if (isLoading) return <BigLoader />;

  return (
    <div className="points-history">
      <h2 className="points-history__title">История</h2>
      <PointsHsitoryTabs list={tabs} />
      <InfinityScroll request={getHistoryList} isRequestMore={isRequestMore}>
        {(
          listDividingByDates(historyList, "transactionDate") as {
            date: string;
            items: IOperationsHistoryItem[];
          }[]
        ).map((date, index) => {
          return (
            <React.Fragment key={index}>
              <div className="date-separator">{date.date}</div>
              {date.items.map((el, i) => {
                return <PointsHistoryItem item={el} key={i} />;
              })}
            </React.Fragment>
          );
        })}
      </InfinityScroll>
    </div>
  );
};

export default observer(PointsHistory);
