import "./index.scss";

import { observer } from "mobx-react";
import React from "react";

const VoteCheckboxText = observer((props) => {
  const { options, click, id, hasOtherOption } = props;

  if (!options) return null;
  return (
    <div className={`answers-text`}>
      {options.map((item, idx) => (
        <label className={["answer"].join(" ")}>
          <div
            className={[
              `answer__text`,
              item.checked ? "answer__text--active" : "",
              hasOtherOption ? "custom" : "",
            ].join(" ")}
          >
            {item.text}
          </div>
          <input
            value={item.text}
            type="checkbox"
            checked={item.checked}
            name={id}
            onChange={() => {
              click(idx, id);
            }}
          />
          <div
            className={[
              "answer__button",
              item.checked ? "answer__button--active" : "",
            ].join(" ")}
          ></div>
        </label>
      ))}
    </div>
  );
});

export default VoteCheckboxText;
