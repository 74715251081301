import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";

function useDesktopScreen(): boolean {
  const { width } = useWindowSize();
  const mobileScreenWidth = 639;

  const [isDesktop, setIsDesktop] = useState<boolean>(
    width > mobileScreenWidth
  );

  useEffect(() => {
    setIsDesktop(width > mobileScreenWidth);
  }, [width]);

  return isDesktop;
}

export default useDesktopScreen;
