import React, { useEffect, useState } from "react";
import "./index.scss";
import { observer } from "mobx-react";

import Categories from "../../../stores/categories";
import Address from "../../../stores/address";
import DropzoneStore from "../../../stores/profileDropzone";
import StatementStore from "../../../stores/statement";
import BurgerMenu from "../../../stores/burgerMenu";

import DropDown from "../DropDown";
import InputWithIcon from "../../../Common/Components/InputWithIcon";
import Dropzone from "../../../Common/Components/Dropzone";
import ShowFile from "../../../Common/Components/ShowFile";
import Button from "../../../Common/Components/Button";
import Popup from "../../../Common/Components/Popup";
import Map from "../EditalbeMap";
import FilesViewer from "../FileViewer";
import { ReactComponent as CloseIcon } from "../../../Common/images/close.svg";
import { ReactComponent as AddressIcon } from "../../../Common/images/location.svg";
import { ReactComponent as PlusIcon } from "../../../Common/images/plus.svg";
import LoaderSpinner from "../Loader";
import scrollTop from "../../../utils/scroll";

const Statement = ({ closeNewStatement }) => {
  const [showFiles, setShowFiles] = useState(false);
  const [startSlide, setStartSlide] = useState(0);
  const { errorMessage } = StatementStore;
  const { error, success } = StatementStore.statuses;
  const { acceptedFiles } = DropzoneStore;

  useEffect(() => {
    Categories.loadCategories();
  }, []);

  useEffect(() => {
    BurgerMenu.setButton(null);
  }, []);

  useEffect(() => {
    scrollTop();
  }, []);

  const discardAllChanges = () => {
    StatementStore.resetStatuses();
    StatementStore.resetAll();
  };

  const closeCreating = () => {
    StatementStore.resetStatuses();
    StatementStore.resetAll();
    closeNewStatement();
  };

  const openFileViewer = (index) => {
    setStartSlide(index);
    setShowFiles(true);
  };

  const showAcceptedFiles = acceptedFiles.map((file, idx) => (
    <ShowFile
      file={file}
      deleteMe={() => DropzoneStore.deleteFile(file.id)}
      key={file.id}
      open={() => openFileViewer(idx)}
    />
  ));

  return (
    <div className="Statement">
      {showFiles && (
        <FilesViewer
          files={acceptedFiles}
          startSlide={startSlide}
          setShowFiles={setShowFiles}
        />
      )}

      {error ? (
        errorMessage ? (
          <Popup
            header="Ошибка"
            content={errorMessage}
            submitTitle="Продолжить"
            onSubmit={StatementStore.resetStatuses}
          />
        ) : (
          <Popup
            header="Ошибка"
            content={"Ваше сообщение не создано!"}
            submitTitle="Новое сообщение"
            onSubmit={discardAllChanges}
            discardTitle="Закрыть создание"
            onDiscard={closeNewStatement}
            onClose={closeCreating}
          />
        )
      ) : success ? (
        <Popup
          header="Отлично"
          content="Ваше сообщение успешно создано. Желаете создать другое сообщение?"
          submitTitle="Новое сообщение"
          onSubmit={discardAllChanges}
          discardTitle="Нет, позже"
          onDiscard={closeCreating}
          onClose={StatementStore.resetStatuses}
        />
      ) : null}
      <div className="Statement__header">
        <h2 className="Statement__title">Новое сообщение</h2>
        <button className="Statement__reset" onClick={closeCreating}>
          <CloseIcon />
        </button>
      </div>
      <div className="Statement__content">
        <div className="Statement__categories">
          <span className="Input__title">Поиск по ключевым словам</span>
          <DropDown
            value={Categories.tag}
            options={Categories.tags}
            onSubmit={Categories.submitTagCategory}
            chosen={Categories.chosen}
            onChange={Categories.changeTagValue}
            title="Категория"
          />
        </div>
        <div className="Statement__categories">
          <span className="Input__title">Категория</span>
          <DropDown
            value={Categories.value}
            options={Categories.categories}
            onSubmit={Categories.submitCategory}
            chosen={Categories.chosen}
            onChange={Categories.changeValue}
            title="Категория"
          />
        </div>

        {Categories.form2.map((item, num) => {
          return (
            <React.Fragment key={num}>
              <div className="Statement__categories">
                {item.length !== 0 && (
                  <>
                    <span className="Input__title">Подкатегория</span>
                    <DropDown
                      value={item.find((it) => it.selected)?.name}
                      options={item}
                      onSubmit={Categories.submitSubCategory}
                      chosen={item.find((it) => it.selected)}
                      onChange={Categories.changeValue}
                      title="Категория"
                      nameItem={num}
                    />
                  </>
                )}
              </div>
            </React.Fragment>
          );
        })}
        <div className="Statement__adress">
          <div className="Statement__box-with-loader">
            <span className="Input__title">Адрес</span>
            {Address.loading ? (
              <span className="Input__loading">Загрузка</span>
            ) : null}
          </div>
          <InputWithIcon
            value={Address.value}
            onChange={(value) => {
              Address.setValueAndGetPos(value);
            }}
            placeholder={"Введите адрес"}
            icon={
              <button onClick={Address.switchMap} className="button-icon">
                <AddressIcon />
              </button>
            }
          />
        </div>
        {Address.showMap ? (
          <div className="Statement__map">
            <Map
              position={Address.position}
              setPosition={Address.setPosAndGetAddress}
              setMap={Address.setMap}
            />
            {Address.loading ? <LoaderSpinner /> : null}
          </div>
        ) : null}
        {/* change className if you need it */}
        <div className="Statement__subCategories">
          <span className="Input__title">Прикрепите файлы</span>
          <div className="Statement__dropzone">
            <Dropzone addFiles={DropzoneStore.addfiles} icon={<PlusIcon />} />
            {showAcceptedFiles}
          </div>
        </div>
        <div className="Statement__end">
          <Button
            title="Создать сообщение"
            onClick={StatementStore.loadStatment}
            type="primary"
            disabled={Address.loading}
          />
          <Button title="Отмена" onClick={closeCreating} type="danger" />
        </div>
      </div>
    </div>
  );
};

export default observer(Statement);
