import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";

import Tabs from "../../../components/Tabs";
import Switchers from "../../../components/Swithchers";
import Select from "../../../components/Select";
import PurchasesItem from "../../../components/PurchasesItem";
import EskInfo from "../../../components/EskInfo";
import InfinityScroll from "../../../components/InfinityScroll";
import listDividingByDates from "../../../utils/listDividingByDates";
import BigLoader from "../../../../Common/Components/BigLoader";

import MyPurchasesStore, {
  IPurchasesItem,
} from "./../../../stores/app/MyPurchasesStore";
import UserStore from "../../../../stores/user";

import { history } from "../../../helpers/history";
import { profileTabs, myPointsOptions } from "../../../constants/profileTabs";

import "./styles.scss";
import { useParams } from "react-router";

const MyPurchases: React.FC = () => {
  const {
    purchasesList,
    isLoading,
    itemsPerRequest,
    isRequestMore,
    init,
    setUserId,
    reset,
    getPurchasesList,
    setTransactionType,
    getPurchaseMore,
    findDOMItem,
  } = MyPurchasesStore;

  const { id, isHaveEskCard } = UserStore;

  const { location } = history;

  const switchersOptions = [
    {
      type: "all",
      text: "Все покупки",
      onChange: () => {
        setTransactionType(undefined);
        getPurchasesList(undefined, undefined, itemsPerRequest);
      },
    },
    {
      type: "notActivated",
      text: "Неактивировано",
      onChange: () => {
        setTransactionType(false);
        getPurchasesList(false, undefined, itemsPerRequest);
      },
    },
    {
      type: "activated",
      text: "Активировано",
      onChange: () => {
        setTransactionType(true);
        getPurchasesList(true, undefined, itemsPerRequest);
      },
    },
  ];

  const { purchaseId } = useParams<{ purchaseId: string }>();

  useEffect(() => {
    if (id) setUserId(id);
  }, [id, setUserId]);

  const scrollToItem = useCallback(async () => {
    const item = await findDOMItem(purchaseId);

    if (item) {
      const correctionToHeaderHeight = 160;
      window.scrollTo({
        top: (item as HTMLElement).offsetTop + correctionToHeaderHeight,
        behavior: "smooth",
      });
    } else if (item === undefined) {
      scrollToItem();
    }
  }, [findDOMItem, purchaseId]);

  useEffect(() => {
    if (purchaseId) {
      scrollToItem();
    } else if (id) {
      init();
    }

    return () => reset();
  }, [init, reset, purchaseId, id, scrollToItem]);

  return (
    <div className="my-purchases">
      <Tabs routes={profileTabs} className="my-purchases__tabs" />
      <Select
        isSearchable={false}
        className="my-purchases__select"
        options={myPointsOptions}
        currentType={location.pathname}
        onChange={(path) => {
          history.push(`${path}`);
        }}
      />
      {isHaveEskCard ? (
        <>
          <div className="my-purchases__filter">
            <Switchers list={switchersOptions} />
          </div>
          {isLoading ? (
            <BigLoader />
          ) : (
            <InfinityScroll
              request={getPurchaseMore}
              isRequestMore={isRequestMore}
            >
              <div className="my-purchases__list">
                {(
                  listDividingByDates(purchasesList, "purchaseDate") as {
                    date: string;
                    items: IPurchasesItem[];
                  }[]
                ).map((date, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="date-separator">{date.date}</div>
                      {date.items.map((el, i) => {
                        return <PurchasesItem item={el} key={i} />;
                      })}
                    </React.Fragment>
                  );
                })}
              </div>
            </InfinityScroll>
          )}
        </>
      ) : (
        <EskInfo />
      )}
    </div>
  );
};

export default observer(MyPurchases);
