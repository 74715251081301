import {
  URL_PROFILE_POINTS,
  URL_PROFILE_PURCHASES,
  URL_PROFILE_SHOP,
} from "../routes/paths";

export const profileTabs = [
  { name: "Мои баллы", path: URL_PROFILE_POINTS },
  { name: "Магазин поощрений", path: URL_PROFILE_SHOP },
  { name: "Мои покупки", path: URL_PROFILE_PURCHASES },
];

export const myPointsOptions = [
  { text: "Мои баллы", type: URL_PROFILE_POINTS },
  { text: "Магазин поощрений", type: URL_PROFILE_SHOP },
  { text: "Мои покупки", type: URL_PROFILE_PURCHASES },
];
