import React, { useState } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { IProductParagraphProps } from "./types";
import "./styles.scss";

const ProductParagraph: React.FC<IProductParagraphProps> = ({ text }) => {
  const [showMoreText, setShowMoreText] = useState(text.length < 200);
  return (
    <div className="product-paragraph">
      <p
        className={cn(
          "product-paragraph__text",
          showMoreText && "product-paragraph__text-open"
        )}
      >
        {text}
      </p>
      {!showMoreText && (
        <span
          className="product-paragraph__button"
          onClick={() => {
            setShowMoreText(true);
          }}
        >
          Читать далее
        </span>
      )}
    </div>
  );
};

export default observer(ProductParagraph);
