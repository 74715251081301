import { observer } from "mobx-react";
import React from "react";
import { OKShareButton, TwitterShareButton, VKShareButton } from "react-share";
import "./index.scss";

export const VoteFooter = observer(({ title, image, url, description }) => {
  return (
    <div className={"footerWrapper"}>
      <h4>Поделиться в соцсетях</h4>
      <p className="vote-footer__text">Пусть об этом узнает больше людей!</p>
      <div className={"footerButtons"}>
        <div className={"social"}>
          <VKShareButton
            url={url}
            title={title}
            image={image}
            description={description}
          >
            <div className={"vk footerButtonsItem"}></div>
          </VKShareButton>

          <TwitterShareButton url={url} title={title}>
            <div className="twitter footerButtonsItem"></div>
          </TwitterShareButton>

          <OKShareButton
            url={url}
            title={title}
            description={description}
            image={image}
          >
            <div className="ok footerButtonsItem"></div>
          </OKShareButton>
        </div>
      </div>
    </div>
  );
});
