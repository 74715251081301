import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import PageHeader from "../../components/PageHeader";
import MultiSelection from "../../components/MultiSelection";
import ProductCard from "../../components/ProductCard";
import Popup from "../../components/Popup";
import Loader from "../../../Main/Components/Loader";
import Switcher from "../../../Main/Components/SwitchView";

import CatalogStore from "../../stores/app/CatalogStore";
import CategoriesStore from "../../stores/app/CategoriesStore";
import ProductStore from "../../stores/app/ProductStore";
import UserStore from "../../../stores/user";
import { IProductItem } from "../types";

import { URL_PROFILE_POINTS, URL_PROFILE_PURCHASES } from "../../routes/paths";
import { history } from "../../helpers/history";

import "./styles.scss";
import InfinityScroll from "../../components/InfinityScroll";
import Map from "../../../Main/Components/Map";
import { toJS } from "mobx";

const CatalogPage = () => {
  const {
    productList,
    currentItem,
    selectedCategoriesList,
    isLoading,
    message,
    isRequestMore,
    init,
    getCurrentItem,
    setCategories,
    clearCategoriesList,
    reset,
    getProductsMore,
  } = CatalogStore;

  const { buyProduct } = ProductStore;

  const checkMode = localStorage.getItem("ShopMode");

  const { isAuth, isHaveEskCard, currentPoints } = UserStore;

  const { categoriesList, isLoading: categoryLoading } = CategoriesStore;

  console.log("categoriesList", toJS(productList));

  const [orderStatus, setOrderStatus] = useState("");

  const setStatusesHandler = (order: string, item: IProductItem) => {
    setOrderStatus(order);
    getCurrentItem(item);
  };
  const clearStatusesHandler = () => setOrderStatus("");

  useEffect(() => {
    init();

    return () => reset();
  }, [init, reset, checkMode]);

  useEffect(() => {
    if (checkMode && checkMode === "map") {
      setViewMode("map");
    }
  }, [checkMode]);

  const [viewMode, setViewMode] = useState(checkMode || "list");

  const coordinates: any[] = [];

  productList.forEach((item) => {
    if (
      item.organizationCoordinates?.latitude &&
      item.organizationCoordinates?.longitude
    )
      coordinates.push({
        lat: item.organizationCoordinates.latitude,
        lon: item.organizationCoordinates.longitude,
      });
  });

  const notHaveEskCardHandler = () => {
    clearStatusesHandler();
    history.push(URL_PROFILE_POINTS);
  };

  const notIsAuthHandler = () => {
    clearStatusesHandler();
  };

  const setViewModeFilter = (mode: string) => {
    localStorage.setItem("ShopMode", mode);
    setViewMode(mode);
  };

  const buyHandler = () => {
    currentItem.id &&
      buyProduct(currentItem.id).then(() => {
        clearStatusesHandler();
        history.push(URL_PROFILE_PURCHASES);
      });
  };

  const optionsMode = [
    {
      text: "Список",
      type: "list",
      setViewModeFilter,
      active: viewMode === "list" && true,
    },
    {
      text: "Карта",
      type: "map",
      setViewModeFilter,
      active: viewMode === "map" && true,
    },
  ];

  productList.forEach((product) => {
    if (product.organizationCoordinates) {
      coordinates.push([
        product.organizationCoordinates.latitude,
        product.organizationCoordinates.longitude,
        {
          id: product.id,
          name: product.title,
          balls: product.price,
        },
      ]);
    }
  });

  return (
    <div className="catalog">
      <PageHeader children="Магазин поощрений" />
      <Switcher options={optionsMode} />
      {!categoryLoading && !!categoriesList.length && (
        <MultiSelection
          title="Показать рубрики"
          options={categoriesList}
          selectedList={selectedCategoriesList}
          setItem={setCategories}
          clearSelectedList={clearCategoriesList}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <InfinityScroll request={getProductsMore} isRequestMore={isRequestMore}>
          <div className="catalog__list">
            {viewMode === "list" &&
              productList.map((product, idx) => {
                const isEnoughPoints = currentPoints
                  ? currentPoints >= Number(product.price)
                  : false;

                return (
                  product.isActive && (
                    <ProductCard
                      key={idx}
                      product={product}
                      isCanBuy={isEnoughPoints}
                      onClick={(item: IProductItem) => {
                        if (!isAuth) setOrderStatus("notAuth");
                        if (isAuth && !isHaveEskCard)
                          setOrderStatus("notHaveEsk");
                        if (
                          isAuth &&
                          isHaveEskCard &&
                          isEnoughPoints &&
                          product.isActive
                        )
                          setStatusesHandler("canBuy", item);
                      }}
                    />
                  )
                );
              })}
          </div>
        </InfinityScroll>
      )}
      {orderStatus === "canBuy" && (
        <Popup
          title="Приобретение товара"
          text={`Вы выбрали товар "${currentItem.title}"`}
          submitHandler={buyHandler}
          submitTitle="Списать"
          discardHandler={clearStatusesHandler}
          discardTitle="Отмена"
          onCloseHandler={clearStatusesHandler}
        />
      )}
      {orderStatus === "notAuth" && (
        <Popup
          title="Ошибка"
          text="Для совершения покупки необходимо авторизоваться."
          submitHandler={notIsAuthHandler}
          submitTitle="Войти"
          discardHandler={clearStatusesHandler}
          discardTitle="Отмена"
          onCloseHandler={clearStatusesHandler}
        />
      )}
      {orderStatus === "notHaveEsk" && (
        <Popup
          title="Ошибка"
          text="Для совершения покупки необходимо оформить карту ЕСК."
          submitHandler={notHaveEskCardHandler}
          submitTitle="Подробнее о карте ЕСК"
          discardHandler={clearStatusesHandler}
          discardTitle="Отмена"
          onCloseHandler={clearStatusesHandler}
        />
      )}
      {message && <p>{message}</p>}
      {viewMode === "map" && <Map coordinates={coordinates} type={"shop"} />}
    </div>
  );
};

export default observer(CatalogPage);
