import React, { useState } from "react";
import { ReactComponent as ArrowDownIcon } from "../../../Common/images/ArrowDown.svg";
import InputWithIcon from "../../../Common/Components/InputWithIcon";
import "./index.scss";
import Scrollbars from "react-custom-scrollbars";

const Option = ({ id, name, nameItem, onSubmit, chosen = null }) => {
  const onClick = () => {
    if (chosen?.id !== id) onSubmit(id, nameItem);
  };

  return <li onMouseDown={onClick}>{name}</li>;
};

const InputWithDropDown = ({
  value,
  onChange,
  onSubmit,
  options,
  chosen,
  errorMessage,
  nameItem,
  disabled = false,
  isReadOnly = false,
}) => {
  const [showOpt, setShowOpt] = useState(false); // hide options list
  const changeShowOpt = () => {
    setShowOpt((prevState) => !prevState);
  };

  const onChangeHandler = (value) => {
    onChange(value);
    setShowOpt(true);
  };

  const show = () => {
    setShowOpt(true);
  };

  const hide = (e) => {
    setShowOpt(false);
  };

  const displayOptions =
    chosen === null
      ? options
          .filter((opt) => {
            const lcValue = value.toLowerCase();
            const lcTest = opt.name.toLowerCase();
            return lcTest.includes(lcValue);
          })
          .map((opt) => (
            <Option
              id={opt.id}
              name={opt.name}
              onSubmit={onSubmit}
              key={opt.id}
            />
          ))
      : options.map((opt) => (
          <Option
            id={opt.id}
            name={opt.name}
            onSubmit={onSubmit}
            key={opt.id}
            chosen={chosen}
            nameItem={nameItem}
          />
        ));

  return (
    <div className={`DropDown ${chosen ? "success" : ""}`}>
      {!!errorMessage && <div className={`DropDown-error`}>{errorMessage}</div>}
      <InputWithIcon
        value={value}
        onChange={onChangeHandler}
        placeholder="Не выбрано"
        onFocus={show}
        onBlur={hide}
        success={!!chosen}
        disabled={disabled}
        isReadOnly={isReadOnly}
        icon={
          <button
            onClick={changeShowOpt}
            className={`button-icon ${showOpt && !disabled ? "active" : ""}`} // change status of btn
          >
            <ArrowDownIcon />
          </button>
        }
      />
      <ul className={`${showOpt && !disabled ? "active" : ""}`}>
        <Scrollbars autoHeight autoHeightMax={213}>
          {displayOptions}
        </Scrollbars>
      </ul>
    </div>
  );
};

export default InputWithDropDown;
