import { observer } from "mobx-react";
import React, { FC, useState } from "react";
import FileViewer from "../../../Main/Components/FileViewer";
import WasBecameCard from "../WasBecameCard";
import "./index.scss";

export type TFile = {
  reference: string;
  type: "image";
};

type TWasBecame = {
  becameFile?: TFile;
  wasFile?: TFile;
};

const WasBecame: FC<TWasBecame> = ({ becameFile, wasFile }) => {
  const [isShowWasBecamePhoto, setIsShowWasBecamePhoto] = useState(false);
  const files = [becameFile, wasFile].filter((file) => file?.reference);
  return (
    <div className="was-became">
      <div className="was-became__files">
        <WasBecameCard
          file={wasFile}
          title="Было"
          onClick={() => setIsShowWasBecamePhoto(true)}
        />
        <WasBecameCard
          file={becameFile}
          title="Стало"
          onClick={() => setIsShowWasBecamePhoto(true)}
        />
      </div>
      {isShowWasBecamePhoto && (
        <FileViewer
          files={files}
          startSlide={0}
          setShowFiles={() => {
            setIsShowWasBecamePhoto(false);
          }}
        />
      )}
    </div>
  );
};

export default observer(WasBecame);
