import React from "react";
import { copyrights } from "../../../Project/constants/layoutItems";
import "./index.scss";

const CopyrightsContainer = () => {
  return (
    <div className="copyrights-container">
      {copyrights &&
        copyrights.map((item, idx) => {
          return <p key={idx}>&#169; {item}</p>;
        })}
    </div>
  );
};

export default CopyrightsContainer;
