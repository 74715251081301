import React, { FC } from "react";
import IconLinkBack from "../../assets/svg/iconLinkBack";
import "./index.scss";

type TLinkBackProps = { title?: string; handler: () => void };

const LinkBack: FC<TLinkBackProps> = ({
  title = "Назад",
  handler,
}) => {
  return (
    <div className="linkBack" onClick={handler}>
      <IconLinkBack />
      {title}
    </div>
  );
};

export default LinkBack;
