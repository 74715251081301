import axios from "axios";
import { makeAutoObservable } from "mobx";
import { BASE_URL } from "../constants/api";

class News {
  constructor() {
    this.isShowRubrics = false;
    this.countRubricsItem = 9;
    this.news = [];
    this.selectedRubrics = [];
    this.rubrics = [];
    this.isEmpty = false;

    makeAutoObservable(this);
  }

  setIsShowRubrics = (boolean) => {
    this.isShowRubrics = boolean;
  };

  setCountRubricsItem = (count) => {
    this.countRubricsItem = count;
  };

  fetchNewsList = () => {
    let url = BASE_URL + "news/api/news?type=0&take=12";
    if (this.selectedRubrics?.length) {
      this.selectedRubrics.forEach((rubric) => {
        url += `&rubrics=${rubric}`;
      });
    }
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          this.news = res.data.items;
          if (this.news && this.news.length === 0) {
            this.isEmpty = true;
          } else if (this.news.length <= res.data.count) {
            this.isEmpty = true;
          }
        }
      })
      .catch((error) => {
        return console.error(error);
      });
  };

  fetchRubrics = () => {
    const url = BASE_URL + "news/api/news/rubric/list";
    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          this.rubrics = res.data;
        }
      })
      .catch((error) => {
        return console.error(error);
      });
  };

  fetchMoreNews = (pageNumber) => {
    axios
      .get(BASE_URL + `news/api/news?type=0&skip=${pageNumber * 12}&take=12`)
      .then((res) => {
        if (res.status === 200) {
          this.news = this.news.concat(res.data.items);
          if (res.data.items && res.data.items.length < 12) {
            this.isEmpty = true;
          }
        }
      })
      .catch((error) => {
        return console.error(error);
      });
  };

  onClickRubric = (id) => {
    if (id) {
      if (this.selectedRubrics.includes(id)) {
        const idx = this.selectedRubrics.findIndex((i) => i === id);
        this.selectedRubrics.splice(idx, 1);
      } else {
        this.selectedRubrics.push(id);
      }
    } else {
      this.selectedRubrics = [];
    }
    this.fetchNewsList();
  };
}

export default new News();
