import {
  URL_PROFILE_INFO,
  URL_PROFILE_NOTIFICATIONS,
  URL_PROFILE_POINTS,
  URL_PROFILE_SETTINGS,
  URL_PROFILE_VIOLATIONS,
} from "../Project/routes/paths";

export const profileTabs = [
  { content: "Мои данные", value: "data", path: URL_PROFILE_INFO },
  {
    content: "Мои сообщения",
    value: "statements",
    path: URL_PROFILE_VIOLATIONS,
  },
  {
    content: "Мои баллы",
    value: "points" || ["points", "purchases", "shop"],
    path: URL_PROFILE_POINTS,
  },
  { content: "Настройки", value: "settings", path: URL_PROFILE_SETTINGS },
  {
    content: "Уведомления",
    value: "notifications",
    path: URL_PROFILE_NOTIFICATIONS,
  },
];
