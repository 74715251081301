import "./styles.scss";

import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";

import NavigationMenu from "../NavigationMenu";
import NavigationMenuMore from "../NavigationMenuMore";
import { useLocation } from "react-router";

interface IProps {
  links: {
    content: string;
    path: string;
  }[];
}

const HeaderNavigation: React.FC<IProps> = (props) => {
  const [mainMenuElementsCount, setMainMenuElementsCount] = useState(1);

  const ref = useRef<HTMLDivElement>(null);

  const location = useLocation();

  useEffect(() => {
    const resizeHandler = () => {
      const oneElementMaxWidth: number = 140;
      const containerWidth: number = Number(ref.current?.offsetWidth);
      const firstCount = Math.round(containerWidth / oneElementMaxWidth);

      setMainMenuElementsCount(firstCount);
    };

    window.addEventListener("resize", resizeHandler);
    resizeHandler();

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  const linksMain = props.links.slice(0, mainMenuElementsCount - 1);
  const linksMore = props.links.slice(mainMenuElementsCount - 1);

  return (
    <div className="Navigation" ref={ref}>
      <NavigationMenu links={linksMain} currentPath={location.pathname} />
      {!!linksMore.length && <NavigationMenuMore links={linksMore} />}
    </div>
  );
};
export default observer(HeaderNavigation);
