import React from "react";

import "./index.scss";

const SubmitButton = ({
  onClick,
  text,
  type,
  switchType,
  active,
  setTypeFormFilter,
}) => {
  return (
    <div
      className={`submit-button ${active}`}
      onClick={() => {
        onClick(type);
        switchType(type);
        setTypeFormFilter(type);
      }}
    >
      <span>{text}</span>
    </div>
  );
};

export default SubmitButton;
