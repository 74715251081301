import months from "../constants/months";

const normalizeDate = (date) => {
  const parsed = Date.parse(date);
  const d = new Date(parsed).getDate();
  const m = new Date(parsed).getMonth();
  const y = new Date(parsed).getFullYear();
  const currentY = new Date().getFullYear();
  const resultY = currentY !== y ? y : "";

  return d + " " + months[m].slice(0, 3) + ". " + resultY;
};

export default normalizeDate;
