import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
const root = document.body;

const scrollTop = () => window.scrollTo(0, 0);

export const blockScroll = () => disableBodyScroll(root);

export const showScroll = () => enableBodyScroll(root);

export default scrollTop;
