import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import PageHeader from "../../components/PageHeader";
import ProductCategory from "../../components/ProductCategory";
import ProductParagraph from "../../components/ProductParagraph";
import Loader from "../../../Main/Components/Loader";

import ProductStore from "../../stores/app/ProductStore";

import login from "../../../utils/login";
import { URL_PROFILE_POINTS, URL_PROFILE_PURCHASES } from "../../routes/paths";
import { history } from "../../helpers/history";
import { BASE_URL } from "../../api/api";
import DummyShopPhoto from "../../components/DummyShopPhoto";
import LinkBack from "../../components/LinkBack";

import "./styles.scss";
import ProductPopup from "./ProductPopup";
import Map from "../../../Main/Components/Map";
import FileViewer from "../../../Main/Components/FileViewer";
import { HTMLBlock } from "../../components/HTMLBlock";

interface ParamTypes {
  id: string;
}

const ProductPage = () => {
  const [isOpenPhotos, setIsOpenPhotos] = useState(false);

  const {
    productItem,
    orderStatus,
    isLoading,
    setOrderStatus,
    getProduct,
    buyProduct,
    reset,
  } = ProductStore;
  const {
    title,
    imageId,
    description,
    categories,
    price,
    organizationName,
    organizationAddress,
    organizationCoordinates,
  } = productItem;

  const clearOrderStatusHandler = () => setOrderStatus("");

  const { id } = useParams<ParamTypes>();

  useEffect(() => {
    getProduct(id);

    return () => reset();
  }, [id, getProduct, reset]);

  const notHaveEskCardHandler = () => {
    setOrderStatus("");
    history.push(URL_PROFILE_POINTS);
  };

  const canBuyHandler = () => {
    buyProduct(id).then(() => {
      setOrderStatus("");
      history.push(URL_PROFILE_PURCHASES);
    });
  };

  const handleOpenImageClick = () => {
    setIsOpenPhotos(true);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="product">
      <PageHeader children={title} />

      <div className="product__return-back">
        <LinkBack handler={history.goBack} />
      </div>
      <div className="product__content">
        {imageId ? (
          <div className="product__image">
            <img
              src={BASE_URL + "shop/api/products/image/" + imageId}
              className="product__image-img"
              alt="product-img"
            />
            <div
              className="product__image-button"
              onClick={handleOpenImageClick}
            />
          </div>
        ) : (
          <div className="product__non-photo">
            <DummyShopPhoto />
          </div>
        )}
        <div className="product__text">
          <h2 className="product__price">{price} Баллов</h2>
          {/* <ProductButtons
            isAuth={!!isAuth}
            isActive={isActive}
            isHaveEskCard={isHaveEskCard}
            isEnoughPoints={!!isEnoughPoints}
            changeOrderStatus={changeOrderStatus}
          /> */}

          <HTMLBlock content={description} />
          {categories.length !== 0 && (
            <div className="product__categories">
              <span className="showing">Товар в рубриках:</span>
              <div className="product__categories-list">
                {categories.map((category) => (
                  <ProductCategory text={category} key={category} />
                ))}
              </div>
            </div>
          )}
          {organizationName && (
            <>
              <h2 className="product__text-title">Название организации</h2>
              <ProductParagraph text={(organizationName as string) || "-"} />
            </>
          )}

          {organizationAddress && (
            <>
              <h2 className="product__text-title">Адрес организации</h2>
              <ProductParagraph text={(organizationAddress as string) || "-"} />
            </>
          )}
          {organizationCoordinates && (
            <div className="product__text-title">
              <Map coordinates={organizationCoordinates} zoom={16} />
            </div>
          )}
        </div>
      </div>
      <ProductPopup
        orderStatus={orderStatus}
        title={title}
        canBuyHandler={canBuyHandler}
        clearOrderStatusHandler={clearOrderStatusHandler}
        login={login}
        notHaveEskCardHandler={notHaveEskCardHandler}
      />
      {isOpenPhotos && (
        <FileViewer
          files={[
            {
              type: "image",
              reference: imageId,
            },
          ]}
          startSlide={0}
          setShowFiles={setIsOpenPhotos}
          url={BASE_URL + "shop/api/products/image/"}
        />
      )}
    </div>
  );
};

export default observer(ProductPage);
