import "./index.scss";

import { observer } from "mobx-react";
import React, { useState } from "react";

import scrollTop from "../../../utils/scroll";
import ShowMyStatements from "../ShowMyStatements";
import CreateStatement from "../Statement";

const MyStatements = () => {
  const [newStatement, setNewStatement] = useState(false);

  const openNewStatement = () => {
    scrollTop();
    setNewStatement(true);
  };

  const closeNewStatement = () => {
    scrollTop();
    setNewStatement(false);
  };

  // you can open
  // 1. Statement creation
  // 2. Open one Statement
  // 3. And all user statements
  return (
    <>
      {newStatement ? (
        <CreateStatement closeNewStatement={closeNewStatement} />
      ) : (
        <ShowMyStatements openNewStatement={openNewStatement} />
      )}
    </>
  );
};

export default observer(MyStatements);
