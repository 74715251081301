import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react";

import NewsStore from "../../../stores/news";
import NewsItemStore from "../../../stores/newsItem";
import mobileAppStore from "../../../stores/mobileAppStore";
import cn from "classnames";
import NewsList from "../News/NewsList";
import Button from "../../../Common/Components/Button";
import LinkBack from "../../../Project/components/LinkBack";
import NewsRubrics from "../../../Project/components/NewsRubrics";

import "./index.scss";
import StickyNewsSection from "../../../Project/components/StickyNewsSection";
import ScrollToTop from "../../../Project/utils/ScrollToTop";
import { URL_NEWS, URL_NEWS_ID } from "../../../Project/routes/paths";
import useDesktopScreen from "../../../Project/hooks/useDesktopScreen";

const News = () => {
  const [, setPageNumber] = useState(1);

  const { isEmpty, news } = NewsStore;
  const history = useHistory();
  const { id } = useParams();

  const isDesktop = useDesktopScreen();

  useEffect(() => {
    NewsStore.fetchNewsList();
    NewsStore.fetchRubrics();
  }, []);

  useEffect(() => {
    if (id) {
      NewsItemStore.fetchNewsItem(id);
    }
  }, [id, isDesktop]);

  useEffect(() => {
    if (news.length && !id && isDesktop) {
      history.replace(URL_NEWS_ID(news[0]?.id));
    }
  }, [history, id, news, isDesktop]);

  const onNewsItemClick = (id) => {
    history.push(URL_NEWS_ID(id));
  };

  const goBack = () => {
    history.push(URL_NEWS);
  };

  const loadMoreNews = () => {
    setPageNumber((prev) => {
      NewsStore.fetchMoreNews(prev);
      return ++prev;
    });
  };

  return (
    <>
      {!isDesktop && <ScrollToTop />}
      <div className="news-page">
        {!isDesktop && id && (
          <div
            className={cn(
              "news-page__linkBack-container"
              //раскоментить когда будет ссылка на приложение
              //mobileAppStore.isShown && "news-page__linkBack-container--offset"
            )}
          >
            <LinkBack handler={goBack} />
          </div>
        )}
        {(isDesktop || !!id) && (
          <div className="news-page__container--news">
            <StickyNewsSection id={id} news={news} isSticky={isDesktop} />
          </div>
        )}
        {(isDesktop || !id) && (
          <div className="news-page__container--rubrics">
            <NewsRubrics />
            <div className="news-page__rubrics">
              <NewsList
                onNewsItemClick={onNewsItemClick}
                pathId={id}
                news={news}
              />
            </div>
            {news.length !== 0 && !isEmpty && (
              <div className="TNews__more">
                <Button
                  type="primary"
                  title="Показать ещё"
                  onClick={loadMoreNews}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default observer(News);
