import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

import Tabs from "../../../components/Tabs";
import MultiSelection from "../../../components/MultiSelection";
import ProductCard from "../../../components/ProductCard";
import Popup from "../../../components/Popup";
import Select from "../../../components/Select";
import Loader from "../../../../Main/Components/Loader";

import UserStore from "../../../../stores/user";
import ProductStore from "../../../stores/app/ProductStore";
import CatalogStore from "../../../stores/app/CatalogStore";
import CategoriesStore from "../../../stores/app/CategoriesStore";
import { IProductItem } from "../../types";

import { profileTabs, myPointsOptions } from "../../../constants/profileTabs";
import {
  URL_PROFILE_POINTS,
  URL_PROFILE_PURCHASES,
} from "../../../routes/paths";
import { history } from "../../../helpers/history";

import "./styles.scss";
import InfinityScroll from "../../../components/InfinityScroll";

const RewardStore = () => {
  const {
    productList,
    currentItem,
    selectedCategoriesList,
    isLoading,
    isRequestMore,
    init,
    getCurrentItem,
    setCategories,
    clearCategoriesList,
    reset,
    getProductsMore,
  } = CatalogStore;

  const { buyProduct } = ProductStore;

  const { isAuth, isHaveEskCard, currentPoints } = UserStore;

  const { categoriesList, isLoading: categoryLoading } = CategoriesStore;

  const [orderStatus, setOrderStatus] = useState("");

  const setStatusesHandler = (order: string, item: IProductItem) => {
    setOrderStatus(order);
    getCurrentItem(item);
  };
  const clearStatusesHandler = () => setOrderStatus("");
  const { location } = history;

  useEffect(() => {
    init();

    return () => reset();
  }, [init, reset]);

  const notHaveEskCardHandler = () => {
    clearStatusesHandler();
    history.push(URL_PROFILE_POINTS);
  };

  const notIsAuthHandler = () => {
    clearStatusesHandler();
  };

  const canBuyHandler = () => {
    currentItem.id &&
      buyProduct(currentItem.id).then(() => {
        clearStatusesHandler();
        history.push(URL_PROFILE_PURCHASES);
      });
  };

  return (
    <div className="reward-store">
      <Tabs routes={profileTabs} />
      <Select
        isSearchable={false}
        className="my-purchases__select"
        options={myPointsOptions}
        currentType={location.pathname}
        onChange={(path) => {
          history.push(`${path}`);
        }}
      />
      <div className="reward-store__content">
        {!categoryLoading && !!categoriesList.length && (
          <MultiSelection
            title="Показать рубрики"
            options={categoriesList}
            selectedList={selectedCategoriesList}
            setItem={setCategories}
            clearSelectedList={clearCategoriesList}
          />
        )}
        {isLoading ? (
          <Loader />
        ) : (
          <InfinityScroll
            request={getProductsMore}
            isRequestMore={isRequestMore}
          >
            <div className="catalog__list">
              {productList.map((product, idx) => {
                const isEnoughPoints = currentPoints
                  ? currentPoints >= Number(product.price)
                  : false;
                const issDsabledBuy =
                  isAuth && isHaveEskCard && !isEnoughPoints;

                return (
                  <ProductCard
                    key={idx}
                    product={product}
                    isCanBuy={!issDsabledBuy}
                    onClick={(item: IProductItem) => {
                      if (!isAuth) setOrderStatus("notAuth");
                      if (isAuth && !isHaveEskCard)
                        setOrderStatus("notHaveEsk");
                      if (isAuth && isHaveEskCard && isEnoughPoints)
                        setStatusesHandler("canBuy", item);
                    }}
                  />
                );
              })}
            </div>
          </InfinityScroll>
        )}
      </div>
      {orderStatus === "canBuy" && (
        <Popup
          title="Приобретение товара"
          text={`Вы выбрали товар "${currentItem.title}"`}
          submitHandler={canBuyHandler}
          submitTitle="Списать"
          discardHandler={clearStatusesHandler}
          discardTitle="Отмена"
          onCloseHandler={clearStatusesHandler}
        />
      )}
      {orderStatus === "notAuth" && (
        <Popup
          title="Ошибка"
          text="Для совершения покупки необходимо авторизоваться."
          submitHandler={notIsAuthHandler}
          submitTitle="Войти"
          discardHandler={clearStatusesHandler}
          discardTitle="Отмена"
          onCloseHandler={clearStatusesHandler}
        />
      )}
      {orderStatus === "notHaveEsk" && (
        <Popup
          title="Ошибка"
          text="Для совершения покупки необходимо оформить карту ЕСК."
          submitHandler={notHaveEskCardHandler}
          submitTitle="Подробнее о карте ЕСК"
          discardHandler={clearStatusesHandler}
          discardTitle="Отмена"
          onCloseHandler={clearStatusesHandler}
        />
      )}
    </div>
  );
};

export default observer(RewardStore);
