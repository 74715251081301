import { makeAutoObservable } from "mobx";

class Profile {
  tab = "data";
  open = false;

  setTab = (tab) => {
    this.tab = tab;
  };

  switchOpen = () => {
    if (window.innerWidth < 640) {
      this.open = !this.open;
      return;
    }
    this.open = true; // if desktop - block menu hiding;
  };

  // if mobile version - hide menu
  // if desktop - show it
  deskOrMobile = () => {
    if (window.innerWidth < 640) {
      this.open = false;
    } else {
      this.open = true;
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new Profile();
