import "./index.scss";

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "../../../Common/Components/Button";
import Dropzone from "../../../Common/Components/Dropzone";
import Input from "../../../Common/Components/Input";
import InputWithIcon from "../../../Common/Components/InputWithIcon";
import Popup from "../../../Common/Components/Popup";
import { ReactComponent as AddressIcon } from "../../../Common/images/location.svg";
import { ReactComponent as ShowMapIcon } from "../../../Common/images/map-show.svg";
import { AISAE } from "../../../constants/api";
import editProfileStore from "../../../stores/editProfileStore";
import user from "../../../stores/user";
import CropperImage from "../../Components/CropperImage";
import DropDown from "../../Components/DropDown";
import Map from "../../Components/EditalbeMap";
import LoaderSpinner from "../../Components/Loader";
import Radiobuttons from "../../../Common/Components/Radiobuttons";
import User from "../../../stores/user";
import { URL_PROFILE_INFO } from "../../../Project/routes/paths";

const ProfileEdit = () => {
  const {
    changeForm,
    form,
    getProfile,
    isLoading,
    isGeolocationAllowed,
    staticImage,
    addresses,
    photo,
    setPhoto,
    saveUserData,
    deletePhoto,
    onClickisGeolocationAllowed,
    saveStaticImage,
    imageUrl,
    dynamicImage,
    saveImageUrl,
    saveDynamicImage,
    setImageUrl,
  } = editProfileStore;

  const history = useHistory();

  useEffect(() => {
    getProfile();

    if (photo) {
      User.setImageSrc(AISAE + "api/keycloak/photo?t=" + User.imageLoadCount);
      setImageUrl(AISAE + "api/keycloak/photo");
    } else {
      User.setImageSrc(null);
      setImageUrl(null);
    }
  }, [getProfile, photo, setImageUrl]);

  const goBack = () => {
    history.push(URL_PROFILE_INFO);
  };

  const saveUserHandler = () => {
    saveUserData();

    setTimeout(() => {
      if (imageUrl) {
        setPhoto(dynamicImage);
      } else {
        deletePhoto();
      }
      setTimeout(() => {
        getProfile();
        history.push(URL_PROFILE_INFO);
      }, 500);
    }, 500);
  };

  const returnInputProps = (name) => {
    return {
      value: form[name],
      onChange: (id, value) => {
        changeForm(value, id);
      },
      id: name,
    };
  };

  const returnAddressInput = (name) => {
    return {
      value: addresses[name].value,
      onChange: addresses[name].setValueAndGetPos,
      placeholder: "Введите адрес",
      icon: (
        <button onClick={addresses[name].switchShowMap} className="button-icon">
          <AddressIcon />
        </button>
      ),
    };
  };

  const returnAddressMapProps = (name) => {
    return {
      position: addresses[name].position,
      setPosition: addresses[name].setPosAndGetAddress,
      setMap: addresses[name].setMap,
    };
  };

  if (isLoading) {
    return (
      <div style={{ margin: "0 auto" }}>
        <LoaderSpinner />
      </div>
    );
  }

  const genderValue =
    gendersList.find((item) => item.id === form.gender)?.name || "";

  const deletePhotoHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setImageUrl(null);
  };

  const acceptCropper = () => {
    saveImageUrl(dynamicImage);
    saveStaticImage(null);
  };

  const geoData = [
    {
      text: "Вкл.",
      checked: isGeolocationAllowed,
    },
    {
      text: "Выкл.",
      checked: !isGeolocationAllowed,
    },
  ];

  return (
    <div className="profile-edit">
      {staticImage && (
        <Popup
          submitTitle="Сохранить"
          discardTitle="Отмена"
          onDiscard={() => {
            saveStaticImage(null);
          }}
          onClose={() => {
            saveStaticImage(null);
          }}
          onSubmit={acceptCropper}
          content={
            <CropperImage onInitialized={saveDynamicImage} src={staticImage} />
          }
        />
      )}

      <Dropzone
        className="profile-edit__dropdown"
        addFiles={(files) => {
          files?.[0] && saveStaticImage(files[0]);
        }}
        icon={
          imageUrl ? (
            <div className="profile-edit__add-photo-wrapper">
              <div className="profile-edit__add-photo-change-photo">
                <span>Обновить фотографию</span>
              </div>
              <button
                className="profile-edit__add-photo-delete-photo"
                title="Удалить фото профиля"
                onClick={deletePhotoHandler}
              >
                X
              </button>
              <img src={imageUrl} alt="Профиль" />
            </div>
          ) : (
            <div className="profile-edit__add-photo-title">Добавьте фото</div>
          )
        }
      />
      <div className="profile-edit__form">
        <div className="profile-edit__edit-section">
          <p>Фамилия</p>
          <Input {...returnInputProps("lastName")} />
        </div>
        <div className="profile-edit__edit-section">
          <p>Имя</p>
          <Input {...returnInputProps("firstName")} />
        </div>
        <div className="profile-edit__edit-section">
          <p>Отчество</p>
          <Input {...returnInputProps("middleName")} />
        </div>
        <div className="profile-edit__edit-section">
          <p>Пол</p>
          <DropDown
            isReadOnly={true}
            value={genderValue}
            options={gendersList}
            onSubmit={(id) => {
              changeForm(id, "gender");
            }}
            chosen={gendersList.find((item) => item.id === form.gender)}
            onChange={() => {}}
          />
        </div>
        <div className="profile-edit__edit-section">
          <p>Дата рождения</p>
          <Input {...returnInputProps("birthDate")} mask={"99.99.9999"} />
        </div>
        <div className="profile-edit__edit-section">
          <p>Снилс</p>
          <Input {...returnInputProps("snils")} mask="999-999-999 99" />
        </div>
        <div className="profile-edit__edit-section">
          <p>E-mail</p>
          <Input {...returnInputProps("email")} />
        </div>
        <div className="profile-edit__edit-section">
          <p>Сотовый телефон</p>
          <Input
            {...returnInputProps("phoneNumber")}
            mask="+7(999)-999-99-99"
          />
        </div>
        <div className="profile-edit__edit-section">
          <div className="profile-edit__map-header">
            <p>Адрес регистрации</p>
            {addresses.registration.loading && (
              <span className="profile-edit__map-load-status">Загрузка</span>
            )}
          </div>
          <InputWithIcon {...returnAddressInput("registration")} />
          <button
            className={`profile-edit__show-map-btn ${
              addresses.registration.showMap.registration
                ? "profile-edit__show-map-btn_active"
                : ""
            }`}
            onClick={addresses.registration.switchShowMap}
          >
            Показать <ShowMapIcon />
          </button>
          {addresses.registration.showMap && (
            <Map {...returnAddressMapProps("registration")} />
          )}
        </div>
        <div className="profile-edit__edit-section">
          <div className="profile-edit__map-header">
            <p>Адрес проживания</p>
            {addresses.living.loading && (
              <span className="profile-edit__map-load-status">Загрузка</span>
            )}
          </div>
          <InputWithIcon {...returnAddressInput("living")} />
          <button
            className={`profile-edit__show-map-btn ${
              addresses.living.showMap
                ? "profile-edit__show-map-btn_active"
                : ""
            }`}
            onClick={addresses.living.switchShowMap}
          >
            Показать <ShowMapIcon />
          </button>
          {addresses.living.showMap && (
            <Map {...returnAddressMapProps("living")} />
          )}
        </div>
        <div className="profile-edit__edit-section">
          <p>Геолокация</p>
          <Radiobuttons click={onClickisGeolocationAllowed} options={geoData} />
        </div>
        <div className="profile-edit__btn-group">
          <Button title="Сохранить" onClick={saveUserHandler} type="primary" />
          <Button title="Отмена" onClick={goBack} type="danger" />
        </div>
      </div>
    </div>
  );
};

const gendersList = [
  {
    name: "Мужчина",
    id: "Мужчина",
  },
  {
    name: "Женщина",
    id: "Женщина",
  },
];

export default observer(ProfileEdit);
