import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import playNotification from "../../../utils/playNotification";
import getDateAndTime from "../../../utils/getDateAndTime";
import "./index.scss";

import ShowFile from "../ShowFile";
import Chat from "../../../stores/chat";

// owner = user | admin
const ChatItem = ({ msg, setFiles, setStart, setShowFiles, createNewLine }) => {
  const { date, text, fileReferences: files } = msg;

  const { dm, time } = getDateAndTime(date);
  const owner = msg.isMyMessage ? "user" : "admin"; // hard code

  const setFilesOnOpen = () => {
    setFiles(files);
  };

  const showAcceptedFiles = files.map((file, idx) => {
    const onOpen = () => {
      setStart(idx);
      setFilesOnOpen();
      setShowFiles(true);
    };

    return (
      <ShowFile
        file={file}
        className="chat__file"
        key={file.reference}
        open={onOpen}
      />
    );
  });

  return (
    <>
      <div className={`chat__msg ${owner}`}>
        <div className="chat__msg-title">{text}</div>
        {files?.length ? (
          <div className="chat__msg-files">{showAcceptedFiles}</div>
        ) : null}
        <div className="chat__msg-time">{time}</div>
      </div>
      {createNewLine && (
        <div className="chat__date-wrapper">
          <div className="chat__date-line"></div>
          <div className="chat__date">{dm}</div>
          <div className="chat__date-line"></div>
        </div>
      )}
    </>
  );
};

const ChatMain = () => {
  const { messages, newMessages } = Chat;
  // poup FileViewer
  const [files, setFiles] = useState([]);
  const [start, setStart] = useState(0);
  const [showFiles, setShowFiles] = useState(false);

  // Add new Date line in messages
  let currentDate = null;
  let createNewLine = true;

  const displayMessages = messages
    .map((msg) => {
      const { dm } = getDateAndTime(msg.date);
      createNewLine = false;

      if (currentDate !== dm) {
        currentDate = dm;
        createNewLine = true;
      }

      return (
        <ChatItem
          msg={msg}
          key={msg.id}
          setFiles={setFiles}
          setStart={setStart}
          setShowFiles={setShowFiles}
          createNewLine={createNewLine}
        />
      );
    })
    .reverse();

  useEffect(() => {
    if (newMessages) {
      playNotification();
    }
  }, [newMessages]);

  return (
    <div className="chat__main">
      {displayMessages}
    </div>
  );
};

export default observer(ChatMain);
