import React from "react";

const IconList = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6415 11.1736H5.77338C5.02297 11.1736 4.41455 11.8019 4.41455 12.5767C4.41455 13.3509 5.02297 13.9791 5.77338 13.9791H16.6415C17.3916 13.9791 18 13.3509 18 12.5767C18 11.8022 17.3916 11.1736 16.6415 11.1736Z"
      />
      <path
        d="M16.6415 5.59099H5.77338C5.02297 5.59099 4.41455 6.21894 4.41455 6.99373C4.41455 7.76823 5.02297 8.39618 5.77338 8.39618H16.6415C17.3916 8.39618 18 7.76823 18 6.99373C18 6.21924 17.3916 5.59099 16.6415 5.59099Z"
      />
      <path
        d="M5.77338 2.81355H16.6415C17.3916 2.81355 18 2.1856 18 1.4108C18 0.636011 17.3916 0.00835675 16.6415 0.00835675H5.77338C5.02297 0.00835675 4.41455 0.636309 4.41455 1.4108C4.41455 2.1853 5.02297 2.81355 5.77338 2.81355Z"
      />
      <path
        d="M1.37937 2.84728C2.14117 2.84728 2.75873 2.20989 2.75873 1.42364C2.75873 0.637384 2.14117 0 1.37937 0C0.617563 0 0 0.637384 0 1.42364C0 2.20989 0.617563 2.84728 1.37937 2.84728Z"
      />
      <path
        d="M1.37937 8.41707C2.14101 8.41707 2.75844 7.77982 2.75844 6.99373C2.75844 6.20764 2.14101 5.57039 1.37937 5.57039C0.617723 5.57039 0.000289175 6.20764 0.000289175 6.99373C0.000289175 7.77982 0.617723 8.41707 1.37937 8.41707Z"
      />
      <path
        d="M1.37937 14C2.14117 14 2.75873 13.3626 2.75873 12.5764C2.75873 11.7901 2.14117 11.1527 1.37937 11.1527C0.617563 11.1527 0 11.7901 0 12.5764C0 13.3626 0.617563 14 1.37937 14Z"
      />
    </svg>
  );
};

export default IconList;
