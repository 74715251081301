import "./styles.scss";

import { observer } from "mobx-react-lite";
import React, { FC } from "react";

export const HTMLBlock: FC<{ content?: string }> = observer(({ content }) => {
  return (
    <div
      className="html-block"
      dangerouslySetInnerHTML={{ __html: content || "" }}
    />
  );
});

HTMLBlock.displayName = "HTMLBlock";
