import React from "react";
import InputMask from "react-input-mask";
import Tooltip from "../Tooltip";
import cn from 'classnames';

import "./index.scss";

const Input = (props) => {
  const [errorActive, setErrorActive] = React.useState(true);
  const { value, onChange, id, mask, errorMessage } = props;

  React.useEffect(() => {
    setErrorActive(true);
  }, [errorMessage, value]);

  const handleClose = () => {
    setErrorActive(false);
  };

  const handleSwitch = () => {
    setErrorActive(!errorActive);
  };

  return (
    <div className={cn("input-wrapper", !value && "input-wrapper__isEmpty")}>
      {!!errorMessage && errorActive && (
        <Tooltip text={errorMessage} onClose={handleClose} />
      )}
      {!!errorMessage && (
        <button
          className="input-error__open-button"
          onClick={handleSwitch}
          aria-label="закрыть подсказку"
          tabIndex={-1}
        />
      )}
      {mask ? (
        <InputMask
          type="text"
          defaultValue={value || ""}
          onChange={(event) => {
            onChange(id, event.target.value);
          }}
          mask={mask}
          alwaysShowMask={false}
        />
      ) : (
        <input
          type="text"
          defaultValue={value || ""}
          onChange={(event) => {
            onChange(id, event.target.value);
          }}
        />
      )}
    </div>
  );
};

export default Input;
