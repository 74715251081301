import "./index.scss";

import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import cn from "classnames";

import votesStore from "../../../stores/votes";
import Filter from "../../Components/FilterType";
import Cards from "../../Components/Tiles";
import DropDown from "../../Components/DropDown";
import {
  URL_COMPLETED_VOTE_ID,
  URL_VOTE_ID,
} from "../../../Project/routes/paths";

const VotesComponent = inject("history")(
  observer((props) => {
    const { history } = props;
    const { fetchVotesList, votes, resetStatuses } = votesStore;

    /** Для проверки находимся на главной странице или в голосованиях */
    const mainPage = history.location.pathname === "/";

    const checkType = localStorage.getItem("VotesType");

    const [page, setPage] = useState(1);
    const [type, setType] = useState(checkType ? checkType : "available");
    const [count, setCount] = useState(!mainPage ? 10 : 7);

    const setTypeFormFilter = (type) => {
      localStorage.setItem("VotesType", type);
      setType(type);
      setPage(1);
      setCount(10);
      fetchVotesList(type, page, count);
    };

    useEffect(() => {
      window.scrollTo(0, sessionStorage.getItem("scrollPosY"));

      return () => sessionStorage.setItem("scrollPosY", window.pageYOffset);
    }, []);

    useEffect(() => {
      if (!checkType) {
        fetchVotesList(type, page, count);
      } else {
        fetchVotesList(checkType, page, count);
      }

      resetStatuses();
    }, [resetStatuses, fetchVotesList, checkType, type, page, count]);

    const onItemClick = (id, status, isDone = false) => {
      const notCompleted = status !== "Completed";

      if (notCompleted && !isDone) {
        history.push(URL_VOTE_ID(id));
      } else {
        history.push(URL_COMPLETED_VOTE_ID(id));
      }
    };

    const fetchMoreData = () => setCount(count + 10);

    const dropdownData = [
      {
        name: "Анонсы",
        id: "announcement",
      },
      {
        name: "Активные",
        id: "available",
      },
      {
        name: "Завершенные",
        id: "completed",
      },
    ];

    const seletedType = dropdownData.find((item) => item.id === type);

    const options = [
      {
        text: "Анонсы",
        type: "announcement",
        action: () => {},
        setTypeFormFilter,
        active: checkType === "announcement" && true,
      },
      {
        text: "Активные",
        type: "available",
        action: () => {},
        setTypeFormFilter,
        active: (checkType === "available" || (!checkType && type)) && true,
      },
      {
        text: "Завершенные",
        type: "completed",
        action: () => {},
        setTypeFormFilter,
        active: checkType === "completed" && true,
      },
    ];

    const isVotes = history.location.pathname.includes("votes");

    return (
      <div className={cn("votes", isVotes && "votes-page")}>
        <div className="header">
          <h2 className="header_title">Опросы</h2>
        </div>
        <div className="votes__filter">
          <span className="showing">Показать на странице</span>
          <Filter options={options} />
        </div>
        <div className="votes__dropdown">
          <DropDown
            isReadOnly={true}
            value={seletedType.name}
            options={dropdownData}
            onSubmit={setTypeFormFilter}
          />
        </div>

        <InfiniteScroll
          dataLength={votes.length}
          next={fetchMoreData}
          hasMore={true}
        >
          <div className="votes-list">
            {votes.map((item, idx) => (
              <Cards item={item} key={idx} onTileClick={onItemClick} />
            ))}
          </div>
        </InfiniteScroll>
      </div>
    );
  })
);

export default VotesComponent;
