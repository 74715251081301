import { observer } from "mobx-react";
import React from "react";
import "./style.scss";

/** варианты ответов без картинок */
const VoteRadiobuttonsText = (props) => {
  const { options, name, click, id } = props;

  if (!options) return null;
  return (
    <div className="answers-text">
      {options?.map((item, idx) => (
        <label
          className={["answer", item.checked && "answer--active"].join(" ")}
          key={idx}
        >
          <div
            className={[
              "answer__text",
              item.checked && "answer__text--active",
            ].join(" ")}
          >
            {item.text}
          </div>
          <input
            value={item.text}
            type="radio"
            checked={item.checked && item.checked}
            name={name}
            onChange={() => {
              click(idx, id);
            }}
          />
          <div
            className={[
              "answer__button",
              item.checked && "answer__button--active",
            ].join(" ")}
          ></div>
        </label>
      ))}
    </div>
  );
};

export default observer(VoteRadiobuttonsText);
