import axios from "axios";
import { makeAutoObservable } from "mobx";
import {
  URL_HAS_NEW_MESSAGES,
  URL_MY_VIOLATIONS,
  URL_VIOLATIONS,
  URL_VIOLATIONS_ID,
} from "../constants/api";
import getURLWithQuery from "../Project/utils/getURLWithQuery";
import fetchService from "../utils/fetch";
import user from "./user";

class Violation {
  constructor() {
    this.violations = [];
    this.violation = {};
    this.files = [];
    this.options = [
      { text: "да", checked: true, id: 1 },
      { text: "нет", checked: false, id: 2 },
    ];
    this.selected = { text: "да", checked: true };
    this.checked = true;
    this.isEmpty = false;
    this.errorStatus = undefined;
    this.newMessageInViolation = false;
    this.itemsPerPage = 18;

    makeAutoObservable(this);
  }

  fetchViolations = async () => {
    await fetchService
      .get(
        getURLWithQuery(
          URL_VIOLATIONS,
          `/${"published"}?page=${1}&number=${this.itemsPerPage}`
        )
      )
      .then((res) => {
        if (res.status === 200) {
          this.violations = res.data;
          if (res.data.length >= this.itemsPerPage) {
            this.isEmpty = true;
          } else if (res.data.length < this.itemsPerPage) {
            this.isEmpty = true;
          }
        }
      })
      .catch((error) => {
        console.error("fetchViolations", error);
      });
  };

  fetchNextViolationsList = async (num, type = "published") => {
    this.isEmpty = false;

    await fetchService
      .get(
        getURLWithQuery(
          URL_VIOLATIONS,
          `/${type}?page=${num}&number=${this.itemsPerPage}`
        )
      )
      .then((res) => {
        if (res.status === 200) {
          if (num !== 1) {
            this.violations = this.violations.concat(res.data);
          } else {
            this.violations = res.data;
          }
          if (res.data.length === 0) {
            this.isEmpty = true;
          } else if (res.data.length < this.itemsPerPage) {
            this.isEmpty = true;
          }
        }
      })
      .catch((error) => {
        console.error("fetchNextViolationsList", error);
      });
  };

  fetchViolation = async (id) => {
    this.violation = {};
    await fetchService
      .get(URL_VIOLATIONS_ID(id))
      .then((res) => {
        if (res.status === 200) {
          this.violation = res.data;
        }
      })
      .catch((error) => {
        console.error("fetchViolation", error);
        if (error && error.response) {
          const { status } = error.response;
          this.errorStatus = status;
        }
      });
  };

  handleChangeAgreement = (num) => {
    this.checked = num === 0 ? true : false;
    const selected = this.options[num];

    this.options = this.options.map((item) => {
      if (item.id === selected.id) {
        item.checked = true;
      } else {
        item.checked = false;
      }
      return item;
    });
  };

  fetchTypesViolations = (type) => {
    this.isEmpty = true;
    this.violations = [];
    fetchService
      .get(
        getURLWithQuery(
          URL_VIOLATIONS,
          `/${type}?page=${1}&number=${this.itemsPerPage}`
        )
      )
      .then((res) => {
        if (res.status === 200) {
          this.isEmpty = false;
          this.violations = res.data;
          if (res.data.length === 0) {
            this.isEmpty = true;
          } else if (res.data.length < this.itemsPerPage) {
            this.isEmpty = true;
          }
        }
      })
      .catch((error) => {
        console.error("fetchTypesViolations", error);
      });
  };

  handleSupporViolation = async (id) => {
    try {
      const res = await fetchService.post(
        getURLWithQuery(
          URL_VIOLATIONS,
          `/${id}/support?isSubscribe=${this.checked}`
        )
      );
      if (res.status === 200) {
        this.fetchViolation(id);
      } else {
      }
    } catch (error) {}
  };

  fetchMyViolations = async () => {
    this.violations = [];

    const ok = await user.checkAuth();

    if (!ok) {
      return;
    }

    await fetchService
      .get(URL_MY_VIOLATIONS)
      .then((res) => {
        if (res.status === 200) {
          this.violations = res.data;
        }
      })
      .catch((error) => {
        console.error("fetchMyViolations", error);
      });
  };

  checkNewMessagesInMyViolation = async () => {
    const isAuth = await user.checkAuth();

    if (isAuth) {
      axios.get(URL_HAS_NEW_MESSAGES).then((res) => {
        this.newMessageInViolation = res.data;
      });
    } else return;
  };

  resetViolation = () => {
    this.violation = {};
  };

  resetStatuses = () => {
    this.errorStatus = undefined;
  };
}

export default new Violation();
