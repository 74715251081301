import { observer } from "mobx-react";
import React from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const CropperImage = observer((props) => {
  const { src, className = "", onInitialized } = props;

  return (
    <Cropper
      className={className}
      zoomTo={0.5}
      initialAspectRatio={1}
      src={src}
      viewMode={1}
      minCropBoxHeight={100}
      minCropBoxWidth={100}
      background={false}
      responsive={true}
      autoCropArea={1}
      checkOrientation={false}
      onInitialized={onInitialized}
      guides={true}
    />
  );
});

export default CropperImage;
