import React, { useCallback, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import TextareaAutosize from "react-textarea-autosize";

import { ReactComponent as SendLogo } from "../../images/send.svg";
import { ReactComponent as FileLogo } from "../../images/addFile.svg";

import Chat from "../../../stores/chat";

import Dropzone from "../Dropzone";
import ShowFile from "../ShowFile";
import { CHAT_FILE_TYPES } from "../../../constants/dropzone";

import "./index.scss";

const ChatFooter = () => {
  const { valid, message, messages, open } = Chat;
  const { text, files } = message;

  const sendMessage = () => {
    if (valid) {
      Chat.sendMessage();
    }
  };

  let keyPressed = useRef({
    Control: false,
    Enter: false,
  });

  const keyDown = useCallback(
    (e) => {
      if (!valid && !open) return;

      if (e.key === "Enter" || e.key === "Control")
        keyPressed.current[e.key] = true;

      if (Object.values(keyPressed.current).every((key) => key))
        Chat.sendMessage();
    },
    [keyPressed, valid, open]
  );

  const keyUp = useCallback((e) => {
    keyPressed.current = {
      Control: false,
      Enter: false,
    };
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", keyDown);
    document.addEventListener("keyup", keyUp);

    return () => {
      document.removeEventListener("keydown", keyDown);
      document.addEventListener("keyup", keyUp);
    };
  }, [keyDown, keyUp]);

  const showAcceptedFiles = files.map((file, idx) => (
    <ShowFile
      file={file}
      deleteMe={() => Chat.deleteFileAndValidate(file.id)}
      key={file.id}
      open={() => {}}
      className="chat__file"
    />
  ));

  return (
    <div className="chat__footer">
      {messages.length < 1 ? (
        <span className="chat__block-input">
          Вы не можете отправлять сообщения, пока администратор вам не напишет
        </span>
      ) : (
        <>
          <Dropzone
            addFiles={Chat.addFilesAndValidate}
            icon={<FileLogo />}
            className="chat__addFile"
            accept={CHAT_FILE_TYPES}
          />
          <TextareaAutosize
            placeholder="Введите сообщение"
            type="text"
            className="chat__input"
            value={text}
            onChange={(e) => Chat.changeTextAndValidate(e.target.value)}
            maxRows={15}
          />
          <button
            className={`button-icon chat__send ${valid ? "active" : ""}`}
            onClick={sendMessage}
          >
            <SendLogo />
          </button>
          <span className="chat__desc">
            Enter - перенос строки, Ctrl+Enter - отправка
          </span>
          {showAcceptedFiles?.length ? (
            <div className="chat__my-files">{showAcceptedFiles}</div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default observer(ChatFooter);
