import { makeAutoObservable, runInAction } from "mobx";
import { API } from "../../../api";

export interface IOperationsHistoryItem {
  transactionType: string | null;
  transactionDate: string | null;
  numberOfPoints: number | null;
  action: string | null;
  title: string | null;
}
class MyPointsStore {
  operationsHistoryList: IOperationsHistoryItem[] = [];
  userId: string = "";
  isLoading: boolean = false;
  currentPoints: number | null = null;
  isHasCard: boolean = false;
  itemsPerRequest: number = 10;
  currentItemsPerRequest: number = 10;
  skipItem?: number = undefined;
  transactionType?: "Accrual" | "Debit" = undefined;
  allItemsCount: number | null = null;
  content?: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get isRequestMore() {
    return this.allItemsCount
      ? this.allItemsCount > this.operationsHistoryList.length
      : false;
  }

  getPoints = () => {
    this.isLoading = true;
    API.shop.Points.pointsGet()
      .then(({ data }) => {
        runInAction(() => {
          this.currentPoints = data.points || null;
          this.isHasCard = data.hasCard || false;
          this.content = data.content;
        });
      })
      .catch((e) => {
        throw new Error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  setTransactionType = (type: "Accrual" | "Debit" | undefined) => {
    this.transactionType = type;
    this.operationsHistoryList = [];
  };

  getHistoryMore = () => {
    this.getOperationsList(
      this.transactionType,
      this.operationsHistoryList.length,
      this.currentItemsPerRequest
    );
  };

  getOperationsList = async (
    type?: "Debit" | "Accrual",
    skip?: number,
    itemsPerRequest?: number
  ) => {
    if (!this.operationsHistoryList.length) {
      this.isLoading = true;
    }

    return API.shop.Points.pointsHistoryGet(type, skip, itemsPerRequest)
      .then(({ data }) => {
        runInAction(() => {
          this.allItemsCount = data.count ?? null;
          if (data.items) {
            const newItems = data.items.map((item) => ({
              transactionType: item.transactionType || null,
              transactionDate: item.transactionDate || null,
              numberOfPoints: item.numberOfPoints || null,
              action: item.action || null,
              title: item.title || null,
            }));
            this.operationsHistoryList = [
              ...this.operationsHistoryList,
              ...newItems,
            ];
          }
        });
      })
      .catch((e) => {
        throw new Error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  setUserId = (id: string) => {
    this.userId = id;
  };

  init = () => {
    this.getPoints();
    this.getOperationsList(undefined, undefined, this.itemsPerRequest);
  };

  reset = () => {
    this.operationsHistoryList = [];
    this.userId = "";
    this.isLoading = false;
    this.currentItemsPerRequest = 10;
    this.itemsPerRequest = 10;
    this.transactionType = undefined;
  };
}

export default new MyPointsStore();
