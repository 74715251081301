import { makeAutoObservable, runInAction } from "mobx";
import CategoriesStore from "./CategoriesStore";
import { API } from "../../../api";
import { IProductItem } from "../../Shop/types";

class CatalogStore {
  productList: IProductItem[] = [];
  isLoading: boolean = false;
  selectedCategoriesList: string[] = [];
  message: string = "";

  itemsPerRequest: number = 12;
  allItemsCount: number | null = null;

  currentItem: IProductItem = {
    id: null,
    title: "",
    imageId: null,
    croppedImageId: null,
    description: "",
    categories: [],
    price: null,
    isActive: false,
    organizationAddress: null,
    organizationCoordinates: null,
    organizationName: null,
  };

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getProductList(this.selectedCategoriesList, 0, this.itemsPerRequest);
    CategoriesStore.getCategoriesList();
  };

  get isRequestMore() {
    return this.allItemsCount
      ? this.allItemsCount > this.productList.length
      : false;
  }

  getProductsMore = () => {
    this.getProductList(
      this.selectedCategoriesList,
      this.productList.length,
      this.itemsPerRequest
    );
  };

  getProductList = async (
    categoriesIds?: string[],
    skip?: number,
    itemsPerRequest?: number
  ) => {
    if (!this.productList.length) {
      this.isLoading = true;
    }

    return API.shop.Products.productsGet(categoriesIds, skip, itemsPerRequest)
      .then((res) => {
        runInAction(() => {
          this.allItemsCount = res.data.count ?? null;
          if (res.data.items) {
            const newItems = res.data.items.map((item) => ({
              id: item.id || null,
              title: item.title || "Нет названия",
              description: item.description || "Нет описания",
              price: item.price || null,
              imageId: item.imageId || null,
              croppedImageId: item.croppedImageId || null,
              categories: item.categories || [],
              isActive: item.isActive || false,
              organizationAddress: item.organizationAddress || null,
              organizationCoordinates: item.organizationCoordinates,
              organizationName: item.organizationName || null,
            }));
            this.productList = [...this.productList, ...newItems];
          }
        });
      })
      .catch((e) => {
        runInAction(() => {
          this.message = "Товары не найдены...";
        });

        throw new Error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  getCurrentItem = (value: IProductItem) => {
    this.currentItem = value;
  };

  resetCurrentItem = () => {
    this.currentItem = {
      id: "",
      title: "",
      imageId: null,
      croppedImageId: null,
      description: "",
      categories: [],
      price: null,
      isActive: false,
      organizationAddress: null,
      organizationCoordinates: null,
      organizationName: null,
    };
  };

  setCategories = (id: string) => {
    const idx = this.selectedCategoriesList.findIndex((i) => i === id);

    if (idx >= 0) {
      this.selectedCategoriesList.splice(idx, 1);
    } else {
      this.selectedCategoriesList.push(id);
    }

    this.productList = [];
    this.getProductList(this.selectedCategoriesList, 0, this.itemsPerRequest);
  };

  clearCategoriesList = () => {
    this.selectedCategoriesList = [];
    this.productList = [];
    this.getProductList(this.selectedCategoriesList, 0, this.itemsPerRequest);
  };

  reset = () => {
    this.productList = [];
    CategoriesStore.resetCategories();
    this.isLoading = false;
    this.allItemsCount = null;
    this.itemsPerRequest = 12;
    this.message = "";
  };
}

export default new CatalogStore();
