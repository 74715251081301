import React, { FC } from "react";
import Popup from "../../../components/Popup";

type IProductPopup = {
  orderStatus: string;
  title: string;
  canBuyHandler: () => void;
  clearOrderStatusHandler: () => void;
  login: () => void;
  notHaveEskCardHandler: () => void;
};

const ProductPopup: FC<IProductPopup> = ({
  orderStatus,
  title,
  canBuyHandler,
  clearOrderStatusHandler,
  login,
  notHaveEskCardHandler,
}) => {
  const baseProps = {
    discardHandler: clearOrderStatusHandler,
    discardTitle: "Отмена",
    onCloseHandler: clearOrderStatusHandler,
  };
  const getProps = () => {
    switch (orderStatus) {
      case "canBuy":
        return {
          title: "Приобретение товара",
          text: `Вы выбрали товар "${title}"`,
          submitHandler: canBuyHandler,
          submitTitle: "Списать",
        };
      case "notAuth":
        return {
          title: "Ошибка",
          text: "Для совершения покупки необходимо авторизоваться.",
          submitHandler: login,
          submitTitle: "Войти",
        };
      case "notHaveEsk":
        return {
          title: "Ошибка",
          text: "Для совершения покупки необходимо оформить карту ЕСК.",
          submitHandler: notHaveEskCardHandler,
          submitTitle: "Подробнее о карте ЕСК",
        };

      default:
        return {
          title: "Ошибка",
          text: "Неизвестная ошибка",
          submitHandler: clearOrderStatusHandler,
          submitTitle: "Выйти",
        };
    }
  };

  return <>{orderStatus && <Popup {...getProps()} {...baseProps} />}</>;
};

export default ProductPopup;
