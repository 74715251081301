import { observer } from "mobx-react";
import React from "react";
import FileSaver from "file-saver";

import "./styles.scss";

import { ReactComponent as PdfComponent } from "../../../../Common/images/pdf.svg";
import { ReactComponent as DocComponent } from "../../../../Common/images/doc.svg";
import { ReactComponent as JpgComponent } from "../../../../Common/images/jpg.svg";
import { API } from "../../../../api";
import cutText from "../../../../utils/cutText";

/**
 * кидаем сюда файлы
 * section указывает где находится компонент,
 * чтобы использовать конкретный запрос для получения файлов
 */

interface IFileProps {
  reference: string;
  type: string;
  name: string;
  size: number;
  section: string;
}

const Files: React.FC<IFileProps> = ({ reference, size, name, section }) => {
  const type = name.split(".")[name.split(".").length - 1];
  const getImageFromType = (type: string) => {
    console.log({ type });

    switch (type) {
      case "pdf":
        return <PdfComponent className="file-component__icon" />;

      case "doc":
      case "docx":
        return <DocComponent className="file-component__icon" />;

      case "png":
      case "jpeg":
      case "jpg":
        return <JpgComponent className="file-component__icon" />;

      default:
        return;
    }
  };

  const handleDownloadFileOnClick = (id: string) => {
    console.log({ type });

    if (section === "main")
      API.aisae.VotesFiles.apiVotesFilesVotesFilesFileIdGet(id, {
        responseType: "blob",
      }).then((res) => {
        const fileData = new File([res.data as unknown as BlobPart], name);
        FileSaver.saveAs(fileData, `${name}.${type}`);
      });

    if (section === "quest")
      API.aisae.VotesFiles.apiVotesFilesQuestionsFilesFileIdGet(id, {
        responseType: "blob",
      }).then((res) => {
        const fileData = new File([res.data as unknown as BlobPart], name);
        FileSaver.saveAs(fileData, `${name}.${type}`);
      });

    if (section === "answer")
      API.aisae.VotesFiles.apiVotesFilesAnswersFilesFileIdGet(id, {
        responseType: "blob",
      }).then((res) => {
        const fileData = new File([res.data as unknown as BlobPart], name);
        FileSaver.saveAs(fileData, `${name}.${type}`);
      });
  };

  return (
    <div
      onClick={() => {
        handleDownloadFileOnClick(reference);
      }}
      className="file-component"
    >
      {getImageFromType(type)}
      <span className="file-component__text">
        {cutText(name.split(".")[0], 20)}{" "}
        {(Math.ceil(size) / 1000 / 1000).toFixed(2)} МБ
      </span>
    </div>
  );
};

export default observer(Files);
