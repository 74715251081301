import React from "react";

const IconGeo = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 0C2.24299 0 0 2.27451 0 5.07025C0 8.53985 4.47451 13.6334 4.66502 13.8486C4.84395 14.0507 5.15637 14.0503 5.33498 13.8486C5.52549 13.6334 10 8.53985 10 5.07025C9.99995 2.27451 7.75699 0 5 0ZM5 7.62123C3.61288 7.62123 2.4844 6.47687 2.4844 5.07025C2.4844 3.66363 3.6129 2.51929 5 2.51929C6.3871 2.51929 7.51557 3.66366 7.51557 5.07028C7.51557 6.47689 6.3871 7.62123 5 7.62123Z"
      />
    </svg>
  );
};

export default IconGeo;
