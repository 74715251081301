import React, { useEffect } from "react";
import { observer } from "mobx-react";
import "./index.scss";

import Button from "../../../Common/Components/Button";
import CarouselTile from "../CarouselTile";

import ViolationStore from "../../../stores/violations";
import BurgerMenu from "../../../stores/burgerMenu";
import { useHistory } from "react-router";
import { URL_PROFILE_VIOLATIONS_ID } from "../../../Project/routes/paths";

const ShowMyStatements = ({ openNewStatement }) => {
  const history = useHistory();
  const { fetchMyViolations, violations } = ViolationStore;

  const getItemId = (id) => {
    history.push(URL_PROFILE_VIOLATIONS_ID(id));
  };

  useEffect(() => {
    fetchMyViolations();
  }, [fetchMyViolations]);

  useEffect(() => {
    BurgerMenu.setButton({
      title: "Создать сообщение",
      onClick: openNewStatement,
    });

    return () => {
      BurgerMenu.setButton(null);
    };
  }, [openNewStatement]);

  return (
    <div className="MyStatements">
      <Button
        title="Создать сообщение"
        type="primary"
        onClick={openNewStatement}
      />
      <div className="MyStatements__all">
        {violations &&
          violations.map((item, idx) => {
            return (
              <CarouselTile
                item={item}
                key={idx}
                getItemId={() => getItemId(item.id)}
                newMessage={!!item.hasNewChatMessages}
              />
            );
          })}
      </div>
    </div>
  );
};

export default observer(ShowMyStatements);
