import { axiosInstance, config } from "..";
import { BASE_URL } from "../../constants/api";
import * as api from "./openApi";

const URL_API_AISAE = BASE_URL + "aisae";

export const AISAE = {
  Categories: new api.CategoriesApi(config, URL_API_AISAE, axiosInstance),
  Common: new api.CommonApi(config, URL_API_AISAE, axiosInstance),
  Chat: new api.ChatApi(config, URL_API_AISAE, axiosInstance),
  File: new api.FileApi(config, URL_API_AISAE, axiosInstance),
  Geolocations: new api.GeolocationsApi(config, URL_API_AISAE, axiosInstance),
  //   Instructions: new api.InstructionsApi(config, URL_API_AISAE, axiosInstance),
  Keycloak: new api.KeycloakApi(config, URL_API_AISAE, axiosInstance),
  Question: new api.QuestionApi(config, URL_API_AISAE, axiosInstance),
  Result: new api.ResultApi(config, URL_API_AISAE, axiosInstance),
  Violations: new api.ViolationsApi(config, URL_API_AISAE, axiosInstance),
  Vote: new api.VoteApi(config, URL_API_AISAE, axiosInstance),
  VotesFiles: new api.VotesFilesApi(config, URL_API_AISAE, axiosInstance),
};
