import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import scrollTop from "./scroll";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const [, route] = pathname.split("/");

    if (route === "news") return;

    scrollTop();
  }, [pathname]);

  return null;
}
