import React, { useEffect } from "react";
import { observer } from "mobx-react";

import InstructionsStore from "../../stores/app/InstructionsStore";

const ParticipationPage = () => {
  const { getParticipationContent, clearInstructions, participation } =
    InstructionsStore;

  useEffect(() => {
    getParticipationContent();

    return () => clearInstructions();
  }, [getParticipationContent, clearInstructions]);

  return (
    <div className="instructions">
      {participation && (
        <div dangerouslySetInnerHTML={{ __html: participation }} />
      )}
    </div>
  );
};

export default observer(ParticipationPage);
