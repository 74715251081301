import React, { useState } from "react";
import cn from "classnames";

import Textarea from "../../../Common/Components/Textarea";

import "./index.scss";

const CustomField = (props) => {
  const {
    acceptCustomValue,
    id,
    onChange,
    onClick,
    hidden,
    decline,
    customOpen,
    value,
  } = props;

  const [disabled, changeDisable] = useState(true);
  const [isShowField, setIsShowField] = useState(false);

  const onCustomFieldClick = () => {
    onClick(true);
    !hidden && customOpen(id);
    changeDisable(true);
  };

  const close = () => {
    decline(id);
    onClick(!hidden);
    changeDisable(true);
    setIsShowField(false);
  };

  return (
    <div
      className={cn(
        isShowField || hidden ? `custom-field true` : `custom-field false`,
        value && "free-answer-field__isChosen"
      )}
    >
      <div
        className={cn("header", "custom-field-header")}
        onClick={() => {
          setIsShowField(true);
          onCustomFieldClick();
        }}
      >
        <h3>Свой вариант</h3>
        <button
          className={cn(
            "custom-field-header__button",
            value ? "true" : "false"
          )}
        />
      </div>
      <button className="custom-field-header__button-close" onClick={close} />
      {hidden && (
        <div className="form">
          <Textarea
            value={value}
            onChange={(value) => onChange(value)}
            changeDisable={changeDisable}
            decline={decline}
            id={id}
          />
          <div className="custom-field__buttons">
            <div
              className={`accept button ${disabled}`}
              disabled={disabled}
              onClick={() => {
                acceptCustomValue(id);
                changeDisable(true);
                setIsShowField(false);
                onClick(!hidden);
              }}
            >
              <span>Принять</span>
            </div>
            <div className="decline button" onClick={close}>
              <span>Отменить</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomField;
