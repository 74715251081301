const months = [
  "январь",
  "февраль",
  "март",
  "апрель",
  "мая",
  "июня",
  "июля",
  "август",
  "сентябрь",
  "октябрь",
  "ноябрь",
  "декабрь",
];

const monthsGenitive = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "авгуса",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

export { monthsGenitive }

export default months;
