// export const BASE_URL = "http://172.16.100.102:8999/";
// export const BASE_URL = "http://uds.ekadm.ru/";

/** Прод Сервер */
// export const BASE_URL = "https://активный.екатеринбург.рф/";

/** Тестовый сервер */
export const BASE_URL = "https://ae.aiwoo.info/";
// export const BASE_URL = "http://localhost:8999/";

export const AUTH = BASE_URL + "auth/";
export const AISAE = BASE_URL + "aisae/";
export const URL_API_NEWS = BASE_URL + "news/api";
export const IMAGE_URL = BASE_URL + "news/api";
export const NEWS_ITEM_IMAGE_URL = BASE_URL + "news/api/news/";
export const NEWS_FILE_API = BASE_URL + "news/api/news/file/";
export const NEWS_PHOTO_API = BASE_URL + "news/api/news/photo/";
export const IDP = BASE_URL + "idp/";

// export const AUTH_URL = "http://uds.ekadm.ru/";
export const AUTH_URL = "https://ae.aiwoo.info/";
// export const AUTH_URL = "http://localhost:8180/";
export const SETTINGS = AUTH_URL + "auth/realms/AISAE/account/";

export const FAKE_BASE_URL = "https://fakestoreapi.com/";
export const PRODUCTS_URL = BASE_URL + "products";
export const PRODUCTS_CATEGORIES_URL = PRODUCTS_URL + "/categories";

export const URL_VIOLATIONS = AISAE + "api/violations";
export const URL_MY_VIOLATIONS = URL_VIOLATIONS + "/myviolations";
export const URL_HAS_NEW_MESSAGES = AISAE + "api/chat/hasNewMessages";

export const URL_VIOLATIONS_ID = (id) => {
  return URL_VIOLATIONS + `/${id}`;
};

export const URL_FILE_ID = (id) => {
  return `${AISAE}api/file/${id}`;
};
