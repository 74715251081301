import AboutPage from "../../Main/Pages/About";
import VotesPage from "../../Main/Pages/Votes";
import SingleVotePage from "../../Main/Pages/Vote";
import CompletedVotePage from "../../Main/Pages/CompletedVote";
import ViolationsPage from "../../Main/Pages/Violations";
import SingleViolationPage from "../../Main/Pages/Violation";
import NewsPage from "../../Main/Pages/TNews";
import QuarterlyPage from "../../Main/Pages/QuarterlyPage";

import AgreementPage from "../pages/Agreement";
import ConditionsPage from "../pages/Conditions";
import ParticipationPage from "../pages/Participation";

import ShopCatalogPage from "../Shop/Catalog";
import ShopProductPage from "../Shop/Product";

import ProfileMyPointsPage from "../Shop/Profile/MyPoints";
import ProfileRewardStorePage from "../Shop/Profile/RewardStore";
import ProfileMyPurchasesPage from "../Shop/Profile/MyPurchases";
import MyStatementsPage from "../../Main/Components/MyStatements";
import ViolationPopup from "../../Main/Components/ViolationPopup";
import NotificationsPage from "../../Main/Components/Notifications";
import SettingsPage from "../../Main/Components/Settings";
import ProfileDataPage from "../../Main/Components/UserData";
import ProfileEditPage from "../../Main/Pages/EditProfile";

import {
  URL_ABOUT,
  URL_VOTES,
  URL_VOTE_ID,
  URL_COMPLETED_VOTE_ID,
  URL_NEWS,
  URL_NEWS_ID,
  URL_QUARTERLY,
  URL_VIOLATIONS,
  URL_VIOLATION_ID,
  URL_CONDITIONS,
  URL_AGREEMENT,
  URL_SHOP,
  URL_SHOP_ID,
  URL_PROFILE_POINTS,
  URL_PROFILE_PURCHASES,
  URL_PROFILE_SHOP,
  URL_PROFILE_EDIT,
  URL_PROFILE_INFO,
  URL_PROFILE_VIOLATIONS,
  URL_PROFILE_VIOLATIONS_ID,
  URL_PROFILE_NOTIFICATIONS_ID,
  URL_PROFILE_SETTINGS,
  URL_PROFILE_NOTIFICATIONS,
  URL_PROFILE_PURCHASES_ID,
  URL_POLICY,
} from "./paths";

interface IRoute {
  path: string;
  component: React.ComponentType<any>;
}

export const routes: IRoute[] = [
  { path: URL_ABOUT, component: AboutPage },
  { path: URL_VOTES, component: VotesPage },
  { path: URL_COMPLETED_VOTE_ID(), component: CompletedVotePage },
  { path: URL_VOTE_ID(), component: SingleVotePage },
  { path: URL_NEWS, component: NewsPage },
  { path: URL_NEWS_ID(), component: NewsPage },
  { path: URL_VIOLATIONS, component: ViolationsPage },
  { path: URL_VIOLATION_ID(), component: SingleViolationPage },
  { path: URL_QUARTERLY, component: QuarterlyPage },
  { path: URL_AGREEMENT, component: AgreementPage },
  { path: URL_CONDITIONS, component: ConditionsPage },
  { path: URL_POLICY, component: ParticipationPage },
  { path: URL_SHOP, component: ShopCatalogPage },
  { path: URL_SHOP_ID(), component: ShopProductPage },
];

export const profileRoutes: IRoute[] = [
  { path: URL_PROFILE_POINTS, component: ProfileMyPointsPage },
  { path: URL_PROFILE_PURCHASES, component: ProfileMyPurchasesPage },
  {
    path: URL_PROFILE_PURCHASES_ID(),
    component: ProfileMyPurchasesPage,
  },
  { path: URL_PROFILE_SHOP, component: ProfileRewardStorePage },
  { path: URL_PROFILE_SETTINGS, component: SettingsPage },
  { path: URL_PROFILE_NOTIFICATIONS, component: NotificationsPage },
  { path: URL_PROFILE_VIOLATIONS, component: MyStatementsPage },
  { path: URL_PROFILE_VIOLATIONS_ID(), component: ViolationPopup },
  { path: URL_PROFILE_NOTIFICATIONS_ID(), component: ViolationPopup },
  { path: URL_PROFILE_INFO, component: ProfileDataPage },
  { path: URL_PROFILE_EDIT, component: ProfileEditPage },
];

export const newProjectRoutes: IRoute[] = [
  // { path: "SHOP_URL", component: ShopURL },
];
