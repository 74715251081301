import { observer } from "mobx-react";
import React from "react";

import LoaderSpinner from "../../../Main/Components/Loader";
import BigLoaderStore from "../../../stores/bigLoader";

import "./index.scss";

const BigLoader = () => {
  const { active } = BigLoaderStore;

  return active ? (
    <div className="BigLoader">
      <LoaderSpinner />
    </div>
  ) : null;
};

export default observer(BigLoader);
