/* tslint:disable */
/* eslint-disable */
/**
 * News API
 * Модуль новостей
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface DocumentModel
 */
export interface DocumentModel {
    /**
     * 
     * @type {string}
     * @memberof DocumentModel
     */
    'content'?: string | null;
}
/**
 * 
 * @export
 * @interface FAQRubricGetList
 */
export interface FAQRubricGetList {
    /**
     * 
     * @type {string}
     * @memberof FAQRubricGetList
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQRubricGetList
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface FAQsGetItem
 */
export interface FAQsGetItem {
    /**
     * 
     * @type {string}
     * @memberof FAQsGetItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQsGetItem
     */
    'question'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FAQsGetItem
     */
    'rubricId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQsGetItem
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQsGetItem
     */
    'answer'?: string | null;
    /**
     * 
     * @type {Array<FAQsGetItemFile>}
     * @memberof FAQsGetItem
     */
    'files'?: Array<FAQsGetItemFile> | null;
}
/**
 * 
 * @export
 * @interface FAQsGetItemFile
 */
export interface FAQsGetItemFile {
    /**
     * 
     * @type {string}
     * @memberof FAQsGetItemFile
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQsGetItemFile
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface FAQsGetListItem
 */
export interface FAQsGetListItem {
    /**
     * 
     * @type {string}
     * @memberof FAQsGetListItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQsGetListItem
     */
    'question'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FAQsGetListItem
     */
    'rubricId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FAQsGetListItem
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface FAQsGetListResponse
 */
export interface FAQsGetListResponse {
    /**
     * 
     * @type {number}
     * @memberof FAQsGetListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<FAQsGetListItem>}
     * @memberof FAQsGetListResponse
     */
    'items'?: Array<FAQsGetListItem> | null;
}
/**
 * 
 * @export
 * @interface NewsGetListItemResult
 */
export interface NewsGetListItemResult {
    /**
     * 
     * @type {string}
     * @memberof NewsGetListItemResult
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsGetListItemResult
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsGetListItemResult
     */
    'date'?: string;
    /**
     * 
     * @type {Array<NewsGetNewsFile>}
     * @memberof NewsGetListItemResult
     */
    'files'?: Array<NewsGetNewsFile> | null;
    /**
     * 
     * @type {Array<NewsGetNewsFile>}
     * @memberof NewsGetListItemResult
     */
    'photos'?: Array<NewsGetNewsFile> | null;
    /**
     * 
     * @type {boolean}
     * @memberof NewsGetListItemResult
     */
    'hasVideo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewsGetListItemResult
     */
    'hasPhoto'?: boolean;
}
/**
 * 
 * @export
 * @interface NewsGetListResponse
 */
export interface NewsGetListResponse {
    /**
     * 
     * @type {number}
     * @memberof NewsGetListResponse
     */
    'count'?: number;
    /**
     * 
     * @type {Array<NewsGetListItemResult>}
     * @memberof NewsGetListResponse
     */
    'items'?: Array<NewsGetListItemResult> | null;
}
/**
 * 
 * @export
 * @interface NewsGetNewsFile
 */
export interface NewsGetNewsFile {
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsFile
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsFile
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface NewsGetNewsItem
 */
export interface NewsGetNewsItem {
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsItem
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsItem
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsItem
     */
    'content'?: string | null;
    /**
     * 
     * @type {Array<NewsGetNewsFile>}
     * @memberof NewsGetNewsItem
     */
    'files'?: Array<NewsGetNewsFile> | null;
    /**
     * 
     * @type {Array<NewsGetNewsFile>}
     * @memberof NewsGetNewsItem
     */
    'photos'?: Array<NewsGetNewsFile> | null;
    /**
     * 
     * @type {boolean}
     * @memberof NewsGetNewsItem
     */
    'hasVideo'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewsGetNewsItem
     */
    'hasPhoto'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsGetNewsItem
     */
    'baseUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface NewsRubricGetList
 */
export interface NewsRubricGetList {
    /**
     * 
     * @type {string}
     * @memberof NewsRubricGetList
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsRubricGetList
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NewsTypes = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type NewsTypes = typeof NewsTypes[keyof typeof NewsTypes];



/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary получение политики персональных данных
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsPersonalDataPolicyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/personalDataPolicy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Получение условий участия
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsTermsOfParticipationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/termsOfParticipation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение пользовательского соглашения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsUserAgreementGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/userAgreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary получение политики персональных данных
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsPersonalDataPolicyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsPersonalDataPolicyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Получение условий участия
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsTermsOfParticipationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsTermsOfParticipationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение пользовательского соглашения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsUserAgreementGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsUserAgreementGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary получение политики персональных данных
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsPersonalDataPolicyGet(options?: any): AxiosPromise<DocumentModel> {
            return localVarFp.documentsPersonalDataPolicyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Получение условий участия
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsTermsOfParticipationGet(options?: any): AxiosPromise<DocumentModel> {
            return localVarFp.documentsTermsOfParticipationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение пользовательского соглашения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsUserAgreementGet(options?: any): AxiosPromise<DocumentModel> {
            return localVarFp.documentsUserAgreementGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - interface
 * @export
 * @interface DocumentsApi
 */
export interface DocumentsApiInterface {
    /**
     * 
     * @summary получение политики персональных данных
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    documentsPersonalDataPolicyGet(options?: AxiosRequestConfig): AxiosPromise<DocumentModel>;

    /**
     * 
     * @summary Получение условий участия
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    documentsTermsOfParticipationGet(options?: AxiosRequestConfig): AxiosPromise<DocumentModel>;

    /**
     * 
     * @summary получение пользовательского соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    documentsUserAgreementGet(options?: AxiosRequestConfig): AxiosPromise<DocumentModel>;

}

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI implements DocumentsApiInterface {
    /**
     * 
     * @summary получение политики персональных данных
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsPersonalDataPolicyGet(options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).documentsPersonalDataPolicyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Получение условий участия
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsTermsOfParticipationGet(options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).documentsTermsOfParticipationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение пользовательского соглашения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsUserAgreementGet(options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).documentsUserAgreementGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FAQRubricsApi - axios parameter creator
 * @export
 */
export const FAQRubricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary получения списка рубрик, упорядоченных по названию
         * @param {string} [id] необязательный, для получения единственной рубрики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqRubricListGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/faq/rubric/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FAQRubricsApi - functional programming interface
 * @export
 */
export const FAQRubricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FAQRubricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary получения списка рубрик, упорядоченных по названию
         * @param {string} [id] необязательный, для получения единственной рубрики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqRubricListGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FAQRubricGetList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqRubricListGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FAQRubricsApi - factory interface
 * @export
 */
export const FAQRubricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FAQRubricsApiFp(configuration)
    return {
        /**
         * 
         * @summary получения списка рубрик, упорядоченных по названию
         * @param {string} [id] необязательный, для получения единственной рубрики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqRubricListGet(id?: string, options?: any): AxiosPromise<Array<FAQRubricGetList>> {
            return localVarFp.faqRubricListGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FAQRubricsApi - interface
 * @export
 * @interface FAQRubricsApi
 */
export interface FAQRubricsApiInterface {
    /**
     * 
     * @summary получения списка рубрик, упорядоченных по названию
     * @param {string} [id] необязательный, для получения единственной рубрики
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQRubricsApiInterface
     */
    faqRubricListGet(id?: string, options?: AxiosRequestConfig): AxiosPromise<Array<FAQRubricGetList>>;

}

/**
 * FAQRubricsApi - object-oriented interface
 * @export
 * @class FAQRubricsApi
 * @extends {BaseAPI}
 */
export class FAQRubricsApi extends BaseAPI implements FAQRubricsApiInterface {
    /**
     * 
     * @summary получения списка рубрик, упорядоченных по названию
     * @param {string} [id] необязательный, для получения единственной рубрики
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQRubricsApi
     */
    public faqRubricListGet(id?: string, options?: AxiosRequestConfig) {
        return FAQRubricsApiFp(this.configuration).faqRubricListGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FAQsApi - axios parameter creator
 * @export
 */
export const FAQsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary получения файла прикрепленного к ответу
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqFileIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqFileIdGet', 'id', id)
            const localVarPath = `/faq/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary поулчение часто часто задаваемого вопроса
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('faqIdGet', 'id', id)
            const localVarPath = `/faq/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение списка часто задаваемых вопросов
         * @param {string} [rubricId] необязательный параметр, для получения списка вопросов по рубрике
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqListGet: async (rubricId?: string, skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/faq/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rubricId !== undefined) {
                localVarQueryParameter['rubricId'] = rubricId;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FAQsApi - functional programming interface
 * @export
 */
export const FAQsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FAQsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary получения файла прикрепленного к ответу
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqFileIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqFileIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary поулчение часто часто задаваемого вопроса
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQsGetItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение списка часто задаваемых вопросов
         * @param {string} [rubricId] необязательный параметр, для получения списка вопросов по рубрике
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqListGet(rubricId?: string, skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FAQsGetListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqListGet(rubricId, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FAQsApi - factory interface
 * @export
 */
export const FAQsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FAQsApiFp(configuration)
    return {
        /**
         * 
         * @summary получения файла прикрепленного к ответу
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqFileIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.faqFileIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary поулчение часто часто задаваемого вопроса
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqIdGet(id: string, options?: any): AxiosPromise<FAQsGetItem> {
            return localVarFp.faqIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение списка часто задаваемых вопросов
         * @param {string} [rubricId] необязательный параметр, для получения списка вопросов по рубрике
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqListGet(rubricId?: string, skip?: number, take?: number, options?: any): AxiosPromise<FAQsGetListResponse> {
            return localVarFp.faqListGet(rubricId, skip, take, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FAQsApi - interface
 * @export
 * @interface FAQsApi
 */
export interface FAQsApiInterface {
    /**
     * 
     * @summary получения файла прикрепленного к ответу
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQsApiInterface
     */
    faqFileIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary поулчение часто часто задаваемого вопроса
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQsApiInterface
     */
    faqIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<FAQsGetItem>;

    /**
     * 
     * @summary получение списка часто задаваемых вопросов
     * @param {string} [rubricId] необязательный параметр, для получения списка вопросов по рубрике
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQsApiInterface
     */
    faqListGet(rubricId?: string, skip?: number, take?: number, options?: AxiosRequestConfig): AxiosPromise<FAQsGetListResponse>;

}

/**
 * FAQsApi - object-oriented interface
 * @export
 * @class FAQsApi
 * @extends {BaseAPI}
 */
export class FAQsApi extends BaseAPI implements FAQsApiInterface {
    /**
     * 
     * @summary получения файла прикрепленного к ответу
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQsApi
     */
    public faqFileIdGet(id: string, options?: AxiosRequestConfig) {
        return FAQsApiFp(this.configuration).faqFileIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary поулчение часто часто задаваемого вопроса
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQsApi
     */
    public faqIdGet(id: string, options?: AxiosRequestConfig) {
        return FAQsApiFp(this.configuration).faqIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение списка часто задаваемых вопросов
     * @param {string} [rubricId] необязательный параметр, для получения списка вопросов по рубрике
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FAQsApi
     */
    public faqListGet(rubricId?: string, skip?: number, take?: number, options?: AxiosRequestConfig) {
        return FAQsApiFp(this.configuration).faqListGet(rubricId, skip, take, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MobileProjectApi - axios parameter creator
 * @export
 */
export const MobileProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Поучение описания для мобильного приложения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileprojectGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mobileproject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение изображения мобильного приложения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileprojectImageGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mobileproject/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileProjectApi - functional programming interface
 * @export
 */
export const MobileProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Поучение описания для мобильного приложения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileprojectGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileprojectGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение изображения мобильного приложения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mobileprojectImageGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mobileprojectImageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MobileProjectApi - factory interface
 * @export
 */
export const MobileProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileProjectApiFp(configuration)
    return {
        /**
         * 
         * @summary Поучение описания для мобильного приложения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileprojectGet(options?: any): AxiosPromise<void> {
            return localVarFp.mobileprojectGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение изображения мобильного приложения
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mobileprojectImageGet(options?: any): AxiosPromise<void> {
            return localVarFp.mobileprojectImageGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileProjectApi - interface
 * @export
 * @interface MobileProjectApi
 */
export interface MobileProjectApiInterface {
    /**
     * 
     * @summary Поучение описания для мобильного приложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProjectApiInterface
     */
    mobileprojectGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary получение изображения мобильного приложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProjectApiInterface
     */
    mobileprojectImageGet(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * MobileProjectApi - object-oriented interface
 * @export
 * @class MobileProjectApi
 * @extends {BaseAPI}
 */
export class MobileProjectApi extends BaseAPI implements MobileProjectApiInterface {
    /**
     * 
     * @summary Поучение описания для мобильного приложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProjectApi
     */
    public mobileprojectGet(options?: AxiosRequestConfig) {
        return MobileProjectApiFp(this.configuration).mobileprojectGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение изображения мобильного приложения
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileProjectApi
     */
    public mobileprojectImageGet(options?: AxiosRequestConfig) {
        return MobileProjectApiFp(this.configuration).mobileprojectImageGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary метод получения файла прикрепленного к новости
         * @param {string} id id файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsFileIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsFileIdGet', 'id', id)
            const localVarPath = `/news/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение списка новостей упорядоченных от новых к поздним
         * @param {NewsTypes} [type] тип новости:&lt;br /&gt;              0 - обычные новости&lt;br /&gt;              1 - новости баннера&lt;br /&gt;
         * @param {Array<string>} [rubrics] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsGet: async (type?: NewsTypes, rubrics?: Array<string>, skip?: number, take?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (rubrics) {
                localVarQueryParameter['rubrics'] = rubrics;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsIdGet', 'id', id)
            const localVarPath = `/news/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary метод получения для краулеров
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdStaticGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsIdStaticGet', 'id', id)
            const localVarPath = `/news/{id}/static`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение обрезанной картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdCroppedimageGet: async (newsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('newsNewsIdCroppedimageGet', 'newsId', newsId)
            const localVarPath = `/news/{newsId}/croppedimage`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdImageGet: async (newsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('newsNewsIdImageGet', 'newsId', newsId)
            const localVarPath = `/news/{newsId}/image`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary получение превью картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdPreviewimageGet: async (newsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newsId' is not null or undefined
            assertParamExists('newsNewsIdPreviewimageGet', 'newsId', newsId)
            const localVarPath = `/news/{newsId}/previewimage`
                .replace(`{${"newsId"}}`, encodeURIComponent(String(newsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary метод получения фото прикрепленного к новости
         * @param {string} id id фото
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPhotoIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsPhotoIdGet', 'id', id)
            const localVarPath = `/news/photo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary метод получения файла прикрепленного к новости
         * @param {string} id id файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsFileIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsFileIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение списка новостей упорядоченных от новых к поздним
         * @param {NewsTypes} [type] тип новости:&lt;br /&gt;              0 - обычные новости&lt;br /&gt;              1 - новости баннера&lt;br /&gt;
         * @param {Array<string>} [rubrics] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsGet(type?: NewsTypes, rubrics?: Array<string>, skip?: number, take?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsGetListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsGet(type, rubrics, skip, take, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsGetNewsItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary метод получения для краулеров
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsIdStaticGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsIdStaticGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение обрезанной картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsNewsIdCroppedimageGet(newsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsNewsIdCroppedimageGet(newsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsNewsIdImageGet(newsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsNewsIdImageGet(newsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary получение превью картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsNewsIdPreviewimageGet(newsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsNewsIdPreviewimageGet(newsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary метод получения фото прикрепленного к новости
         * @param {string} id id фото
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsPhotoIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsPhotoIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsApiFp(configuration)
    return {
        /**
         * 
         * @summary метод получения файла прикрепленного к новости
         * @param {string} id id файла
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsFileIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.newsFileIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение списка новостей упорядоченных от новых к поздним
         * @param {NewsTypes} [type] тип новости:&lt;br /&gt;              0 - обычные новости&lt;br /&gt;              1 - новости баннера&lt;br /&gt;
         * @param {Array<string>} [rubrics] 
         * @param {number} [skip] 
         * @param {number} [take] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsGet(type?: NewsTypes, rubrics?: Array<string>, skip?: number, take?: number, options?: any): AxiosPromise<NewsGetListResponse> {
            return localVarFp.newsGet(type, rubrics, skip, take, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение новости
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdGet(id: string, options?: any): AxiosPromise<NewsGetNewsItem> {
            return localVarFp.newsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary метод получения для краулеров
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdStaticGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.newsIdStaticGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение обрезанной картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdCroppedimageGet(newsId: string, options?: any): AxiosPromise<string> {
            return localVarFp.newsNewsIdCroppedimageGet(newsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdImageGet(newsId: string, options?: any): AxiosPromise<string> {
            return localVarFp.newsNewsIdImageGet(newsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary получение превью картинки новости
         * @param {string} newsId id новости
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsNewsIdPreviewimageGet(newsId: string, options?: any): AxiosPromise<string> {
            return localVarFp.newsNewsIdPreviewimageGet(newsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary метод получения фото прикрепленного к новости
         * @param {string} id id фото
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPhotoIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.newsPhotoIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsApi - interface
 * @export
 * @interface NewsApi
 */
export interface NewsApiInterface {
    /**
     * 
     * @summary метод получения файла прикрепленного к новости
     * @param {string} id id файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsFileIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary получение списка новостей упорядоченных от новых к поздним
     * @param {NewsTypes} [type] тип новости:&lt;br /&gt;              0 - обычные новости&lt;br /&gt;              1 - новости баннера&lt;br /&gt;
     * @param {Array<string>} [rubrics] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsGet(type?: NewsTypes, rubrics?: Array<string>, skip?: number, take?: number, options?: AxiosRequestConfig): AxiosPromise<NewsGetListResponse>;

    /**
     * 
     * @summary получение новости
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<NewsGetNewsItem>;

    /**
     * 
     * @summary метод получения для краулеров
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsIdStaticGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary получение обрезанной картинки новости
     * @param {string} newsId id новости
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsNewsIdCroppedimageGet(newsId: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary получение картинки новости
     * @param {string} newsId id новости
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsNewsIdImageGet(newsId: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary получение превью картинки новости
     * @param {string} newsId id новости
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsNewsIdPreviewimageGet(newsId: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * 
     * @summary метод получения фото прикрепленного к новости
     * @param {string} id id фото
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    newsPhotoIdGet(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI implements NewsApiInterface {
    /**
     * 
     * @summary метод получения файла прикрепленного к новости
     * @param {string} id id файла
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsFileIdGet(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsFileIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение списка новостей упорядоченных от новых к поздним
     * @param {NewsTypes} [type] тип новости:&lt;br /&gt;              0 - обычные новости&lt;br /&gt;              1 - новости баннера&lt;br /&gt;
     * @param {Array<string>} [rubrics] 
     * @param {number} [skip] 
     * @param {number} [take] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsGet(type?: NewsTypes, rubrics?: Array<string>, skip?: number, take?: number, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsGet(type, rubrics, skip, take, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение новости
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsIdGet(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary метод получения для краулеров
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsIdStaticGet(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsIdStaticGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение обрезанной картинки новости
     * @param {string} newsId id новости
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsNewsIdCroppedimageGet(newsId: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsNewsIdCroppedimageGet(newsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение картинки новости
     * @param {string} newsId id новости
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsNewsIdImageGet(newsId: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsNewsIdImageGet(newsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary получение превью картинки новости
     * @param {string} newsId id новости
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsNewsIdPreviewimageGet(newsId: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsNewsIdPreviewimageGet(newsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary метод получения фото прикрепленного к новости
     * @param {string} id id фото
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsPhotoIdGet(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsPhotoIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsRubricsApi - axios parameter creator
 * @export
 */
export const NewsRubricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary получения списка рубрик новостей, упорядоченных по названию
         * @param {string} [id] необязательный, для получения единственной рубрики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsRubricListGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news/rubric/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsRubricsApi - functional programming interface
 * @export
 */
export const NewsRubricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsRubricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary получения списка рубрик новостей, упорядоченных по названию
         * @param {string} [id] необязательный, для получения единственной рубрики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsRubricListGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsRubricGetList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsRubricListGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsRubricsApi - factory interface
 * @export
 */
export const NewsRubricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsRubricsApiFp(configuration)
    return {
        /**
         * 
         * @summary получения списка рубрик новостей, упорядоченных по названию
         * @param {string} [id] необязательный, для получения единственной рубрики
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsRubricListGet(id?: string, options?: any): AxiosPromise<Array<NewsRubricGetList>> {
            return localVarFp.newsRubricListGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsRubricsApi - interface
 * @export
 * @interface NewsRubricsApi
 */
export interface NewsRubricsApiInterface {
    /**
     * 
     * @summary получения списка рубрик новостей, упорядоченных по названию
     * @param {string} [id] необязательный, для получения единственной рубрики
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsRubricsApiInterface
     */
    newsRubricListGet(id?: string, options?: AxiosRequestConfig): AxiosPromise<Array<NewsRubricGetList>>;

}

/**
 * NewsRubricsApi - object-oriented interface
 * @export
 * @class NewsRubricsApi
 * @extends {BaseAPI}
 */
export class NewsRubricsApi extends BaseAPI implements NewsRubricsApiInterface {
    /**
     * 
     * @summary получения списка рубрик новостей, упорядоченных по названию
     * @param {string} [id] необязательный, для получения единственной рубрики
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsRubricsApi
     */
    public newsRubricListGet(id?: string, options?: AxiosRequestConfig) {
        return NewsRubricsApiFp(this.configuration).newsRubricListGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectDescriptionApi - axios parameter creator
 * @export
 */
export const ProjectDescriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary получение описания проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectdescriptionContentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projectdescription/content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectDescriptionApi - functional programming interface
 * @export
 */
export const ProjectDescriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectDescriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary получение описания проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectdescriptionContentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectdescriptionContentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectDescriptionApi - factory interface
 * @export
 */
export const ProjectDescriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectDescriptionApiFp(configuration)
    return {
        /**
         * 
         * @summary получение описания проекта
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectdescriptionContentGet(options?: any): AxiosPromise<string> {
            return localVarFp.projectdescriptionContentGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectDescriptionApi - interface
 * @export
 * @interface ProjectDescriptionApi
 */
export interface ProjectDescriptionApiInterface {
    /**
     * 
     * @summary получение описания проекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDescriptionApiInterface
     */
    projectdescriptionContentGet(options?: AxiosRequestConfig): AxiosPromise<string>;

}

/**
 * ProjectDescriptionApi - object-oriented interface
 * @export
 * @class ProjectDescriptionApi
 * @extends {BaseAPI}
 */
export class ProjectDescriptionApi extends BaseAPI implements ProjectDescriptionApiInterface {
    /**
     * 
     * @summary получение описания проекта
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDescriptionApi
     */
    public projectdescriptionContentGet(options?: AxiosRequestConfig) {
        return ProjectDescriptionApiFp(this.configuration).projectdescriptionContentGet(options).then((request) => request(this.axios, this.basePath));
    }
}


