import React from "react";
import "./index.scss";

// type = primary | danger | sub | disabled

const Button = (props) => {
  const { title, onClick, type, disabled = false, iconRight = null } = props;

  return (
    <button className={"Button " + type} onClick={onClick} disabled={disabled}>
      {title} {iconRight}
    </button>
  );
};

export default Button;
