import { makeAutoObservable } from "mobx";

class BigLoader {
  active = false;
  delay = null;

  turnOn = () => {
    this.delay = setTimeout(() => (this.active = true), 500);
  };

  turnOff = () => {
    clearTimeout(this.delay);
    this.active = false;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new BigLoader();
