import axios from "axios";
import { makeAutoObservable } from "mobx";

import { BASE_URL } from "../constants/api";

export class MobileAppStore {
  text: string = "";
  title: string = "";
  isShown: boolean = true;

  constructor() {
    makeAutoObservable(this);
    this.checkIsShown();
  }

  checkIsShown = () =>
    sessionStorage.getItem("MobileApp") && this.changeShown();

  getText = async () => {
    await axios.get(BASE_URL + "news/api/mobileproject").then((res) => {
      const { title, content } = res.data;
      this.text = content;
      this.title = title;
    });
  };

  changeShown = () => {
    this.isShown = false;
  };

  reset = () => {
    this.text = "";
    this.title = "";
  };
}

export default new MobileAppStore();
