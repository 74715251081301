import React from "react";
import { observer } from "mobx-react";

import AboutQuestionItem from "../AboutQuestionItem";

const AboutQuestionComponentsList = observer((props) => {
  const { options } = props;

  return (
    <div className="questions">
      {options &&
        options.map((elem, idx) => {
          return <AboutQuestionItem elem={elem} key={idx} />;
        })}
    </div>
  );
});

export default AboutQuestionComponentsList;
