import React from "react";
import { observer } from "mobx-react";

import "./index.scss";

const AnswerComponent = ({ answer }) => {
  return (
    <div className="answer">
      <span className="answer__title">Ответ</span>
      <div dangerouslySetInnerHTML={{ __html: answer && answer.answer }}></div>
    </div>
  );
};

export default observer(AnswerComponent);
