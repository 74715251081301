import axios from "axios";
import { makeAutoObservable } from "mobx";
import { AISAE, IDP } from "../constants/api";

class User {
  isAuth = false;
  visualPersentage = [];
  id = null;
  firstName = "";
  lastName = "";
  middleName = "";
  email = null;
  birthDate = null;
  phones = {
    mobileNumber: null,
  };
  snils = null;
  gender = null;
  isGeolocationAllowed = null;
  livingAddress = {
    address: null,
    coordinates: {
      latitude: null,
      longitude: null,
    },
  };
  registrationAddress = {
    address: null,
    coordinates: {
      latitude: null,
      longitude: null,
    },
  };
  hasPhoto = null;
  idp = 0;
  location = {
    latitude: null,
    longitude: null,
  };
  hasLocation = null;
  fields = [];
  currentPoints = null;
  isHaveEskCard = false;
  photo = null;
  imageSrc = null;
  imageLoadCount = 0;

  setImageSrc = () => {
    if (this.hasPhoto) {
      this.imageSrc = AISAE + "api/keycloak/photo?t=" + this.imageLoadCount;
      setTimeout(() => {
        this.imageLoadCount = this.imageLoadCount + 1;
      }, 500);
    } else {
      this.imageSrc = null;
    }
  };

  setAuth = (bool) => {
    this.isAuth = bool;
  };

  checkAuth = async () => {
    // return true;
    try {
      const test = await fetch(AISAE + "api/keycloak/me");

      if (test.status === 200) return true;

      return false;
    } catch (error) {
      return false;
    }
  };

  loadUser = async () => {
    //Здесь можно эмулировать авторизацию
    // this.setAuth(true);
    // return;
    try {
      const ok = await this.checkAuth();
      if (ok) {
        const res = await fetch(AISAE + "api/keycloak/profile");
        const data = await res.json();

        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.middleName = data.middleName && data.middleName;
        this.email = data.email;
        this.birthDate = data.birthDate;
        this.phone = data.phones;
        this.snils = data.snils;
        this.gender = data.gender;
        this.livingAddress = data.livingAddress;
        this.registrationAddress = data.registrationAddress;
        this.hasPhoto = data.hasPhoto;
        this.idp = data.idp;
        this.isGeolocationAllowed = data.isGeolocationAllowed;
        this.isHaveEskCard = data.hasCard;
        this.currentPoints = data.points;

        this.setAuth(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  setLocation = async (coords) => {
    try {
      if (!coords || !coords?.longitude || !coords?.latitude)
        throw new Error(`Can't identify coords`);

      this.location = coords;

      await fetch(AISAE + "api/keycloak/geoloc/set", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(coords),
      });

      this.refreshIdp();
    } catch (error) {
      console.log(error);
    }
  };

  getFieldsIdp = () => {
    if (this.id) {
      axios
        .get(IDP + "fields/" + this.id)
        .then(({ data }) => {
          if (data) {
            this.fields = data.fields;
          }
        })
        .catch((error) => console.error(error));
    }
  };

  setHasLocation = (bool) => {
    if (typeof bool !== "boolean") {
      throw new TypeError("Wrong type given, expected a boolean");
    }

    this.hasLocation = bool;
  };

  deleteLocation = async () => {
    this.hasLocation = false;
    this.location = {
      latitude: null,
      longitude: null,
    };

    try {
      await fetch(AISAE + "api/keycloak/geoloc/delete", {
        method: "POST",
      });
      this.refreshIdp();
    } catch (error) {}
  };

  refreshIdp = async () => {
    try {
      const res = await fetch(IDP + "byid/" + this.id);
      const data = await res.json();

      this.idp = data.idp;
    } catch (error) {
      console.log(error);
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new User();
// ONLY FOR TEST
export { User as TestUser };
