import React, { useState } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { IPointsHistoryTabs } from "./types";
import "./styles.scss";

const PointsHsitoryTabs: React.FC<IPointsHistoryTabs> = ({ list = [] }) => {
  const [currentType, setCurrentType] = useState("all");

  return (
    <div className="pointsHistoryTabs">
      {list.map((item, idx) => {
        const { text, type, onChange } = item;
        const isCurrentTab = currentType === type;
        return (
          <button
            key={idx}
            className={cn(
              "pointsHistoryTabs__item",
              isCurrentTab && "pointsHistoryTabs__item-active"
            )}
            onClick={() => {
              onChange();
              setCurrentType(type);
            }}
          >
            {text}
          </button>
        );
      })}
    </div>
  );
};

export default observer(PointsHsitoryTabs);
