import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useRef } from "react";

import "./index.scss";

interface IInfinityScrollProps {
  isRequestMore: boolean;
  request: () => void;
}

const InfinityScroll: FC<IInfinityScrollProps> = ({
  isRequestMore,
  request,
  children,
}) => {
  const endPoint = useRef(null);

  useEffect(() => {
    const options = {
      rootMargin: "0px",
      threshold: 1.0,
    };
    const callback = function (entries: any) {
      if (entries[0].isIntersecting && isRequestMore) {
        request();
      }
    };
    const observer = new IntersectionObserver(callback, options);

    if (endPoint.current) observer.observe(endPoint.current);
    return () => observer.disconnect();
  }, [request, isRequestMore]);
  return (
    <div className="infinity-scroll">
      {children}
      <div ref={endPoint} className="infinity-scroll__end-point"></div>
    </div>
  );
};

export default observer(InfinityScroll);
