export const URL_HOME = "/";
export const URL_ABOUT = "/about";

export const URL_VOTES = "/votes";
export const URL_VOTE_ID = (id?: string) => {
  return id ? URL_VOTES + `/${id}` : URL_VOTES + "/:id";
};
export const URL_COMPLETED_VOTE_ID = (id?: string) => {
  return id ? `/completed-vote/${id}` : "/completed-vote/:id";
};

export const URL_NEWS = "/news";
export const URL_NEWS_ID = (id?: string) => {
  return id ? URL_NEWS + `/${id}` : URL_NEWS + "/:id";
};

export const URL_QUARTERLY = "/quarterly";

export const URL_VIOLATIONS = "/violations";
export const URL_VIOLATION_ID = (id?: string) => {
  return id ? URL_VIOLATIONS + `/${id}` : URL_VIOLATIONS + "/:id";
};

export const URL_CONDITIONS = "/documents/termsOfParticipation";
export const URL_AGREEMENT = "/documents/userAgreement";
export const URL_POLICY = "/documents/personalDataPolicy";

export const URL_PROFILE = "/profile";
export const URL_PROFILE_INFO = `${URL_PROFILE}/data`;
export const URL_PROFILE_EDIT = `${URL_PROFILE}/edit`;
export const URL_PROFILE_VIOLATIONS = `${URL_PROFILE}/statements`;

export const URL_PROFILE_VIOLATIONS_ID = (id?: string) => {
  return id
    ? URL_PROFILE_VIOLATIONS + `/${id}`
    : URL_PROFILE_VIOLATIONS + "/:id";
};

export const URL_PROFILE_SETTINGS = `${URL_PROFILE}/settings`;
export const URL_PROFILE_NOTIFICATIONS = `${URL_PROFILE}/notifications`;
export const URL_PROFILE_NOTIFICATIONS_ID = (id?: string) => {
  return id
    ? URL_PROFILE_NOTIFICATIONS + `/${id}`
    : URL_PROFILE_NOTIFICATIONS + "/:id";
};

export const URL_PROFILE_POINTS = `${URL_PROFILE}/points`;
export const URL_PROFILE_PURCHASES = `${URL_PROFILE}/purchases`;
export const URL_PROFILE_SHOP = `${URL_PROFILE}/shop`;
export const URL_PROFILE_PURCHASES_ID = (id?: string) => {
  return id
    ? URL_PROFILE_PURCHASES + `/${id}`
    : URL_PROFILE_PURCHASES + "/:purchaseId";
};

export const URL_SHOP = `/shop`;
export const URL_SHOP_ID = (id?: string) => {
  return id ? URL_SHOP + `/${id}` : URL_SHOP + "/:id";
};
