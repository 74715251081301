import { makeAutoObservable, runInAction } from "mobx";
import { API } from "../../../api";

export interface IPurchasesItem {
  id: string | null;
  productId: string | null;
  title: string;
  imageId: string | null;
  isActive: boolean;
  purchaseDate: string | null;
}

class MyPurchasesStore {
  purchasesList: IPurchasesItem[] = [];
  userId: string = "";
  isLoading: boolean = false;
  itemsPerRequest: number = 10;
  currentItemsPerRequest: number = 10;
  skipItem?: number = undefined;
  purchaseType?: boolean = undefined;
  allItemsCount: number | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.getPurchasesList(undefined, undefined, this.itemsPerRequest);
  };

  setTransactionType = (type: boolean | undefined) => {
    this.purchaseType = type;
    this.purchasesList = [];
  };

  get isRequestMore() {
    return this.allItemsCount
      ? this.allItemsCount > this.purchasesList.length
      : false;
  }

  getPurchaseMore = () => {
    this.getPurchasesList(
      this.purchaseType,
      this.purchasesList.length,
      this.currentItemsPerRequest
    );
  };

  findDOMItem = async (purchaseId: string) => {
    if (this.allItemsCount && this.purchasesList.length >= this.allItemsCount)
      return false;

    let matchedItem: Element | null = null;

    await this.getPurchasesList(
      undefined,
      this.purchasesList.length,
      this.itemsPerRequest
    );
    // TODO: Попробовать вынести обращение к ДОМ или изменить способ получения компонента
    const purchasesItems = Array.from(
      document.getElementsByClassName("purchases-item")
    );

    purchasesItems.forEach((el) => {
      if (el.id === purchaseId) {
        matchedItem = el;
      }
    });

    if (matchedItem) {
      return matchedItem;
    }
  };

  getPurchasesList = async (
    IsActive?: boolean,
    skip?: number,
    itemsPerRequest?: number
  ) => {
    if (!this.purchasesList.length) {
      this.isLoading = true;
    }

    return API.shop.Purchases.purchasesGet(IsActive, skip, itemsPerRequest)
      .then(({ data }) => {
        runInAction(() => {
          this.allItemsCount = data.count ?? null;
          if (data.items) {
            const newItems = data.items.map((item) => ({
              id: item.id || null,
              productId: item.productId || null,
              title: item.title || "Название отсутствует",
              imageId: item.imageId || null,
              isActive: item.isActive || false,
              purchaseDate: item.purchaseDate || null,
            }));
            this.purchasesList = [...this.purchasesList, ...newItems];
          }
        });
      })
      .catch((e) => {
        throw new Error(e);
      })
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  };

  setUserId = (id: string) => {
    this.userId = id;
  };

  reset = () => {
    this.purchasesList = [];
    this.userId = "";
    this.isLoading = false;
    this.currentItemsPerRequest = 10;
    this.itemsPerRequest = 10;
    this.purchaseType = undefined;
  };
}

export default new MyPurchasesStore();
