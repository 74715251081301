import React, { FC } from "react";
import "./index.scss";

const DummyPhoto: FC = () => {
  return (
    <div className="dummy-photo">
      <p className="dummy-photo__title">
        Фото
        <br />
        отсутствует
      </p>
    </div>
  );
};

export default DummyPhoto;
