import axios from "axios";
import { AISAE } from "./aisae";
import { NEWS } from "./news";
import { SHOP } from "./shop";

export const API = {
  aisae: AISAE,
  shop: SHOP,
  news: NEWS,
};

export const config = undefined;

export const axiosInstance = axios.create();
