import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router";
import AppWrapper from "./Main/AppWrapper";
import NewApp from "./Project/App";
import { newProjectRoutes } from "./Project/routes";

const Splitter = () => {
  const { pathname } = useLocation();
  const isUseNewRoute: boolean = !!newProjectRoutes.find(
    (route) => route.path === pathname
  );
  return (

      isUseNewRoute ? <NewApp /> : <AppWrapper />
  );
};

export default observer(Splitter);
