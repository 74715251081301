import { makeAutoObservable } from "mobx";
import { AISAE } from "../constants/api";

class Settings {
  settings = {
    news: [
      {
        id: "28734ce2-76cd-4353-9cbf-f208bafecf3f",
        text: "Вкл.",
        checked: false,
      },
      {
        id: "28734ce2-76cd-4353-9cbf-f208bafecf3f",
        text: "Выкл.",
        checked: true,
      },
    ],
    events: [
      {
        id: "503e7b03-3e7a-4d42-b1fa-bce8bd3afd4e",
        text: "Сообщения",
        checked: false,
      },
      {
        id: "55b571af-5042-43d9-850e-69e632161621",
        text: "Голосование",
        checked: false,
      },
      {
        id: "e6bfd904-642b-45a1-affc-f9d4f4e9a71c",
        text: "Новости",
        checked: false,
      },
      {
        id: "e7bfd904-642b-45a1-affc-f9d4f4e9a71c",
        text: "Мои баллы",
        checked: false,
      },
    ],
  };

  fetchSettigns = async () => {
    try {
      const res = await fetch(AISAE + "api/keycloak/notifications/settings");

      if (!res.ok) throw new Error();

      const body = await res.json();

      this.settings = body;
    } catch (error) {
      console.error("fetchSettigns");
      console.log(error);
    }
  };

  changeRadioButton = (key, idx) => {
    this.settings[key] = this.settings[key].map((option, currentIndex) => {
      if (currentIndex === idx) {
        return {
          ...option,
          checked: true,
        };
      } else {
        return {
          ...option,
          checked: false,
        };
      }
    });

    if (!this.settings.news[0].checked) {
      this.resetCheckboxes();
    }

    this.updateSettings();
  };

  changeCheckBox = (key, idx) => {
    if (typeof this.settings[key][idx].checked === "boolean")
      this.settings[key][idx].checked = !this.settings[key][idx].checked;

    if (
      key === "events" &&
      this.settings.events.some((checkbox) => checkbox.checked)
    ) {
      this.settings.news[0].checked = true;
      this.settings.news[1].checked = false;
    }

    this.updateSettings();
  };

  updateSettings = async () => {
    try {
      const news = this.settings.news[0].checked;
      const violations = this.settings.events[0].checked;
      const votes = this.settings.events[1].checked;
      const newsChanges = this.settings.events[2].checked;
      const points = this.settings.events[3].checked;

      const body = JSON.stringify({
        news,
        violations,
        votes,
        newsChanges,
        points,
      });

      const res = await fetch(AISAE + "api/keycloak/notifications/settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });

      if (!res.ok) throw new Error();
    } catch (error) {
      console.log(error);
    }
  };

  resetCheckboxes = () => {
    this.settings.events.forEach((checkbox) => (checkbox.checked = false));
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default new Settings();
