import { monthsGenitive } from "../constants/months";

const getDateAndTime = (date) => {
  const parsed = Date.parse(date);
  
  const d = new Date(parsed).getDate();
  const m = new Date(parsed).getMonth();

  const h = new Date(parsed).getHours();
  const min = new Date(parsed).getMinutes();

  return {
    dm: d + " " + monthsGenitive[m],
    time: (h < 10 ? '0' + h : h) + ":" + (min < 10 ? '0' + min : min),
  };
};

export default getDateAndTime;
