import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AuthWarning from "../../../Common/Components/AuthWarning";
import Button from "../../../Common/Components/Button";
import { AISAE, BASE_URL } from "../../../constants/api";
import ErrorComponent from "../../../Project/components/ErrorComponent";
import {
  URL_COMPLETED_VOTE_ID,
  URL_PROFILE_INFO,
  URL_VOTES,
} from "../../../Project/routes/paths";
import User from "../../../stores/user";
import votesStore from "../../../stores/votes";
import normalizeDate from "../../../utils/normalizeDate";
import inside from "../../../utils/validation/insidePoly";
import FileViewer from "../../Components/FileViewer";
import LoaderSpinner from "../../Components/Loader";
import Map from "../../Components/Map";
import QuestionsComponent from "../../Components/QuestionsComponent";
import "./index.scss";
import { VoteFooter } from "./VoteFooter/index";
import VoteFiles from "./voteFiles";

const VoteComponent = inject("history")(
  observer((props) => {
    const { history } = props;
    const { pathname } = history.location;
    const { isAuth, location, hasLocation } = User;
    const { id } = useParams();

    const {
      fetchVote,
      vote,
      errorStatus,
      resetStatuses,
      isValidQuestion,
      voteSuccess,
      fetchComletedVote,
      isLoading,
    } = votesStore;

    const {
      questions,
      title,
      description,
      status,
      endDateTime,
      startDateTime,
      idp,
      fileId,
      croppedFileId,
      coordinates,
      isReadOnly,
      isGeo,
      isSuitableForIdp,
      isSuitableForLivingAddress,
      isSuitableForRegistrationAddress,
      isSuitableForGender,
      isSuitableForAge,
      isNotInRegion,
      isDone,
    } = vote;

    useEffect(() => {
      fetchVote(id, location);
      resetStatuses();
      return () => votesStore.resetVote();
    }, []);

    useEffect(() => {
      if (voteSuccess || isDone) {
        fetchComletedVote(id);
        history.push(URL_COMPLETED_VOTE_ID(id));
      }
    }, [fetchComletedVote, id, voteSuccess, isDone]);

    const [isOpen, setIsOpen] = useState(false);

    const handleShowDialog = () => {
      setIsOpen(!isOpen);
      const veil = document.getElementsByClassName("veil");
      if (veil && !isOpen) {
        veil[0].style.display = "block";
      }
      if (veil && isOpen) {
        veil[0].style.display = "none";
      }
    };

    const goToProfileData = () => {
      history.push(URL_PROFILE_INFO);
    };

    const pastNormalizeDate = startDateTime && normalizeDate(startDateTime);
    const futureNormalizedDate = endDateTime && normalizeDate(endDateTime);

    const futureDate = Date.parse(endDateTime);
    const now = new Date().getTime();
    const pastDate = Date.parse(startDateTime);
    const delta = futureDate - pastDate;
    const passedTime = now - pastDate >= 0 ? now - pastDate : 0;
    const resPercent = Math.floor((passedTime * 100) / delta);

    const { geoCenter, polygon } = geo(coordinates);

    if (!!isLoading) return <LoaderSpinner />;

    return !errorStatus ? (
      <div className="vote-component component">
        <div className="header">
          <h1>{title && title}</h1>
          {vote.numberOfPoints > 0 && (
            <p className="vote-component__balls">
              {vote.numberOfPoints} баллов
            </p>
          )}
        </div>
        <div className="tile-date">
          <div
            className={
              resPercent > 75
                ? "line close"
                : 50 < resPercent
                ? "line middle"
                : "line green"
            }
            style={{ width: `${resPercent >= 100 ? 100 : resPercent}%` }}
          />
          <div className="default-line" />
          <span className="tile-date-start">{pastNormalizeDate}</span>
          <span className="tile-date-end">{futureNormalizedDate}</span>
        </div>
        {votesStore.voteSuccess ? (
          <>
            <span className="sucsess">Голосование успешно завершено</span>
            <Link className="to button back" to={URL_VOTES}>
              <span>К списку голосований</span>
            </Link>
          </>
        ) : (
          <>
            <div className="desc-section">
              <div className="image-text">
                {fileId && (
                  <div
                    className={"image-wrapper"}
                    style={{
                      backgroundImage: `url(${AISAE}api/file/${croppedFileId})`,
                    }}
                  >
                    <button
                      onClick={handleShowDialog}
                      className={"imageScaleButton"}
                    ></button>
                  </div>
                )}
                {isOpen && (
                  <FileViewer
                    files={[
                      {
                        type: "image",
                        reference: fileId,
                      },
                    ]}
                    startSlide={0}
                    setShowFiles={handleShowDialog}
                    url={`${AISAE}api/file/`}
                  />
                )}
                <div>
                  {description && (
                    <p className="desc-section__text">{description}</p>
                  )}
                  <div className="vote-component__files">
                    {vote.filesInfo?.length !== 0 &&
                      vote.filesInfo?.map((file) => {
                        return (
                          <VoteFiles
                            type={file?.type}
                            reference={file?.id}
                            name={file.name}
                            size={file.size}
                            section={"main"}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
              {coordinates &&
                geoCenter &&
                (isNotInRegion ||
                  !isSuitableForLivingAddress ||
                  !isSuitableForRegistrationAddress) && (
                  <div className="vote-map">
                    <Map
                      marker={isGeo ? location : null}
                      polygon={polygon}
                      center={geoCenter}
                    />
                    {isGeo && (
                      <div
                        className={`vote-map__warning ${
                          !hasLocation && isAuth ? "active" : ""
                        }`}
                      >
                        Разрешите доступ к геопозиции чтобы голосовать
                        <Button
                          type="sub"
                          title="Разрешить"
                          onClick={votesStore.getGeo}
                        />
                      </div>
                    )}
                  </div>
                )}
            </div>
            {isReadOnly && isAuth ? (
              <>
                {!isSuitableForGender && (
                  <p className="not-allowed">
                    Извините, Вы не можете проголосовать, так как данные Вашего
                    профиля не соответствуют заданной категории голосующих
                    ("пол").
                  </p>
                )}
                {!isSuitableForAge && (
                  <p className="not-allowed">
                    Извините, Вы не можете проголосовать, так как Ваш возраст не
                    соответствует заданной категории голосующих.
                  </p>
                )}
                {(!isSuitableForLivingAddress ||
                  !isSuitableForRegistrationAddress) && (
                  <p className="not-allowed">
                    Извините, Вы не можете проголосовать, так как Ваш адрес
                    регистрации/проживания не соответствует заданному
                    территориальному признаку голосующих
                  </p>
                )}
                {!isSuitableForIdp && (
                  <p className="not-allowed">
                    Извините, вы не можете проголосовать, так как недостаточно
                    данных для участия в голосовании.
                    <br />
                    Для участия в голосовании заполненность профиля должна быть{" "}
                    {idp} %.
                  </p>
                )}
                {isNotInRegion && (
                  <p className="not-allowed">
                    Извините, Вы не можете проголосовать, так как Ваш адрес
                    геолокации не соответствует заданному территориальному
                    признаку голосующих
                  </p>
                )}
                {(!isSuitableForGender ||
                  !isSuitableForAge ||
                  !isSuitableForIdp ||
                  !isSuitableForLivingAddress ||
                  !isSuitableForRegistrationAddress) && (
                  <div className="goto-profile">
                    <Button
                      type="primary"
                      title="Заполнить данные профиля"
                      onClick={goToProfileData}
                    />
                  </div>
                )}
              </>
            ) : true ? (
              (!isGeo ||
                (isGeo &&
                  coordinates &&
                  hasLocation &&
                  polygon &&
                  inside(
                    [location.latitude, location.longitude],
                    polygon
                  ))) && (
                <div
                  className={
                    questions && questions.length > 1
                      ? "vote-items more"
                      : "vote-items"
                  }
                >
                  {status !== "Announcement" && status !== "Completed" && (
                    <>
                      <QuestionsComponent
                        questions={questions}
                        isValidQuestion={isValidQuestion}
                      />

                      <p className="not-valid">
                        {!isValidQuestion &&
                          "Пожалуйста, ответьте на обязательные вопросы"}
                      </p>
                      <div
                        className="button button_check"
                        onClick={votesStore.onVoteEnd}
                      >
                        <span>Завершить голосование</span>
                      </div>
                    </>
                  )}
                  <VoteFooter
                    url={BASE_URL + pathname.slice(1)}
                    title={title}
                    image={`${AISAE}api/file/${fileId}`}
                    description={description}
                  />
                </div>
              )
            ) : (
              <>
                <div className="vote-warning">
                  <AuthWarning warning="Для того чтобы участвовать в голосовании необходимо авторизоваться!" />
                </div>
                <VoteFooter
                  url={BASE_URL + pathname.slice(1)}
                  title={title}
                  image={`${AISAE}api/file/${fileId}`}
                  description={description}
                />
              </>
            )}
          </>
        )}
      </div>
    ) : (
      <ErrorComponent errorStatus={errorStatus} />
    );
  })
);

function geo(coordinates) {
  let x = 0;
  let y = 0;
  let polygon = coordinates ? [] : null;

  coordinates &&
    coordinates.forEach((coords, index) => {
      x += coords.latitude;
      y += coords.longitude;
      polygon[index] = [coords.latitude, coords.longitude];
    });

  const geoCenter = coordinates
    ? {
        latitude: x / coordinates.length,
        longitude: y / coordinates.length,
      }
    : null;
  return { geoCenter, polygon };
}

export default VoteComponent;
