import { makeAutoObservable, runInAction } from "mobx";

import { API } from "../api";
import { AISAE } from "../constants/api";
import fetch from "../utils/fetch";

class Categories {
  isLoadings = {
    getTag: false,
  };

  constructor() {
    this.value = "";
    this.categories = [];
    this.chosen = null;
    this.tag = "";
    this.tags = [];
    this.form2 = [];
    this.isHaveSubCategories = false;
    makeAutoObservable(this);
  }

  changeValue = (value) => {
    this.chosen = null;
    this.value = value;
  };

  submitCategory = async (id) => {
    this.form2 = [];

    this.chosen = this.categories.find((item) => item.id === id);
    this.value = this.chosen.name;
    const res = await fetch.get(
      AISAE + `api/categories/subcategories?categoryId=` + this.chosen.id
    );

    runInAction(() => {
      if (res.data.length === 0) {
        this.isHaveSubCategories = false;
        return;
      }
      this.isHaveSubCategories = true;
      this.form2.push(res.data);
    });
  };

  submitTagsCategory = (id, tag) => {
    this.form2 = [];
    this.chosen = this.categories.find((item) => item.id === id);
    this.value = tag.name;

    if (tag.children.length === 0) {
      this.loadNewList(tag.id);
      return;
    }

    this.recursive(tag.children);
  };

  loadNewList = async (id) => {
    const res = await fetch.get(
      AISAE + `api/categories/subcategories?categoryId=` + id
    );

    if (res.data.length === 0) {
      return;
    }
    runInAction(() => this.form2.push(res.data));
  };

  submitSubCategory = (value, name, type) => {
    if (this.form2[name].length !== 0) {
      this.form2[name].forEach((item) => (item.selected = false));
      this.form2[name].find((item) => item.id === value).selected = true;
    }

    const index = this.form2.findIndex((item) =>
      item.find((it) => it.id === value)
    );

    if (index > -1) {
      if (index < this.form2.length) {
        this.form2.splice(index + 1);
      }
    }

    if (value) {
      this.loadNewList(value);
    }
  };

  loadCategories = async () => {
    try {
      const { data, status } = await fetch.get(AISAE + "api/categories");

      if (status !== 200) throw new Error(`[Server]: can't load Categories`);
      runInAction(() => (this.categories = data));
    } catch (error) {
      console.error(error);
    }
  };

  getTags = (value) => {
    this.isLoadings.getTag = true;
    API.aisae.Categories.apiCategoriesSearchbytagSearchStringGet(value)
      .then(({ data }) => {
        runInAction(() => (this.tags = data || []));
      })
      .catch((e) => {
        runInAction(() => (this.tags = []));
      })
      .finally(() => {
        runInAction(() => {
          this.isLoadings.getTag = false;
        });
      });
  };

  changeTagValue = async (value) => {
    this.tag = value;
    if (this.tag) await this.getTags(this.tag);
  };

  submitTagCategory = async (id) => {
    this.form2 = [];
    this.tag = this.tags.find((item) => item.id === id).name;

    const res = await fetch.get(AISAE + `api/categories/${id}/selected`);

    runInAction(() => {
      if (res.data) {
        const array = res.data.map((item) => {
          item.selected = item.isSelected;

          return item;
        });
        const tag = array.find((item) => item.isSelected);
        this.submitTagsCategory(tag.id, tag);
      }
    });
  };

  recursive = (data) => {
    this.form2.push(
      data.map((item) => {
        item.selected = item.isSelected;
        item.value = item.name;
        return item;
      })
    );

    data.forEach((item, idx) => {
      if (item.isSelected) {
        if (item.children.length !== 0) {
          this.recursive(item.children);
        } else {
          this.loadNewList(item.id);
        }
      }
    });
  };

  reset = () => {
    this.value = "";
    this.categories = [];
    this.chosen = null;
    this.tag = "";
    this.tags = [];
  };

  get selectedCategoryId() {
    return this.categories?.find((i) => i.name)?.id || null;
  }
}

export default new Categories();
