import { makeAutoObservable, runInAction } from "mobx";

import { API } from "../../../../api";
import { IProductItem } from "../../../Shop/types";

class ProductStore {
  productItem: IProductItem = {
    id: "",
    title: "",
    imageId: null,
    croppedImageId: null,
    description: "",
    categories: [],
    price: null,
    isActive: false,
    organizationName: null,
    organizationAddress: null,
    organizationCoordinates: {
      latitude: 56.842517679070234,
      longitude: 60.549173355102546,
    },
  };
  isLoading: boolean = false;
  orderStatus: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  getProduct = async (id: string) => {
    this.isLoading = true;

    API.shop.Products.productsIdGet(id)
      .then(({ data }) => {
        runInAction(() => {
          this.productItem = {
            id: data.id || "apiProductsIdGet нет id",
            title: data.title || "Нет названия",
            imageId: data.imageId || null,
            croppedImageId: data.croppedImageId || null,
            description: data.description || "Нет описания",
            categories: data.categories || [],
            price: data.price || null,
            isActive: data.isActive || false,
            organizationAddress: data.organizationAddress,
            organizationName: data.organizationName,
            organizationCoordinates: data.organizationCoordinates,
          };
        });
      })
      .catch((e) => {
        throw new Error(e);
      })
      .finally(() => runInAction(() => (this.isLoading = false)));
  };

  buyProduct = async (id: string) => {
    runInAction(() => {
      this.isLoading = true;
    });

    return API.shop.Products.productsProductIdBuyPost(id).finally(() => {
      runInAction(() => {
        this.isLoading = false;
      });
    });
  };

  setOrderStatus = (value: string) => {
    this.orderStatus = value;
  };

  reset = () => {
    this.productItem = {
      id: "",
      title: "",
      imageId: null,
      croppedImageId: null,
      description: "",
      categories: [],
      price: null,
      isActive: false,
    };

    this.isLoading = false;
  };
}

export default new ProductStore();
